/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EAssetSource = {
    Unknown: 'Unknown',
    SystemSeeded: 'SystemSeeded',
    UserUploaded: 'UserUploaded',
    MediaOrbitGeneated: 'MediaOrbitGeneated',
    ServiceTicket: 'ServiceTicket'
} as const;
export type EAssetSource = typeof EAssetSource[keyof typeof EAssetSource];


export function EAssetSourceFromJSON(json: any): EAssetSource {
    return EAssetSourceFromJSONTyped(json, false);
}

export function EAssetSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): EAssetSource {
    return json as EAssetSource;
}

export function EAssetSourceToJSON(value?: EAssetSource | null): any {
    return value as any;
}

