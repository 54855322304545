/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EServiceTicketState } from './EServiceTicketState';
import {
    EServiceTicketStateFromJSON,
    EServiceTicketStateFromJSONTyped,
    EServiceTicketStateToJSON,
} from './EServiceTicketState';

/**
 * 
 * @export
 * @interface ServiceTicketReplyRequest
 */
export interface ServiceTicketReplyRequest {
    /**
     * 
     * @type {string}
     * @memberof ServiceTicketReplyRequest
     */
    message: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceTicketReplyRequest
     */
    assetIds: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceTicketReplyRequest
     */
    isInternal: boolean;
    /**
     * 
     * @type {EServiceTicketState}
     * @memberof ServiceTicketReplyRequest
     */
    newState: EServiceTicketState;
}

/**
 * Check if a given object implements the ServiceTicketReplyRequest interface.
 */
export function instanceOfServiceTicketReplyRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "message" in value;
    isInstance = isInstance && "assetIds" in value;
    isInstance = isInstance && "isInternal" in value;
    isInstance = isInstance && "newState" in value;

    return isInstance;
}

export function ServiceTicketReplyRequestFromJSON(json: any): ServiceTicketReplyRequest {
    return ServiceTicketReplyRequestFromJSONTyped(json, false);
}

export function ServiceTicketReplyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceTicketReplyRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': json['message'],
        'assetIds': json['assetIds'],
        'isInternal': json['isInternal'],
        'newState': EServiceTicketStateFromJSON(json['newState']),
    };
}

export function ServiceTicketReplyRequestToJSON(value?: ServiceTicketReplyRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': value.message,
        'assetIds': value.assetIds,
        'isInternal': value.isInternal,
        'newState': EServiceTicketStateToJSON(value.newState),
    };
}

