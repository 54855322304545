import { ControlledModal } from 'components/ControlledModal';
import { useState } from 'react';
import { AngleForm } from './AngleForm';
import { useGetAngle } from 'api/useAnglesApi';
import { useGetSections } from 'api/useSectionsApi';

interface AngleUpdateProps {
  id?: string;
  projectId: string;
  trigger?: React.ReactNode;
  isInitialOpen?: boolean;
  onClosed?: () => void;
}

export const AngleUpdate = ({
  id,
  projectId,
  trigger,
  isInitialOpen,
  onClosed,
}: AngleUpdateProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(isInitialOpen ?? false);

  const { data } = useGetAngle(id);
  const { data: sections } = useGetSections(projectId);

  if (!data || !sections) return null;

  const onModalStateChange = (state: boolean) => {
    setIsOpen(state);
    if (!state) {
      onClosed?.();
    }
  };

  return (
    <>
      {trigger ? (
        <div onClick={() => onModalStateChange(true)}>{trigger}</div>
      ) : null}
      <ControlledModal
        showModal={onModalStateChange}
        isOpen={isOpen}
        title="Opdater vinkel"
        closeOnOutsideClick
        hideActionBar
      >
        <AngleForm
          projectId={projectId}
          targetAngle={data}
          allSections={sections.data}
          onSuccess={() => onModalStateChange(false)}
          onCancel={() => onModalStateChange(false)}
          inDialog={true}
        />
      </ControlledModal>
    </>
  );
};
