/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EExternalIntegrationType = {
    Facebook: 'Facebook'
} as const;
export type EExternalIntegrationType = typeof EExternalIntegrationType[keyof typeof EExternalIntegrationType];


export function EExternalIntegrationTypeFromJSON(json: any): EExternalIntegrationType {
    return EExternalIntegrationTypeFromJSONTyped(json, false);
}

export function EExternalIntegrationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EExternalIntegrationType {
    return json as EExternalIntegrationType;
}

export function EExternalIntegrationTypeToJSON(value?: EExternalIntegrationType | null): any {
    return value as any;
}

