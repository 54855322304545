import { AssetSimpleResponse, EAssetType } from 'api/core';
import { AudioLinesIcon, CameraOffIcon } from 'lucide-react';

interface AssetPreviewCellProps {
  asset: AssetSimpleResponse;
}

export const AssetPreviewCell = ({ asset }: AssetPreviewCellProps) => {
  return (
    <div>
      {asset.type === EAssetType.Image || asset.previewUrl ? (
        <img
          className="h-6"
          src={asset.previewUrl || asset.url}
          alt={asset.originalFileName}
        />
      ) : asset.type === EAssetType.Audio ? (
        <AudioLinesIcon className="h-6" />
      ) : (
        <CameraOffIcon className="h-6" />
      )}
    </div>
  );
};
