import { useTableQueryState } from 'components/Table/useTableQueryState';
import { Page } from 'layouts/Admin/Page';
import { useGetTickets } from 'api/useServiceTicketApi';
import { ServiceTicketsTable } from 'pages/Organization/ServiceTicketsTable';
import { useState } from 'react';

export const ServiceTicketAdminView = () => {
  const [showArchived, setShowArchived] = useState(false);

  return (
    <Page
      title={'Alle supportsager'}
      breadcrumbs={[{ name: 'Administration' }, { name: 'Alle supportsager' }]}
    >
      <div className="flex justify-start py-2 pb-3 w-full">
        <div className="ml-auto">
          <label className="cursor-pointer label">
            <span className="label-text me-2">Vis Færdige</span>
            <input
              type="checkbox"
              id="showArchived"
              name="showArchived"
              checked={showArchived}
              onChange={() => setShowArchived(!showArchived)}
              className="toggle toggle-primary"
            />
          </label>
        </div>
      </div>
      <TableWrapper
        includeActive={!showArchived}
        includeArchived={showArchived}
      />
    </Page>
  );
};

interface TableWrapperProps {
  includeActive: boolean;
  includeArchived: boolean;
}

const TableWrapper = ({
  includeActive,
  includeArchived,
}: TableWrapperProps) => {
  const { state } = useTableQueryState();

  const expectedFilter = JSON.stringify({
    includeActive: includeActive,
    includeArchived: includeArchived,
  });

  if (state.customState != expectedFilter) {
    state.setCustomState(expectedFilter);
  }

  const { data: tickets, isFetching: isFetching } = useGetTickets(true, state);

  return (
    <ServiceTicketsTable
      data={tickets}
      isFetching={isFetching}
      queryState={state}
      showOrganizationColumn={true}
    />
  );
};
