/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AssetMetadataWithValue } from './AssetMetadataWithValue';
import {
    AssetMetadataWithValueFromJSON,
    AssetMetadataWithValueFromJSONTyped,
    AssetMetadataWithValueToJSON,
} from './AssetMetadataWithValue';
import type { EAssetSource } from './EAssetSource';
import {
    EAssetSourceFromJSON,
    EAssetSourceFromJSONTyped,
    EAssetSourceToJSON,
} from './EAssetSource';
import type { EEntityState } from './EEntityState';
import {
    EEntityStateFromJSON,
    EEntityStateFromJSONTyped,
    EEntityStateToJSON,
} from './EEntityState';

/**
 * 
 * @export
 * @interface AssetCreateRequest
 */
export interface AssetCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof AssetCreateRequest
     */
    projectId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetCreateRequest
     */
    fileName: string;
    /**
     * 
     * @type {string}
     * @memberof AssetCreateRequest
     */
    checksumSha256: string;
    /**
     * 
     * @type {string}
     * @memberof AssetCreateRequest
     */
    mimeType: string;
    /**
     * 
     * @type {number}
     * @memberof AssetCreateRequest
     */
    fileSize: number;
    /**
     * 
     * @type {EAssetSource}
     * @memberof AssetCreateRequest
     */
    source: EAssetSource;
    /**
     * 
     * @type {Array<AssetMetadataWithValue>}
     * @memberof AssetCreateRequest
     */
    metadata: Array<AssetMetadataWithValue>;
    /**
     * 
     * @type {EEntityState}
     * @memberof AssetCreateRequest
     */
    entityState: EEntityState;
}

/**
 * Check if a given object implements the AssetCreateRequest interface.
 */
export function instanceOfAssetCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fileName" in value;
    isInstance = isInstance && "checksumSha256" in value;
    isInstance = isInstance && "mimeType" in value;
    isInstance = isInstance && "fileSize" in value;
    isInstance = isInstance && "source" in value;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "entityState" in value;

    return isInstance;
}

export function AssetCreateRequestFromJSON(json: any): AssetCreateRequest {
    return AssetCreateRequestFromJSONTyped(json, false);
}

export function AssetCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'fileName': json['fileName'],
        'checksumSha256': json['checksumSha256'],
        'mimeType': json['mimeType'],
        'fileSize': json['fileSize'],
        'source': EAssetSourceFromJSON(json['source']),
        'metadata': ((json['metadata'] as Array<any>).map(AssetMetadataWithValueFromJSON)),
        'entityState': EEntityStateFromJSON(json['entityState']),
    };
}

export function AssetCreateRequestToJSON(value?: AssetCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projectId': value.projectId,
        'fileName': value.fileName,
        'checksumSha256': value.checksumSha256,
        'mimeType': value.mimeType,
        'fileSize': value.fileSize,
        'source': EAssetSourceToJSON(value.source),
        'metadata': ((value.metadata as Array<any>).map(AssetMetadataWithValueToJSON)),
        'entityState': EEntityStateToJSON(value.entityState),
    };
}

