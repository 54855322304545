import { useGetUsers } from 'api/useUsersApi';
import { useTableQueryState } from 'components/Table/useTableQueryState';
import { UserCreate } from 'pages/Administration/components/User/UserCreate';
import { UsersTable } from 'pages/Administration/components/User/UsersTable';
import { Page } from 'layouts/Admin/Page';
import { useAuth } from 'auth/AuthProvider';
import { PermissionProtectedComponent } from 'auth/PermissionProtectedComponent';
import { EPermission } from 'api/core';

export const OrganizationUsers = () => {
  const { user } = useAuth();
  const { state } = useTableQueryState();
  const { data: users, isFetching } = useGetUsers(state);

  return (
    <Page
      title={user?.organization.name}
      breadcrumbs={[
        { name: user?.organization.name ?? '' },
        { name: 'Brugere' },
      ]}
    >
      <PermissionProtectedComponent permissions={[EPermission.UserWrite]}>
        <UserCreate
          trigger={
            <div className="flex justify-start py-4 w-full">
              <button className="btn btn-primary mr-2">Opret bruger</button>
            </div>
          }
        />
      </PermissionProtectedComponent>
      <UsersTable data={users} isFetching={isFetching} queryState={state} />
    </Page>
  );
};
