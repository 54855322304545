import { AnimatedIcon } from 'components/Icon/AnimatedIcon';
import { Tooltip } from 'components/Tooltip';
import { Link } from 'components/Typography/Link';
import { ReactNode } from 'react';

interface LinkCellProps {
  path: string;
  icon?: ReactNode;
  tooltip?: string;
  isExternal?: boolean;
}

export const LinkCell = ({
  path,
  icon,
  tooltip,
  isExternal,
}: LinkCellProps) => {
  return (
    <Tooltip tooltip={tooltip}>
      <Link to={path} target={isExternal ? '_blank' : undefined}>
        <button className="text-primary-content">
          {icon ? icon : <AnimatedIcon icon="open-icon" className="h-6 w-6" />}
        </button>
      </Link>
    </Tooltip>
  );
};
