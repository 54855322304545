/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DynamicFieldValidationResponse } from './DynamicFieldValidationResponse';
import {
    DynamicFieldValidationResponseFromJSON,
    DynamicFieldValidationResponseFromJSONTyped,
    DynamicFieldValidationResponseToJSON,
} from './DynamicFieldValidationResponse';

/**
 * 
 * @export
 * @interface DynamicTemplateFieldResponse
 */
export interface DynamicTemplateFieldResponse {
    /**
     * 
     * @type {Date}
     * @memberof DynamicTemplateFieldResponse
     */
    lastModifiedUtc: Date;
    /**
     * 
     * @type {Date}
     * @memberof DynamicTemplateFieldResponse
     */
    createdUtc: Date;
    /**
     * 
     * @type {string}
     * @memberof DynamicTemplateFieldResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DynamicTemplateFieldResponse
     */
    templateKey: string;
    /**
     * 
     * @type {string}
     * @memberof DynamicTemplateFieldResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DynamicTemplateFieldResponse
     */
    defaultValue: string;
    /**
     * 
     * @type {string}
     * @memberof DynamicTemplateFieldResponse
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof DynamicTemplateFieldResponse
     */
    order: number;
    /**
     * 
     * @type {Array<DynamicFieldValidationResponse>}
     * @memberof DynamicTemplateFieldResponse
     */
    validations: Array<DynamicFieldValidationResponse>;
}

/**
 * Check if a given object implements the DynamicTemplateFieldResponse interface.
 */
export function instanceOfDynamicTemplateFieldResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lastModifiedUtc" in value;
    isInstance = isInstance && "createdUtc" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "templateKey" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "defaultValue" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "order" in value;
    isInstance = isInstance && "validations" in value;

    return isInstance;
}

export function DynamicTemplateFieldResponseFromJSON(json: any): DynamicTemplateFieldResponse {
    return DynamicTemplateFieldResponseFromJSONTyped(json, false);
}

export function DynamicTemplateFieldResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DynamicTemplateFieldResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastModifiedUtc': (new Date(json['lastModifiedUtc'])),
        'createdUtc': (new Date(json['createdUtc'])),
        'id': json['id'],
        'templateKey': json['templateKey'],
        'name': json['name'],
        'defaultValue': json['defaultValue'],
        'description': json['description'],
        'order': json['order'],
        'validations': ((json['validations'] as Array<any>).map(DynamicFieldValidationResponseFromJSON)),
    };
}

export function DynamicTemplateFieldResponseToJSON(value?: DynamicTemplateFieldResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lastModifiedUtc': (value.lastModifiedUtc.toISOString()),
        'createdUtc': (value.createdUtc.toISOString()),
        'id': value.id,
        'templateKey': value.templateKey,
        'name': value.name,
        'defaultValue': value.defaultValue,
        'description': value.description,
        'order': value.order,
        'validations': ((value.validations as Array<any>).map(DynamicFieldValidationResponseToJSON)),
    };
}

