/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FacebookToken
 */
export interface FacebookToken {
    /**
     * 
     * @type {string}
     * @memberof FacebookToken
     */
    shortLivedUserAccessToken: string;
    /**
     * 
     * @type {string}
     * @memberof FacebookToken
     */
    longLivedUserAccessToken?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacebookToken
     */
    longLivedPageAccessToken?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacebookToken
     */
    pageId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacebookToken
     */
    pageName?: string | null;
}

/**
 * Check if a given object implements the FacebookToken interface.
 */
export function instanceOfFacebookToken(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "shortLivedUserAccessToken" in value;

    return isInstance;
}

export function FacebookTokenFromJSON(json: any): FacebookToken {
    return FacebookTokenFromJSONTyped(json, false);
}

export function FacebookTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): FacebookToken {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shortLivedUserAccessToken': json['shortLivedUserAccessToken'],
        'longLivedUserAccessToken': !exists(json, 'longLivedUserAccessToken') ? undefined : json['longLivedUserAccessToken'],
        'longLivedPageAccessToken': !exists(json, 'longLivedPageAccessToken') ? undefined : json['longLivedPageAccessToken'],
        'pageId': !exists(json, 'pageId') ? undefined : json['pageId'],
        'pageName': !exists(json, 'pageName') ? undefined : json['pageName'],
    };
}

export function FacebookTokenToJSON(value?: FacebookToken | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shortLivedUserAccessToken': value.shortLivedUserAccessToken,
        'longLivedUserAccessToken': value.longLivedUserAccessToken,
        'longLivedPageAccessToken': value.longLivedPageAccessToken,
        'pageId': value.pageId,
        'pageName': value.pageName,
    };
}

