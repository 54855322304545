import { useGetFields } from 'api/useFieldsApi';
import { ControlledModal } from 'components/ControlledModal';
import { useState } from 'react';
import { CaseForm } from './CaseForm';
import { useGetAssets } from 'api/useAssetsApi';

interface CaseCreateProps {
  projectId: string;
  trigger?: React.ReactNode;
  isInitialOpen?: boolean;
  onClosed?: () => void;
}

export const CaseCreate = ({
  projectId,
  trigger,
  isInitialOpen,
  onClosed,
}: CaseCreateProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(isInitialOpen ?? false);

  const { data } = useGetFields(projectId);

  const { data: assets } = useGetAssets(projectId);

  const onModalStateChange = (state: boolean) => {
    setIsOpen(state);
    if (!state) {
      onClosed?.();
    }
  };

  if (!data || !assets) return null;

  return (
    <>
      {trigger ? (
        <div onClick={() => onModalStateChange(true)}>{trigger}</div>
      ) : null}
      <ControlledModal
        showModal={onModalStateChange}
        isOpen={isOpen}
        title="Opret sag"
        closeOnOutsideClick
        hideActionBar
      >
        <CaseForm
          projectId={projectId}
          allFields={data.data}
          allAssets={assets.data}
          onSuccess={() => onModalStateChange(false)}
          onCancel={() => onModalStateChange(false)}
          inDialog={true}
        />
      </ControlledModal>
    </>
  );
};
