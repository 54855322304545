import { useDeleteProject, useGetProject } from 'api/useProjectsApi';
import { Page } from 'layouts/Admin/Page';
import { useNavigate, useParams } from 'react-router-dom';
import { ProjectForm } from './components/Project/ProjectForm';
import { DeleteEntityById } from 'components/Form/DeleteEntityById';
import { PermissionProtectedComponent } from 'auth/PermissionProtectedComponent';
import { EPermission } from 'api/core';

export const ProjectSettings = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const { data } = useGetProject(id, true);

  if (!data) return null;

  const onDeleted = () => {
    navigate('/projects');
  };

  return (
    <Page
      title={data.name}
      breadcrumbs={[
        { name: 'Projekter' },
        { name: data.name },
        { name: 'Indstillinger' },
      ]}
    >
      <PermissionProtectedComponent permissions={[EPermission.ProjectDelete]}>
        <div className="flex mt-2">
          <DeleteEntityById
            id={id}
            trigger={
              <button className="btn btn-error mr-2">
                Slet {data.singleCase ? 'sag' : 'projekt'}
              </button>
            }
            onDeleted={onDeleted}
            titlePropertyKey={'name'}
            getter={useGetProject}
            deleter={useDeleteProject}
          />
        </div>
      </PermissionProtectedComponent>
      <ProjectForm targetProject={data} />

      {data.estateOrbitEnabled ? (
        <PermissionProtectedComponent
          permissions={[
            EPermission.ModuleMediaOrbit,
            EPermission.EstateOrbitViewInjectionCode,
          ]}
        >
          <div className="mt-4 space-y-4">
            <h2 className="text-xl font-semibold">Injektionskode</h2>
            <div className="mockup-code text-primary">
              <pre className="px-6 select-all">
                <code>
                  {`
<div id="estate-orbit" data-project-id="${id}"></div>
<script src="${process.env.REACT_APP_MEDIA_ORBIT_MODULE_URL}/injection.js"></script>
      `.trim()}
                </code>
              </pre>
            </div>
          </div>
        </PermissionProtectedComponent>
      ) : null}
    </Page>
  );
};
