/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Page } from './Page';
import {
    PageFromJSON,
    PageFromJSONTyped,
    PageToJSON,
} from './Page';
import type { ProjectResponse } from './ProjectResponse';
import {
    ProjectResponseFromJSON,
    ProjectResponseFromJSONTyped,
    ProjectResponseToJSON,
} from './ProjectResponse';

/**
 * 
 * @export
 * @interface ProjectResponsePagedData
 */
export interface ProjectResponsePagedData {
    /**
     * 
     * @type {Page}
     * @memberof ProjectResponsePagedData
     */
    page: Page;
    /**
     * 
     * @type {Array<ProjectResponse>}
     * @memberof ProjectResponsePagedData
     */
    data: Array<ProjectResponse>;
}

/**
 * Check if a given object implements the ProjectResponsePagedData interface.
 */
export function instanceOfProjectResponsePagedData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "page" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function ProjectResponsePagedDataFromJSON(json: any): ProjectResponsePagedData {
    return ProjectResponsePagedDataFromJSONTyped(json, false);
}

export function ProjectResponsePagedDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectResponsePagedData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': PageFromJSON(json['page']),
        'data': ((json['data'] as Array<any>).map(ProjectResponseFromJSON)),
    };
}

export function ProjectResponsePagedDataToJSON(value?: ProjectResponsePagedData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': PageToJSON(value.page),
        'data': ((value.data as Array<any>).map(ProjectResponseToJSON)),
    };
}

