import {
  AssetResponse,
  EPermission,
  EServiceTicketState,
  ServiceTicketReplyRequest,
  ServiceTicketResponse,
} from 'api/core';
import { useReplyTicket } from 'api/useServiceTicketApi';
import { useAuth } from 'auth/AuthProvider';
import { AssetPreviewModal } from 'components/Asset/AssetPreviewModal';
import { ConfirmationDialog } from 'components/Confirmation';
import { AssetUpload } from 'components/Form/AssetUpload';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

interface ServiceTicketProps {
  ticket: ServiceTicketResponse;
  onSuccess?: () => void;
}

export const ServiceTicketReplyForm = ({
  ticket,
  onSuccess,
}: ServiceTicketProps) => {
  const { user } = useAuth();
  const { mutateAsync: replyAsync, isPending: isPendingReply } =
    useReplyTicket();

  const viewingFromOherOrg = user?.organization.id !== ticket.organization.id;

  const fileInputRef = useRef<HTMLInputElement>(null);
  const onAssetUploaded = async (asset: AssetResponse | null) => {
    if (!asset) return;
    setSelectedAssets((prev) => [...prev, asset]);
  };

  const [selectedAssets, setSelectedAssets] = useState<AssetResponse[]>([]);

  const { register, handleSubmit, reset, watch } =
    useForm<ServiceTicketReplyRequest>({
      defaultValues: {},
    });

  const [isClosing, setIsClosing] = useState(false);
  const [showClosingDialog, setShowClosingDialog] = useState(false);
  const message = watch('message');

  const { hasPermissions } = useAuth();
  const isSupport = hasPermissions([EPermission.ServiceTicketSupporter]);

  const onSubmit = handleSubmit(async (result) => {
    const newState = result.isInternal
      ? ticket.state
      : isSupport
        ? EServiceTicketState.AwaitingCustomer
        : EServiceTicketState.AwaitingSupport;
    await replyAsync({
      id: ticket.id,
      serviceTicketReplyRequest: {
        ...result,
        newState: newState,
        assetIds: selectedAssets.map((a) => a.id),
      },
    });
    onSuccess?.();
    reset();
    setSelectedAssets([]);
  });

  const onClose = async () => {
    setShowClosingDialog(false);
    setIsClosing(true);
    await replyAsync({
      id: ticket.id,
      serviceTicketReplyRequest: {
        isInternal: false,
        message: message && message.trim().length ? message : 'Ticket lukket',
        newState: EServiceTicketState.Done,
        assetIds: selectedAssets.map((a) => a.id),
      },
    });
    setIsClosing(false);
    onSuccess?.();
    reset();
    setSelectedAssets([]);
  };

  if (ticket.state == 'Done') return null;

  return (
    <>
      <form onSubmit={onSubmit} className="space-y-2">
        {isSupport ? (
          <div className="form-control items-start">
            <label className="cursor-pointer label">
              <span className="label-text me-2">Intern besked</span>
              <input
                {...register('isInternal')}
                type="checkbox"
                className="toggle toggle-primary"
              />
            </label>
          </div>
        ) : null}

        <div className="form-control">
          <textarea
            {...register('message', { required: true })}
            className="textarea textarea-bordered"
            rows={4}
          />
        </div>

        {!viewingFromOherOrg ? (
          <a
            className="btn btn-primary"
            onClick={() => fileInputRef.current?.click()}
          >
            Upload ny fil
          </a>
        ) : null}

        <AssetUpload
          fileInputRef={fileInputRef}
          onAssetUploaded={onAssetUploaded}
          allowMultiple
          source="ServiceTicket"
        />

        <div className="flex flex-wrap gap-2">
          {selectedAssets.map((asset) => (
            <div
              key={asset.id}
              className="bg-gray-100 border border-gray-300 p-2 rounded-lg flex flex-col items-center"
            >
              <div className="text-xs text-gray-700 mb-1">
                {asset.originalFileName}
              </div>
              <AssetPreviewModal asset={asset} className="h-8" />
              <button
                type="button"
                className="btn btn-error"
                onClick={() =>
                  setSelectedAssets((prev) =>
                    prev.filter((a) => a.id !== asset.id)
                  )
                }
              >
                Fjern
              </button>
            </div>
          ))}
        </div>

        <div className="flex justify-center space-x-4 pt-4">
          <button
            className="btn btn-primary"
            disabled={isPendingReply || isClosing}
          >
            Svar
          </button>
          <button
            type="button"
            className="btn btn-warning"
            onClick={() => setShowClosingDialog(true)}
            disabled={isPendingReply || isClosing}
          >
            Luk ticket
          </button>
        </div>
      </form>

      <ConfirmationDialog
        title={'Luk ticket'}
        description={'Er du sikker på at du vil lukke denen ticket?'}
        showModal={() => showClosingDialog}
        isOpen={showClosingDialog}
        onConfirm={onClose}
        onCancel={() => setShowClosingDialog(false)}
      />
    </>
  );
};
