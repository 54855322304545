/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EPublicationPlatform } from './EPublicationPlatform';
import {
    EPublicationPlatformFromJSON,
    EPublicationPlatformFromJSONTyped,
    EPublicationPlatformToJSON,
} from './EPublicationPlatform';

/**
 * 
 * @export
 * @interface PublicationUpdateRequest
 */
export interface PublicationUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicationUpdateRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PublicationUpdateRequest
     */
    message: string;
    /**
     * 
     * @type {Date}
     * @memberof PublicationUpdateRequest
     */
    scheduledTimeUtc: Date;
    /**
     * 
     * @type {Array<EPublicationPlatform>}
     * @memberof PublicationUpdateRequest
     */
    platforms: Array<EPublicationPlatform>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PublicationUpdateRequest
     */
    assetIds: Array<string>;
}

/**
 * Check if a given object implements the PublicationUpdateRequest interface.
 */
export function instanceOfPublicationUpdateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "message" in value;
    isInstance = isInstance && "scheduledTimeUtc" in value;
    isInstance = isInstance && "platforms" in value;
    isInstance = isInstance && "assetIds" in value;

    return isInstance;
}

export function PublicationUpdateRequestFromJSON(json: any): PublicationUpdateRequest {
    return PublicationUpdateRequestFromJSONTyped(json, false);
}

export function PublicationUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublicationUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'message': json['message'],
        'scheduledTimeUtc': (new Date(json['scheduledTimeUtc'])),
        'platforms': ((json['platforms'] as Array<any>).map(EPublicationPlatformFromJSON)),
        'assetIds': json['assetIds'],
    };
}

export function PublicationUpdateRequestToJSON(value?: PublicationUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'message': value.message,
        'scheduledTimeUtc': (value.scheduledTimeUtc.toISOString()),
        'platforms': ((value.platforms as Array<any>).map(EPublicationPlatformToJSON)),
        'assetIds': value.assetIds,
    };
}

