import { UseMutationResult } from '@tanstack/react-query';
import { ConfirmationDialog } from 'components/Confirmation';
import { Tooltip } from 'components/Tooltip';
import { useState } from 'react';

interface MutateEntityProps<T, MutationVariables> {
  trigger?: React.ReactNode;
  isInitialOpen?: boolean;
  onMutate?: (result: T) => void;
  onClosed?: () => void;
  onMutateConfirm?: () => void;
  title: string;
  description: string;
  setter: () => UseMutationResult<T, Error, MutationVariables, unknown>;
  mutationVariables: MutationVariables;
  hidden?: boolean;
  disabled?: boolean;
  tooltip?: string;
}

export function MutateEntity<T, MutationVariables>({
  trigger,
  isInitialOpen,
  onMutate,
  onClosed,
  onMutateConfirm,
  title,
  description,
  setter,
  mutationVariables,
  hidden,
  disabled,
  tooltip,
}: MutateEntityProps<T, MutationVariables>) {
  const [isOpen, setIsOpen] = useState<boolean>(isInitialOpen ?? false);

  const { mutateAsync } = setter();

  if (hidden) return null;

  const onMutateConfirmInternal = async () => {
    onMutateConfirm?.();
    const result = await mutateAsync(mutationVariables);
    onMutate?.(result);
    onModalStateChange(false);
  };

  const onModalStateChange = (state: boolean) => {
    setIsOpen(state);
    if (!state) {
      onClosed?.();
    }
  };

  const onTriggerClick = () => {
    if (disabled) return;
    onModalStateChange(true);
  };

  return (
    <>
      {trigger ? (
        <Tooltip tooltip={tooltip}>
          <div onClick={onTriggerClick}>{trigger}</div>
        </Tooltip>
      ) : null}
      <ConfirmationDialog
        title={title}
        description={description}
        showModal={onModalStateChange}
        isOpen={isOpen}
        onConfirm={onMutateConfirmInternal}
      />
    </>
  );
}
