/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Language,
  TranslationTermConfiguration,
} from '../models';
import {
    LanguageFromJSON,
    LanguageToJSON,
    TranslationTermConfigurationFromJSON,
    TranslationTermConfigurationToJSON,
} from '../models';

export interface TranslationsLanguageIdGetRequest {
    id: number;
}

export interface TranslationsTermsIdGetRequest {
    id: number;
    fallbackMissingTranslations?: boolean;
}

/**
 * 
 */
export class TranslationsApi extends runtime.BaseAPI {

    /**
     */
    async translationsLanguageIdGetRaw(requestParameters: TranslationsLanguageIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Language>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling translationsLanguageIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Translations/language/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LanguageFromJSON(jsonValue));
    }

    /**
     */
    async translationsLanguageIdGet(requestParameters: TranslationsLanguageIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Language> {
        const response = await this.translationsLanguageIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async translationsLanguagesGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Language>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Translations/languages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LanguageFromJSON));
    }

    /**
     */
    async translationsLanguagesGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Language>> {
        const response = await this.translationsLanguagesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async translationsPurgePostRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Language>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Translations/purge`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LanguageFromJSON));
    }

    /**
     */
    async translationsPurgePost(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Language>> {
        const response = await this.translationsPurgePostRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async translationsTermsConfigurationGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TranslationTermConfiguration>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Translations/terms/configuration`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TranslationTermConfigurationFromJSON));
    }

    /**
     */
    async translationsTermsConfigurationGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TranslationTermConfiguration>> {
        const response = await this.translationsTermsConfigurationGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async translationsTermsIdGetRaw(requestParameters: TranslationsTermsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: string; }>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling translationsTermsIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.fallbackMissingTranslations !== undefined) {
            queryParameters['fallbackMissingTranslations'] = requestParameters.fallbackMissingTranslations;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Translations/terms/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async translationsTermsIdGet(requestParameters: TranslationsTermsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: string; }> {
        const response = await this.translationsTermsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
