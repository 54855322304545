/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EAssetType = {
    Other: 'Other',
    Image: 'Image',
    Video: 'Video',
    Audio: 'Audio',
    Document: 'Document'
} as const;
export type EAssetType = typeof EAssetType[keyof typeof EAssetType];


export function EAssetTypeFromJSON(json: any): EAssetType {
    return EAssetTypeFromJSONTyped(json, false);
}

export function EAssetTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EAssetType {
    return json as EAssetType;
}

export function EAssetTypeToJSON(value?: EAssetType | null): any {
    return value as any;
}

