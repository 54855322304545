/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EPermission } from './EPermission';
import {
    EPermissionFromJSON,
    EPermissionFromJSONTyped,
    EPermissionToJSON,
} from './EPermission';
import type { OrganizationSimpleResponse } from './OrganizationSimpleResponse';
import {
    OrganizationSimpleResponseFromJSON,
    OrganizationSimpleResponseFromJSONTyped,
    OrganizationSimpleResponseToJSON,
} from './OrganizationSimpleResponse';
import type { RoleResponse } from './RoleResponse';
import {
    RoleResponseFromJSON,
    RoleResponseFromJSONTyped,
    RoleResponseToJSON,
} from './RoleResponse';

/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    sessionId: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    email: string;
    /**
     * 
     * @type {Array<RoleResponse>}
     * @memberof LoginResponse
     */
    roles: Array<RoleResponse>;
    /**
     * 
     * @type {Array<EPermission>}
     * @memberof LoginResponse
     */
    permissions: Array<EPermission>;
    /**
     * 
     * @type {OrganizationSimpleResponse}
     * @memberof LoginResponse
     */
    organization: OrganizationSimpleResponse;
    /**
     * 
     * @type {Array<string>}
     * @memberof LoginResponse
     */
    parentOrganizationIds: Array<string>;
}

/**
 * Check if a given object implements the LoginResponse interface.
 */
export function instanceOfLoginResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "sessionId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "roles" in value;
    isInstance = isInstance && "permissions" in value;
    isInstance = isInstance && "organization" in value;
    isInstance = isInstance && "parentOrganizationIds" in value;

    return isInstance;
}

export function LoginResponseFromJSON(json: any): LoginResponse {
    return LoginResponseFromJSONTyped(json, false);
}

export function LoginResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'sessionId': json['sessionId'],
        'name': json['name'],
        'email': json['email'],
        'roles': ((json['roles'] as Array<any>).map(RoleResponseFromJSON)),
        'permissions': ((json['permissions'] as Array<any>).map(EPermissionFromJSON)),
        'organization': OrganizationSimpleResponseFromJSON(json['organization']),
        'parentOrganizationIds': json['parentOrganizationIds'],
    };
}

export function LoginResponseToJSON(value?: LoginResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sessionId': value.sessionId,
        'name': value.name,
        'email': value.email,
        'roles': ((value.roles as Array<any>).map(RoleResponseToJSON)),
        'permissions': ((value.permissions as Array<any>).map(EPermissionToJSON)),
        'organization': OrganizationSimpleResponseToJSON(value.organization),
        'parentOrganizationIds': value.parentOrganizationIds,
    };
}

