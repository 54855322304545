import {
  DynamicTemplateCreateRequest,
  DynamicTemplateResponse,
  DynamicTemplateUpdateRequest,
  OrganizationResponse,
} from 'api/core';
import {
  useCreateDynamicTemplate,
  useUpdateDynamicTemplate,
} from 'api/useDynamicTemplatesApi';
import { IconButton } from 'components/Button/IconButton';
import { DynamicTemplatePreview } from 'components/DynamicTemplate/DynamicTemplatePreview';
import { LabelWithHelperText } from 'components/Form/LabelWithHelperText';
import { AnimatedIcon } from 'components/Icon/AnimatedIcon';
import { EntitySelectMultiple } from 'components/Select/EntitySelectMultiple';
import { ChevronDownCircleIcon, ChevronUpCircleIcon } from 'lucide-react';
import { useMemo } from 'react';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';

interface DynamicTemplateFormProps {
  targetDynamicTemplate?: DynamicTemplateResponse;
  allOrganizations: OrganizationResponse[];
  onSuccess: () => void;
  onCancel: () => void;
  inDialog?: boolean;
}

export const DynamicTemplateForm = ({
  targetDynamicTemplate,
  allOrganizations,
  onSuccess,
  onCancel,
  inDialog,
}: DynamicTemplateFormProps) => {
  const { mutateAsync: createAsync, isPending: isPendingCreate } =
    useCreateDynamicTemplate();
  const { mutateAsync: updateAsync, isPending: isPendingUpdate } =
    useUpdateDynamicTemplate();

  const methods = useForm<
    DynamicTemplateCreateRequest | DynamicTemplateUpdateRequest
  >({
    defaultValues: {
      ...targetDynamicTemplate,
    },
  });

  const { register, handleSubmit, setValue, watch } = methods;

  const onSubmit = handleSubmit(async (result) => {
    const fieldsNotPresentInHtml = result.fields
      .map((e) => e.templateKey)
      .filter((fieldKey) => !result.html.includes(`{{${fieldKey}}}`));
    if (fieldsNotPresentInHtml.length > 0) {
      toast.error(
        `Felterne ${fieldsNotPresentInHtml.join(', ')} findes ikke i HTML`
      );
      return;
    }

    const htmlFields =
      result.html.match(/{{(.*?)}}/g)?.map((key) => key.slice(2, -2)) ?? [];
    const htmlFieldsNotPresentInFields = htmlFields.filter(
      (fieldKey) =>
        !result.fields.some((field) => field.templateKey == fieldKey)
    );
    if (htmlFieldsNotPresentInFields.length > 0) {
      toast.error(
        `Felterne ${htmlFieldsNotPresentInFields.join(', ')} findes ikke i felterne`
      );
      return;
    }

    if (targetDynamicTemplate) {
      await updateAsync({
        id: targetDynamicTemplate.id,
        dynamicTemplateUpdateRequest: result,
      });
    } else {
      await createAsync({
        dynamicTemplateCreateRequest: result,
      });
    }
    onSuccess();
  });

  const onOrganizationSelected = (
    organizations: OrganizationResponse[] | null
  ) => {
    setValue('organizationIds', organizations?.map((e) => e.id) ?? []);
  };

  const watchedFields = watch('fields');
  const watchedHtml = watch('html');

  const htmlMappingsMissingFields = useMemo(() => {
    // Parse all field keys from the HTML
    const fieldKeys = watchedHtml
      ?.match(/{{(.*?)}}/g)
      ?.map((key) => key.slice(2, -2));

    // Return list of all missing field keys
    const missingFieldKeys = fieldKeys?.filter(
      (key) => !watchedFields.some((field) => field.templateKey == key)
    );

    return missingFieldKeys ?? [];
    // TODO: Fix fields doesn't trigger update
  }, [watchedFields, watchedHtml]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <div className="flex gap-4 mt-4">
          <div className="w-1/3">
            <div role="tablist" className="tabs tabs-bordered">
              <input
                type="radio"
                name="dynamic-template-tabs"
                role="tab"
                className="tab"
                aria-label="Generelt"
                defaultChecked
              />
              <div role="tabpanel" className="tab-content p-4 col-span-3">
                <div className="form-control">
                  <LabelWithHelperText
                    label="Organisationer"
                    helperText="Du kan låse en dynamisk overlejring til en eller flere bestemte organisationer. Det betyder den ikke kan bruges af andre organisationer."
                  />
                  <EntitySelectMultiple<OrganizationResponse>
                    data={allOrganizations}
                    renderFormat={(format) => <>{format.name}</>}
                    onSelect={onOrganizationSelected}
                    initialValue={allOrganizations.filter((e) =>
                      targetDynamicTemplate?.organizationIds?.some(
                        (f) => f == e.id
                      )
                    )}
                    searchPropertyKey="name"
                    inDialog={inDialog}
                  />
                  <input
                    {...register('organizationIds')}
                    type="text"
                    className="hidden"
                  />
                </div>

                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Navn</span>
                  </label>
                  <input
                    {...register('name', { required: true })}
                    type="text"
                    className="input input-bordered"
                  />
                </div>

                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Beskrivelse</span>
                  </label>
                  <input
                    {...register('description', { required: true })}
                    type="text"
                    className="input input-bordered"
                  />
                </div>
              </div>

              <input
                type="radio"
                name="dynamic-template-tabs"
                role="tab"
                className="tab"
                aria-label="Felter"
              />
              <div
                role="tabpanel"
                className="tab-content pt-4 col-span-3 overflow-y-auto max-h-[700px] pr-3"
              >
                <Fields />
              </div>

              <input
                type="radio"
                name="dynamic-template-tabs"
                role="tab"
                className="tab"
                aria-label="HTML designer"
              />
              <div role="tabpanel" className="tab-content pt-4 col-span-3">
                <div className="form-control">
                  <textarea
                    {...register('html', { required: true })}
                    className="textarea textarea-bordered"
                    rows={26}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="w-2/3 space-y-2">
            {htmlMappingsMissingFields.length > 0 ? (
              <div
                role="alert"
                className="alert alert-error rounded-none text-white"
              >
                <div>
                  Der mangler felter for følgende mappings:{' '}
                  {htmlMappingsMissingFields.map((fieldKey) => (
                    <span className="font-bold" key={fieldKey}>
                      {fieldKey},{' '}
                    </span>
                  ))}
                </div>
              </div>
            ) : null}
            <DynamicTemplatePreview
              html={watch('html')}
              previewAspectRatio={1}
              showReplayButton={true}
            />
          </div>
        </div>

        <div className="flex justify-center space-x-4 pt-4">
          <button
            className="btn btn-primary"
            disabled={isPendingCreate || isPendingUpdate}
          >
            {targetDynamicTemplate ? 'Opdater' : 'Opret'}
          </button>
          {onCancel ? (
            <button type="button" className="btn" onClick={onCancel}>
              Annuller
            </button>
          ) : null}
        </div>
      </form>
    </FormProvider>
  );
};

const Fields = () => {
  const { watch, register, control } = useFormContext<
    DynamicTemplateCreateRequest | DynamicTemplateUpdateRequest
  >();

  const isFieldMappedInHtml = (fieldKey: string) => {
    return watch('html').includes(`{{${fieldKey}}}`);
  };

  const { fields, append, remove, swap } = useFieldArray({
    control,
    name: 'fields',
  });

  return (
    <div className="form-control space-y-2">
      {fields.map((field, index) => {
        const isMapped = isFieldMappedInHtml(
          watch(`fields.${index}.templateKey`)
        );
        return (
          <div key={field.id}>
            <div
              className={twMerge(
                'collapse collapse-arrow border rounded-none',
                !isMapped && 'border-red-600'
              )}
            >
              <input
                type="radio"
                name="field-accordion"
                defaultChecked={index === 0}
              />
              <div className="collapse-title flex justify-between items-center">
                <div>
                  {!isMapped ? (
                    <p className="text-red-600 text-xs">
                      (Felt nøgle <b>{watch(`fields.${index}.templateKey`)}</b>{' '}
                      findes ikke i HTML)
                    </p>
                  ) : null}
                  {watch(`fields.${index}.name`) || `Felt ${index + 1}`}
                </div>

                <div className="z-50">
                  <IconButton
                    disabled={index === 0}
                    icon={
                      <div>
                        <ChevronUpCircleIcon
                          className={twMerge(
                            'h-8 md:h-6 w-8 md:w-6',
                            index === 0 && 'text-gray-400'
                          )}
                        />
                      </div>
                    }
                    tooltip="Flyt felt op"
                    onClick={() => swap(index, index - 1)}
                  />
                  <IconButton
                    disabled={index === fields.length - 1}
                    icon={
                      <div>
                        <ChevronDownCircleIcon
                          className={twMerge(
                            'h-8 md:h-6 w-8 md:w-6',
                            index === fields.length - 1 && 'text-gray-400'
                          )}
                        />
                      </div>
                    }
                    tooltip="Flyt felt ned"
                    onClick={() => swap(index, index + 1)}
                  />
                  <IconButton
                    icon={
                      <div>
                        <AnimatedIcon
                          icon="trash-icon"
                          className="h-8 md:h-6 w-8 md:w-6"
                        />
                      </div>
                    }
                    tooltip="Fjern felt"
                    onClick={() => remove(index)}
                  />
                </div>
              </div>
              <div className="collapse-content">
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Felt nøgle</span>
                  </label>
                  <input
                    {...register(`fields.${index}.templateKey`, {
                      required: true,
                    })}
                    type="text"
                    className="input input-bordered"
                  />
                </div>

                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Navn</span>
                  </label>
                  <input
                    {...register(`fields.${index}.name`, {
                      required: true,
                    })}
                    type="text"
                    className="input input-bordered"
                  />
                </div>

                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Default værdi</span>
                  </label>
                  <input
                    {...register(`fields.${index}.defaultValue`, {
                      required: false,
                    })}
                    type="text"
                    className="input input-bordered"
                  />
                </div>

                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Beskrivelse</span>
                  </label>
                  <input
                    {...register(`fields.${index}.description`, {
                      required: true,
                    })}
                    type="text"
                    className="input input-bordered"
                  />
                </div>
                <FieldValidations index={index} />
              </div>
            </div>
          </div>
        );
      })}

      <button
        type="button"
        className="btn btn-primary mt-2"
        onClick={() =>
          append({
            templateKey: '',
            name: '',
            defaultValue: '',
            description: '',
            validations: [],
          })
        }
      >
        Tilføj felt
      </button>
    </div>
  );
};

interface FieldValidationsProps {
  index: number;
}

const FieldValidations = ({ index }: FieldValidationsProps) => {
  const { register, control } = useFormContext<
    DynamicTemplateCreateRequest | DynamicTemplateUpdateRequest
  >();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `fields.${index}.validations`,
  });

  return (
    <div className="form-control">
      <label className="label">
        <span className="label-text">Valideringer</span>
      </label>
      {fields.map((_validation, vIndex) => (
        <div key={vIndex} className="mb-2">
          <div className="collapse collapse-arrow bg-base-200 rounded-none">
            <input type="radio" name={`validation-accordion-${index}`} />
            <div className="collapse-title flex justify-between items-center">
              <div>Validering {vIndex + 1}</div>
              <div className="z-50">
                <IconButton
                  icon={
                    <div>
                      <AnimatedIcon
                        icon="trash-icon"
                        className="h-8 md:h-6 w-8 md:w-6"
                      />
                    </div>
                  }
                  tooltip="Fjern felt validering"
                  onClick={() => remove(vIndex)}
                />
              </div>
            </div>
            <div className="collapse-content">
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Regex</span>
                </label>
                <input
                  {...register(`fields.${index}.validations.${vIndex}.regex`, {
                    required: true,
                  })}
                  type="text"
                  className="input input-bordered"
                />
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Fejlbesked</span>
                </label>
                <input
                  {...register(
                    `fields.${index}.validations.${vIndex}.errorMessage`,
                    { required: true }
                  )}
                  type="text"
                  className="input input-bordered"
                />
              </div>
            </div>
          </div>
        </div>
      ))}
      <button
        type="button"
        className="btn btn-primary mt-2"
        onClick={() =>
          append({
            regex: '',
            errorMessage: '',
          })
        }
      >
        Tilføj felt validering
      </button>
    </div>
  );
};
