/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EConcatenationMode } from './EConcatenationMode';
import {
    EConcatenationModeFromJSON,
    EConcatenationModeFromJSONTyped,
    EConcatenationModeToJSON,
} from './EConcatenationMode';

/**
 * 
 * @export
 * @interface MediaSequenceUpdateRequest
 */
export interface MediaSequenceUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof MediaSequenceUpdateRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MediaSequenceUpdateRequest
     */
    metaMappingUserId?: string | null;
    /**
     * 
     * @type {EConcatenationMode}
     * @memberof MediaSequenceUpdateRequest
     */
    concatenationMode: EConcatenationMode;
}

/**
 * Check if a given object implements the MediaSequenceUpdateRequest interface.
 */
export function instanceOfMediaSequenceUpdateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "concatenationMode" in value;

    return isInstance;
}

export function MediaSequenceUpdateRequestFromJSON(json: any): MediaSequenceUpdateRequest {
    return MediaSequenceUpdateRequestFromJSONTyped(json, false);
}

export function MediaSequenceUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MediaSequenceUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'metaMappingUserId': !exists(json, 'metaMappingUserId') ? undefined : json['metaMappingUserId'],
        'concatenationMode': EConcatenationModeFromJSON(json['concatenationMode']),
    };
}

export function MediaSequenceUpdateRequestToJSON(value?: MediaSequenceUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'metaMappingUserId': value.metaMappingUserId,
        'concatenationMode': EConcatenationModeToJSON(value.concatenationMode),
    };
}

