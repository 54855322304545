/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AuditLogResponse,
  AuditLogResponsePagedData,
} from '../models';
import {
    AuditLogResponseFromJSON,
    AuditLogResponseToJSON,
    AuditLogResponsePagedDataFromJSON,
    AuditLogResponsePagedDataToJSON,
} from '../models';

export interface AuditLogsGetRequest {
    pageNumber?: number;
    size?: number;
    orderByProperty?: string;
    ascending?: boolean;
    searchTerm?: string;
}

export interface AuditLogsIdGetRequest {
    id: number;
}

/**
 * 
 */
export class AuditLogsApi extends runtime.BaseAPI {

    /**
     */
    async auditLogsGetRaw(requestParameters: AuditLogsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuditLogResponsePagedData>> {
        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.orderByProperty !== undefined) {
            queryParameters['orderByProperty'] = requestParameters.orderByProperty;
        }

        if (requestParameters.ascending !== undefined) {
            queryParameters['ascending'] = requestParameters.ascending;
        }

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['searchTerm'] = requestParameters.searchTerm;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/AuditLogs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuditLogResponsePagedDataFromJSON(jsonValue));
    }

    /**
     */
    async auditLogsGet(requestParameters: AuditLogsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuditLogResponsePagedData> {
        const response = await this.auditLogsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async auditLogsIdGetRaw(requestParameters: AuditLogsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuditLogResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling auditLogsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/AuditLogs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuditLogResponseFromJSON(jsonValue));
    }

    /**
     */
    async auditLogsIdGet(requestParameters: AuditLogsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuditLogResponse> {
        const response = await this.auditLogsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
