/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Page } from './Page';
import {
    PageFromJSON,
    PageFromJSONTyped,
    PageToJSON,
} from './Page';
import type { SessionResponse } from './SessionResponse';
import {
    SessionResponseFromJSON,
    SessionResponseFromJSONTyped,
    SessionResponseToJSON,
} from './SessionResponse';

/**
 * 
 * @export
 * @interface SessionResponsePagedData
 */
export interface SessionResponsePagedData {
    /**
     * 
     * @type {Page}
     * @memberof SessionResponsePagedData
     */
    page: Page;
    /**
     * 
     * @type {Array<SessionResponse>}
     * @memberof SessionResponsePagedData
     */
    data: Array<SessionResponse>;
}

/**
 * Check if a given object implements the SessionResponsePagedData interface.
 */
export function instanceOfSessionResponsePagedData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "page" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function SessionResponsePagedDataFromJSON(json: any): SessionResponsePagedData {
    return SessionResponsePagedDataFromJSONTyped(json, false);
}

export function SessionResponsePagedDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SessionResponsePagedData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': PageFromJSON(json['page']),
        'data': ((json['data'] as Array<any>).map(SessionResponseFromJSON)),
    };
}

export function SessionResponsePagedDataToJSON(value?: SessionResponsePagedData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': PageToJSON(value.page),
        'data': ((value.data as Array<any>).map(SessionResponseToJSON)),
    };
}

