/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ETag } from './ETag';
import {
    ETagFromJSON,
    ETagFromJSONTyped,
    ETagToJSON,
} from './ETag';

/**
 * 
 * @export
 * @interface AssetTagRequest
 */
export interface AssetTagRequest {
    /**
     * 
     * @type {ETag}
     * @memberof AssetTagRequest
     */
    tag: ETag;
    /**
     * 
     * @type {number}
     * @memberof AssetTagRequest
     */
    confidence?: number | null;
}

/**
 * Check if a given object implements the AssetTagRequest interface.
 */
export function instanceOfAssetTagRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "tag" in value;

    return isInstance;
}

export function AssetTagRequestFromJSON(json: any): AssetTagRequest {
    return AssetTagRequestFromJSONTyped(json, false);
}

export function AssetTagRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetTagRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tag': ETagFromJSON(json['tag']),
        'confidence': !exists(json, 'confidence') ? undefined : json['confidence'],
    };
}

export function AssetTagRequestToJSON(value?: AssetTagRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tag': ETagToJSON(value.tag),
        'confidence': value.confidence,
    };
}

