/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SessionResponse
 */
export interface SessionResponse {
    /**
     * 
     * @type {string}
     * @memberof SessionResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionResponse
     */
    userId?: string;
    /**
     * 
     * @type {Date}
     * @memberof SessionResponse
     */
    expiryTimeUtc?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SessionResponse
     */
    lastAccess?: Date;
    /**
     * 
     * @type {string}
     * @memberof SessionResponse
     */
    ip?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SessionResponse
     */
    userAgent?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SessionResponse
     */
    isCurrentSession?: boolean;
}

/**
 * Check if a given object implements the SessionResponse interface.
 */
export function instanceOfSessionResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SessionResponseFromJSON(json: any): SessionResponse {
    return SessionResponseFromJSONTyped(json, false);
}

export function SessionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SessionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'expiryTimeUtc': !exists(json, 'expiryTimeUtc') ? undefined : (new Date(json['expiryTimeUtc'])),
        'lastAccess': !exists(json, 'lastAccess') ? undefined : (new Date(json['lastAccess'])),
        'ip': !exists(json, 'ip') ? undefined : json['ip'],
        'userAgent': !exists(json, 'userAgent') ? undefined : json['userAgent'],
        'isCurrentSession': !exists(json, 'isCurrentSession') ? undefined : json['isCurrentSession'],
    };
}

export function SessionResponseToJSON(value?: SessionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userId': value.userId,
        'expiryTimeUtc': value.expiryTimeUtc === undefined ? undefined : (value.expiryTimeUtc.toISOString()),
        'lastAccess': value.lastAccess === undefined ? undefined : (value.lastAccess.toISOString()),
        'ip': value.ip,
        'userAgent': value.userAgent,
        'isCurrentSession': value.isCurrentSession,
    };
}

