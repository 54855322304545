import {
  buildQueryState,
  useTableQueryState,
} from 'components/Table/useTableQueryState';
import { Page } from 'layouts/Admin/Page';
import { useGetOrganizations } from 'api/useOrganizationsApi';
import { OrganizationsTable } from './components/Organization/OrganizationsTable';
import { OrganizationCreate } from './components/Organization/OrganizationCreate';
import { PermissionProtectedComponent } from 'auth/PermissionProtectedComponent';
import { EPermission, OrganizationResponse } from 'api/core';

export const OrganizationsView = () => {
  const { state } = useTableQueryState();
  const { data, isFetching } = useGetOrganizations(state);

  const { data: allOrganizations, isFetching: allOrganizationsFetching } =
    useGetOrganizations(
      buildQueryState<OrganizationResponse>({
        pagination: {
          pageIndex: 0,
          pageSize: 1000,
        },
        sorting: [
          {
            id: 'name',
            desc: false,
          },
        ],
      })
    );

  return (
    <Page title={'Organisationer'} breadcrumbs={[{ name: 'Organisationer' }]}>
      <PermissionProtectedComponent
        permissions={[EPermission.OrganizationWrite]}
      >
        <OrganizationCreate
          trigger={
            <div className="flex justify-start py-4 w-full">
              <button className="btn btn-primary mr-2">
                Opret organisation
              </button>
            </div>
          }
        />
      </PermissionProtectedComponent>
      <OrganizationsTable
        data={data}
        allOrganizations={allOrganizations?.data ?? []}
        isFetching={isFetching || allOrganizationsFetching}
        queryState={state}
      />
    </Page>
  );
};
