/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EPublicationDestinationState = {
    Draft: 'Draft',
    Scheduled: 'Scheduled',
    Published: 'Published',
    Failed: 'Failed'
} as const;
export type EPublicationDestinationState = typeof EPublicationDestinationState[keyof typeof EPublicationDestinationState];


export function EPublicationDestinationStateFromJSON(json: any): EPublicationDestinationState {
    return EPublicationDestinationStateFromJSONTyped(json, false);
}

export function EPublicationDestinationStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): EPublicationDestinationState {
    return json as EPublicationDestinationState;
}

export function EPublicationDestinationStateToJSON(value?: EPublicationDestinationState | null): any {
    return value as any;
}

