import { ControlledModal } from 'components/ControlledModal';
import { useState } from 'react';
import { DynamicTemplateForm } from './DynamicTemplateForm';
import { useGetDynamicTemplate } from 'api/useDynamicTemplatesApi';
import { useGetOrganizations } from 'api/useOrganizationsApi';
import { OrganizationResponse } from 'api/core';
import { buildQueryState } from 'components/Table/useTableQueryState';

interface DynamicTemplateUpdateProps {
  id?: string;
  trigger?: React.ReactNode;
  isInitialOpen?: boolean;
  onClosed?: () => void;
}

export const DynamicTemplateUpdate = ({
  id,
  trigger,
  isInitialOpen,
  onClosed,
}: DynamicTemplateUpdateProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(isInitialOpen ?? false);

  const { data } = useGetDynamicTemplate(id);

  const { data: allOrganizations } = useGetOrganizations(
    buildQueryState<OrganizationResponse>({
      pagination: {
        pageIndex: 0,
        pageSize: 100,
      },
      sorting: [
        {
          id: 'name',
          desc: false,
        },
      ],
    })
  );

  const onModalStateChange = (state: boolean) => {
    setIsOpen(state);
    if (!state) {
      onClosed?.();
    }
  };

  if (!data || !allOrganizations) return null;

  return (
    <>
      {trigger ? (
        <div onClick={() => onModalStateChange(true)}>{trigger}</div>
      ) : null}
      <ControlledModal
        showModal={onModalStateChange}
        isOpen={isOpen}
        title="Opdater dynamisk overlejring"
        hideActionBar
        className="max-w-[70%]"
      >
        <DynamicTemplateForm
          targetDynamicTemplate={data}
          allOrganizations={allOrganizations.data}
          onSuccess={() => onModalStateChange(false)}
          onCancel={() => onModalStateChange(false)}
          inDialog={true}
        />
      </ControlledModal>
    </>
  );
};
