import { Page } from 'layouts/Admin/Page';
import { useGetMailingLists } from 'api/useMailingListsApi';
import { toast } from 'react-toastify';

export const MailListsView = () => {
  const { data, isFetching } = useGetMailingLists();

  return (
    <Page title={'Mail lister'} description="Benyt følgende til BCC.">
      <div className="flex flex-col space-y-4 mt-4">
        {isFetching ? (
          <div>Loading...</div>
        ) : (
          <div className="flex flex-col space-y-4">
            {(data || []).map((mailList) => (
              <div key={mailList.name} className="flex flex-col space-y-2">
                <div className="flex flex-row gap-4">
                  <div className="font-bold">{mailList.name}</div>
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(mailList.emails.join(';'));
                      toast.success('Teksten er kopieret til udklipsholderen');
                    }}
                    className="text-sm text-blue-500 hover:underline"
                  >
                    Kopier tekst
                  </button>
                </div>
                <div className="mockup-code">
                  <pre data-prefix="BCC">{mailList.emails.join(';')}</pre>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </Page>
  );
};
