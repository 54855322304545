import { useGetOrganization } from 'api/useOrganizationsApi';
import { ControlledModal } from 'components/ControlledModal';
import { useState } from 'react';
import { OrganizationForm } from './OrganizationForm';
import { useGetVisualIdentities } from 'api/useVisualIdentitiesApi';

interface OrganizationUpdateProps {
  id?: string;
  trigger?: React.ReactNode;
  isInitialOpen?: boolean;
  onClosed?: () => void;
}

export const OrganizationUpdate = ({
  id,
  trigger,
  isInitialOpen,
  onClosed,
}: OrganizationUpdateProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(isInitialOpen ?? false);

  const { data } = useGetOrganization(id);
  const { data: visualIdentities } = useGetVisualIdentities();

  if (!data || !visualIdentities) return null;

  const onModalStateChange = (state: boolean) => {
    setIsOpen(state);
    if (!state) {
      onClosed?.();
    }
  };

  return (
    <>
      {trigger ? (
        <div onClick={() => onModalStateChange(true)}>{trigger}</div>
      ) : null}
      <ControlledModal
        showModal={onModalStateChange}
        isOpen={isOpen}
        title="Opdater organisation"
        closeOnOutsideClick
        hideActionBar
      >
        <OrganizationForm
          targetOrganization={data}
          allVisualIdentites={visualIdentities.data}
          onSuccess={() => onModalStateChange(false)}
          onCancel={() => onModalStateChange(false)}
        />
      </ControlledModal>
    </>
  );
};
