/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EWritingTargetAudience = {
    Unspecified: 'Unspecified',
    FamilyWithChildren: 'FamilyWithChildren',
    Couples: 'Couples',
    Seniors: 'Seniors',
    Singles: 'Singles',
    Students: 'Students'
} as const;
export type EWritingTargetAudience = typeof EWritingTargetAudience[keyof typeof EWritingTargetAudience];


export function EWritingTargetAudienceFromJSON(json: any): EWritingTargetAudience {
    return EWritingTargetAudienceFromJSONTyped(json, false);
}

export function EWritingTargetAudienceFromJSONTyped(json: any, ignoreDiscriminator: boolean): EWritingTargetAudience {
    return json as EWritingTargetAudience;
}

export function EWritingTargetAudienceToJSON(value?: EWritingTargetAudience | null): any {
    return value as any;
}

