/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ETemplatePosition = {
    First: 'First',
    Last: 'Last'
} as const;
export type ETemplatePosition = typeof ETemplatePosition[keyof typeof ETemplatePosition];


export function ETemplatePositionFromJSON(json: any): ETemplatePosition {
    return ETemplatePositionFromJSONTyped(json, false);
}

export function ETemplatePositionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ETemplatePosition {
    return json as ETemplatePosition;
}

export function ETemplatePositionToJSON(value?: ETemplatePosition | null): any {
    return value as any;
}

