/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddAddWatermarkResponse } from './AddAddWatermarkResponse';
import {
    AddAddWatermarkResponseFromJSON,
    AddAddWatermarkResponseFromJSONTyped,
    AddAddWatermarkResponseToJSON,
} from './AddAddWatermarkResponse';
import type { AddBackgroundMusicResponse } from './AddBackgroundMusicResponse';
import {
    AddBackgroundMusicResponseFromJSON,
    AddBackgroundMusicResponseFromJSONTyped,
    AddBackgroundMusicResponseToJSON,
} from './AddBackgroundMusicResponse';
import type { AddDynamicOverlayResponse } from './AddDynamicOverlayResponse';
import {
    AddDynamicOverlayResponseFromJSON,
    AddDynamicOverlayResponseFromJSONTyped,
    AddDynamicOverlayResponseToJSON,
} from './AddDynamicOverlayResponse';
import type { AudioAdjustmentsResponse } from './AudioAdjustmentsResponse';
import {
    AudioAdjustmentsResponseFromJSON,
    AudioAdjustmentsResponseFromJSONTyped,
    AudioAdjustmentsResponseToJSON,
} from './AudioAdjustmentsResponse';
import type { EBranchMutationType } from './EBranchMutationType';
import {
    EBranchMutationTypeFromJSON,
    EBranchMutationTypeFromJSONTyped,
    EBranchMutationTypeToJSON,
} from './EBranchMutationType';
import type { ResizingResponse } from './ResizingResponse';
import {
    ResizingResponseFromJSON,
    ResizingResponseFromJSONTyped,
    ResizingResponseToJSON,
} from './ResizingResponse';

/**
 * 
 * @export
 * @interface MediaSequenceBranchMutationResponse
 */
export interface MediaSequenceBranchMutationResponse {
    /**
     * 
     * @type {Date}
     * @memberof MediaSequenceBranchMutationResponse
     */
    lastModifiedUtc: Date;
    /**
     * 
     * @type {Date}
     * @memberof MediaSequenceBranchMutationResponse
     */
    createdUtc: Date;
    /**
     * 
     * @type {string}
     * @memberof MediaSequenceBranchMutationResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MediaSequenceBranchMutationResponse
     */
    mediaSequenceId: string;
    /**
     * 
     * @type {EBranchMutationType}
     * @memberof MediaSequenceBranchMutationResponse
     */
    type: EBranchMutationType;
    /**
     * 
     * @type {string}
     * @memberof MediaSequenceBranchMutationResponse
     */
    mutationOptionsJson?: string | null;
    /**
     * 
     * @type {AddBackgroundMusicResponse}
     * @memberof MediaSequenceBranchMutationResponse
     */
    addBackgroundMusic?: AddBackgroundMusicResponse;
    /**
     * 
     * @type {AddAddWatermarkResponse}
     * @memberof MediaSequenceBranchMutationResponse
     */
    addWatermark?: AddAddWatermarkResponse;
    /**
     * 
     * @type {AddDynamicOverlayResponse}
     * @memberof MediaSequenceBranchMutationResponse
     */
    addDynamicOverlay?: AddDynamicOverlayResponse;
    /**
     * 
     * @type {AudioAdjustmentsResponse}
     * @memberof MediaSequenceBranchMutationResponse
     */
    audioAdjustments?: AudioAdjustmentsResponse;
    /**
     * 
     * @type {ResizingResponse}
     * @memberof MediaSequenceBranchMutationResponse
     */
    resizing?: ResizingResponse;
}

/**
 * Check if a given object implements the MediaSequenceBranchMutationResponse interface.
 */
export function instanceOfMediaSequenceBranchMutationResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lastModifiedUtc" in value;
    isInstance = isInstance && "createdUtc" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "mediaSequenceId" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function MediaSequenceBranchMutationResponseFromJSON(json: any): MediaSequenceBranchMutationResponse {
    return MediaSequenceBranchMutationResponseFromJSONTyped(json, false);
}

export function MediaSequenceBranchMutationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MediaSequenceBranchMutationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastModifiedUtc': (new Date(json['lastModifiedUtc'])),
        'createdUtc': (new Date(json['createdUtc'])),
        'id': json['id'],
        'mediaSequenceId': json['mediaSequenceId'],
        'type': EBranchMutationTypeFromJSON(json['type']),
        'mutationOptionsJson': !exists(json, 'mutationOptionsJson') ? undefined : json['mutationOptionsJson'],
        'addBackgroundMusic': !exists(json, 'addBackgroundMusic') ? undefined : AddBackgroundMusicResponseFromJSON(json['addBackgroundMusic']),
        'addWatermark': !exists(json, 'addWatermark') ? undefined : AddAddWatermarkResponseFromJSON(json['addWatermark']),
        'addDynamicOverlay': !exists(json, 'addDynamicOverlay') ? undefined : AddDynamicOverlayResponseFromJSON(json['addDynamicOverlay']),
        'audioAdjustments': !exists(json, 'audioAdjustments') ? undefined : AudioAdjustmentsResponseFromJSON(json['audioAdjustments']),
        'resizing': !exists(json, 'resizing') ? undefined : ResizingResponseFromJSON(json['resizing']),
    };
}

export function MediaSequenceBranchMutationResponseToJSON(value?: MediaSequenceBranchMutationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lastModifiedUtc': (value.lastModifiedUtc.toISOString()),
        'createdUtc': (value.createdUtc.toISOString()),
        'id': value.id,
        'mediaSequenceId': value.mediaSequenceId,
        'type': EBranchMutationTypeToJSON(value.type),
        'mutationOptionsJson': value.mutationOptionsJson,
        'addBackgroundMusic': AddBackgroundMusicResponseToJSON(value.addBackgroundMusic),
        'addWatermark': AddAddWatermarkResponseToJSON(value.addWatermark),
        'addDynamicOverlay': AddDynamicOverlayResponseToJSON(value.addDynamicOverlay),
        'audioAdjustments': AudioAdjustmentsResponseToJSON(value.audioAdjustments),
        'resizing': ResizingResponseToJSON(value.resizing),
    };
}

