import { useTableQueryState } from 'components/Table/useTableQueryState';
import { Page } from 'layouts/Admin/Page';
import { ProjectsTable } from './components/Project/ProjecsTable';
import { useGetProjects } from 'api/useProjectsApi';
import { ProjectCreate } from './components/Project/ProjectCreate';
import { EPermission } from 'api/core';
import { PermissionProtectedComponent } from 'auth/PermissionProtectedComponent';

export const Projects = () => {
  const { state } = useTableQueryState();
  const { data, isFetching } = useGetProjects(state);

  return (
    <Page title="Projekter" breadcrumbs={[{ name: 'Projekter' }]}>
      <PermissionProtectedComponent permissions={[EPermission.ProjectWrite]}>
        <ProjectCreate
          trigger={
            <div className="flex justify-start py-4 w-full md:hidden">
              <button className="btn btn-primary mr-2">Opret projekt</button>
            </div>
          }
        />
      </PermissionProtectedComponent>
      <ProjectsTable data={data} isFetching={isFetching} queryState={state} />
    </Page>
  );
};
