import { ColumnDef } from '@tanstack/react-table';
import { SectionResponse, SectionResponsePagedData } from 'api/core';
import { CustomTable } from 'components/Table/CustomTable';
import { ActionCell } from 'components/Table/Cell/ActionCell';
import { DateTimeCell } from 'components/Table/Cell/DateTimeCell';
import { useGetCustomReactTable } from 'components/Table/useGetTableSet';
import { TableQueryState } from 'components/Table/useTableQueryState';

import { useMemo, useState } from 'react';
import { SectionUpdate } from './SectionUpdate';
import { DeleteEntityById } from 'components/Form/DeleteEntityById';
import { AnimatedIcon } from 'components/Icon/AnimatedIcon';
import { useDeleteSection, useGetSection } from 'api/useSectionsApi';

interface TableProps {
  data?: SectionResponsePagedData;
  isFetching: boolean;
  queryState: TableQueryState;
}

export const SectionsTable = ({ data, isFetching, queryState }: TableProps) => {
  const [editTarget, setEditTarget] = useState<SectionResponse | null>(null);
  const [deleteTarget, setDeleteTarget] = useState<SectionResponse | null>(
    null
  );

  const columns = useMemo(() => {
    const baseColumns: ColumnDef<SectionResponse>[] = [
      {
        accessorKey: 'order',
        header: 'Rækkefølge',
      },
      {
        accessorKey: 'name',
        header: 'Navn',
      },
      {
        accessorKey: 'maskId',
        header: 'Maske ID',
      },
      {
        accessorKey: 'lastModifiedUtc',
        header: 'Sidst redigeret',
        cell: (e) => <DateTimeCell value={e.row.original.lastModifiedUtc} />,
      },
      {
        accessorKey: 'createdUtc',
        header: 'Oprettet',
        cell: (e) => <DateTimeCell value={e.row.original.createdUtc} />,
      },
      {
        accessorKey: '_',
        header: 'Handlinger',
        enableSorting: false,
        cell: (e) => (
          <div className="flex space-x-5 md:space-x-3">
            <ActionCell
              icon={
                <AnimatedIcon
                  icon="pencil-icon"
                  className="h-8 md:h-6 w-8 md:w-6"
                />
              }
              onClick={() => setEditTarget(e.row.original)}
            />
            <ActionCell
              icon={
                <AnimatedIcon
                  icon="trash-icon"
                  className="h-8 md:h-6 w-8 md:w-6"
                />
              }
              onClick={() => setDeleteTarget(e.row.original)}
            />
          </div>
        ),
      },
    ];
    return baseColumns;
  }, [setEditTarget, setDeleteTarget]);

  const table = useGetCustomReactTable(
    data?.data ?? [],
    data?.page.totalPages ?? -1,
    columns,
    queryState
  );

  return (
    <>
      <CustomTable table={table} isLoading={isFetching} />
      {editTarget ? (
        <SectionUpdate
          id={editTarget?.id}
          isInitialOpen={true}
          onClosed={() => setEditTarget(null)}
        />
      ) : null}
      {deleteTarget ? (
        <DeleteEntityById
          id={deleteTarget?.id}
          isInitialOpen={true}
          onClosed={() => setDeleteTarget(null)}
          titlePropertyKey={'name'}
          getter={useGetSection}
          deleter={useDeleteSection}
        />
      ) : null}
    </>
  );
};
