import './index.css';
import './sentry';

import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AuthProvider } from './auth/AuthProvider';
import { App } from './App';
import { AdminLayout } from './layouts/Admin/AdminLayout';
import { NotFound } from './components/NotFound';
import { authenticatedRoutes, loginRoutes } from './routes';
import { LoginLayout } from 'layouts/Login/LoginLayout';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { wrapCreateBrowserRouter } from '@sentry/react';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const basename = document.querySelector('base')?.getAttribute('href') ?? '/';

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(
  [
    {
      path: '/',
      element: (
        <App>
          <AuthProvider>
            <AdminLayout />
          </AuthProvider>
        </App>
      ),
      errorElement: <NotFound />,
      children: [...authenticatedRoutes],
    },
    {
      path: '/login',
      element: (
        <App>
          <AuthProvider>
            <LoginLayout />
          </AuthProvider>
        </App>
      ),
      errorElement: <NotFound />,
      children: loginRoutes,
    },
  ],
  { basename }
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
