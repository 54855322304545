import { ColumnDef } from '@tanstack/react-table';
import { EPermission, UserResponse, UserResponsePagedData } from 'api/core';
import { CustomTable } from 'components/Table/CustomTable';
import { ActionCell } from 'components/Table/Cell/ActionCell';
import { DateTimeCell } from 'components/Table/Cell/DateTimeCell';
import { useGetCustomReactTable } from 'components/Table/useGetTableSet';
import { TableQueryState } from 'components/Table/useTableQueryState';
import { useMemo, useState } from 'react';
import { DeleteEntityById } from 'components/Form/DeleteEntityById';
import { ControlledModal } from 'components/ControlledModal';
import {
  useDeleteUser,
  useGetUser,
  useResendWelcomeMail,
} from 'api/useUsersApi';
import { UserUpdate } from './UserUpdate';
import { AnimatedIcon } from 'components/Icon/AnimatedIcon';

interface TableProps {
  data?: UserResponsePagedData;
  isFetching: boolean;
  queryState: TableQueryState;
}

export const UsersTable = ({ data, isFetching, queryState }: TableProps) => {
  const [editTarget, setEditTarget] = useState<UserResponse | null>(null);
  const [deleteTarget, setDeleteTarget] = useState<UserResponse | null>(null);
  const [permissionsTarget, setPermissionsTarget] =
    useState<UserResponse | null>(null);

  const { mutateAsync: resendWelcomeMailAsync } = useResendWelcomeMail();

  const columns = useMemo(() => {
    const resendWelcomeEmail = async (user: UserResponse) => {
      if (
        !confirm(
          `Er du sikker på at du vil gensende velkomstmail til ${user.name}?`
        )
      )
        return;
      await resendWelcomeMailAsync({ id: user.id });
    };

    const baseColumns: ColumnDef<UserResponse>[] = [
      {
        accessorKey: 'name',
        header: 'Navn',
      },
      {
        accessorKey: 'title',
        header: 'Titel',
      },
      {
        accessorKey: 'email',
        header: 'Email',
      },
      {
        accessorKey: 'roles',
        header: 'Roller',
        cell: (e) => (
          <>{e.row.original.roles?.map((e) => <p key={e.id}>{e.title}</p>)}</>
        ),
        enableSorting: false,
      },
      {
        accessorKey: 'roles.permissions',
        header: 'Rettigheder',
        enableSorting: false,
        cell: (e) =>
          e.row.original.roles.length > 0 ? (
            <ActionCell
              icon={<AnimatedIcon icon="zoom-icon" className="h-6 w-6" />}
              onClick={() => setPermissionsTarget(e.row.original)}
            />
          ) : null,
      },
      {
        accessorKey: 'lastModifiedUtc',
        header: 'Sidst redigeret',
        cell: (e) => <DateTimeCell value={e.row.original.lastModifiedUtc} />,
      },
      {
        accessorKey: 'createdUtc',
        header: 'Oprettet',
        cell: (e) => <DateTimeCell value={e.row.original.createdUtc} />,
      },
      {
        accessorKey: '_',
        header: 'Handlinger',
        enableSorting: false,
        cell: (e) => (
          <div className="flex space-x-5 md:space-x-3">
            <ActionCell
              permissions={[EPermission.UserWrite]}
              icon={
                <AnimatedIcon
                  icon="pencil-icon"
                  className="h-8 md:h-6 w-8 md:w-6"
                />
              }
              onClick={() => setEditTarget(e.row.original)}
            />
            <ActionCell
              permissions={[EPermission.UserDelete]}
              icon={
                <AnimatedIcon
                  icon="trash-icon"
                  className="h-8 md:h-6 w-8 md:w-6"
                />
              }
              onClick={() => setDeleteTarget(e.row.original)}
            />
            <ActionCell
              permissions={[
                EPermission.UserWrite,
                EPermission.UserResendWelcomeMail,
              ]}
              tooltip="Gensend velkomstmail"
              icon={
                <AnimatedIcon
                  icon="mail-plus-icon"
                  className="h-8 md:h-6 w-8 md:w-6"
                />
              }
              onClick={() => resendWelcomeEmail(e.row.original)}
            />
          </div>
        ),
      },
    ];
    return baseColumns;
  }, [
    setEditTarget,
    setDeleteTarget,
    setPermissionsTarget,
    resendWelcomeMailAsync,
  ]);

  const table = useGetCustomReactTable(
    data?.data ?? [],
    data?.page.totalPages ?? -1,
    columns,
    queryState
  );

  return (
    <>
      <CustomTable table={table} isLoading={isFetching} />
      {editTarget ? (
        <UserUpdate
          id={editTarget?.id}
          isInitialOpen={true}
          onClosed={() => setEditTarget(null)}
        />
      ) : null}
      {deleteTarget ? (
        <DeleteEntityById
          id={deleteTarget?.id}
          isInitialOpen={true}
          onClosed={() => setDeleteTarget(null)}
          titlePropertyKey={'name'}
          getter={useGetUser}
          deleter={useDeleteUser}
        />
      ) : null}
      {permissionsTarget ? (
        <ControlledModal
          showModal={() => setPermissionsTarget(null)}
          isOpen={permissionsTarget !== null}
          title={`${permissionsTarget.name} rettigheder`}
          closeOnOutsideClick
          hideActionBar
        >
          {permissionsTarget.roles?.map((role) => (
            <div className="pt-4" key={role.id}>
              <h3 className="font-bold">{role.title}</h3>
              {role.permissions?.map((permission) => (
                <p key={permission}>{permission}</p>
              ))}
            </div>
          ))}
        </ControlledModal>
      ) : null}
    </>
  );
};
