/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AssetResponse } from './AssetResponse';
import {
    AssetResponseFromJSON,
    AssetResponseFromJSONTyped,
    AssetResponseToJSON,
} from './AssetResponse';

/**
 * 
 * @export
 * @interface AddBackgroundMusicResponse
 */
export interface AddBackgroundMusicResponse {
    /**
     * 
     * @type {string}
     * @memberof AddBackgroundMusicResponse
     */
    assetId: string;
    /**
     * 
     * @type {AssetResponse}
     * @memberof AddBackgroundMusicResponse
     */
    asset: AssetResponse;
    /**
     * 
     * @type {number}
     * @memberof AddBackgroundMusicResponse
     */
    backgroundAudioVolume: number;
}

/**
 * Check if a given object implements the AddBackgroundMusicResponse interface.
 */
export function instanceOfAddBackgroundMusicResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "assetId" in value;
    isInstance = isInstance && "asset" in value;
    isInstance = isInstance && "backgroundAudioVolume" in value;

    return isInstance;
}

export function AddBackgroundMusicResponseFromJSON(json: any): AddBackgroundMusicResponse {
    return AddBackgroundMusicResponseFromJSONTyped(json, false);
}

export function AddBackgroundMusicResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddBackgroundMusicResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assetId': json['assetId'],
        'asset': AssetResponseFromJSON(json['asset']),
        'backgroundAudioVolume': json['backgroundAudioVolume'],
    };
}

export function AddBackgroundMusicResponseToJSON(value?: AddBackgroundMusicResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assetId': value.assetId,
        'asset': AssetResponseToJSON(value.asset),
        'backgroundAudioVolume': value.backgroundAudioVolume,
    };
}

