import { useEffect, useState } from 'react';

const DEFAULT_DOCUMENT_TITLE = 'Smart Create';

export const useDocumentTitle = (title?: string) => {
  const [documentTitle, setDocumentTitle] = useState<string | undefined>(title);

  useEffect(() => {
    if (documentTitle) {
      document.title = `${DEFAULT_DOCUMENT_TITLE} - ${documentTitle}`;
    } else {
      document.title = DEFAULT_DOCUMENT_TITLE;
    }
  }, [documentTitle]);

  return setDocumentTitle;
};
