/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MediaSequenceTemplateCloneRequest
 */
export interface MediaSequenceTemplateCloneRequest {
    /**
     * 
     * @type {string}
     * @memberof MediaSequenceTemplateCloneRequest
     */
    templateId: string;
    /**
     * 
     * @type {string}
     * @memberof MediaSequenceTemplateCloneRequest
     */
    targetOrganizationId: string;
    /**
     * 
     * @type {string}
     * @memberof MediaSequenceTemplateCloneRequest
     */
    name: string;
}

/**
 * Check if a given object implements the MediaSequenceTemplateCloneRequest interface.
 */
export function instanceOfMediaSequenceTemplateCloneRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "templateId" in value;
    isInstance = isInstance && "targetOrganizationId" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function MediaSequenceTemplateCloneRequestFromJSON(json: any): MediaSequenceTemplateCloneRequest {
    return MediaSequenceTemplateCloneRequestFromJSONTyped(json, false);
}

export function MediaSequenceTemplateCloneRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MediaSequenceTemplateCloneRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'templateId': json['templateId'],
        'targetOrganizationId': json['targetOrganizationId'],
        'name': json['name'],
    };
}

export function MediaSequenceTemplateCloneRequestToJSON(value?: MediaSequenceTemplateCloneRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'templateId': value.templateId,
        'targetOrganizationId': value.targetOrganizationId,
        'name': value.name,
    };
}

