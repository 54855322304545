import {
  AssetSimpleResponse,
  EAssetType,
  MediaSequenceAssetCreateRequest,
  MediaSequenceAssetResponse,
  MediaSequenceAssetUpdateRequest,
} from 'api/core';
import {
  useCreateMediaSequenceAsset,
  useUpdateMediaSequenceAsset,
} from 'api/useMediaSequenceAssetsApi';
import { AssetUpload } from 'components/Form/AssetUpload';
import { AssetSelectSingleAsync } from 'components/Select/AssetSelectSingleAsync';
import { MEDIA_SEQUENCE_ACCEPTED_FILES } from 'pages/MediaOrbit/media-sequence-utils';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

interface MediaSequenceAssetFormProps {
  targetAsset?: MediaSequenceAssetResponse;
  mediaSequenceId?: string;
  projectId?: string;
  onSuccess?: (asset: MediaSequenceAssetResponse) => void;
  onCancel?: () => void;
  inDialog?: boolean;
}

export const MediaSequenceAssetForm = ({
  targetAsset,
  mediaSequenceId,
  projectId,
  onSuccess,
  onCancel,
  inDialog,
}: MediaSequenceAssetFormProps) => {
  const { mutateAsync: createAsync, isPending: isPendingCreate } =
    useCreateMediaSequenceAsset();
  const { mutateAsync: updateAsync, isPending: isPendingUpdate } =
    useUpdateMediaSequenceAsset();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [initialSelectedAsset, setInitialSelectedAsset] = useState<
    AssetSimpleResponse | undefined
  >(targetAsset?.asset);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { touchedFields },
  } = useForm<
    MediaSequenceAssetCreateRequest | MediaSequenceAssetUpdateRequest
  >({
    defaultValues: targetAsset
      ? {
          name: targetAsset.name,
          assetId: targetAsset.asset.id,
        }
      : undefined,
  });

  const onSubmit = handleSubmit(async (result) => {
    if (targetAsset) {
      const assetResponse = await updateAsync({
        id: targetAsset.id,
        mediaSequenceAssetUpdateRequest: result,
      });
      onSuccess?.(assetResponse);
    } else {
      if (!mediaSequenceId) return;
      const assetResponse = await createAsync({
        mediaSequenceId,
        mediaSequenceAssetCreateRequest: result,
      });
      onSuccess?.(assetResponse);
    }
  });

  const onAssetSelected = (asset: AssetSimpleResponse | null) => {
    if (!asset) return;
    if ((!targetAsset && !touchedFields.name) || getValues('name') === '') {
      setValue('name', asset.originalFileName);
    }
    setValue('assetId', asset.id);
    setInitialSelectedAsset(asset);
  };

  return (
    <>
      <AssetUpload
        fileInputRef={fileInputRef}
        projectId={projectId}
        onAssetUploaded={(asset) => onAssetSelected(asset)}
        accept={MEDIA_SEQUENCE_ACCEPTED_FILES}
      />
      <form onSubmit={onSubmit} className="space-y-2">
        <div className="form-control">
          <label className="label">
            <span className="label-text">Fil</span>
          </label>
          <AssetSelectSingleAsync
            // Allow the user to seearch in all assets on organization (Not just project assets)
            // projectId={projectId}
            types={[EAssetType.Image, EAssetType.Video]}
            onSelected={onAssetSelected}
            initialValue={initialSelectedAsset}
            showCreateOption
            onCreateOptionSelected={() => fileInputRef.current?.click()}
            inDialog={inDialog}
          />
          <input
            {...register('assetId', { required: true })}
            type="text"
            className="hidden"
          />
        </div>

        <div className="form-control">
          <label className="label">
            <span className="label-text">Navn</span>
          </label>
          <input
            {...register('name', { required: true })}
            type="text"
            className="input input-bordered"
          />
        </div>

        <div className="flex justify-center space-x-4 pt-4">
          <button
            className="btn btn-primary"
            disabled={isPendingCreate || isPendingUpdate}
          >
            {targetAsset ? 'Opdater' : 'Tilføj'}
          </button>
          {onCancel ? (
            <button type="button" className="btn" onClick={onCancel}>
              Annuller
            </button>
          ) : null}
        </div>
      </form>
    </>
  );
};
