/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ECaseState = {
    Available: 'AVAILABLE',
    Reserved: 'RESERVED',
    Unavailable: 'UNAVAILABLE',
    Deactivated: 'DEACTIVATED'
} as const;
export type ECaseState = typeof ECaseState[keyof typeof ECaseState];


export function ECaseStateFromJSON(json: any): ECaseState {
    return ECaseStateFromJSONTyped(json, false);
}

export function ECaseStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ECaseState {
    return json as ECaseState;
}

export function ECaseStateToJSON(value?: ECaseState | null): any {
    return value as any;
}

