import {
  AssetSimpleResponse,
  EPublicationPlatform,
  PublicationCreateRequest,
  PublicationResponse,
  PublicationUpdateRequest,
} from 'api/core';
import {
  useCreatePublication,
  useInvokePublication,
  useUpdatePublication,
} from 'api/usePublicationsApi';
import { EntitySelectMultiple } from 'components/Select/EntitySelectMultiple';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useGetExternalIntegrations } from 'api/useExternalIntegrationsApi';
import { useIsMobile } from 'utils/useIsMobile';
import { AssetPreviewCell } from 'components/Table/Cell/AssetPreviewCell';
import { useGetAssets } from 'api/useAssetsApi';

type PlatformWithId = { id: EPublicationPlatform; title: string };

interface PublicationFormProps {
  targetPublication?: PublicationResponse;
  onSuccess?: () => void;
  onCancel?: () => void;
}

export const PublicationForm = ({
  targetPublication,
  onSuccess,
  onCancel,
}: PublicationFormProps) => {
  const { mutateAsync: createAsync, isPending: isPendingCreate } =
    useCreatePublication();
  const { mutateAsync: updateAsync, isPending: isPendingUpdate } =
    useUpdatePublication();
  const { mutateAsync: invokeAsync, isPending: isPendingInvoke } =
    useInvokePublication();

  // TODO: This only includes the first page of assets. We should fetch all pages?

  const { data: assetsPage } = useGetAssets();

  const disabled = targetPublication?.state === 'Scheduled';

  const assets = useMemo(() => {
    return (
      assetsPage?.data?.map((asset) => ({
        id: asset.id,
        originalFileName: asset.originalFileName,
        url: asset.url,
        previewUrl: asset.previewUrl,
        type: asset.type,
      })) ?? []
    );
  }, [assetsPage?.data]);

  const { data: externalIntegrations } = useGetExternalIntegrations();

  const platforms: PlatformWithId[] = [];
  if (
    externalIntegrations?.data.some(
      (ei) => ei.type === 'Facebook' && !ei.isExpired
    )
  ) {
    platforms.push({
      id: EPublicationPlatform.FacebookFeed,
      title: 'Facebook feed',
    });
    //platforms.push({
    //  id: EPublicationPlatform.FacebookStory,
    //  title: 'Facebook story',
    //});
  }

  //if (externalIntegrations?.data.some((ei) => ei.type === 'Instagram' && !ei.isExpired)) {
  //  platforms.push({ id: EPublicationPlatform.InstagramFeed, title: 'Instagram feed' });
  //  platforms.push({ id: EPublicationPlatform.InstagramStory, title: 'Instagram story' });
  //}

  let utcDate;
  if (targetPublication?.scheduledTimeUtc) {
    utcDate = new Date(targetPublication?.scheduledTimeUtc);
  } else {
    const now = new Date();
    utcDate = new Date(now.getTime());

    const currentUTCHours = now.getUTCHours();

    if (currentUTCHours < 8) {
      utcDate.setUTCHours(10, 0, 0, 0);
    } else if (currentUTCHours >= 20) {
      utcDate.setUTCDate(now.getUTCDate() + 1);
      utcDate.setUTCHours(10, 0, 0, 0);
    } else {
      utcDate.setUTCHours(currentUTCHours + 2, 0, 0, 0);
    }
  }

  const localDate = new Date(
    utcDate.getTime() - new Date().getTimezoneOffset() * 60000
  );
  const localDateOnly = localDate.toISOString().split('T')[0];
  const localTimeOnly = localDate.toISOString().split('T')[1].substring(0, 5);

  const { register, handleSubmit, setValue, watch, formState } = useForm<
    (PublicationCreateRequest | PublicationUpdateRequest) & {
      scheduledTimeLocalDate: string;
      scheduledTimeLocalTime: string;
    }
  >({
    defaultValues: {
      ...targetPublication,
      scheduledTimeLocalDate: localDateOnly,
      scheduledTimeLocalTime: localTimeOnly,
      platforms: !targetPublication
        ? platforms.map((p) => p.id)
        : (platforms
            .filter((p) =>
              targetPublication?.destinations?.some((d) => d.platform === p.id)
            )
            .map((p) => p.id) ?? []),
      assetIds: targetPublication?.assets.map((asset) => asset.id) ?? [],
    },
  });

  const isMobile = useIsMobile();
  const selectedScheduledTimeLocalDate = watch('scheduledTimeLocalDate');
  const selectedScheduledTimeLocalTime = watch('scheduledTimeLocalTime');
  const selectedAssetIds = watch('assetIds');
  const selectedPlatforms = watch('platforms');
  const [isScheduledTimeValid, setIsScheduledTimeValid] =
    useState<boolean>(false);
  const [areAssetIdsValid, setAreAssetIdsValid] = useState<boolean>(false);

  useEffect(() => {
    const validateScheduledTime = () => {
      const selectedScheduledTime = new Date(
        `${selectedScheduledTimeLocalDate}T${selectedScheduledTimeLocalTime}`
      );
      const fifteenMinutesFromNow = new Date(Date.now() + 15 * 60 * 1000);
      const twentyEightDaysFromNow = new Date(
        Date.now() + 28 * 24 * 60 * 60 * 1000
      );
      setIsScheduledTimeValid(
        selectedScheduledTime >= fifteenMinutesFromNow &&
          selectedScheduledTime <= twentyEightDaysFromNow
      );
    };

    // Run validation immediately and then every second
    validateScheduledTime();
    const interval = setInterval(validateScheduledTime, 1000);

    // Cleanup the interval on component unmount or when dependencies change
    return () => clearInterval(interval);
  }, [selectedScheduledTimeLocalDate, selectedScheduledTimeLocalTime]);

  useEffect(() => {
    const selectedAssets = assets.filter((a) =>
      selectedAssetIds?.includes(a.id)
    );
    if (selectedAssets.length === 0) {
      setAreAssetIdsValid(false);
      return;
    }

    // Exacly one video
    if (selectedAssets.length === 1 && selectedAssets[0].type === 'Video') {
      setAreAssetIdsValid(true);
      return;
    }

    // One or more images
    setAreAssetIdsValid(selectedAssets.every((a) => a.type === 'Image'));
  }, [assets, selectedAssetIds]);

  const onSubmit = handleSubmit(async (result) => {
    const localDate = new Date(
      `${result.scheduledTimeLocalDate}T${result.scheduledTimeLocalTime}`
    );

    if (targetPublication) {
      await updateAsync({
        id: targetPublication.id,
        publicationUpdateRequest: {
          ...result,
          scheduledTimeUtc: localDate,
        },
      });
    } else {
      await createAsync({
        publicationCreateRequest: {
          ...result,
          scheduledTimeUtc: localDate,
        },
      });
    }
    onSuccess?.();
  });

  const onPlatformsSelected = (platforms: PlatformWithId[] | null) => {
    const platformIds = platforms?.map((platform) => platform.id) ?? [];
    setValue('platforms', platformIds);
  };

  const onAssetsSelected = (assets: AssetSimpleResponse[] | null) => {
    const assetIds = assets?.map((asset) => asset.id) ?? [];
    setValue('assetIds', assetIds);
  };

  const [isFormDirty, setIsFormDirty] = useState(false);
  const watchedValues = watch();

  useEffect(() => {
    // Function to deeply compare two objects
    const isEqual = (obj1: unknown, obj2: unknown): boolean => {
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    };

    const hasChanged = !isEqual(watchedValues, formState.defaultValues);
    setIsFormDirty(hasChanged);
  }, [watchedValues, formState.defaultValues]);

  return (
    <form onSubmit={onSubmit} className="space-y-2">
      <div className="form-control">
        <label className="label">
          <span className="label-text">Navn</span>
        </label>
        <input
          {...register('name', { required: true })}
          type="text"
          className="input input-bordered"
          disabled={disabled}
        />
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">Publiceringsdato</span>
        </label>
        <input
          {...register('scheduledTimeLocalDate', {
            required: true,
            validate: () => isScheduledTimeValid,
          })}
          type="date"
          min={
            new Date(Date.now() + 15 * 60 * 1000).toISOString().split('T')[0]
          }
          max={
            new Date(Date.now() + 28 * 24 * 60 * 60 * 1000)
              .toISOString()
              .split('T')[0]
          }
          defaultValue={new Date().toISOString().substring(0, 16)}
          className="input input-bordered"
          disabled={disabled}
        />
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">Publiceringstidspunkt</span>
        </label>
        <input
          {...register('scheduledTimeLocalTime', { required: true })}
          type="time"
          defaultValue={new Date().toLocaleTimeString('en-GB', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          })}
          className="input input-bordered"
          disabled={disabled}
        />
      </div>

      {!isScheduledTimeValid && !disabled ? (
        <div className="text-red-600">
          Publiceringstidspunkt skal være mindst 15 minutter i fremtiden, og
          højst 28 dage i fremtiden.
        </div>
      ) : null}

      <div className="form-control">
        <label className="label">
          <span className="label-text">Besked</span>
        </label>
        <textarea
          {...register('message', {
            required: true,
          })}
          rows={isMobile ? 4 : 6}
          className="textarea textarea-bordered w-full"
          disabled={disabled}
        />
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">Filer</span>
        </label>
        <EntitySelectMultiple<AssetSimpleResponse>
          data={assets}
          renderFormat={(asset) => (
            <div className="flex items-center justify-start space-x-2">
              <AssetPreviewCell asset={asset} />
              <p>{asset.originalFileName}</p>
            </div>
          )}
          onSelect={onAssetsSelected}
          initialValue={targetPublication?.assets ?? []}
          searchPropertyKey="originalFileName"
          inDialog={true}
          disabled={disabled}
        />
        <input
          {...register('assetIds', { required: false })}
          type="text"
          className="input input-bordered hidden"
          disabled={disabled}
        />
      </div>

      {!areAssetIdsValid && !disabled ? (
        <div className="text-red-600">
          Filer skal bestå enten af
          <ul className="list-disc pl-4">
            <li>Én video, eller</li>
            <li>Én eller flere billeder</li>
          </ul>
        </div>
      ) : null}

      {/* // TODO: This should have a disabled state? */}
      <div className="form-control">
        <label className="label">
          <span className="label-text">Kanaler</span>
        </label>
        <EntitySelectMultiple<PlatformWithId>
          data={platforms}
          renderFormat={(platform) => <p>{platform.title}</p>}
          onSelect={onPlatformsSelected}
          initialValue={
            !targetPublication
              ? [...platforms]
              : (platforms.filter((p) =>
                  targetPublication?.destinations?.some(
                    (d) => d.platform === p.id
                  )
                ) ?? [])
          }
          searchPropertyKey="title"
          inDialog={true}
          disabled={disabled}
        />
        <input
          {...register('platforms', { required: true })}
          type="text"
          className="hidden"
        />
      </div>

      <div className="flex justify-center space-x-4 pt-4">
        <button
          className="btn btn-primary"
          disabled={
            isPendingCreate ||
            isPendingUpdate ||
            !isScheduledTimeValid ||
            !areAssetIdsValid ||
            !selectedPlatforms?.length ||
            disabled ||
            !isFormDirty
          }
        >
          {targetPublication ? 'Opdater' : 'Opret'}
        </button>
        {targetPublication && targetPublication.state === 'Draft' ? (
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              invokeAsync({ id: targetPublication.id });
              onSuccess?.();
            }}
            disabled={
              isFormDirty ||
              !isScheduledTimeValid ||
              !areAssetIdsValid ||
              !selectedPlatforms?.length ||
              isPendingInvoke
            }
          >
            Planlæg publicering
          </button>
        ) : null}
        {onCancel ? (
          <button type="button" className="btn" onClick={onCancel}>
            Annuller
          </button>
        ) : null}
      </div>
    </form>
  );
};
