/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FieldCreateRequest,
  FieldResponse,
  FieldResponsePagedData,
  FieldUpdateRequest,
} from '../models';
import {
    FieldCreateRequestFromJSON,
    FieldCreateRequestToJSON,
    FieldResponseFromJSON,
    FieldResponseToJSON,
    FieldResponsePagedDataFromJSON,
    FieldResponsePagedDataToJSON,
    FieldUpdateRequestFromJSON,
    FieldUpdateRequestToJSON,
} from '../models';

export interface FieldsGetRequest {
    projectId?: string;
    pageNumber?: number;
    size?: number;
    orderByProperty?: string;
    ascending?: boolean;
    searchTerm?: string;
}

export interface FieldsIdDeleteRequest {
    id: string;
}

export interface FieldsIdGetRequest {
    id: string;
}

export interface FieldsIdPutRequest {
    id: string;
    fieldUpdateRequest?: FieldUpdateRequest;
}

export interface FieldsProjectIdPostRequest {
    projectId: string;
    fieldCreateRequest?: FieldCreateRequest;
}

/**
 * 
 */
export class FieldsApi extends runtime.BaseAPI {

    /**
     */
    async fieldsGetRaw(requestParameters: FieldsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FieldResponsePagedData>> {
        const queryParameters: any = {};

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.orderByProperty !== undefined) {
            queryParameters['orderByProperty'] = requestParameters.orderByProperty;
        }

        if (requestParameters.ascending !== undefined) {
            queryParameters['ascending'] = requestParameters.ascending;
        }

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['searchTerm'] = requestParameters.searchTerm;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Fields`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FieldResponsePagedDataFromJSON(jsonValue));
    }

    /**
     */
    async fieldsGet(requestParameters: FieldsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FieldResponsePagedData> {
        const response = await this.fieldsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fieldsIdDeleteRaw(requestParameters: FieldsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FieldResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fieldsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Fields/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FieldResponseFromJSON(jsonValue));
    }

    /**
     */
    async fieldsIdDelete(requestParameters: FieldsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FieldResponse> {
        const response = await this.fieldsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fieldsIdGetRaw(requestParameters: FieldsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FieldResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fieldsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Fields/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FieldResponseFromJSON(jsonValue));
    }

    /**
     */
    async fieldsIdGet(requestParameters: FieldsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FieldResponse> {
        const response = await this.fieldsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fieldsIdPutRaw(requestParameters: FieldsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FieldResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fieldsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Fields/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FieldUpdateRequestToJSON(requestParameters.fieldUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FieldResponseFromJSON(jsonValue));
    }

    /**
     */
    async fieldsIdPut(requestParameters: FieldsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FieldResponse> {
        const response = await this.fieldsIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fieldsProjectIdPostRaw(requestParameters: FieldsProjectIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FieldResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling fieldsProjectIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Fields/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FieldCreateRequestToJSON(requestParameters.fieldCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FieldResponseFromJSON(jsonValue));
    }

    /**
     */
    async fieldsProjectIdPost(requestParameters: FieldsProjectIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FieldResponse> {
        const response = await this.fieldsProjectIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
