import { ColumnDef } from '@tanstack/react-table';
import { AssetResponse, AssetResponsePagedData, EPermission } from 'api/core';
import { CustomTable } from 'components/Table/CustomTable';
import { ActionCell } from 'components/Table/Cell/ActionCell';
import { DateTimeCell } from 'components/Table/Cell/DateTimeCell';
import { useGetCustomReactTable } from 'components/Table/useGetTableSet';
import { TableQueryState } from 'components/Table/useTableQueryState';
import { useMemo, useState } from 'react';
import { DeleteEntityById } from 'components/Form/DeleteEntityById';
import { useDeleteAsset, useGetAsset } from 'api/useAssetsApi';
import { formatFileSize } from 'utils/format/file-size';
import { AssetUpdate } from './AssetUpdate';
import { AnimatedIcon } from 'components/Icon/AnimatedIcon';
import { assetSourceTranslate, assetTypeTranslate } from 'utils/enum-translate';
import { LinkCell } from 'components/Table/Cell/LinkCell';
import { useAuth } from 'auth/AuthProvider';
import { AssetPreviewModal } from 'components/Asset/AssetPreviewModal';

interface TableProps {
  data?: AssetResponsePagedData;
  isFetching: boolean;
  queryState: TableQueryState;
  hideProjectColumn?: boolean;
}

export const AssetsTable = ({
  data,
  isFetching,
  queryState,
  hideProjectColumn,
}: TableProps) => {
  const [editTarget, setEditTarget] = useState<AssetResponse | null>(null);
  const [deleteTarget, setDeleteTarget] = useState<AssetResponse | null>(null);
  const { hasPermissions } = useAuth();

  const columns = useMemo(() => {
    const baseColumns: ColumnDef<AssetResponse>[] = [
      {
        accessorKey: '_preview',
        header: 'Preview',
        enableSorting: false,
        cell: (e) => (
          <AssetPreviewModal asset={e.row.original} disablePreview />
        ),
      },
      {
        accessorKey: 'originalFileName',
        header: 'Navn',
      },
      {
        accessorKey: 'projectId',
        header: 'Projekt',
        cell: (e) =>
          e.row.original.projectId ? (
            <LinkCell path={`/projects/${e.row.original.projectId}`} />
          ) : null,
      },
      {
        accessorKey: 'type',
        header: 'Type',
        cell: (e) => assetTypeTranslate(e.row.original.type),
      },
      {
        accessorKey: 'fileSize',
        header: 'Størrelse',
        cell: (e) => formatFileSize(e.row.original.fileSize),
      },
      {
        accessorKey: 'user.name',
        header: 'Bruger',
        enableSorting: false,
      },
      {
        accessorKey: 'source',
        header: 'Kilde',
        cell: (e) => assetSourceTranslate(e.row.original.source),
      },
      // {
      //   accessorKey: 'lastModifiedUtc',
      //   header: 'Sidst redigeret',
      //   cell: (e) => <DateTimeCell value={e.row.original.lastModifiedUtc} />,
      // },
      {
        accessorKey: 'createdUtc',
        header: 'Oprettet',
        cell: (e) => <DateTimeCell value={e.row.original.createdUtc} />,
      },
      {
        accessorKey: '_',
        header: 'Handlinger',
        enableSorting: false,
        cell: (e) =>
          e.row.original.isGlobal ? null : (
            <div className="flex space-x-5 md:space-x-3">
              <ActionCell
                permissions={[EPermission.AssetWrite]}
                icon={
                  <AnimatedIcon
                    icon="pencil-icon"
                    className="h-8 md:h-6 w-8 md:w-6"
                  />
                }
                onClick={() => setEditTarget(e.row.original)}
              />
              <ActionCell
                permissions={[EPermission.AssetDelete]}
                icon={
                  <AnimatedIcon
                    icon="trash-icon"
                    className="h-8 md:h-6 w-8 md:w-6"
                  />
                }
                onClick={() => setDeleteTarget(e.row.original)}
              />
            </div>
          ),
      },
    ];
    return baseColumns;
  }, [setEditTarget, setDeleteTarget]);

  const table = useGetCustomReactTable(
    data?.data ?? [],
    data?.page.totalPages ?? -1,
    columns,
    queryState,
    {
      columnVisibility: {
        projectId:
          !!hideProjectColumn && hasPermissions([EPermission.ModuleProject]),
      },
    }
  );

  return (
    <>
      <CustomTable table={table} isLoading={isFetching} />
      {editTarget ? (
        <AssetUpdate
          id={editTarget?.id}
          isInitialOpen={true}
          onClosed={() => setEditTarget(null)}
        />
      ) : null}
      {deleteTarget ? (
        <DeleteEntityById
          id={deleteTarget?.id}
          isInitialOpen={true}
          onClosed={() => setDeleteTarget(null)}
          titlePropertyKey={'originalFileName'}
          getter={useGetAsset}
          deleter={useDeleteAsset}
        />
      ) : null}
    </>
  );
};
