import { ResponsiveBar } from '@nivo/bar';
import {
  ChartResponse,
  ChartResponseEntry,
  UserSimpleResponse,
} from 'api/core';
import { Tooltip } from 'components/Tooltip';
import { useMemo } from 'react';
import { formatDate, formatDistanceToNow } from 'utils/format/date';
import { useIsMobile } from 'utils/useIsMobile';

export const ChartGrid = ({ charts }: { charts: ChartResponse[] }) => {
  const isMobile = useIsMobile();

  if (charts.length === 0 || isMobile) {
    return null;
  }

  return (
    <div className="grid grid-cols-1  gap-6">
      {charts.map((chart, index) => (
        <ChartCard key={index} chart={chart} />
      ))}
    </div>
  );
};

interface ChartCardProps {
  chart: ChartResponse;
}

interface ProcessedDataEntry {
  date: string;
  [userName: string]: number | string;
}

const ChartCard = ({ chart }: ChartCardProps) => {
  const userMap = useMemo(() => {
    const map: Record<string, string> = {};
    chart.users.forEach((user: UserSimpleResponse) => {
      map[user.id] = user.name;
    });
    return map;
  }, [chart.users]);

  const processedData = useMemo<ProcessedDataEntry[]>(() => {
    const groupedData: Record<string, ProcessedDataEntry> = {};
    (chart.entries || []).forEach((entry: ChartResponseEntry) => {
      if (entry.date && entry.userId) {
        const dateKey = formatDate(entry.date);
        const userName = userMap[entry.userId];
        if (userName) {
          if (!groupedData[dateKey]) {
            groupedData[dateKey] = { date: dateKey };
          }
          groupedData[dateKey][userName] = entry.count || 0;
        }
      }
    });

    return Object.values(groupedData).sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );
  }, [chart.entries, userMap]);

  const uniqueUserNames = useMemo<string[]>(() => {
    return chart.users.map((user: UserSimpleResponse) => user.name);
  }, [chart.users]);

  if (!chart.entries || chart.entries.length === 0) {
    return null;
  }

  return (
    <div className="border p-4 shadow rounded-lg">
      <div className="flex items-center gap-2">
        <h1 className="text-primary text-lg font-bold">{chart.name}</h1>
        <Tooltip
          tooltip={`${formatDate(chart.startingDate)}-${formatDate(new Date())}`}
        >
          <h3 className="text-sm text-gray-500">
            Viser data for de sidste{' '}
            {formatDistanceToNow(chart.startingDate, {
              unit: 'day',
              addSuffix: false,
            })}
          </h3>
        </Tooltip>
      </div>
      <div style={{ height: 300 }}>
        <ResponsiveBar
          data={processedData}
          keys={uniqueUserNames}
          indexBy="date"
          margin={{
            top: 20,
            right: 150,
            bottom: 20,
            left: 30,
          }}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'top-right',
              direction: 'column',
              translateX: 130,
              translateY: -20,
              itemWidth: 120,
              itemHeight: 20,
              itemsSpacing: 10,
              symbolSize: 20,
              itemOpacity: 0.85,
              itemDirection: 'left-to-right',
              toggleSerie: true,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          colors={{ scheme: 'accent' }}
          enableLabel={false}
          groupMode="grouped"
          tooltip={({ indexValue }) => {
            const dataForDate = processedData.find(
              (entry) => entry.date === indexValue
            );
            return (
              <div className="flex flex-col gap-2 p-4 bg-white border border-gray-300 rounded-md shadow-lg">
                <div>
                  <h3 className="text-lg text-primary font-bold">
                    {indexValue}
                  </h3>
                  <h4 className="text-sm text-gray-500">
                    {formatDistanceToNow(new Date(indexValue))}
                  </h4>
                </div>
                <div className="flex flex-col gap-2">
                  {uniqueUserNames.map((userName) => (
                    <div key={userName} className="text-sm">
                      {userName}:{' '}
                      <span className="font-bold">
                        {dataForDate ? (dataForDate[userName] ?? 0) : 0}
                      </span>
                    </div>
                  ))}
                </div>
                <h4 className="text-sm">
                  I alt:{' '}
                  <span className="font-bold">
                    {dataForDate
                      ? Object.values(dataForDate)
                          .filter((value) => typeof value === 'number')
                          .reduce((acc, curr) => acc + (curr as number), 0)
                      : 0}
                  </span>
                </h4>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};
