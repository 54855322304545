import { useMutation, useQuery } from '@tanstack/react-query';
import { TableQueryState } from 'components/Table/useTableQueryState';
import { queryClient } from 'query-client';
import { toast } from 'react-toastify';
import { CoreBaseConfiguration } from './BaseConfiguration';
import {
  AssetCreateRequest,
  AssetsApi,
  AssetUpdateRequest,
  EAssetType,
} from './core';

const AssetApiKeys = {
  GET_ASSETS_SEARCH: 'assets-search',
  GET_ASSETS: 'assets',
  GET_ASSET: 'asset',
};

const client = new AssetsApi(new CoreBaseConfiguration());

// TODO: Improve this with pagination or something?
export const useSearchAssets = ({
  projectId,
  searchTerm,
  types,
}: {
  projectId?: string;
  searchTerm?: string;
  types?: EAssetType[];
}) => {
  return useQuery({
    queryKey: [AssetApiKeys.GET_ASSETS_SEARCH, searchTerm, types],
    queryFn: () =>
      client.assetsSearchGet({
        projectId,
        size: 50,
        searchTerm,
        types,
      }),
    enabled: searchTerm !== undefined || searchTerm != '',
  });
};

export const useGetAssets = (projectId?: string, state?: TableQueryState) => {
  return useQuery({
    queryKey: [AssetApiKeys.GET_ASSETS, projectId, state],
    queryFn: () =>
      client.assetsGet({
        projectId,
        pageNumber: state?.pagination.pageIndex,
        size: state?.pagination.pageSize,
        orderByProperty: state?.sorting[0]?.id,
        ascending: state?.sorting[0]?.desc === false,
        searchTerm: state?.globalFilter,
      }),
  });
};

export const useGetAsset = (id?: string) => {
  return useQuery({
    queryKey: [AssetApiKeys.GET_ASSET, id],
    queryFn: () => client.assetsIdGet({ id: id ?? '' }),
    enabled: id !== undefined,
  });
};

export const useBeginCreateAsset = () => {
  return useMutation({
    mutationFn: ({
      assetCreateRequest,
    }: {
      assetCreateRequest: AssetCreateRequest;
    }) => client.assetsPost({ assetCreateRequest }),
    onSuccess: (e) => {
      if (e.foundMatchingAsset) {
        queryClient.invalidateQueries({
          queryKey: [AssetApiKeys.GET_ASSETS],
        });
        toast.success(`Filen '${e.originalFileName}' er blevet oprettet`, {
          toastId: 'createAsset',
        });
      }
    },
  });
};

export const useFinalizeCreateAsset = () => {
  return useMutation({
    mutationFn: ({ id }: { id: string }) => client.assetsFinalizeIdPut({ id }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [AssetApiKeys.GET_ASSETS],
      });
      toast.success(`Filen '${e.originalFileName}' er blevet oprettet`, {
        toastId: 'finalizeAsset',
      });
    },
  });
};

export const useUpdateAsset = () => {
  return useMutation({
    mutationFn: ({
      id,
      assetUpdateRequest,
    }: {
      id: string;
      assetUpdateRequest: AssetUpdateRequest;
    }) => client.assetsIdPut({ id, assetUpdateRequest }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [AssetApiKeys.GET_ASSETS],
      });
      queryClient.invalidateQueries({
        queryKey: [AssetApiKeys.GET_ASSET, e.id],
      });
      toast.success(`Filen '${e.originalFileName}' er blevet opdateret`, {
        toastId: 'updateAsset',
      });
    },
  });
};

export const useDeleteAsset = () => {
  return useMutation({
    mutationFn: ({ id }: { id: string }) => client.assetsIdDelete({ id }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [AssetApiKeys.GET_ASSETS],
      });
      queryClient.invalidateQueries({
        queryKey: [AssetApiKeys.GET_ASSET, e.id],
      });
      toast.success(`Filen '${e.originalFileName}' er blevet slettet`, {
        toastId: 'deleteAsset',
      });
    },
  });
};

export const useUploadPresignedFileUrl = () => {
  return useMutation({
    mutationFn: async ({ file, url }: { file: File | Blob; url: string }) => {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type,
        },
        body: file,
        redirect: 'follow',
      });

      if (!response.ok) {
        // Handle HTTP errors explicitly
        console.error(response);
        throw new Error(
          `Upload fejlede ${response.status}: ${response.statusText}`
        );
      }
    },
  });
};
