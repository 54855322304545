import { ControlledModal } from 'components/ControlledModal';
import { useState } from 'react';
import { MediaSequenceAssetForm } from './MediaSequenceAssetForm';
import { useGetMediaSequenceAsset } from 'api/useMediaSequenceAssetsApi';
import { MediaSequenceAssetResponse } from 'api/core';

interface MediaSequenceAssetUpdateProps {
  id?: string;
  projectId?: string;
  trigger?: React.ReactNode;
  isInitialOpen?: boolean;
  onSuccess?: (asset: MediaSequenceAssetResponse) => void;
  onClosed?: () => void;
}

export const MediaSequenceAssetUpdate = ({
  id,
  projectId,
  trigger,
  isInitialOpen,
  onSuccess,
  onClosed,
}: MediaSequenceAssetUpdateProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(isInitialOpen ?? false);

  const { data } = useGetMediaSequenceAsset(id);

  const onModalStateChange = (state: boolean) => {
    setIsOpen(state);
    if (!state) {
      onClosed?.();
    }
  };

  const onFormSuccess = (asset: MediaSequenceAssetResponse) => {
    onModalStateChange(false);
    onSuccess?.(asset);
  };

  if (!data) return null;

  return (
    <>
      {trigger ? (
        <div onClick={() => onModalStateChange(true)}>{trigger}</div>
      ) : null}
      <ControlledModal
        showModal={onModalStateChange}
        isOpen={isOpen}
        title="Opdater fil"
        closeOnOutsideClick
        hideActionBar
      >
        <MediaSequenceAssetForm
          targetAsset={data}
          projectId={projectId}
          onSuccess={onFormSuccess}
          onCancel={() => onModalStateChange(false)}
          inDialog={true}
        />
      </ControlledModal>
    </>
  );
};
