/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddDynamicOverlayCreateRequest
 */
export interface AddDynamicOverlayCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof AddDynamicOverlayCreateRequest
     */
    dynamicTemplateId: string;
    /**
     * 
     * @type {number}
     * @memberof AddDynamicOverlayCreateRequest
     */
    delayInSeconds: number;
    /**
     * 
     * @type {number}
     * @memberof AddDynamicOverlayCreateRequest
     */
    durationInSeconds: number;
    /**
     * 
     * @type {number}
     * @memberof AddDynamicOverlayCreateRequest
     */
    transparencyPercentage: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AddDynamicOverlayCreateRequest
     */
    dynamicTemplateFieldValues: { [key: string]: string; };
}

/**
 * Check if a given object implements the AddDynamicOverlayCreateRequest interface.
 */
export function instanceOfAddDynamicOverlayCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "dynamicTemplateId" in value;
    isInstance = isInstance && "delayInSeconds" in value;
    isInstance = isInstance && "durationInSeconds" in value;
    isInstance = isInstance && "transparencyPercentage" in value;
    isInstance = isInstance && "dynamicTemplateFieldValues" in value;

    return isInstance;
}

export function AddDynamicOverlayCreateRequestFromJSON(json: any): AddDynamicOverlayCreateRequest {
    return AddDynamicOverlayCreateRequestFromJSONTyped(json, false);
}

export function AddDynamicOverlayCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddDynamicOverlayCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dynamicTemplateId': json['dynamicTemplateId'],
        'delayInSeconds': json['delayInSeconds'],
        'durationInSeconds': json['durationInSeconds'],
        'transparencyPercentage': json['transparencyPercentage'],
        'dynamicTemplateFieldValues': json['dynamicTemplateFieldValues'],
    };
}

export function AddDynamicOverlayCreateRequestToJSON(value?: AddDynamicOverlayCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dynamicTemplateId': value.dynamicTemplateId,
        'delayInSeconds': value.delayInSeconds,
        'durationInSeconds': value.durationInSeconds,
        'transparencyPercentage': value.transparencyPercentage,
        'dynamicTemplateFieldValues': value.dynamicTemplateFieldValues,
    };
}

