/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CaseResponse } from './CaseResponse';
import {
    CaseResponseFromJSON,
    CaseResponseFromJSONTyped,
    CaseResponseToJSON,
} from './CaseResponse';
import type { Page } from './Page';
import {
    PageFromJSON,
    PageFromJSONTyped,
    PageToJSON,
} from './Page';

/**
 * 
 * @export
 * @interface CaseResponsePagedData
 */
export interface CaseResponsePagedData {
    /**
     * 
     * @type {Page}
     * @memberof CaseResponsePagedData
     */
    page: Page;
    /**
     * 
     * @type {Array<CaseResponse>}
     * @memberof CaseResponsePagedData
     */
    data: Array<CaseResponse>;
}

/**
 * Check if a given object implements the CaseResponsePagedData interface.
 */
export function instanceOfCaseResponsePagedData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "page" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function CaseResponsePagedDataFromJSON(json: any): CaseResponsePagedData {
    return CaseResponsePagedDataFromJSONTyped(json, false);
}

export function CaseResponsePagedDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CaseResponsePagedData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': PageFromJSON(json['page']),
        'data': ((json['data'] as Array<any>).map(CaseResponseFromJSON)),
    };
}

export function CaseResponsePagedDataToJSON(value?: CaseResponsePagedData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': PageToJSON(value.page),
        'data': ((value.data as Array<any>).map(CaseResponseToJSON)),
    };
}

