import {
  AssetSimpleResponse,
  EAssetType,
  EPosition,
  MediaSequenceBranchMutationCreateRequest,
  MediaSequenceBranchMutationResponse,
  MediaSequenceBranchMutationUpdateRequest,
} from 'api/core';
import { AssetUpload } from 'components/Form/AssetUpload';
import { ImageOverlayPreview } from 'components/Image/ImageOverlayPreview';
import { AssetSelectSingleAsync } from 'components/Select/AssetSelectSingleAsync';
import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { positionTranslate } from 'utils/enum-translate';

interface AddWatermarkProps {
  targetBranchMutation?: MediaSequenceBranchMutationResponse;
  projectId?: string;
  inDialog?: boolean;
}

export const AddWatermark = ({
  targetBranchMutation,
  projectId,
  inDialog,
}: AddWatermarkProps) => {
  const {
    register,
    setValue,
    watch,
    formState: { disabled },
  } = useFormContext<
    | MediaSequenceBranchMutationCreateRequest
    | MediaSequenceBranchMutationUpdateRequest
  >();

  const watermarkfileInputRef = useRef<HTMLInputElement>(null);
  const [initialSelectedWatermarkAsset, setInitialSelectedWatermarkAsset] =
    useState<AssetSimpleResponse | undefined>(
      targetBranchMutation?.addWatermark?.asset
    );

  const isCenteredOverlay =
    watch('addWatermark.position') === EPosition.TopCenter ||
    watch('addWatermark.position') === EPosition.BottomCenter;

  useEffect(() => {
    if (isCenteredOverlay) {
      setValue('addWatermark.marginPercentageX', 0);
    }
  }, [isCenteredOverlay, setValue]);

  const onWatermarkImageSelected = (asset: AssetSimpleResponse | null) => {
    if (!asset) return;
    setValue('addWatermark.assetId', asset.id);
    setInitialSelectedWatermarkAsset(asset);
  };

  return (
    <>
      <AssetUpload
        fileInputRef={watermarkfileInputRef}
        projectId={projectId}
        onAssetUploaded={onWatermarkImageSelected}
        accept="image/*"
      />
      <div className="form-control">
        <label className="label">
          <span className="label-text">Billede</span>
        </label>
        <AssetSelectSingleAsync
          // Allow the user to seearch in all assets on organization (Not just project assets)
          // projectId={projectId}
          types={[EAssetType.Image]}
          onSelected={onWatermarkImageSelected}
          initialValue={initialSelectedWatermarkAsset}
          showCreateOption
          onCreateOptionSelected={() => watermarkfileInputRef.current?.click()}
          inDialog={inDialog}
          disabled={disabled}
        />
        <input
          {...register('addWatermark.assetId', { required: true })}
          type="text"
          className="hidden"
        />
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">Placering</span>
        </label>
        <select
          {...register('addWatermark.position', { required: true })}
          className="select select-bordered"
        >
          {[
            // EPosition.FullScreen,
            EPosition.TopLeft,
            EPosition.TopCenter,
            EPosition.TopRight,
            EPosition.BottomLeft,
            EPosition.BottomCenter,
            EPosition.BottomRight,
          ].map((position) => (
            <option key={position} value={position}>
              {positionTranslate(position)}
            </option>
          ))}
        </select>
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">Bredde (%)</span>
        </label>
        <input
          {...register('addWatermark.widthPercentage', {
            required: true,
          })}
          type="number"
          min={1}
          defaultValue={10}
          max={100}
          step={1}
          className="input input-bordered"
        />
      </div>

      <ImageOverlayPreview
        overlayAsset={initialSelectedWatermarkAsset}
        position={watch('addWatermark.position')}
        widthPercentage={watch('addWatermark.widthPercentage')}
        marginPercentageX={
          isCenteredOverlay ? 0 : watch('addWatermark.marginPercentageX')
        }
        marginPercentageY={watch('addWatermark.marginPercentageY')}
      />

      <div className="collapse collapse-arrow bg-base-200">
        <input type="checkbox" />
        <div className="collapse-title">Avancerede indstillinger</div>
        <div className="collapse-content">
          {!isCenteredOverlay ? (
            <div className="form-control">
              <label className="label">
                <span className="label-text">Forskydning på X-akse (%)</span>
              </label>
              <input
                {...register('addWatermark.marginPercentageX', {
                  required: true,
                })}
                type="number"
                min={0}
                max={100}
                step={1}
                defaultValue={0}
                className="input input-bordered"
              />
            </div>
          ) : null}

          <div className="form-control">
            <label className="label">
              <span className="label-text">Forskydning på Y-akse (%)</span>
            </label>
            <input
              {...register('addWatermark.marginPercentageY', {
                required: true,
              })}
              type="number"
              min={0}
              max={100}
              step={1}
              defaultValue={0}
              className="input input-bordered"
            />
          </div>

          <div className="form-control">
            <label className="label">
              <span className="label-text">Forsinkelse</span>
            </label>
            <input
              {...register('addWatermark.delayInSeconds', {
                required: true,
              })}
              type="number"
              min={0}
              max={86400}
              step={0.1}
              defaultValue={0}
              className="input input-bordered"
            />
          </div>

          <div className="form-control">
            <label className="label">
              <span className="label-text">Varighed</span>
            </label>
            <input
              {...register('addWatermark.durationInSeconds', {
                required: true,
              })}
              type="number"
              min={1}
              max={86400}
              step={0.1}
              defaultValue={86400}
              className="input input-bordered"
            />
          </div>
        </div>
      </div>
    </>
  );
};
