/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EExternalIntegrationType } from './EExternalIntegrationType';
import {
    EExternalIntegrationTypeFromJSON,
    EExternalIntegrationTypeFromJSONTyped,
    EExternalIntegrationTypeToJSON,
} from './EExternalIntegrationType';

/**
 * 
 * @export
 * @interface ExternalIntegrationResponse
 */
export interface ExternalIntegrationResponse {
    /**
     * 
     * @type {Date}
     * @memberof ExternalIntegrationResponse
     */
    lastModifiedUtc: Date;
    /**
     * 
     * @type {Date}
     * @memberof ExternalIntegrationResponse
     */
    createdUtc: Date;
    /**
     * 
     * @type {string}
     * @memberof ExternalIntegrationResponse
     */
    id: string;
    /**
     * 
     * @type {EExternalIntegrationType}
     * @memberof ExternalIntegrationResponse
     */
    type: EExternalIntegrationType;
    /**
     * 
     * @type {Date}
     * @memberof ExternalIntegrationResponse
     */
    expiryTimeUtc: Date;
    /**
     * 
     * @type {string}
     * @memberof ExternalIntegrationResponse
     */
    details: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExternalIntegrationResponse
     */
    readonly isExpired: boolean;
}

/**
 * Check if a given object implements the ExternalIntegrationResponse interface.
 */
export function instanceOfExternalIntegrationResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lastModifiedUtc" in value;
    isInstance = isInstance && "createdUtc" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "expiryTimeUtc" in value;
    isInstance = isInstance && "details" in value;
    isInstance = isInstance && "isExpired" in value;

    return isInstance;
}

export function ExternalIntegrationResponseFromJSON(json: any): ExternalIntegrationResponse {
    return ExternalIntegrationResponseFromJSONTyped(json, false);
}

export function ExternalIntegrationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalIntegrationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastModifiedUtc': (new Date(json['lastModifiedUtc'])),
        'createdUtc': (new Date(json['createdUtc'])),
        'id': json['id'],
        'type': EExternalIntegrationTypeFromJSON(json['type']),
        'expiryTimeUtc': (new Date(json['expiryTimeUtc'])),
        'details': json['details'],
        'isExpired': json['isExpired'],
    };
}

export function ExternalIntegrationResponseToJSON(value?: ExternalIntegrationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lastModifiedUtc': (value.lastModifiedUtc.toISOString()),
        'createdUtc': (value.createdUtc.toISOString()),
        'id': value.id,
        'type': EExternalIntegrationTypeToJSON(value.type),
        'expiryTimeUtc': (value.expiryTimeUtc.toISOString()),
        'details': value.details,
    };
}

