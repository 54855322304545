import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { ModelError, ResponseError } from 'api/core';
import { toast } from 'react-toastify';

const handleError = async (error: unknown, id: string | number) => {
  const castedError = error as ResponseError;
  if (castedError.response) {
    const innerError = (await castedError.response.json()) as ModelError;
    const innerErrorDetails = innerError?.details?.[0];
    const message = innerErrorDetails
      ? `${innerError.errorMessage}: ${innerErrorDetails}`
      : innerError.errorMessage;
    toast.error(message, {
      toastId: id,
      autoClose: innerErrorDetails ? 10 * 1000 : undefined,
    });
  } else {
    toast.error(castedError.message || 'Ukendt fejl');
  }
};

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => handleError(error, query.queryHash),
  }),
  mutationCache: new MutationCache({
    onError: (error, _1, _2, mutation) =>
      handleError(error, mutation.mutationId),
  }),
});
