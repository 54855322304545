import { EPermission } from 'api/core';
import { useAuth } from 'auth/AuthProvider';
import { twMerge } from 'tailwind-merge';

interface GridItemProps {
  children: React.ReactNode;
  colSpan?: number;
  mobileOnly?: boolean;
  actionRequired?: boolean;
  permissions?: EPermission[];
}

export const GridItem = ({
  children,
  colSpan,
  mobileOnly,
  actionRequired,
  permissions,
}: GridItemProps) => {
  const { hasPermissions } = useAuth();
  if (permissions && !hasPermissions(permissions)) return null;

  return (
    <div
      className={twMerge(
        'stat border shadow-md border-gray-300 rounded-xl',
        !colSpan && 'col-span-1',
        mobileOnly && 'md:hidden',
        colSpan && `col-span-${colSpan}`,
        actionRequired && 'border-2 border-warning'
      )}
    >
      {children}
    </div>
  );
};
