export interface SubtitleSection {
  index: string;
  startTime: string;
  endTime: string;
  text: string;
  errors: {
    startTime?: string;
    endTime?: string;
    text?: string;
  };
}

// Convert SRT time format to seconds
export const timeToSeconds = (time: string) => {
  try {
    if (!time || !time.includes(':') || !time.includes(',')) return 0;
    if (time.split(',').length > 2) return 0;

    const [hours, minutes, seconds] = time.split(':');
    const [secs, ms] = seconds.split(',');
    const total =
      parseInt(hours, 10) * 3600 +
      parseInt(minutes, 10) * 60 +
      parseInt(secs, 10) +
      parseInt(ms, 10) / 1000;
    return Math.ceil(total * 100) / 100;
  } catch (error) {
    console.error('Error while converting time to seconds:', error);
    return 0;
  }
};

// Convert seconds to SRT time format
export const secondsToTime = (seconds: number) => {
  if (seconds === Infinity || isNaN(seconds) || seconds <= 0) {
    return '00:00:00,000';
  }

  const hrs = Math.floor(seconds / 3600)
    .toString()
    .padStart(2, '0');
  const mins = Math.floor((seconds % 3600) / 60)
    .toString()
    .padStart(2, '0');
  const secs = Math.floor(seconds % 60)
    .toString()
    .padStart(2, '0');
  const ms =
    Math.ceil((seconds % 1) * 1000)
      .toString()
      .padStart(3, '0')
      .slice(0, 2) + '0';
  return `${hrs}:${mins}:${secs},${ms}`;
};

export const parseSRT = (rawText: string): SubtitleSection[] => {
  try {
    const sections = rawText.split(/\n\n+/);
    const parsedSections = sections.map((section) => {
      const lines = section.split('\n');
      const index = lines[0];
      const [startTime, endTime] = lines[1].split(' --> ');
      const text = lines.slice(2).join('\n');

      const sectionResult: SubtitleSection = {
        index: index.trim(),
        startTime: secondsToTime(timeToSeconds(startTime.trim())),
        endTime: secondsToTime(timeToSeconds(endTime.trim())),
        text: text.trim(),
        errors: {},
      };

      return sectionResult;
    });

    return parsedSections;
  } catch (error) {
    console.error('Error while parsing SRT:', error);
    return [];
  }
};

export const stringifySRT = (sections: SubtitleSection[]) => {
  const filteredSections = sections.filter(
    (section) => section.text.trim() !== ''
  );

  const srtString = filteredSections
    .map((section) => {
      const timeRange = `${section.startTime} --> ${section.endTime}`;
      return `${section.index}\n${timeRange}\n${section.text}`;
    })
    .join('\n\n');

  const srtPattern =
    /^(\d+\n\d{2}:\d{2}:\d{2},\d{3} --> \d{2}:\d{2}:\d{2},\d{3}\n(?:[^\n]*\n?)+)+$/;

  if (!srtPattern.test(srtString)) {
    console.warn('Invalid SRT format');
    return null;
  }

  return srtString;
};

export const setErrorMessages = (
  sections: SubtitleSection[],
  videoLengthInSeconds?: number
) => {
  for (let i = 0; i < sections.length; i++) {
    const previous = i >= 1 ? sections[i - 1] : null;
    const current = sections[i];
    const next = i < sections.length - 1 ? sections[i + 1] : null;

    // Reset errors for current section
    current.errors = {};

    const errors = {
      startTime: new Array<string>(),
      endTime: new Array<string>(),
      text: new Array<string>(),
    };

    // Check if text is empty
    if (!current.text.trim()) {
      errors.text.push('Indholdet må ikke være tomt.');
    }

    if (isNaN(timeToSeconds(current.startTime))) {
      errors.startTime.push('Starttidspunkt må ikke være tomt.');
    }

    if (isNaN(timeToSeconds(current.endTime))) {
      errors.endTime.push('Sluttidspunkt må ikke være tomt.');
    }

    // Check if startTime is before endTime in the same section
    if (timeToSeconds(current.startTime) >= timeToSeconds(current.endTime)) {
      errors.startTime.push('Starttidspunkt skal være før sluttidspunkt.');
      errors.endTime.push('Sluttidspunkt skal være efter starttidspunkt.');
    }

    // Check if startTime is after previous endTime
    if (
      previous &&
      timeToSeconds(current.startTime) < timeToSeconds(previous.endTime)
    ) {
      errors.startTime.push(
        'Starttidspunkt skal være efter forrige sektions sluttidspunkt.'
      );
    }

    // Check if endTime is before next startTime
    if (
      next &&
      timeToSeconds(current.endTime) > timeToSeconds(next.startTime)
    ) {
      errors.endTime.push(
        'Sluttidspunkt skal være før næste sektions starttidspunkt.'
      );
    }

    // Check if startTime is before video start
    if (timeToSeconds(current.startTime) < 0) {
      errors.startTime.push('Starttidspunkt skal være efter videoens start.');
    }

    // Check if startTime is after video end
    if (
      videoLengthInSeconds &&
      timeToSeconds(current.startTime) > videoLengthInSeconds
    ) {
      errors.startTime.push('Starttidspunkt skal være før videoen slutter.');
    }

    // Set errors for current section
    current.errors = {
      text: errors.text.join('\n') || undefined,
      startTime: errors.startTime.join('\n') || undefined,
      endTime: errors.endTime.join('\n') || undefined,
    };
  }
};
