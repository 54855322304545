/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AssetResponse } from './AssetResponse';
import {
    AssetResponseFromJSON,
    AssetResponseFromJSONTyped,
    AssetResponseToJSON,
} from './AssetResponse';

/**
 * 
 * @export
 * @interface MediaSequenceBranchResponse
 */
export interface MediaSequenceBranchResponse {
    /**
     * 
     * @type {Date}
     * @memberof MediaSequenceBranchResponse
     */
    lastModifiedUtc: Date;
    /**
     * 
     * @type {Date}
     * @memberof MediaSequenceBranchResponse
     */
    createdUtc: Date;
    /**
     * 
     * @type {string}
     * @memberof MediaSequenceBranchResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MediaSequenceBranchResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MediaSequenceBranchResponse
     */
    mediaSequenceId: string;
    /**
     * 
     * @type {string}
     * @memberof MediaSequenceBranchResponse
     */
    projectId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MediaSequenceBranchResponse
     */
    width: number;
    /**
     * 
     * @type {number}
     * @memberof MediaSequenceBranchResponse
     */
    height: number;
    /**
     * 
     * @type {AssetResponse}
     * @memberof MediaSequenceBranchResponse
     */
    asset?: AssetResponse;
}

/**
 * Check if a given object implements the MediaSequenceBranchResponse interface.
 */
export function instanceOfMediaSequenceBranchResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lastModifiedUtc" in value;
    isInstance = isInstance && "createdUtc" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "mediaSequenceId" in value;
    isInstance = isInstance && "width" in value;
    isInstance = isInstance && "height" in value;

    return isInstance;
}

export function MediaSequenceBranchResponseFromJSON(json: any): MediaSequenceBranchResponse {
    return MediaSequenceBranchResponseFromJSONTyped(json, false);
}

export function MediaSequenceBranchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MediaSequenceBranchResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastModifiedUtc': (new Date(json['lastModifiedUtc'])),
        'createdUtc': (new Date(json['createdUtc'])),
        'id': json['id'],
        'name': json['name'],
        'mediaSequenceId': json['mediaSequenceId'],
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'width': json['width'],
        'height': json['height'],
        'asset': !exists(json, 'asset') ? undefined : AssetResponseFromJSON(json['asset']),
    };
}

export function MediaSequenceBranchResponseToJSON(value?: MediaSequenceBranchResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lastModifiedUtc': (value.lastModifiedUtc.toISOString()),
        'createdUtc': (value.createdUtc.toISOString()),
        'id': value.id,
        'name': value.name,
        'mediaSequenceId': value.mediaSequenceId,
        'projectId': value.projectId,
        'width': value.width,
        'height': value.height,
        'asset': AssetResponseToJSON(value.asset),
    };
}

