import { ColumnDef } from '@tanstack/react-table';
import {
  SessionStatisticResponse,
  SessionStatisticResponsePagedData,
} from 'api/core';
import { CustomTable } from 'components/Table/CustomTable';
import { DateTimeCell } from 'components/Table/Cell/DateTimeCell';
import { useGetCustomReactTable } from 'components/Table/useGetTableSet';
import { TableQueryState } from 'components/Table/useTableQueryState';
import { useMemo } from 'react';

interface TableProps {
  data?: SessionStatisticResponsePagedData;
  isFetching: boolean;
  queryState: TableQueryState;
}

export const OrganizationSessionsTable = ({
  data,
  isFetching,
  queryState,
}: TableProps) => {
  const columns = useMemo(() => {
    const baseColumns: ColumnDef<SessionStatisticResponse>[] = [
      {
        accessorKey: 'user.name',
        header: 'Navn',
      },
      {
        accessorKey: 'user.email',
        header: 'E-mail',
      },
      {
        accessorKey: 'lastAccessUtc',
        header: 'Seneste aktivitet',
        cell: (e) => {
          const lastAccessDate = new Date(
            e.row.original.lastAccessUtc
          ).getTime();
          const now = Date.now(); // This will give the current time in milliseconds
          const oneWeek = 7 * 24 * 60 * 60 * 1000; // Milliseconds in one week
          const fourWeeks = 4 * 7 * 24 * 60 * 60 * 1000; // Milliseconds in four weeks

          let badgeColor = 'badge-error';

          if (now - lastAccessDate <= oneWeek) {
            badgeColor = 'badge-default';
          } else if (now - lastAccessDate <= fourWeeks) {
            badgeColor = 'badge-warning';
          }

          return (
            <div className={`badge ${badgeColor}`}>
              <DateTimeCell value={e.row.original.lastAccessUtc} />
            </div>
          );
        },
      },
    ];
    return baseColumns;
  }, []);

  const table = useGetCustomReactTable(
    data?.data ?? [],
    data?.page.totalPages ?? -1,
    columns,
    queryState
  );

  return <CustomTable table={table} isLoading={isFetching} hideSearch={true} />;
};
