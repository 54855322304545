/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SectionCreateRequest
 */
export interface SectionCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof SectionCreateRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SectionCreateRequest
     */
    maskId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SectionCreateRequest
     */
    order?: number;
}

/**
 * Check if a given object implements the SectionCreateRequest interface.
 */
export function instanceOfSectionCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function SectionCreateRequestFromJSON(json: any): SectionCreateRequest {
    return SectionCreateRequestFromJSONTyped(json, false);
}

export function SectionCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SectionCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'maskId': !exists(json, 'maskId') ? undefined : json['maskId'],
        'order': !exists(json, 'order') ? undefined : json['order'],
    };
}

export function SectionCreateRequestToJSON(value?: SectionCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'maskId': value.maskId,
        'order': value.order,
    };
}

