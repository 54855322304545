/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EUpdateType = {
    Invoked: 'Invoked',
    Processing: 'Processing',
    Processed: 'Processed',
    Resumed: 'Resumed',
    Failed: 'Failed',
    ProcessedWithMissingFrames: 'ProcessedWithMissingFrames'
} as const;
export type EUpdateType = typeof EUpdateType[keyof typeof EUpdateType];


export function EUpdateTypeFromJSON(json: any): EUpdateType {
    return EUpdateTypeFromJSONTyped(json, false);
}

export function EUpdateTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EUpdateType {
    return json as EUpdateType;
}

export function EUpdateTypeToJSON(value?: EUpdateType | null): any {
    return value as any;
}

