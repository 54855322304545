/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ETag = {
    Bathroom: 'Bathroom',
    Bedroom: 'Bedroom',
    DinningRoom: 'DinningRoom',
    Kitchen: 'Kitchen',
    LaundryRoom: 'LaundryRoom',
    LivingRoom: 'LivingRoom',
    Person: 'Person',
    GroupPhoto: 'GroupPhoto'
} as const;
export type ETag = typeof ETag[keyof typeof ETag];


export function ETagFromJSON(json: any): ETag {
    return ETagFromJSONTyped(json, false);
}

export function ETagFromJSONTyped(json: any, ignoreDiscriminator: boolean): ETag {
    return json as ETag;
}

export function ETagToJSON(value?: ETag | null): any {
    return value as any;
}

