import {
  AccountJoinOrganizationPutRequest,
  LoginResponse,
  OrganizationSimpleResponse,
} from 'api/core';
import { useJoinOrganization } from 'api/useAuthApi';
import { EntitySelectSingle } from 'components/Select/EntitySelectSingle';
import { useForm } from 'react-hook-form';

interface JoinOrganizationFormProps {
  user: LoginResponse;
  allOrganizations: OrganizationSimpleResponse[];
  onSuccess: () => void;
  onCancel: () => void;
  inDialog?: boolean;
}

export const JoinOrganizationForm = ({
  user,
  allOrganizations,
  onSuccess,
  onCancel,
  inDialog,
}: JoinOrganizationFormProps) => {
  const { mutateAsync, isPending } = useJoinOrganization();

  const { register, handleSubmit, setValue, watch } =
    useForm<AccountJoinOrganizationPutRequest>({
      defaultValues: {
        organizationId: user.organization.id,
      },
    });

  const onSubmit = handleSubmit(async (result) => {
    await mutateAsync({
      organizationId: result.organizationId,
    });
    onSuccess();
  });

  const onOrganizationSelected = (
    organization: OrganizationSimpleResponse | null
  ) => {
    if (!organization) return;
    setValue('organizationId', organization.id);
  };

  return (
    <form onSubmit={onSubmit} className="space-y-2">
      <div className="form-control">
        <label className="label">
          <span className="label-text">Butik</span>
        </label>
        <EntitySelectSingle<OrganizationSimpleResponse>
          data={allOrganizations}
          renderFormat={(format) => (
            <span
              className={format.id == user.organization.id ? 'font-bold' : ''}
            >
              {format.name}
            </span>
          )}
          onSelect={onOrganizationSelected}
          initialValue={allOrganizations.find(
            (org) => org.id === user.organization.id
          )}
          searchPropertyKey="name"
          inDialog={inDialog}
        />
        <input
          {...register('organizationId', { required: true })}
          type="text"
          className="hidden"
        />
        <div className="label">
          <span className="label-text-alt">
            Du kan ændre din butik her, hvis du arbejder på vegne af en anden
            butik.
          </span>
        </div>
      </div>

      <div className="flex justify-center space-x-4 pt-4">
        <button
          className="btn btn-primary"
          disabled={
            isPending || watch('organizationId') == user.organization.id
          }
        >
          Skift
        </button>
        {onCancel ? (
          <button type="button" className="btn" onClick={onCancel}>
            Annuller
          </button>
        ) : null}
      </div>
    </form>
  );
};
