import {
  DynamicTemplateFieldResponse,
  DynamicTemplateResponse,
  MediaSequenceAssetResponse,
  MediaSequenceBranchMutationCreateRequest,
  MediaSequenceBranchMutationUpdateRequest,
  MetaMappingResponse,
} from 'api/core';
import {
  useGetDynamicTemplate,
  useSearchDynamicTemplates,
} from 'api/useDynamicTemplatesApi';
import { useGetMetaMappings } from 'api/useMetaMappingsApi';
import { DynamicTemplatePreview } from 'components/DynamicTemplate/DynamicTemplatePreview';
import { LabelWithHelperText } from 'components/Form/LabelWithHelperText';
import { EntitySelectSingle } from 'components/Select/EntitySelectSingle';
import { EntitySelectSingleAsync } from 'components/Select/EntitySelectSingleAsync';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

interface AddDynamicOverlayProps {
  mediaSequenceId: string;
  dynamicTemplateId?: string;
  asset?: MediaSequenceAssetResponse;
  previewAspectRatio?: number;
  inDialog?: boolean;
  fallBackdynamicTemplateFieldValues?: { [key: string]: string };
}

export const AddDynamicOverlay = ({
  mediaSequenceId,
  dynamicTemplateId,
  asset,
  previewAspectRatio,
  inDialog,
  fallBackdynamicTemplateFieldValues,
}: AddDynamicOverlayProps) => {
  const { data: template } = useGetDynamicTemplate(dynamicTemplateId);
  const { data: metaMappings } = useGetMetaMappings(mediaSequenceId);

  const {
    register,
    setValue,
    watch,
    formState: { disabled },
  } = useFormContext<
    | MediaSequenceBranchMutationCreateRequest
    | MediaSequenceBranchMutationUpdateRequest
  >();

  const [selectedDynamicTemplate, setSelectedDynamicTemplate] =
    useState<DynamicTemplateResponse | null>(null);

  const [savedFieldValues, setSavedFieldValues] = useState<{
    [key: string]: string;
  }>({});

  const resetFieldValues = (dynamicTemplate: DynamicTemplateResponse) => {
    const ids = dynamicTemplate.fields.map((field) => field.id);
    ids.forEach((id) => {
      const currentValue = watch(
        `addDynamicOverlay.dynamicTemplateFieldValues.${id}`
      );
      if (currentValue) {
        setSavedFieldValues((prev) => ({ ...prev, [id]: currentValue }));
      }
      setValue(`addDynamicOverlay.dynamicTemplateFieldValues.${id}`, '');
    });
  };

  const onDynamicTemplateSelected = (
    dynamicTemplate: DynamicTemplateResponse | null
  ) => {
    if (
      selectedDynamicTemplate &&
      dynamicTemplate?.id === selectedDynamicTemplate.id
    )
      return;

    if (selectedDynamicTemplate) resetFieldValues(selectedDynamicTemplate);

    setSelectedDynamicTemplate(dynamicTemplate);

    if (!dynamicTemplate) return;
    setValue('addDynamicOverlay.dynamicTemplateId', dynamicTemplate.id);
  };

  useEffect(() => {
    if (!template) return;
    setSelectedDynamicTemplate(template);
  }, [template]);

  useEffect(() => {
    if (!selectedDynamicTemplate) return;

    const ids = selectedDynamicTemplate.fields.map((field) => field.id);
    ids.forEach((id) => {
      const currentValue = watch(
        `addDynamicOverlay.dynamicTemplateFieldValues.${id}`
      );
      const fieldDefaultvalue = selectedDynamicTemplate.fields.find(
        (field) => field.id === id
      )?.defaultValue;

      setValue(
        `addDynamicOverlay.dynamicTemplateFieldValues.${id}`,
        savedFieldValues[id] || currentValue || fieldDefaultvalue || ''
      );
    });
    // We need this, to only run when the id changes, otherwise it will trigger even when we just change tab
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDynamicTemplate?.id, savedFieldValues, setValue]);

  const watchedFieldValues = watch(
    'addDynamicOverlay.dynamicTemplateFieldValues'
  );

  const actualFieldValues = disabled
    ? fallBackdynamicTemplateFieldValues
    : watchedFieldValues;

  return (
    <div
      className={twMerge(
        'flex gap-4',
        selectedDynamicTemplate && 'w-[70vw]',
        !selectedDynamicTemplate && 'w-full md:w-[512px]'
      )}
    >
      <div
        className={twMerge(
          'flex flex-col gap-2',
          selectedDynamicTemplate && 'w-1/2',
          !selectedDynamicTemplate && 'w-full'
        )}
      >
        <div className="form-control">
          <label className="label">
            <span className="label-text">Skabelon</span>
          </label>
          <EntitySelectSingleAsync<
            DynamicTemplateResponse,
            { searchTerm?: string }
          >
            useSearchFunction={useSearchDynamicTemplates}
            searchFunctionOrder={(a, b) => a.name.localeCompare(b.name)}
            searchParams={{}}
            renderSuggestion={(templateSuggestion) => (
              <>{templateSuggestion.name}</>
            )}
            onSuggestionSelected={onDynamicTemplateSelected}
            initialValue={template}
            inDialog={inDialog}
            disabled={disabled}
          />
          <input
            {...register('addDynamicOverlay.dynamicTemplateId', {
              required: true,
            })}
            type="text"
            className="hidden"
          />
        </div>

        {selectedDynamicTemplate ? (
          <div className="max-h-96 overflow-y-auto">
            {metaMappings && metaMappings.length > 0 ? (
              <div className="label">
                <p className="text-sm text-gray-600">
                  Du kan vælge mellem nogle foruddefinerede værdier eller
                  indtaste en manuel værdi. De foruddefinerede værdier er
                  baseret på de metadata, der er tilknyttet videoen. Hvis du
                  vælger en foruddefineret værdi, vil værdien blive opdateret,
                  hvis metadataen ændres.
                </p>
              </div>
            ) : null}
            {selectedDynamicTemplate?.fields
              .sort((a, b) => a.order - b.order)
              .map((field, i) => (
                <DynamicOverlayKeyValue
                  key={i}
                  field={field}
                  metaMappings={metaMappings}
                  fieldValues={actualFieldValues}
                  inDialog={inDialog}
                />
              ))}
          </div>
        ) : null}

        {selectedDynamicTemplate ? (
          <div className="collapse collapse-arrow bg-base-200">
            <input type="checkbox" />
            <div className="collapse-title">Avancerede indstillinger</div>
            <div className="collapse-content">
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Forsinkelse</span>
                </label>
                <input
                  {...register('addDynamicOverlay.delayInSeconds', {
                    required: true,
                    disabled: disabled,
                  })}
                  type="number"
                  min={0}
                  max={86400}
                  defaultValue={0}
                  className="input input-bordered"
                />
              </div>

              <div className="form-control">
                <label className="label">
                  <span className="label-text">Varighed</span>
                </label>
                <input
                  {...register('addDynamicOverlay.durationInSeconds', {
                    required: true,
                    disabled: disabled,
                  })}
                  type="number"
                  min={-60}
                  max={86400}
                  step="any"
                  defaultValue={4}
                  className="input input-bordered"
                />
              </div>

              <div className="form-control">
                <label className="label">
                  <span className="label-text">Gennemsigtighed</span>
                </label>
                <input
                  {...register('addDynamicOverlay.transparencyPercentage', {
                    required: true,
                    disabled: disabled,
                  })}
                  type="range"
                  min={0}
                  max={50}
                  step="1"
                  defaultValue={0}
                  className="input input-bordered cursor-pointer"
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>

      {selectedDynamicTemplate ? (
        <div className="flex flex-col gap-2 w-1/2 mt-6">
          <DynamicTemplatePreview
            html={selectedDynamicTemplate?.html}
            fields={selectedDynamicTemplate?.fields}
            dynamicTemplateFieldValues={actualFieldValues}
            metaMappings={metaMappings}
            asset={asset}
            previewAspectRatio={previewAspectRatio}
            opacity={
              1 - watch('addDynamicOverlay.transparencyPercentage') / 100
            }
          />
        </div>
      ) : null}
    </div>
  );
};

interface DynamicOverlayKeyValueProps {
  field: DynamicTemplateFieldResponse;
  metaMappings?: MetaMappingResponse[];
  fieldValues?: { [key: string]: string };
  inDialog?: boolean;
}

const DynamicOverlayKeyValue = ({
  field,
  metaMappings,
  fieldValues,
  inDialog,
}: DynamicOverlayKeyValueProps) => {
  const {
    setValue,
    watch,
    register,
    formState: { errors, disabled },
  } = useFormContext<
    | MediaSequenceBranchMutationCreateRequest
    | MediaSequenceBranchMutationUpdateRequest
  >();

  const validationRules = field.validations.reduce((rules, validation) => {
    return {
      ...rules,
      pattern: {
        value: new RegExp(validation.regex),
        message: validation.errorMessage,
      },
    };
  }, {});

  const currentKey = watch(
    `addDynamicOverlay.dynamicTemplateFieldValues.${field.id}`
  );

  const onMetaMappingSelected = async (
    metaMapping: MetaMappingResponse | null
  ) => {
    if (metaMapping) {
      setValue(
        `addDynamicOverlay.dynamicTemplateFieldValues.${field.id}`,
        metaMapping.key,
        { shouldValidate: true }
      );
    } else {
      setValue(`addDynamicOverlay.dynamicTemplateFieldValues.${field.id}`, '', {
        shouldValidate: true,
      });
    }
  };

  const targetMetaMapping = useMemo(() => {
    if (currentKey) {
      return metaMappings?.find(
        (metaMapping) => metaMapping.key === currentKey
      );
    } else {
      return metaMappings?.find(
        (metaMapping) => metaMapping.value === fieldValues?.[field.id]
      );
    }
  }, [metaMappings, currentKey, field, fieldValues]);

  return (
    <div className="form-control">
      <LabelWithHelperText
        label={field.name}
        helperText={field.description}
        inDialog={inDialog}
      />
      <div className="flex flex-col gap-2">
        {metaMappings && metaMappings.length > 0 ? (
          <EntitySelectSingle<MetaMappingResponse>
            data={metaMappings}
            initialValue={targetMetaMapping}
            renderFormat={(metaMapping) => (
              <div className="truncate">
                {metaMapping.displayName} -{' '}
                <span className="font-bold">{metaMapping.value}</span>
              </div>
            )}
            onSelect={onMetaMappingSelected}
            searchPropertyKey="key"
            searchFn={(searchTerm, data) => {
              return (
                !searchTerm ||
                data.displayName
                  .toLocaleLowerCase()
                  .includes(searchTerm.toLocaleLowerCase()) ||
                data.value
                  .toLocaleLowerCase()
                  .includes(searchTerm.toLocaleLowerCase()) ||
                data.key
                  .toLocaleLowerCase()
                  .includes(searchTerm.toLocaleLowerCase())
              );
            }}
            inDialog={inDialog}
            placeholder="Vælg en foruddefineret værdi"
            disabled={disabled}
          />
        ) : null}
        {!targetMetaMapping ? (
          <input
            {...register(
              `addDynamicOverlay.dynamicTemplateFieldValues.${field.id}`,
              {
                required: true,
                disabled: disabled,
                ...validationRules,
              }
            )}
            type="text"
            className="input input-bordered"
            autoComplete={`dynamicTemplateField-${field.id}`}
            placeholder="Indtast en manuel værdi"
            onFocus={(e) => {
              if (e.target.value === '') {
                // Show autofill suggestions
                e.target.click();
              }
            }}
          />
        ) : null}
        {targetMetaMapping ? (
          <div className="label">
            <p className="text-sm text-gray-600">
              Dette felts værdi er sat til den foruddefinerede værdi{' '}
              <span className="font-bold">{targetMetaMapping.displayName}</span>{' '}
              som er{' '}
              <span className="font-bold">{targetMetaMapping.value}</span>. Hvis
              du vil fjerne den foruddefinerede værdi og benytte en manuel værdi
              så tryk på krydset ud for den foruddefinerede værdi.
            </p>
          </div>
        ) : null}
        {errors?.addDynamicOverlay?.dynamicTemplateFieldValues?.[field.id] && (
          <p className="text-red-500">
            {
              errors.addDynamicOverlay.dynamicTemplateFieldValues[field.id]
                ?.message
            }
          </p>
        )}
      </div>
    </div>
  );
};
