/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CaseResponse } from './CaseResponse';
import {
    CaseResponseFromJSON,
    CaseResponseFromJSONTyped,
    CaseResponseToJSON,
} from './CaseResponse';
import type { EServiceTicketState } from './EServiceTicketState';
import {
    EServiceTicketStateFromJSON,
    EServiceTicketStateFromJSONTyped,
    EServiceTicketStateToJSON,
} from './EServiceTicketState';
import type { MediaSequenceResponse } from './MediaSequenceResponse';
import {
    MediaSequenceResponseFromJSON,
    MediaSequenceResponseFromJSONTyped,
    MediaSequenceResponseToJSON,
} from './MediaSequenceResponse';
import type { OrganizationResponse } from './OrganizationResponse';
import {
    OrganizationResponseFromJSON,
    OrganizationResponseFromJSONTyped,
    OrganizationResponseToJSON,
} from './OrganizationResponse';
import type { ProjectResponse } from './ProjectResponse';
import {
    ProjectResponseFromJSON,
    ProjectResponseFromJSONTyped,
    ProjectResponseToJSON,
} from './ProjectResponse';
import type { PublicationResponse } from './PublicationResponse';
import {
    PublicationResponseFromJSON,
    PublicationResponseFromJSONTyped,
    PublicationResponseToJSON,
} from './PublicationResponse';
import type { ServiceTicketReplyResponse } from './ServiceTicketReplyResponse';
import {
    ServiceTicketReplyResponseFromJSON,
    ServiceTicketReplyResponseFromJSONTyped,
    ServiceTicketReplyResponseToJSON,
} from './ServiceTicketReplyResponse';
import type { UserSimpleResponse } from './UserSimpleResponse';
import {
    UserSimpleResponseFromJSON,
    UserSimpleResponseFromJSONTyped,
    UserSimpleResponseToJSON,
} from './UserSimpleResponse';

/**
 * 
 * @export
 * @interface ServiceTicketResponse
 */
export interface ServiceTicketResponse {
    /**
     * 
     * @type {Date}
     * @memberof ServiceTicketResponse
     */
    lastModifiedUtc: Date;
    /**
     * 
     * @type {Date}
     * @memberof ServiceTicketResponse
     */
    createdUtc: Date;
    /**
     * 
     * @type {string}
     * @memberof ServiceTicketResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceTicketResponse
     */
    title: string;
    /**
     * 
     * @type {EServiceTicketState}
     * @memberof ServiceTicketResponse
     */
    state: EServiceTicketState;
    /**
     * 
     * @type {OrganizationResponse}
     * @memberof ServiceTicketResponse
     */
    organization: OrganizationResponse;
    /**
     * 
     * @type {UserSimpleResponse}
     * @memberof ServiceTicketResponse
     */
    createdByUser: UserSimpleResponse;
    /**
     * 
     * @type {Array<ServiceTicketReplyResponse>}
     * @memberof ServiceTicketResponse
     */
    replies: Array<ServiceTicketReplyResponse>;
    /**
     * 
     * @type {ProjectResponse}
     * @memberof ServiceTicketResponse
     */
    project?: ProjectResponse;
    /**
     * 
     * @type {CaseResponse}
     * @memberof ServiceTicketResponse
     */
    _case?: CaseResponse;
    /**
     * 
     * @type {MediaSequenceResponse}
     * @memberof ServiceTicketResponse
     */
    mediaSequence?: MediaSequenceResponse;
    /**
     * 
     * @type {PublicationResponse}
     * @memberof ServiceTicketResponse
     */
    publication?: PublicationResponse;
}

/**
 * Check if a given object implements the ServiceTicketResponse interface.
 */
export function instanceOfServiceTicketResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lastModifiedUtc" in value;
    isInstance = isInstance && "createdUtc" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "organization" in value;
    isInstance = isInstance && "createdByUser" in value;
    isInstance = isInstance && "replies" in value;

    return isInstance;
}

export function ServiceTicketResponseFromJSON(json: any): ServiceTicketResponse {
    return ServiceTicketResponseFromJSONTyped(json, false);
}

export function ServiceTicketResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceTicketResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastModifiedUtc': (new Date(json['lastModifiedUtc'])),
        'createdUtc': (new Date(json['createdUtc'])),
        'id': json['id'],
        'title': json['title'],
        'state': EServiceTicketStateFromJSON(json['state']),
        'organization': OrganizationResponseFromJSON(json['organization']),
        'createdByUser': UserSimpleResponseFromJSON(json['createdByUser']),
        'replies': ((json['replies'] as Array<any>).map(ServiceTicketReplyResponseFromJSON)),
        'project': !exists(json, 'project') ? undefined : ProjectResponseFromJSON(json['project']),
        '_case': !exists(json, 'case') ? undefined : CaseResponseFromJSON(json['case']),
        'mediaSequence': !exists(json, 'mediaSequence') ? undefined : MediaSequenceResponseFromJSON(json['mediaSequence']),
        'publication': !exists(json, 'publication') ? undefined : PublicationResponseFromJSON(json['publication']),
    };
}

export function ServiceTicketResponseToJSON(value?: ServiceTicketResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lastModifiedUtc': (value.lastModifiedUtc.toISOString()),
        'createdUtc': (value.createdUtc.toISOString()),
        'id': value.id,
        'title': value.title,
        'state': EServiceTicketStateToJSON(value.state),
        'organization': OrganizationResponseToJSON(value.organization),
        'createdByUser': UserSimpleResponseToJSON(value.createdByUser),
        'replies': ((value.replies as Array<any>).map(ServiceTicketReplyResponseToJSON)),
        'project': ProjectResponseToJSON(value.project),
        'case': CaseResponseToJSON(value._case),
        'mediaSequence': MediaSequenceResponseToJSON(value.mediaSequence),
        'publication': PublicationResponseToJSON(value.publication),
    };
}

