/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EPublicationState = {
    Draft: 'Draft',
    Scheduled: 'Scheduled'
} as const;
export type EPublicationState = typeof EPublicationState[keyof typeof EPublicationState];


export function EPublicationStateFromJSON(json: any): EPublicationState {
    return EPublicationStateFromJSONTyped(json, false);
}

export function EPublicationStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): EPublicationState {
    return json as EPublicationState;
}

export function EPublicationStateToJSON(value?: EPublicationState | null): any {
    return value as any;
}

