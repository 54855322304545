import {
  AngleCreateRequest,
  AngleResponse,
  AngleUpdateRequest,
  AssetSimpleResponse,
  EAssetType,
  SectionResponse,
} from 'api/core';
import { useCreateAngle, useUpdateAngle } from 'api/useAnglesApi';
import { AssetUpload } from 'components/Form/AssetUpload';
import { AssetSelectSingleAsync } from 'components/Select/AssetSelectSingleAsync';
import { EntitySelectSingle } from 'components/Select/EntitySelectSingle';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

interface AngleFormProps {
  projectId: string;
  targetAngle?: AngleResponse;
  allSections: SectionResponse[];
  onSuccess: () => void;
  onCancel: () => void;
  inDialog?: boolean;
}

export const AngleForm = ({
  projectId,
  targetAngle,
  allSections,
  onSuccess,
  onCancel,
  inDialog,
}: AngleFormProps) => {
  const { mutateAsync: createAsync, isPending: isPendingCreate } =
    useCreateAngle();
  const { mutateAsync: updateAsync, isPending: isPendingUpdate } =
    useUpdateAngle();

  const overlayfileInputRef = useRef<HTMLInputElement>(null);
  const renderingFileInputRef = useRef<HTMLInputElement>(null);

  const [initialSelectedOverlayAsset, setInitialSelectedOverlayAsset] =
    useState<AssetSimpleResponse | undefined>(targetAngle?.overlay);

  const [initialSelectedRenderingAsset, setInitialSelectedRenderingAsset] =
    useState<AssetSimpleResponse | undefined>(targetAngle?.rendering);

  const { register, handleSubmit, setValue } = useForm<
    AngleCreateRequest | AngleUpdateRequest
  >({
    defaultValues: {
      ...targetAngle,
      sectionId: targetAngle?.section.id,
      overlayId: targetAngle?.overlay.id,
      renderingId: targetAngle?.rendering.id,
    },
  });

  const onSubmit = handleSubmit(async (result) => {
    if (targetAngle) {
      await updateAsync({
        id: targetAngle.id,
        angleUpdateRequest: result,
      });
    } else {
      await createAsync({
        angleCreateRequest: result,
      });
    }
    onSuccess();
  });

  const onSectionSelected = (section: SectionResponse | null) => {
    if (!section) return;
    setValue('sectionId', section.id);
  };

  const onOverlayImageSelected = (asset: AssetSimpleResponse | null) => {
    if (!asset) return;
    setValue('overlayId', asset.id);
    setInitialSelectedOverlayAsset(asset);
  };

  const onRenderingImageSelected = (asset: AssetSimpleResponse | null) => {
    if (!asset) return;
    setValue('renderingId', asset.id);
    setInitialSelectedRenderingAsset(asset);
  };

  return (
    <>
      <AssetUpload
        fileInputRef={overlayfileInputRef}
        projectId={projectId}
        onAssetUploaded={onOverlayImageSelected}
        accept="image/*"
      />
      <AssetUpload
        fileInputRef={renderingFileInputRef}
        projectId={projectId}
        onAssetUploaded={onRenderingImageSelected}
        accept="image/*"
      />
      <form onSubmit={onSubmit} className="space-y-2">
        <div className="form-control">
          <label className="label">
            <span className="label-text">Sektion</span>
          </label>
          <EntitySelectSingle<SectionResponse>
            data={allSections}
            renderFormat={(section) => <p>{section.name}</p>}
            onSelect={onSectionSelected}
            initialValue={targetAngle?.section}
            searchPropertyKey="name"
            inDialog={inDialog}
          />
          <input
            {...register('sectionId', { required: false })}
            type="text"
            className="hidden"
          />
        </div>

        <div className="form-control">
          <label className="label">
            <span className="label-text">Navn</span>
          </label>
          <input
            {...register('name', { required: true })}
            type="text"
            className="input input-bordered"
          />
        </div>

        <div className="form-control">
          <label className="label">
            <span className="label-text">Overlay</span>
          </label>
          <AssetSelectSingleAsync
            // Only search in project assets
            projectId={projectId}
            types={[EAssetType.Image]}
            onSelected={onOverlayImageSelected}
            initialValue={initialSelectedOverlayAsset}
            showCreateOption
            onCreateOptionSelected={() => overlayfileInputRef.current?.click()}
            inDialog={inDialog}
          />
          <input
            {...register('overlayId', { required: true })}
            type="text"
            className="hidden"
          />
        </div>

        <div className="form-control">
          <label className="label">
            <span className="label-text">Rendering</span>
          </label>
          <AssetSelectSingleAsync
            // Only search in project assets
            projectId={projectId}
            types={[EAssetType.Image]}
            onSelected={onRenderingImageSelected}
            initialValue={initialSelectedRenderingAsset}
            showCreateOption
            onCreateOptionSelected={() =>
              renderingFileInputRef.current?.click()
            }
            inDialog={inDialog}
          />
          <input
            {...register('renderingId', { required: true })}
            type="text"
            className="hidden"
          />
        </div>

        <div className="form-control">
          <label className="label">
            <span className="label-text">Nord forskydning</span>
          </label>
          <input
            {...register('northOffset')}
            type="number"
            min={0}
            max={360}
            className="input input-bordered"
          />
        </div>

        <div className="form-control">
          <label className="label">
            <span className="label-text">Rækkefølge</span>
          </label>
          <input
            {...register('order', {
              required: true,
            })}
            type="number"
            min={0}
            className="input input-bordered"
          />
        </div>

        <div className="flex justify-center space-x-4 pt-4">
          <button
            className="btn btn-primary"
            disabled={isPendingCreate || isPendingUpdate}
          >
            {targetAngle ? 'Opdater' : 'Opret'}
          </button>
          {onCancel ? (
            <button type="button" className="btn" onClick={onCancel}>
              Annuller
            </button>
          ) : null}
        </div>
      </form>
    </>
  );
};
