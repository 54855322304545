/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ServiceTicketStateResponse
 */
export interface ServiceTicketStateResponse {
    /**
     * 
     * @type {number}
     * @memberof ServiceTicketStateResponse
     */
    awaitingCustomerCount: number;
}

/**
 * Check if a given object implements the ServiceTicketStateResponse interface.
 */
export function instanceOfServiceTicketStateResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "awaitingCustomerCount" in value;

    return isInstance;
}

export function ServiceTicketStateResponseFromJSON(json: any): ServiceTicketStateResponse {
    return ServiceTicketStateResponseFromJSONTyped(json, false);
}

export function ServiceTicketStateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceTicketStateResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'awaitingCustomerCount': json['awaitingCustomerCount'],
    };
}

export function ServiceTicketStateResponseToJSON(value?: ServiceTicketStateResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'awaitingCustomerCount': value.awaitingCustomerCount,
    };
}

