/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddAddWatermarkCreateRequest } from './AddAddWatermarkCreateRequest';
import {
    AddAddWatermarkCreateRequestFromJSON,
    AddAddWatermarkCreateRequestFromJSONTyped,
    AddAddWatermarkCreateRequestToJSON,
} from './AddAddWatermarkCreateRequest';
import type { AddBackgroundMusicCreateRequest } from './AddBackgroundMusicCreateRequest';
import {
    AddBackgroundMusicCreateRequestFromJSON,
    AddBackgroundMusicCreateRequestFromJSONTyped,
    AddBackgroundMusicCreateRequestToJSON,
} from './AddBackgroundMusicCreateRequest';
import type { AddDynamicOverlayCreateRequest } from './AddDynamicOverlayCreateRequest';
import {
    AddDynamicOverlayCreateRequestFromJSON,
    AddDynamicOverlayCreateRequestFromJSONTyped,
    AddDynamicOverlayCreateRequestToJSON,
} from './AddDynamicOverlayCreateRequest';
import type { AudioAdjustmentsCreateRequest } from './AudioAdjustmentsCreateRequest';
import {
    AudioAdjustmentsCreateRequestFromJSON,
    AudioAdjustmentsCreateRequestFromJSONTyped,
    AudioAdjustmentsCreateRequestToJSON,
} from './AudioAdjustmentsCreateRequest';
import type { EBranchMutationType } from './EBranchMutationType';
import {
    EBranchMutationTypeFromJSON,
    EBranchMutationTypeFromJSONTyped,
    EBranchMutationTypeToJSON,
} from './EBranchMutationType';
import type { ResizingCreateRequest } from './ResizingCreateRequest';
import {
    ResizingCreateRequestFromJSON,
    ResizingCreateRequestFromJSONTyped,
    ResizingCreateRequestToJSON,
} from './ResizingCreateRequest';

/**
 * 
 * @export
 * @interface MediaSequenceBranchMutationCreateRequest
 */
export interface MediaSequenceBranchMutationCreateRequest {
    /**
     * 
     * @type {EBranchMutationType}
     * @memberof MediaSequenceBranchMutationCreateRequest
     */
    type: EBranchMutationType;
    /**
     * 
     * @type {AddBackgroundMusicCreateRequest}
     * @memberof MediaSequenceBranchMutationCreateRequest
     */
    addBackgroundMusic?: AddBackgroundMusicCreateRequest;
    /**
     * 
     * @type {AddAddWatermarkCreateRequest}
     * @memberof MediaSequenceBranchMutationCreateRequest
     */
    addWatermark?: AddAddWatermarkCreateRequest;
    /**
     * 
     * @type {AddDynamicOverlayCreateRequest}
     * @memberof MediaSequenceBranchMutationCreateRequest
     */
    addDynamicOverlay?: AddDynamicOverlayCreateRequest;
    /**
     * 
     * @type {AudioAdjustmentsCreateRequest}
     * @memberof MediaSequenceBranchMutationCreateRequest
     */
    audioAdjustments?: AudioAdjustmentsCreateRequest;
    /**
     * 
     * @type {ResizingCreateRequest}
     * @memberof MediaSequenceBranchMutationCreateRequest
     */
    resizing?: ResizingCreateRequest;
}

/**
 * Check if a given object implements the MediaSequenceBranchMutationCreateRequest interface.
 */
export function instanceOfMediaSequenceBranchMutationCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function MediaSequenceBranchMutationCreateRequestFromJSON(json: any): MediaSequenceBranchMutationCreateRequest {
    return MediaSequenceBranchMutationCreateRequestFromJSONTyped(json, false);
}

export function MediaSequenceBranchMutationCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MediaSequenceBranchMutationCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': EBranchMutationTypeFromJSON(json['type']),
        'addBackgroundMusic': !exists(json, 'addBackgroundMusic') ? undefined : AddBackgroundMusicCreateRequestFromJSON(json['addBackgroundMusic']),
        'addWatermark': !exists(json, 'addWatermark') ? undefined : AddAddWatermarkCreateRequestFromJSON(json['addWatermark']),
        'addDynamicOverlay': !exists(json, 'addDynamicOverlay') ? undefined : AddDynamicOverlayCreateRequestFromJSON(json['addDynamicOverlay']),
        'audioAdjustments': !exists(json, 'audioAdjustments') ? undefined : AudioAdjustmentsCreateRequestFromJSON(json['audioAdjustments']),
        'resizing': !exists(json, 'resizing') ? undefined : ResizingCreateRequestFromJSON(json['resizing']),
    };
}

export function MediaSequenceBranchMutationCreateRequestToJSON(value?: MediaSequenceBranchMutationCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': EBranchMutationTypeToJSON(value.type),
        'addBackgroundMusic': AddBackgroundMusicCreateRequestToJSON(value.addBackgroundMusic),
        'addWatermark': AddAddWatermarkCreateRequestToJSON(value.addWatermark),
        'addDynamicOverlay': AddDynamicOverlayCreateRequestToJSON(value.addDynamicOverlay),
        'audioAdjustments': AudioAdjustmentsCreateRequestToJSON(value.audioAdjustments),
        'resizing': ResizingCreateRequestToJSON(value.resizing),
    };
}

