/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EFieldVisibility = {
    AlwaysHidden: 'ALWAYS_HIDDEN',
    DesktopOnly: 'DESKTOP_ONLY',
    MobileOnly: 'MOBILE_ONLY',
    AlwaysVisible: 'ALWAYS_VISIBLE'
} as const;
export type EFieldVisibility = typeof EFieldVisibility[keyof typeof EFieldVisibility];


export function EFieldVisibilityFromJSON(json: any): EFieldVisibility {
    return EFieldVisibilityFromJSONTyped(json, false);
}

export function EFieldVisibilityFromJSONTyped(json: any, ignoreDiscriminator: boolean): EFieldVisibility {
    return json as EFieldVisibility;
}

export function EFieldVisibilityToJSON(value?: EFieldVisibility | null): any {
    return value as any;
}

