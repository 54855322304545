/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DynamicFieldValidationCreateRequest } from './DynamicFieldValidationCreateRequest';
import {
    DynamicFieldValidationCreateRequestFromJSON,
    DynamicFieldValidationCreateRequestFromJSONTyped,
    DynamicFieldValidationCreateRequestToJSON,
} from './DynamicFieldValidationCreateRequest';

/**
 * 
 * @export
 * @interface DynamicTemplateFieldCreateRequest
 */
export interface DynamicTemplateFieldCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof DynamicTemplateFieldCreateRequest
     */
    templateKey: string;
    /**
     * 
     * @type {string}
     * @memberof DynamicTemplateFieldCreateRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DynamicTemplateFieldCreateRequest
     */
    defaultValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DynamicTemplateFieldCreateRequest
     */
    description: string;
    /**
     * 
     * @type {Array<DynamicFieldValidationCreateRequest>}
     * @memberof DynamicTemplateFieldCreateRequest
     */
    validations: Array<DynamicFieldValidationCreateRequest>;
}

/**
 * Check if a given object implements the DynamicTemplateFieldCreateRequest interface.
 */
export function instanceOfDynamicTemplateFieldCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "templateKey" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "validations" in value;

    return isInstance;
}

export function DynamicTemplateFieldCreateRequestFromJSON(json: any): DynamicTemplateFieldCreateRequest {
    return DynamicTemplateFieldCreateRequestFromJSONTyped(json, false);
}

export function DynamicTemplateFieldCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DynamicTemplateFieldCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'templateKey': json['templateKey'],
        'name': json['name'],
        'defaultValue': !exists(json, 'defaultValue') ? undefined : json['defaultValue'],
        'description': json['description'],
        'validations': ((json['validations'] as Array<any>).map(DynamicFieldValidationCreateRequestFromJSON)),
    };
}

export function DynamicTemplateFieldCreateRequestToJSON(value?: DynamicTemplateFieldCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'templateKey': value.templateKey,
        'name': value.name,
        'defaultValue': value.defaultValue,
        'description': value.description,
        'validations': ((value.validations as Array<any>).map(DynamicFieldValidationCreateRequestToJSON)),
    };
}

