/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MediaSequenceResponse,
  MediaSequenceResponsePagedData,
  MediaSequenceTemplateCloneRequest,
} from '../models';
import {
    MediaSequenceResponseFromJSON,
    MediaSequenceResponseToJSON,
    MediaSequenceResponsePagedDataFromJSON,
    MediaSequenceResponsePagedDataToJSON,
    MediaSequenceTemplateCloneRequestFromJSON,
    MediaSequenceTemplateCloneRequestToJSON,
} from '../models';

export interface MediaOrbitAdministrationCloneTemplatePostRequest {
    mediaSequenceTemplateCloneRequest?: MediaSequenceTemplateCloneRequest;
}

export interface MediaOrbitAdministrationTemplatesGetRequest {
    pageNumber?: number;
    size?: number;
    orderByProperty?: string;
    ascending?: boolean;
    searchTerm?: string;
}

/**
 * 
 */
export class MediaSequenceAdministrationApi extends runtime.BaseAPI {

    /**
     */
    async mediaOrbitAdministrationCloneTemplatePostRaw(requestParameters: MediaOrbitAdministrationCloneTemplatePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MediaSequenceResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaOrbit/Administration/clone-template`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MediaSequenceTemplateCloneRequestToJSON(requestParameters.mediaSequenceTemplateCloneRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MediaSequenceResponseFromJSON(jsonValue));
    }

    /**
     */
    async mediaOrbitAdministrationCloneTemplatePost(requestParameters: MediaOrbitAdministrationCloneTemplatePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MediaSequenceResponse> {
        const response = await this.mediaOrbitAdministrationCloneTemplatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaOrbitAdministrationTemplatesGetRaw(requestParameters: MediaOrbitAdministrationTemplatesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MediaSequenceResponsePagedData>> {
        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.orderByProperty !== undefined) {
            queryParameters['orderByProperty'] = requestParameters.orderByProperty;
        }

        if (requestParameters.ascending !== undefined) {
            queryParameters['ascending'] = requestParameters.ascending;
        }

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['searchTerm'] = requestParameters.searchTerm;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaOrbit/Administration/templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MediaSequenceResponsePagedDataFromJSON(jsonValue));
    }

    /**
     */
    async mediaOrbitAdministrationTemplatesGet(requestParameters: MediaOrbitAdministrationTemplatesGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MediaSequenceResponsePagedData> {
        const response = await this.mediaOrbitAdministrationTemplatesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
