import { ControlledModal } from 'components/ControlledModal';
import { useState } from 'react';
import { PublicationForm } from './PublicationForm';
import { useGetPublication } from 'api/usePublicationsApi';

interface PublicationUpdateProps {
  id?: string;
  trigger?: React.ReactNode;
  isInitialOpen?: boolean;
  onClosed?: () => void;
}

export const PublicationUpdate = ({
  id,
  trigger,
  isInitialOpen,
  onClosed,
}: PublicationUpdateProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(isInitialOpen ?? false);

  const { data } = useGetPublication(id);

  if (!data) return null;

  const onModalStateChange = (state: boolean) => {
    setIsOpen(state);
    if (!state) {
      onClosed?.();
    }
  };

  return (
    <>
      {trigger ? (
        <div onClick={() => onModalStateChange(true)}>{trigger}</div>
      ) : null}
      <ControlledModal
        showModal={onModalStateChange}
        isOpen={isOpen}
        title="Opdater publicering"
        closeOnOutsideClick
        hideActionBar
      >
        <PublicationForm
          targetPublication={data}
          onSuccess={() => onModalStateChange(false)}
          onCancel={() => onModalStateChange(false)}
        />
      </ControlledModal>
    </>
  );
};
