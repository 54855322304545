import { useGetCase } from 'api/useCasesApi';
import { ControlledModal } from 'components/ControlledModal';
import { useState } from 'react';
import { MediaSequenceCreateForm } from './MediaSequenceCreateForm';
import { MediaSequenceResponse } from 'api/core';

interface MediaSequenceCreateProps {
  existingMediaSequence?: MediaSequenceResponse;
  caseId?: string;
  trigger?: React.ReactNode;
  isInitialOpen?: boolean;
  onClosed?: () => void;
}

export const MediaSequenceCreate = ({
  existingMediaSequence,
  caseId,
  trigger,
  isInitialOpen,
  onClosed,
}: MediaSequenceCreateProps) => {
  const { data: existingCase, isLoading, isError } = useGetCase(caseId);
  const [isOpen, setIsOpen] = useState<boolean>(isInitialOpen ?? false);

  if (caseId !== null && (isLoading || isError)) {
    return null;
  }

  const onModalStateChange = (state: boolean) => {
    setIsOpen(state);
    if (!state) {
      onClosed?.();
    }
  };

  return (
    <>
      {trigger ? (
        <div onClick={() => onModalStateChange(true)}>{trigger}</div>
      ) : null}
      <ControlledModal
        showModal={onModalStateChange}
        isOpen={isOpen}
        title="Opret video"
        closeOnOutsideClick
        hideActionBar
      >
        <MediaSequenceCreateForm
          existingMediaSequence={existingMediaSequence}
          existingCase={existingCase}
          onSuccess={() => onModalStateChange(false)}
          onCancel={() => onModalStateChange(false)}
          inDialog={true}
        />
      </ControlledModal>
    </>
  );
};
