import { Tooltip } from 'components/Tooltip';
import { ReactNode } from 'react';
import { InfoIcon } from 'lucide-react';

interface LabelWithHelperTextProps {
  label: ReactNode;
  helperText: string;
  inDialog?: boolean;
  htmlFor?: string;
  position?: 'top' | 'right' | 'bottom' | 'left';
}

export const LabelWithHelperText = ({
  label,
  helperText,
  inDialog = false,
  htmlFor,
  position,
}: LabelWithHelperTextProps) => {
  return (
    <label className="label" htmlFor={htmlFor}>
      <span className="label-text">{label}</span>
      {helperText ? (
        <Tooltip
          position={position || (inDialog ? 'left' : undefined)}
          tooltip={helperText}
        >
          <InfoIcon className="h-8 md:h-5 w-8 md:w-5" />
        </Tooltip>
      ) : null}
    </label>
  );
};
