import { useMutation, useQuery } from '@tanstack/react-query';

import { TableQueryState } from 'components/Table/useTableQueryState';
import { queryClient } from 'query-client';
import { toast } from 'react-toastify';
import { CoreBaseConfiguration } from './BaseConfiguration';
import {
  ServiceTicketCreateRequest,
  ServiceTicketReplyRequest,
  ServiceTicketsApi,
} from './core';

export const ApiKeys = {
  GET_TICKETS: 'tickets',
  GET_TICKET_STATE: 'ticket-state',
  GET_TICKET: 'ticket',
};

const client = new ServiceTicketsApi(new CoreBaseConfiguration());

export const useGetTickets = (
  includeForAllOrganizations: boolean,
  state?: TableQueryState
) => {
  const customState: {
    includeActive: boolean;
    includeArchived: boolean;
  } = JSON.parse(state?.customState || '{}');

  return useQuery({
    queryKey: [ApiKeys.GET_TICKETS, state, includeForAllOrganizations],
    queryFn: () =>
      client.serviceTicketsGet({
        pageNumber: state?.pagination.pageIndex,
        size: state?.pagination.pageSize,
        orderByProperty: state?.sorting[0]?.id,
        ascending: state?.sorting[0]?.desc === false,
        searchTerm: state?.globalFilter,
        includeForAllOrganizations,
        includeActive: customState.includeActive,
        includeArchived: customState.includeArchived,
      }),
  });
};

export const useGetTicketState = () => {
  return useQuery({
    queryKey: [ApiKeys.GET_TICKETS],
    queryFn: () => client.serviceTicketsStateGet(),
  });
};

export const useGetTicket = (id?: string) => {
  return useQuery({
    queryKey: [ApiKeys.GET_TICKET, id],
    queryFn: () => client.serviceTicketsIdGet({ id: id ?? '' }),
    enabled: id !== undefined,
  });
};

export const useCreateTicket = () => {
  return useMutation({
    mutationFn: ({
      serviceTicketCreateRequest,
    }: {
      serviceTicketCreateRequest: ServiceTicketCreateRequest;
    }) => client.serviceTicketsPost({ serviceTicketCreateRequest }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [ApiKeys.GET_TICKETS],
      });
      toast.success(`Ticket '${e.title}' er blevet oprettet`, {
        toastId: 'createTicket',
      });
    },
  });
};

export const useReplyTicket = () => {
  return useMutation({
    mutationFn: ({
      id,
      serviceTicketReplyRequest,
    }: {
      id: string;
      serviceTicketReplyRequest: ServiceTicketReplyRequest;
    }) => client.serviceTicketsIdReplyPut({ id, serviceTicketReplyRequest }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [ApiKeys.GET_TICKETS],
      });
      queryClient.invalidateQueries({
        queryKey: [ApiKeys.GET_TICKET, e.id],
      });
      toast.success(`Ticket er blevet besvaret`, {
        toastId: 'replyTicket',
      });
    },
  });
};

export const useReopenTicket = () => {
  return useMutation({
    mutationFn: ({ id }: { id: string }) =>
      client.serviceTicketsIdReopenPut({ id }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [ApiKeys.GET_TICKETS],
      });
      queryClient.invalidateQueries({
        queryKey: [ApiKeys.GET_TICKET, e.id],
      });
      toast.success(`Ticket er blevet genåbnet`, {
        toastId: 'reopenTicket',
      });
    },
  });
};

export const useDeleteTicket = () => {
  return useMutation({
    mutationFn: ({ id }: { id: string }) =>
      client.serviceTicketsIdDelete({ id }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [ApiKeys.GET_TICKETS],
      });
      queryClient.invalidateQueries({
        queryKey: [ApiKeys.GET_TICKET, e.id],
      });
      toast.success(`Ticket '${e.title}' er blevet slettet`, {
        toastId: 'deleteTicket',
      });
    },
  });
};
