/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AngleCreateRequest
 */
export interface AngleCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof AngleCreateRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AngleCreateRequest
     */
    sectionId: string;
    /**
     * 
     * @type {number}
     * @memberof AngleCreateRequest
     */
    northOffset?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AngleCreateRequest
     */
    order: number;
    /**
     * 
     * @type {string}
     * @memberof AngleCreateRequest
     */
    renderingId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AngleCreateRequest
     */
    overlayId?: string | null;
}

/**
 * Check if a given object implements the AngleCreateRequest interface.
 */
export function instanceOfAngleCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "sectionId" in value;
    isInstance = isInstance && "order" in value;

    return isInstance;
}

export function AngleCreateRequestFromJSON(json: any): AngleCreateRequest {
    return AngleCreateRequestFromJSONTyped(json, false);
}

export function AngleCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AngleCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'sectionId': json['sectionId'],
        'northOffset': !exists(json, 'northOffset') ? undefined : json['northOffset'],
        'order': json['order'],
        'renderingId': !exists(json, 'renderingId') ? undefined : json['renderingId'],
        'overlayId': !exists(json, 'overlayId') ? undefined : json['overlayId'],
    };
}

export function AngleCreateRequestToJSON(value?: AngleCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'sectionId': value.sectionId,
        'northOffset': value.northOffset,
        'order': value.order,
        'renderingId': value.renderingId,
        'overlayId': value.overlayId,
    };
}

