/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  VisualIdentityCreateRequest,
  VisualIdentityResponse,
  VisualIdentityResponsePagedData,
  VisualIdentityUpdateRequest,
} from '../models';
import {
    VisualIdentityCreateRequestFromJSON,
    VisualIdentityCreateRequestToJSON,
    VisualIdentityResponseFromJSON,
    VisualIdentityResponseToJSON,
    VisualIdentityResponsePagedDataFromJSON,
    VisualIdentityResponsePagedDataToJSON,
    VisualIdentityUpdateRequestFromJSON,
    VisualIdentityUpdateRequestToJSON,
} from '../models';

export interface VisualIdentitiesGetRequest {
    pageNumber?: number;
    size?: number;
    orderByProperty?: string;
    ascending?: boolean;
    searchTerm?: string;
}

export interface VisualIdentitiesIdDeleteRequest {
    id: string;
}

export interface VisualIdentitiesIdGetRequest {
    id: string;
}

export interface VisualIdentitiesIdPutRequest {
    id: string;
    visualIdentityUpdateRequest?: VisualIdentityUpdateRequest;
}

export interface VisualIdentitiesPostRequest {
    visualIdentityCreateRequest?: VisualIdentityCreateRequest;
}

/**
 * 
 */
export class VisualIdentitiesApi extends runtime.BaseAPI {

    /**
     */
    async visualIdentitiesGetRaw(requestParameters: VisualIdentitiesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VisualIdentityResponsePagedData>> {
        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.orderByProperty !== undefined) {
            queryParameters['orderByProperty'] = requestParameters.orderByProperty;
        }

        if (requestParameters.ascending !== undefined) {
            queryParameters['ascending'] = requestParameters.ascending;
        }

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['searchTerm'] = requestParameters.searchTerm;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/VisualIdentities`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VisualIdentityResponsePagedDataFromJSON(jsonValue));
    }

    /**
     */
    async visualIdentitiesGet(requestParameters: VisualIdentitiesGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VisualIdentityResponsePagedData> {
        const response = await this.visualIdentitiesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async visualIdentitiesIdDeleteRaw(requestParameters: VisualIdentitiesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VisualIdentityResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling visualIdentitiesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/VisualIdentities/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VisualIdentityResponseFromJSON(jsonValue));
    }

    /**
     */
    async visualIdentitiesIdDelete(requestParameters: VisualIdentitiesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VisualIdentityResponse> {
        const response = await this.visualIdentitiesIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async visualIdentitiesIdGetRaw(requestParameters: VisualIdentitiesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VisualIdentityResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling visualIdentitiesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/VisualIdentities/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VisualIdentityResponseFromJSON(jsonValue));
    }

    /**
     */
    async visualIdentitiesIdGet(requestParameters: VisualIdentitiesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VisualIdentityResponse> {
        const response = await this.visualIdentitiesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async visualIdentitiesIdPutRaw(requestParameters: VisualIdentitiesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VisualIdentityResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling visualIdentitiesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/VisualIdentities/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: VisualIdentityUpdateRequestToJSON(requestParameters.visualIdentityUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VisualIdentityResponseFromJSON(jsonValue));
    }

    /**
     */
    async visualIdentitiesIdPut(requestParameters: VisualIdentitiesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VisualIdentityResponse> {
        const response = await this.visualIdentitiesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async visualIdentitiesPostRaw(requestParameters: VisualIdentitiesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VisualIdentityResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/VisualIdentities`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VisualIdentityCreateRequestToJSON(requestParameters.visualIdentityCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VisualIdentityResponseFromJSON(jsonValue));
    }

    /**
     */
    async visualIdentitiesPost(requestParameters: VisualIdentitiesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VisualIdentityResponse> {
        const response = await this.visualIdentitiesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
