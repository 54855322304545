/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Page } from './Page';
import {
    PageFromJSON,
    PageFromJSONTyped,
    PageToJSON,
} from './Page';
import type { TextCollectionResponse } from './TextCollectionResponse';
import {
    TextCollectionResponseFromJSON,
    TextCollectionResponseFromJSONTyped,
    TextCollectionResponseToJSON,
} from './TextCollectionResponse';

/**
 * 
 * @export
 * @interface TextCollectionResponsePagedData
 */
export interface TextCollectionResponsePagedData {
    /**
     * 
     * @type {Page}
     * @memberof TextCollectionResponsePagedData
     */
    page: Page;
    /**
     * 
     * @type {Array<TextCollectionResponse>}
     * @memberof TextCollectionResponsePagedData
     */
    data: Array<TextCollectionResponse>;
}

/**
 * Check if a given object implements the TextCollectionResponsePagedData interface.
 */
export function instanceOfTextCollectionResponsePagedData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "page" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function TextCollectionResponsePagedDataFromJSON(json: any): TextCollectionResponsePagedData {
    return TextCollectionResponsePagedDataFromJSONTyped(json, false);
}

export function TextCollectionResponsePagedDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): TextCollectionResponsePagedData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': PageFromJSON(json['page']),
        'data': ((json['data'] as Array<any>).map(TextCollectionResponseFromJSON)),
    };
}

export function TextCollectionResponsePagedDataToJSON(value?: TextCollectionResponsePagedData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': PageToJSON(value.page),
        'data': ((value.data as Array<any>).map(TextCollectionResponseToJSON)),
    };
}

