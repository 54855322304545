import { useMutation, useQuery } from '@tanstack/react-query';

import { TableQueryState } from 'components/Table/useTableQueryState';
import { queryClient } from 'query-client';
import { toast } from 'react-toastify';
import { CoreBaseConfiguration } from './BaseConfiguration';
import {
  CaseCreateRequest,
  CasesApi,
  CaseUpdateRequest,
  ECaseState,
} from './core';
import { MediaSequenceApiKeys } from './useMediaSequencesApi';

const CaseApiKeys = {
  GET_CASES_SEARCH: 'cases-search',
  GET_CASES: 'cases',
  GET_CASE: 'case',
  GET_CASE_STATISTICS: 'case-statistics',
};

const client = new CasesApi(new CoreBaseConfiguration());

export const useSearchCases = ({ searchTerm }: { searchTerm?: string }) => {
  return useQuery({
    queryKey: [CaseApiKeys.GET_CASES_SEARCH, searchTerm],
    queryFn: () =>
      client.casesSearchGet({
        size: 50,
        searchTerm,
      }),
    enabled: searchTerm !== undefined || searchTerm != '',
  });
};

export const getCasesWrapper = (
  projectId?: string,
  state?: TableQueryState
) => {
  return client.casesGet({
    projectId,
    pageNumber: state?.pagination.pageIndex,
    size: state?.pagination.pageSize,
    orderByProperty: state?.sorting[0]?.id,
    ascending: state?.sorting[0]?.desc === false,
    searchTerm: state?.globalFilter,
  });
};

export const useGetCases = (projectId?: string, state?: TableQueryState) => {
  return useQuery({
    queryKey: [CaseApiKeys.GET_CASES, projectId, state],
    queryFn: () =>
      client.casesGet({
        projectId,
        pageNumber: state?.pagination.pageIndex,
        size: state?.pagination.pageSize,
        orderByProperty: state?.sorting[0]?.id,
        ascending: state?.sorting[0]?.desc === false,
        searchTerm: state?.globalFilter,
      }),
    enabled: projectId !== undefined,
  });
};

export const useGetCase = (id?: string | null) => {
  return useQuery({
    queryKey: [CaseApiKeys.GET_CASE, id],
    queryFn: () => client.casesIdGet({ id: id ?? '' }),
    enabled: id !== undefined && id !== null,
  });
};

export const useCreateCase = () => {
  return useMutation({
    mutationFn: ({
      projectId,
      caseCreateRequest,
    }: {
      projectId: string;
      caseCreateRequest: CaseCreateRequest;
    }) => client.casesProjectIdPost({ projectId, caseCreateRequest }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [CaseApiKeys.GET_CASES],
      });
      queryClient.invalidateQueries({
        queryKey: [CaseApiKeys.GET_CASE_STATISTICS, e.project.id],
      });
      toast.success(`Sagen '${e.address}' er blevet oprettet`, {
        toastId: 'createCase',
      });
    },
  });
};

export const useUpdateCase = () => {
  return useMutation({
    mutationFn: ({
      id,
      caseUpdateRequest,
    }: {
      id: string;
      caseUpdateRequest: CaseUpdateRequest;
    }) => client.casesIdPut({ id, caseUpdateRequest }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [CaseApiKeys.GET_CASES],
      });
      queryClient.invalidateQueries({
        queryKey: [CaseApiKeys.GET_CASE, e.id],
      });
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCE],
      });
      queryClient.invalidateQueries({
        queryKey: [CaseApiKeys.GET_CASE_STATISTICS, e.project.id],
      });
      toast.success(`Sagen '${e.address}' er blevet opdateret`, {
        toastId: 'updateCase',
      });
    },
  });
};

export const useAssignCaseAssets = () => {
  return useMutation({
    mutationFn: ({ id, assetIds }: { id: string; assetIds: string[] }) =>
      client.casesIdAssetsPut({ id, requestBody: assetIds }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [CaseApiKeys.GET_CASES],
      });
      queryClient.invalidateQueries({
        queryKey: [CaseApiKeys.GET_CASE, e.id],
      });
      toast.success(`Sagen '${e.address}' har fået opdateret sine filer`, {
        toastId: 'assignCaseAssets',
      });
    },
  });
};

export const useDeleteCase = () => {
  return useMutation({
    mutationFn: ({ id }: { id: string }) => client.casesIdDelete({ id }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [CaseApiKeys.GET_CASES],
      });
      queryClient.invalidateQueries({
        queryKey: [CaseApiKeys.GET_CASE, e.id],
      });
      queryClient.invalidateQueries({
        queryKey: [CaseApiKeys.GET_CASE_STATISTICS, e.project.id],
      });
      toast.success(`Sagen '${e.address}' er blevet slettet`, {
        toastId: 'deleteCase',
      });
    },
  });
};

export const useGetCaseStatisticsForState = (projectId?: string) => {
  return useQuery({
    queryKey: [CaseApiKeys.GET_CASE_STATISTICS, projectId],
    queryFn: () =>
      client.casesStatisticsStatesGet({
        projectId,
      }),
    enabled: projectId !== undefined,
    select: (data) => {
      if (data.items) {
        return data.items.sort((a, b) => {
          if (a.key === ECaseState.Available) return -1;
          if (b.key === ECaseState.Available) return 1;
          if (a.key === ECaseState.Reserved) return -1;
          if (b.key === ECaseState.Reserved) return 1;
          if (a.key === ECaseState.Unavailable) return -1;
          if (b.key === ECaseState.Unavailable) return 1;
          if (a.key === ECaseState.Deactivated) return -1;
          if (b.key === ECaseState.Deactivated) return 1;
          return 0;
        });
      }
      return data.items || [];
    },
  });
};
