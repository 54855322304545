/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EFieldType } from './EFieldType';
import {
    EFieldTypeFromJSON,
    EFieldTypeFromJSONTyped,
    EFieldTypeToJSON,
} from './EFieldType';
import type { EFieldVisibility } from './EFieldVisibility';
import {
    EFieldVisibilityFromJSON,
    EFieldVisibilityFromJSONTyped,
    EFieldVisibilityToJSON,
} from './EFieldVisibility';

/**
 * 
 * @export
 * @interface FieldCreateRequest
 */
export interface FieldCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof FieldCreateRequest
     */
    name?: string | null;
    /**
     * 
     * @type {EFieldType}
     * @memberof FieldCreateRequest
     */
    type?: EFieldType;
    /**
     * 
     * @type {number}
     * @memberof FieldCreateRequest
     */
    order?: number;
    /**
     * 
     * @type {EFieldVisibility}
     * @memberof FieldCreateRequest
     */
    visibility?: EFieldVisibility;
    /**
     * 
     * @type {boolean}
     * @memberof FieldCreateRequest
     */
    isFilter?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FieldCreateRequest
     */
    illustrationId?: string | null;
}

/**
 * Check if a given object implements the FieldCreateRequest interface.
 */
export function instanceOfFieldCreateRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FieldCreateRequestFromJSON(json: any): FieldCreateRequest {
    return FieldCreateRequestFromJSONTyped(json, false);
}

export function FieldCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FieldCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : EFieldTypeFromJSON(json['type']),
        'order': !exists(json, 'order') ? undefined : json['order'],
        'visibility': !exists(json, 'visibility') ? undefined : EFieldVisibilityFromJSON(json['visibility']),
        'isFilter': !exists(json, 'isFilter') ? undefined : json['isFilter'],
        'illustrationId': !exists(json, 'illustrationId') ? undefined : json['illustrationId'],
    };
}

export function FieldCreateRequestToJSON(value?: FieldCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': EFieldTypeToJSON(value.type),
        'order': value.order,
        'visibility': EFieldVisibilityToJSON(value.visibility),
        'isFilter': value.isFilter,
        'illustrationId': value.illustrationId,
    };
}

