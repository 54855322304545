import { useEffect, useState } from 'react';
import Select, { SingleValue } from 'react-select';

interface OptionType<T> {
  value: T;
  label: string;
  searchValue: string;
}

interface EntitySelectSingleProps<T> {
  data: T[];
  renderFormat: (input: T) => React.ReactNode;
  onSelect: (output: T | null) => void;
  initialValue?: T;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  searchPropertyKey: keyof T;
  searchFn?: (input: string, option: T) => boolean;
  inDialog?: boolean;
  disabled?: boolean;
  placeholder?: string;
}

export const EntitySelectSingle = <T extends { id: string | number }>({
  data,
  renderFormat,
  onSelect,
  initialValue,
  menuPlacement = 'auto',
  searchPropertyKey,
  searchFn,
  inDialog,
  disabled,
  placeholder,
}: EntitySelectSingleProps<T>) => {
  const [menuPortalTarget, setMenuPortalTarget] = useState<HTMLElement | null>(
    null
  );
  const [selectedOption, setSelectedOption] = useState<OptionType<T> | null>(
    initialValue
      ? {
          value: initialValue,
          label: '',
          searchValue: initialValue[searchPropertyKey] as unknown as string,
        }
      : null
  );

  useEffect(() => {
    if (inDialog) {
      setMenuPortalTarget(document.querySelector('dialog'));
    } else {
      setMenuPortalTarget(document.querySelector('body'));
    }
  }, [inDialog]);

  useEffect(() => {
    if (initialValue) {
      setSelectedOption({
        value: initialValue,
        label: '',
        searchValue: initialValue[searchPropertyKey] as unknown as string,
      });
    } else {
      setSelectedOption(null);
    }
  }, [initialValue, searchPropertyKey]);

  return (
    <div className="form-control w-full">
      <Select
        id="entity-select-single"
        inputId="entity-select-single-input"
        options={data.map((value) => ({
          value,
          label: '',
          searchValue: value[searchPropertyKey] as unknown as string,
        }))}
        value={selectedOption}
        onChange={(option: SingleValue<OptionType<T>>) => {
          setSelectedOption(option);
          onSelect(option ? option.value : null);
        }}
        formatOptionLabel={(option) => <>{renderFormat(option.value)}</>}
        placeholder={placeholder ?? 'Skriv for at søge...'}
        isClearable
        noOptionsMessage={() => 'Ingen resultater fundet'}
        menuPlacement={menuPlacement}
        getOptionValue={(option) => option.value.id.toString()}
        filterOption={(option, rawInput) => {
          if (searchFn) {
            return searchFn(rawInput, option.data.value);
          }
          const input = rawInput.toLowerCase();
          return option.data.searchValue.toLowerCase().includes(input);
        }}
        menuPortalTarget={menuPortalTarget}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            height: '3rem',
          }),
        }}
        isDisabled={disabled}
      />
    </div>
  );
};
