import { useGetProject } from 'api/useProjectsApi';
import { Page } from 'layouts/Admin/Page';
import { useParams } from 'react-router-dom';
import { ECaseState } from 'api/core';
import { useGetCaseStatisticsForState } from 'api/useCasesApi';
import { twMerge } from 'tailwind-merge';
import { caseStateTranslate } from 'utils/enum-translate';

export const ProjectStatistics = () => {
  const { id } = useParams<{ id: string }>();

  const { data } = useGetProject(id, true);
  const { data: stateStatistics } = useGetCaseStatisticsForState(id);

  if (!data) return null;

  const totaltElements = stateStatistics?.reduce(
    (acc, item) => acc + item.value,
    0
  );

  return (
    <Page
      title={data.name}
      breadcrumbs={[
        { name: 'Projekter' },
        { name: data.name },
        { name: 'Statistik' },
      ]}
    >
      {/* // TODO: Could change to pie chart and maybe create a generic component for this */}
      {stateStatistics?.length ? (
        <div className="py-4">
          <h2 className="text-lg font-semibold">Sagsstatistik</h2>
          {stateStatistics.map((item) => (
            <div key={item.key}>
              <progress
                className={twMerge(
                  'progress w-full',
                  item.key === ECaseState.Available && 'progress-primary',
                  item.key === ECaseState.Unavailable && 'progress-error',
                  item.key === ECaseState.Reserved && 'progress-warning',
                  item.key === ECaseState.Deactivated && 'progress-secondary'
                )}
                value={item.value}
                max={totaltElements}
              ></progress>
              <div className="text-sm text-gray-500 text-left">
                {caseStateTranslate(item.key)} ({item.value})
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </Page>
  );
};
