/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Language
 */
export interface Language {
    /**
     * 
     * @type {number}
     * @memberof Language
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Language
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Language
     */
    formattingLanguageCode?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Language
     */
    selectable?: boolean;
}

/**
 * Check if a given object implements the Language interface.
 */
export function instanceOfLanguage(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LanguageFromJSON(json: any): Language {
    return LanguageFromJSONTyped(json, false);
}

export function LanguageFromJSONTyped(json: any, ignoreDiscriminator: boolean): Language {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'formattingLanguageCode': !exists(json, 'formattingLanguageCode') ? undefined : json['formattingLanguageCode'],
        'selectable': !exists(json, 'selectable') ? undefined : json['selectable'],
    };
}

export function LanguageToJSON(value?: Language | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'formattingLanguageCode': value.formattingLanguageCode,
        'selectable': value.selectable,
    };
}

