import { ResetPasswordUsingKeyRequest } from 'api/core';
import { useResetPassword } from 'api/useAuthApi';
import { useForm } from 'react-hook-form';
import { NavLink, useParams } from 'react-router-dom';

export const ResetPasswordView = () => {
  const { resetKey } = useParams<{ resetKey: string }>();
  const { mutateAsync, isPending } = useResetPassword();

  const { register, handleSubmit } = useForm<ResetPasswordUsingKeyRequest>({
    defaultValues: {
      resetKey,
    },
  });

  const onSubmit = handleSubmit(async (result) => {
    try {
      await mutateAsync({
        resetPasswordUsingKeyRequest: result,
        isRegisteringer: false,
      });
    } catch {
      /* empty */
    }
  });

  return (
    <form onSubmit={onSubmit} className="space-y-4">
      <div className="form-control">
        <input
          {...register('resetKey', { required: true })}
          type="text"
          className="hidden"
        />
      </div>

      <div className="form-control">
        <label className="label">
          <span className="text-base label-text">Adgangskode</span>
        </label>
        <input
          {...register('password', { required: true })}
          type="password"
          placeholder="Adgangskode"
          className="w-full input input-bordered"
          minLength={8}
        />
      </div>

      <div>
        <button className="btn btn-primary btn-block" disabled={isPending}>
          Sæt ny adgangskode
        </button>
      </div>

      <div className="divider"></div>

      <div className="flex justify-center">
        <NavLink to={'/login'} className="link link-hover" end>
          Tilbage til login
        </NavLink>
      </div>
    </form>
  );
};
