import { UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { ErrorComponent } from 'components/Error';
import { MutateEntity } from './MutateEntity';

interface DeleteEntityByIdProps<T> {
  id?: string;
  trigger?: React.ReactNode;
  isInitialOpen?: boolean;
  onDeleted?: (result: T) => void;
  onClosed?: () => void;
  title?: string;
  titlePropertyKey?: keyof T;
  getter: (id?: string) => UseQueryResult<T, Error>;
  deleter: () => UseMutationResult<T, Error, { id: string }, unknown>;
  disabled?: boolean;
  tooltip?: string;
  titleFn?: (entityTitle: string) => string;
  descriptionFn?: (entityTitle: string) => string;
}

export function DeleteEntityById<T>({
  id,
  trigger,
  isInitialOpen,
  onDeleted,
  onClosed,
  title,
  titlePropertyKey,
  getter,
  deleter,
  disabled,
  tooltip,
  titleFn,
  descriptionFn,
}: DeleteEntityByIdProps<T>) {
  const { data, isLoading, isError } = getter(id);

  if (!id || isLoading) return null;
  if (isError || !data) return <ErrorComponent />;

  const defaultTitleFn = (entityTitle: string) => `Slet ${entityTitle}?`;
  const defaultDescriptionFn = (entityTitle: string) =>
    `Er du sikker på du vil slette '${entityTitle}'?`;

  const entityTitle = (
    title ? title : titlePropertyKey ? data[titlePropertyKey] : 'objektet'
  ) as string;

  return (
    <MutateEntity
      trigger={trigger}
      isInitialOpen={isInitialOpen}
      onMutate={onDeleted}
      onClosed={onClosed}
      title={titleFn ? titleFn(entityTitle) : defaultTitleFn(entityTitle)}
      description={
        descriptionFn
          ? descriptionFn(entityTitle)
          : defaultDescriptionFn(entityTitle)
      }
      setter={deleter}
      mutationVariables={{ id }}
      hidden={disabled}
      tooltip={tooltip}
    />
  );
}
