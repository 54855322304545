import { ControlledModal } from 'components/ControlledModal';
import { useState } from 'react';
import { SmartTextCreateForm } from './SmartTextCreateForm';
import { useNavigate } from 'react-router-dom';

interface SmartTextCreateProps {
  trigger?: React.ReactNode;
  isInitialOpen?: boolean;
  onClosed?: () => void;
}

export const SmartTextCreate = ({
  trigger,
  isInitialOpen,
  onClosed,
}: SmartTextCreateProps) => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState<boolean>(isInitialOpen ?? false);

  const onModalStateChange = (state: boolean) => {
    setIsOpen(state);
    if (!state) {
      onClosed?.();
    }
  };

  const onSuccess = (id: string) => {
    onModalStateChange(false);
    navigate('/smart-texts?id=' + id);
  };

  return (
    <>
      {trigger ? (
        <div onClick={() => onModalStateChange(true)}>{trigger}</div>
      ) : null}
      <ControlledModal
        showModal={onModalStateChange}
        isOpen={isOpen}
        title="Opret boligtekst"
        closeOnOutsideClick={false}
        hideActionBar
      >
        <SmartTextCreateForm
          onSuccess={onSuccess}
          onCancel={() => onModalStateChange(false)}
        />
      </ControlledModal>
    </>
  );
};
