/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EPublicationPlatform = {
    FacebookFeed: 'FacebookFeed',
    FacebookStory: 'FacebookStory',
    InstagramFeed: 'InstagramFeed',
    InstagramStory: 'InstagramStory'
} as const;
export type EPublicationPlatform = typeof EPublicationPlatform[keyof typeof EPublicationPlatform];


export function EPublicationPlatformFromJSON(json: any): EPublicationPlatform {
    return EPublicationPlatformFromJSONTyped(json, false);
}

export function EPublicationPlatformFromJSONTyped(json: any, ignoreDiscriminator: boolean): EPublicationPlatform {
    return json as EPublicationPlatform;
}

export function EPublicationPlatformToJSON(value?: EPublicationPlatform | null): any {
    return value as any;
}

