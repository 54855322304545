/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EPermission } from './EPermission';
import {
    EPermissionFromJSON,
    EPermissionFromJSONTyped,
    EPermissionToJSON,
} from './EPermission';
import type { OrganizationResponse } from './OrganizationResponse';
import {
    OrganizationResponseFromJSON,
    OrganizationResponseFromJSONTyped,
    OrganizationResponseToJSON,
} from './OrganizationResponse';
import type { RoleResponse } from './RoleResponse';
import {
    RoleResponseFromJSON,
    RoleResponseFromJSONTyped,
    RoleResponseToJSON,
} from './RoleResponse';

/**
 * 
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
    /**
     * 
     * @type {Date}
     * @memberof UserResponse
     */
    lastModifiedUtc: Date;
    /**
     * 
     * @type {Date}
     * @memberof UserResponse
     */
    createdUtc: Date;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    email: string;
    /**
     * 
     * @type {Array<RoleResponse>}
     * @memberof UserResponse
     */
    roles: Array<RoleResponse>;
    /**
     * 
     * @type {OrganizationResponse}
     * @memberof UserResponse
     */
    organization: OrganizationResponse;
    /**
     * 
     * @type {Array<EPermission>}
     * @memberof UserResponse
     */
    permissions?: Array<EPermission> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponse
     */
    disabled: boolean;
}

/**
 * Check if a given object implements the UserResponse interface.
 */
export function instanceOfUserResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lastModifiedUtc" in value;
    isInstance = isInstance && "createdUtc" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "roles" in value;
    isInstance = isInstance && "organization" in value;
    isInstance = isInstance && "disabled" in value;

    return isInstance;
}

export function UserResponseFromJSON(json: any): UserResponse {
    return UserResponseFromJSONTyped(json, false);
}

export function UserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastModifiedUtc': (new Date(json['lastModifiedUtc'])),
        'createdUtc': (new Date(json['createdUtc'])),
        'id': json['id'],
        'name': json['name'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'email': json['email'],
        'roles': ((json['roles'] as Array<any>).map(RoleResponseFromJSON)),
        'organization': OrganizationResponseFromJSON(json['organization']),
        'permissions': !exists(json, 'permissions') ? undefined : (json['permissions'] === null ? null : (json['permissions'] as Array<any>).map(EPermissionFromJSON)),
        'disabled': json['disabled'],
    };
}

export function UserResponseToJSON(value?: UserResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lastModifiedUtc': (value.lastModifiedUtc.toISOString()),
        'createdUtc': (value.createdUtc.toISOString()),
        'id': value.id,
        'name': value.name,
        'title': value.title,
        'email': value.email,
        'roles': ((value.roles as Array<any>).map(RoleResponseToJSON)),
        'organization': OrganizationResponseToJSON(value.organization),
        'permissions': value.permissions === undefined ? undefined : (value.permissions === null ? null : (value.permissions as Array<any>).map(EPermissionToJSON)),
        'disabled': value.disabled,
    };
}

