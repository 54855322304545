/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EAssetMutationType } from './EAssetMutationType';
import {
    EAssetMutationTypeFromJSON,
    EAssetMutationTypeFromJSONTyped,
    EAssetMutationTypeToJSON,
} from './EAssetMutationType';
import type { EBranchMutationType } from './EBranchMutationType';
import {
    EBranchMutationTypeFromJSON,
    EBranchMutationTypeFromJSONTyped,
    EBranchMutationTypeToJSON,
} from './EBranchMutationType';
import type { EUpdateType } from './EUpdateType';
import {
    EUpdateTypeFromJSON,
    EUpdateTypeFromJSONTyped,
    EUpdateTypeToJSON,
} from './EUpdateType';

/**
 * 
 * @export
 * @interface MediaSequenceUpdateResponse
 */
export interface MediaSequenceUpdateResponse {
    /**
     * 
     * @type {Date}
     * @memberof MediaSequenceUpdateResponse
     */
    lastModifiedUtc: Date;
    /**
     * 
     * @type {Date}
     * @memberof MediaSequenceUpdateResponse
     */
    createdUtc: Date;
    /**
     * 
     * @type {string}
     * @memberof MediaSequenceUpdateResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MediaSequenceUpdateResponse
     */
    correlationId: string;
    /**
     * 
     * @type {string}
     * @memberof MediaSequenceUpdateResponse
     */
    mutationId: string;
    /**
     * 
     * @type {boolean}
     * @memberof MediaSequenceUpdateResponse
     */
    isForecast: boolean;
    /**
     * 
     * @type {EAssetMutationType}
     * @memberof MediaSequenceUpdateResponse
     */
    assetMutationType: EAssetMutationType;
    /**
     * 
     * @type {EBranchMutationType}
     * @memberof MediaSequenceUpdateResponse
     */
    branchMutationType: EBranchMutationType;
    /**
     * 
     * @type {EUpdateType}
     * @memberof MediaSequenceUpdateResponse
     */
    type: EUpdateType;
    /**
     * 
     * @type {Date}
     * @memberof MediaSequenceUpdateResponse
     */
    publishTimeUtc: Date;
    /**
     * 
     * @type {string}
     * @memberof MediaSequenceUpdateResponse
     */
    message: string;
}

/**
 * Check if a given object implements the MediaSequenceUpdateResponse interface.
 */
export function instanceOfMediaSequenceUpdateResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lastModifiedUtc" in value;
    isInstance = isInstance && "createdUtc" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "correlationId" in value;
    isInstance = isInstance && "mutationId" in value;
    isInstance = isInstance && "isForecast" in value;
    isInstance = isInstance && "assetMutationType" in value;
    isInstance = isInstance && "branchMutationType" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "publishTimeUtc" in value;
    isInstance = isInstance && "message" in value;

    return isInstance;
}

export function MediaSequenceUpdateResponseFromJSON(json: any): MediaSequenceUpdateResponse {
    return MediaSequenceUpdateResponseFromJSONTyped(json, false);
}

export function MediaSequenceUpdateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MediaSequenceUpdateResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastModifiedUtc': (new Date(json['lastModifiedUtc'])),
        'createdUtc': (new Date(json['createdUtc'])),
        'id': json['id'],
        'correlationId': json['correlationId'],
        'mutationId': json['mutationId'],
        'isForecast': json['isForecast'],
        'assetMutationType': EAssetMutationTypeFromJSON(json['assetMutationType']),
        'branchMutationType': EBranchMutationTypeFromJSON(json['branchMutationType']),
        'type': EUpdateTypeFromJSON(json['type']),
        'publishTimeUtc': (new Date(json['publishTimeUtc'])),
        'message': json['message'],
    };
}

export function MediaSequenceUpdateResponseToJSON(value?: MediaSequenceUpdateResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lastModifiedUtc': (value.lastModifiedUtc.toISOString()),
        'createdUtc': (value.createdUtc.toISOString()),
        'id': value.id,
        'correlationId': value.correlationId,
        'mutationId': value.mutationId,
        'isForecast': value.isForecast,
        'assetMutationType': EAssetMutationTypeToJSON(value.assetMutationType),
        'branchMutationType': EBranchMutationTypeToJSON(value.branchMutationType),
        'type': EUpdateTypeToJSON(value.type),
        'publishTimeUtc': (value.publishTimeUtc.toISOString()),
        'message': value.message,
    };
}

