import { useEffect, useRef, useState } from 'react';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import styles from './AnimatedIcon.module.css';
import { twMerge } from 'tailwind-merge';

export type AnimatedIconKey =
  | 'angle-icon'
  | 'archive-icon'
  | 'asset-icon'
  | 'audit-log-icon'
  | 'avatars-icon'
  | 'building-icon'
  | 'captions-icon'
  | 'change-icon'
  | 'color-icon'
  | 'copy-icon'
  | 'download-icon'
  | 'estate-orbit-icon'
  | 'factory-icon'
  | 'folder-icon'
  | 'home-icon'
  | 'homes-icon'
  | 'list-icon'
  | 'loading-icon'
  | 'mail-plus-icon'
  | 'movie-icon'
  | 'movie-edit-icon'
  | 'movies-icon'
  | 'open-icon'
  | 'pencil-icon'
  | 'pause-icon'
  | 'play-icon'
  | 'polygon-icon'
  | 'plus-icon'
  | 'preview-icon'
  | 'published-icon'
  | 'record-icon'
  | 'record-stop-icon'
  | 'section-icon'
  | 'settings-icon'
  | 'share-icon'
  | 'social-media-icon'
  | 'support-icon'
  | 'trash-icon'
  | 'unpublished-icon'
  | 'upload-icon'
  | 'write-icon'
  | 'help-icon'
  | 'zoom-icon';

interface AnimatedIconProps {
  icon: AnimatedIconKey;
  autoPlay?: boolean;
  disablePlayOnHover?: boolean;
  loop?: boolean;
  className?: string;
}

export const AnimatedIcon = ({
  icon,
  autoPlay,
  disablePlayOnHover,
  loop,
  className,
}: AnimatedIconProps) => {
  const [animationData, setAnimationData] = useState<unknown>(null);
  const lottieRef = useRef<LottieRefCurrentProps>(null);

  useEffect(() => {
    const loadAnimationData = async () => {
      try {
        const data = await import(`./LordIcons/${icon}.json`);
        setAnimationData(data.default);
      } catch (error) {
        console.error('Failed to load animation data:', error);
      }
    };
    loadAnimationData();
  }, [icon]);

  const onHover = () => {
    if (autoPlay || disablePlayOnHover) return;
    lottieRef.current?.goToAndPlay(0);
  };

  return (
    <div className={styles.container} onMouseEnter={onHover}>
      {animationData ? (
        <Lottie
          lottieRef={lottieRef}
          animationData={animationData}
          className={twMerge('text-accent', className)}
          loop={loop ?? false}
          autoplay={autoPlay ?? false}
        />
      ) : null}
    </div>
  );
};
