/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  SectionCreateRequest,
  SectionResponse,
  SectionResponsePagedData,
  SectionUpdateRequest,
} from '../models';
import {
    SectionCreateRequestFromJSON,
    SectionCreateRequestToJSON,
    SectionResponseFromJSON,
    SectionResponseToJSON,
    SectionResponsePagedDataFromJSON,
    SectionResponsePagedDataToJSON,
    SectionUpdateRequestFromJSON,
    SectionUpdateRequestToJSON,
} from '../models';

export interface EstateOrbitProjectsSectionsGetRequest {
    projectId?: string;
    pageNumber?: number;
    size?: number;
    orderByProperty?: string;
    ascending?: boolean;
    searchTerm?: string;
}

export interface EstateOrbitProjectsSectionsIdDeleteRequest {
    id: string;
}

export interface EstateOrbitProjectsSectionsIdGetRequest {
    id: string;
}

export interface EstateOrbitProjectsSectionsIdPutRequest {
    id: string;
    sectionUpdateRequest?: SectionUpdateRequest;
}

export interface EstateOrbitProjectsSectionsPostRequest {
    projectId?: string;
    sectionCreateRequest?: SectionCreateRequest;
}

/**
 * 
 */
export class EstateOrbitSectionsApi extends runtime.BaseAPI {

    /**
     */
    async estateOrbitProjectsSectionsGetRaw(requestParameters: EstateOrbitProjectsSectionsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SectionResponsePagedData>> {
        const queryParameters: any = {};

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.orderByProperty !== undefined) {
            queryParameters['orderByProperty'] = requestParameters.orderByProperty;
        }

        if (requestParameters.ascending !== undefined) {
            queryParameters['ascending'] = requestParameters.ascending;
        }

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['searchTerm'] = requestParameters.searchTerm;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/EstateOrbit/Projects/Sections`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SectionResponsePagedDataFromJSON(jsonValue));
    }

    /**
     */
    async estateOrbitProjectsSectionsGet(requestParameters: EstateOrbitProjectsSectionsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SectionResponsePagedData> {
        const response = await this.estateOrbitProjectsSectionsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async estateOrbitProjectsSectionsIdDeleteRaw(requestParameters: EstateOrbitProjectsSectionsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SectionResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling estateOrbitProjectsSectionsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/EstateOrbit/Projects/Sections/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SectionResponseFromJSON(jsonValue));
    }

    /**
     */
    async estateOrbitProjectsSectionsIdDelete(requestParameters: EstateOrbitProjectsSectionsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SectionResponse> {
        const response = await this.estateOrbitProjectsSectionsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async estateOrbitProjectsSectionsIdGetRaw(requestParameters: EstateOrbitProjectsSectionsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SectionResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling estateOrbitProjectsSectionsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/EstateOrbit/Projects/Sections/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SectionResponseFromJSON(jsonValue));
    }

    /**
     */
    async estateOrbitProjectsSectionsIdGet(requestParameters: EstateOrbitProjectsSectionsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SectionResponse> {
        const response = await this.estateOrbitProjectsSectionsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async estateOrbitProjectsSectionsIdPutRaw(requestParameters: EstateOrbitProjectsSectionsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SectionResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling estateOrbitProjectsSectionsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/EstateOrbit/Projects/Sections/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SectionUpdateRequestToJSON(requestParameters.sectionUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SectionResponseFromJSON(jsonValue));
    }

    /**
     */
    async estateOrbitProjectsSectionsIdPut(requestParameters: EstateOrbitProjectsSectionsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SectionResponse> {
        const response = await this.estateOrbitProjectsSectionsIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async estateOrbitProjectsSectionsPostRaw(requestParameters: EstateOrbitProjectsSectionsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SectionResponse>> {
        const queryParameters: any = {};

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/EstateOrbit/Projects/Sections`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SectionCreateRequestToJSON(requestParameters.sectionCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SectionResponseFromJSON(jsonValue));
    }

    /**
     */
    async estateOrbitProjectsSectionsPost(requestParameters: EstateOrbitProjectsSectionsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SectionResponse> {
        const response = await this.estateOrbitProjectsSectionsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
