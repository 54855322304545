import { EPermission, LoginResponse } from 'api/core';
import { useGetMe, useLogout } from 'api/useAuthApi';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';

interface IAuthContext {
  isLoading: boolean;
  user: LoginResponse | null;
  signOut: () => void;
  hasPermissions: (permission?: EPermission[]) => boolean;
  hasAnyPermissions: (permission?: EPermission[]) => boolean;
}

const AuthContext = createContext<IAuthContext | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = (): IAuthContext => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};

const useProvideAuth = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const logoutMutation = useLogout();
  const [user, setUser] = useState<LoginResponse | null>(null);
  const {
    isLoading: isLoadingRequest,
    isError: isErrorRequest,
    data,
  } = useGetMe();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    // Do nothing while loading
    if (isLoadingRequest) return;

    // Error means no session
    if (isErrorRequest) {
      // If not on login page, redirect to login
      if (!location.pathname.includes('/login')) {
        navigate(
          '/login?returnUrl=' +
            encodeURIComponent(location.pathname + location.search)
        );
        setUser(null);
      }
    } else if (data) {
      Sentry.setTag('organization_id', data.organization.id);
      Sentry.setTag('organization_name', data.organization.name);
      Sentry.setUser({ id: data.id, email: data.email, username: data.name });
      setUser(data);
      document
        .querySelector('html')
        ?.setAttribute('data-theme', data.organization.theme || 'default');
    }

    setIsLoading(false);
  }, [isLoadingRequest, isErrorRequest, data, navigate, location]);

  const signOut = useCallback(() => {
    logoutMutation.mutate();
  }, [logoutMutation]);

  const hasPermissions = useCallback(
    (permissions?: EPermission[]) => {
      if (!permissions || !permissions.length) return true;
      if (!user?.permissions) {
        return false;
      }
      return permissions.every((permission) =>
        user?.permissions?.includes(permission)
      );
    },
    [user]
  );

  const hasAnyPermissions = useCallback(
    (permissions?: EPermission[]) => {
      if (!permissions || !permissions.length) return true;
      if (!user?.permissions) {
        return false;
      }
      return permissions.some((permission) =>
        user?.permissions?.includes(permission)
      );
    },
    [user]
  );

  return useMemo(
    () => ({
      user,
      signOut,
      hasPermissions,
      hasAnyPermissions,
      isLoading,
    }),
    [user, signOut, hasPermissions, hasAnyPermissions, isLoading]
  );
};
