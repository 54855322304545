/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddDynamicOverlayResponse } from './AddDynamicOverlayResponse';
import {
    AddDynamicOverlayResponseFromJSON,
    AddDynamicOverlayResponseFromJSONTyped,
    AddDynamicOverlayResponseToJSON,
} from './AddDynamicOverlayResponse';
import type { AddOverlayResponse } from './AddOverlayResponse';
import {
    AddOverlayResponseFromJSON,
    AddOverlayResponseFromJSONTyped,
    AddOverlayResponseToJSON,
} from './AddOverlayResponse';
import type { AddVoiceoverResponse } from './AddVoiceoverResponse';
import {
    AddVoiceoverResponseFromJSON,
    AddVoiceoverResponseFromJSONTyped,
    AddVoiceoverResponseToJSON,
} from './AddVoiceoverResponse';
import type { ColorAdjustmentsResponse } from './ColorAdjustmentsResponse';
import {
    ColorAdjustmentsResponseFromJSON,
    ColorAdjustmentsResponseFromJSONTyped,
    ColorAdjustmentsResponseToJSON,
} from './ColorAdjustmentsResponse';
import type { EAssetMutationType } from './EAssetMutationType';
import {
    EAssetMutationTypeFromJSON,
    EAssetMutationTypeFromJSONTyped,
    EAssetMutationTypeToJSON,
} from './EAssetMutationType';
import type { ImageToVideoResponse } from './ImageToVideoResponse';
import {
    ImageToVideoResponseFromJSON,
    ImageToVideoResponseFromJSONTyped,
    ImageToVideoResponseToJSON,
} from './ImageToVideoResponse';
import type { TrimVideoResponse } from './TrimVideoResponse';
import {
    TrimVideoResponseFromJSON,
    TrimVideoResponseFromJSONTyped,
    TrimVideoResponseToJSON,
} from './TrimVideoResponse';

/**
 * 
 * @export
 * @interface MediaSequenceAssetMutationResponse
 */
export interface MediaSequenceAssetMutationResponse {
    /**
     * 
     * @type {Date}
     * @memberof MediaSequenceAssetMutationResponse
     */
    lastModifiedUtc: Date;
    /**
     * 
     * @type {Date}
     * @memberof MediaSequenceAssetMutationResponse
     */
    createdUtc: Date;
    /**
     * 
     * @type {string}
     * @memberof MediaSequenceAssetMutationResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MediaSequenceAssetMutationResponse
     */
    mediaSequenceAssetId: string;
    /**
     * 
     * @type {string}
     * @memberof MediaSequenceAssetMutationResponse
     */
    mediaSequenceId: string;
    /**
     * 
     * @type {EAssetMutationType}
     * @memberof MediaSequenceAssetMutationResponse
     */
    type: EAssetMutationType;
    /**
     * 
     * @type {string}
     * @memberof MediaSequenceAssetMutationResponse
     */
    mutationOptionsJson?: string | null;
    /**
     * 
     * @type {ColorAdjustmentsResponse}
     * @memberof MediaSequenceAssetMutationResponse
     */
    colorAdjustments?: ColorAdjustmentsResponse;
    /**
     * 
     * @type {ImageToVideoResponse}
     * @memberof MediaSequenceAssetMutationResponse
     */
    imageToVideo?: ImageToVideoResponse;
    /**
     * 
     * @type {TrimVideoResponse}
     * @memberof MediaSequenceAssetMutationResponse
     */
    trimVideo?: TrimVideoResponse;
    /**
     * 
     * @type {AddOverlayResponse}
     * @memberof MediaSequenceAssetMutationResponse
     */
    overlay?: AddOverlayResponse;
    /**
     * 
     * @type {AddVoiceoverResponse}
     * @memberof MediaSequenceAssetMutationResponse
     */
    voiceover?: AddVoiceoverResponse;
    /**
     * 
     * @type {AddDynamicOverlayResponse}
     * @memberof MediaSequenceAssetMutationResponse
     */
    addDynamicOverlay?: AddDynamicOverlayResponse;
}

/**
 * Check if a given object implements the MediaSequenceAssetMutationResponse interface.
 */
export function instanceOfMediaSequenceAssetMutationResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lastModifiedUtc" in value;
    isInstance = isInstance && "createdUtc" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "mediaSequenceAssetId" in value;
    isInstance = isInstance && "mediaSequenceId" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function MediaSequenceAssetMutationResponseFromJSON(json: any): MediaSequenceAssetMutationResponse {
    return MediaSequenceAssetMutationResponseFromJSONTyped(json, false);
}

export function MediaSequenceAssetMutationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MediaSequenceAssetMutationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastModifiedUtc': (new Date(json['lastModifiedUtc'])),
        'createdUtc': (new Date(json['createdUtc'])),
        'id': json['id'],
        'mediaSequenceAssetId': json['mediaSequenceAssetId'],
        'mediaSequenceId': json['mediaSequenceId'],
        'type': EAssetMutationTypeFromJSON(json['type']),
        'mutationOptionsJson': !exists(json, 'mutationOptionsJson') ? undefined : json['mutationOptionsJson'],
        'colorAdjustments': !exists(json, 'colorAdjustments') ? undefined : ColorAdjustmentsResponseFromJSON(json['colorAdjustments']),
        'imageToVideo': !exists(json, 'imageToVideo') ? undefined : ImageToVideoResponseFromJSON(json['imageToVideo']),
        'trimVideo': !exists(json, 'trimVideo') ? undefined : TrimVideoResponseFromJSON(json['trimVideo']),
        'overlay': !exists(json, 'overlay') ? undefined : AddOverlayResponseFromJSON(json['overlay']),
        'voiceover': !exists(json, 'voiceover') ? undefined : AddVoiceoverResponseFromJSON(json['voiceover']),
        'addDynamicOverlay': !exists(json, 'addDynamicOverlay') ? undefined : AddDynamicOverlayResponseFromJSON(json['addDynamicOverlay']),
    };
}

export function MediaSequenceAssetMutationResponseToJSON(value?: MediaSequenceAssetMutationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lastModifiedUtc': (value.lastModifiedUtc.toISOString()),
        'createdUtc': (value.createdUtc.toISOString()),
        'id': value.id,
        'mediaSequenceAssetId': value.mediaSequenceAssetId,
        'mediaSequenceId': value.mediaSequenceId,
        'type': EAssetMutationTypeToJSON(value.type),
        'mutationOptionsJson': value.mutationOptionsJson,
        'colorAdjustments': ColorAdjustmentsResponseToJSON(value.colorAdjustments),
        'imageToVideo': ImageToVideoResponseToJSON(value.imageToVideo),
        'trimVideo': TrimVideoResponseToJSON(value.trimVideo),
        'overlay': AddOverlayResponseToJSON(value.overlay),
        'voiceover': AddVoiceoverResponseToJSON(value.voiceover),
        'addDynamicOverlay': AddDynamicOverlayResponseToJSON(value.addDynamicOverlay),
    };
}

