/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChartResponseEntry } from './ChartResponseEntry';
import {
    ChartResponseEntryFromJSON,
    ChartResponseEntryFromJSONTyped,
    ChartResponseEntryToJSON,
} from './ChartResponseEntry';
import type { UserSimpleResponse } from './UserSimpleResponse';
import {
    UserSimpleResponseFromJSON,
    UserSimpleResponseFromJSONTyped,
    UserSimpleResponseToJSON,
} from './UserSimpleResponse';

/**
 * 
 * @export
 * @interface ChartResponse
 */
export interface ChartResponse {
    /**
     * 
     * @type {string}
     * @memberof ChartResponse
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof ChartResponse
     */
    startingDate: Date;
    /**
     * 
     * @type {Array<ChartResponseEntry>}
     * @memberof ChartResponse
     */
    entries: Array<ChartResponseEntry>;
    /**
     * 
     * @type {Array<UserSimpleResponse>}
     * @memberof ChartResponse
     */
    users: Array<UserSimpleResponse>;
}

/**
 * Check if a given object implements the ChartResponse interface.
 */
export function instanceOfChartResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "startingDate" in value;
    isInstance = isInstance && "entries" in value;
    isInstance = isInstance && "users" in value;

    return isInstance;
}

export function ChartResponseFromJSON(json: any): ChartResponse {
    return ChartResponseFromJSONTyped(json, false);
}

export function ChartResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChartResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'startingDate': (new Date(json['startingDate'])),
        'entries': ((json['entries'] as Array<any>).map(ChartResponseEntryFromJSON)),
        'users': ((json['users'] as Array<any>).map(UserSimpleResponseFromJSON)),
    };
}

export function ChartResponseToJSON(value?: ChartResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'startingDate': (value.startingDate.toISOString().substr(0,10)),
        'entries': ((value.entries as Array<any>).map(ChartResponseEntryToJSON)),
        'users': ((value.users as Array<any>).map(UserSimpleResponseToJSON)),
    };
}

