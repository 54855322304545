/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SectionResponse
 */
export interface SectionResponse {
    /**
     * 
     * @type {Date}
     * @memberof SectionResponse
     */
    lastModifiedUtc: Date;
    /**
     * 
     * @type {Date}
     * @memberof SectionResponse
     */
    createdUtc: Date;
    /**
     * 
     * @type {string}
     * @memberof SectionResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SectionResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SectionResponse
     */
    maskId: string;
    /**
     * 
     * @type {number}
     * @memberof SectionResponse
     */
    order: number;
}

/**
 * Check if a given object implements the SectionResponse interface.
 */
export function instanceOfSectionResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lastModifiedUtc" in value;
    isInstance = isInstance && "createdUtc" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "maskId" in value;
    isInstance = isInstance && "order" in value;

    return isInstance;
}

export function SectionResponseFromJSON(json: any): SectionResponse {
    return SectionResponseFromJSONTyped(json, false);
}

export function SectionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SectionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastModifiedUtc': (new Date(json['lastModifiedUtc'])),
        'createdUtc': (new Date(json['createdUtc'])),
        'id': json['id'],
        'name': json['name'],
        'maskId': json['maskId'],
        'order': json['order'],
    };
}

export function SectionResponseToJSON(value?: SectionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lastModifiedUtc': (value.lastModifiedUtc.toISOString()),
        'createdUtc': (value.createdUtc.toISOString()),
        'id': value.id,
        'name': value.name,
        'maskId': value.maskId,
        'order': value.order,
    };
}

