/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MediaSequenceBranchCreateRequest,
  MediaSequenceBranchMutationCreateRequest,
  MediaSequenceBranchMutationResponse,
  MediaSequenceBranchMutationUpdateRequest,
  MediaSequenceBranchResponse,
  MediaSequenceBranchUpdateRequest,
} from '../models';
import {
    MediaSequenceBranchCreateRequestFromJSON,
    MediaSequenceBranchCreateRequestToJSON,
    MediaSequenceBranchMutationCreateRequestFromJSON,
    MediaSequenceBranchMutationCreateRequestToJSON,
    MediaSequenceBranchMutationResponseFromJSON,
    MediaSequenceBranchMutationResponseToJSON,
    MediaSequenceBranchMutationUpdateRequestFromJSON,
    MediaSequenceBranchMutationUpdateRequestToJSON,
    MediaSequenceBranchResponseFromJSON,
    MediaSequenceBranchResponseToJSON,
    MediaSequenceBranchUpdateRequestFromJSON,
    MediaSequenceBranchUpdateRequestToJSON,
} from '../models';

export interface MediaSequenceBranchesBranchIdDeleteRequest {
    id: string;
}

export interface MediaSequenceBranchesBranchIdGetRequest {
    id: string;
}

export interface MediaSequenceBranchesBranchIdPutRequest {
    id: string;
    mediaSequenceBranchUpdateRequest?: MediaSequenceBranchUpdateRequest;
}

export interface MediaSequenceBranchesBranchMutationIdDeleteRequest {
    id: string;
}

export interface MediaSequenceBranchesBranchMutationIdGetRequest {
    id: string;
}

export interface MediaSequenceBranchesBranchMutationIdPutRequest {
    id: string;
    mediaSequenceBranchMutationUpdateRequest?: MediaSequenceBranchMutationUpdateRequest;
}

export interface MediaSequenceBranchesIdBranchMutationPostRequest {
    id: string;
    mediaSequenceBranchMutationCreateRequest?: MediaSequenceBranchMutationCreateRequest;
}

export interface MediaSequenceBranchesMediaSequenceIdBranchPostRequest {
    mediaSequenceId: string;
    mediaSequenceBranchCreateRequest?: MediaSequenceBranchCreateRequest;
}

/**
 * 
 */
export class MediaSequenceBranchesApi extends runtime.BaseAPI {

    /**
     */
    async mediaSequenceBranchesBranchIdDeleteRaw(requestParameters: MediaSequenceBranchesBranchIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MediaSequenceBranchResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mediaSequenceBranchesBranchIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaSequenceBranches/branch/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MediaSequenceBranchResponseFromJSON(jsonValue));
    }

    /**
     */
    async mediaSequenceBranchesBranchIdDelete(requestParameters: MediaSequenceBranchesBranchIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MediaSequenceBranchResponse> {
        const response = await this.mediaSequenceBranchesBranchIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaSequenceBranchesBranchIdGetRaw(requestParameters: MediaSequenceBranchesBranchIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MediaSequenceBranchResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mediaSequenceBranchesBranchIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaSequenceBranches/branch/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MediaSequenceBranchResponseFromJSON(jsonValue));
    }

    /**
     */
    async mediaSequenceBranchesBranchIdGet(requestParameters: MediaSequenceBranchesBranchIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MediaSequenceBranchResponse> {
        const response = await this.mediaSequenceBranchesBranchIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaSequenceBranchesBranchIdPutRaw(requestParameters: MediaSequenceBranchesBranchIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MediaSequenceBranchResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mediaSequenceBranchesBranchIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaSequenceBranches/branch/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MediaSequenceBranchUpdateRequestToJSON(requestParameters.mediaSequenceBranchUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MediaSequenceBranchResponseFromJSON(jsonValue));
    }

    /**
     */
    async mediaSequenceBranchesBranchIdPut(requestParameters: MediaSequenceBranchesBranchIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MediaSequenceBranchResponse> {
        const response = await this.mediaSequenceBranchesBranchIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaSequenceBranchesBranchMutationIdDeleteRaw(requestParameters: MediaSequenceBranchesBranchMutationIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MediaSequenceBranchMutationResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mediaSequenceBranchesBranchMutationIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaSequenceBranches/branch/mutation/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MediaSequenceBranchMutationResponseFromJSON(jsonValue));
    }

    /**
     */
    async mediaSequenceBranchesBranchMutationIdDelete(requestParameters: MediaSequenceBranchesBranchMutationIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MediaSequenceBranchMutationResponse> {
        const response = await this.mediaSequenceBranchesBranchMutationIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaSequenceBranchesBranchMutationIdGetRaw(requestParameters: MediaSequenceBranchesBranchMutationIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MediaSequenceBranchMutationResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mediaSequenceBranchesBranchMutationIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaSequenceBranches/branch-mutation/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MediaSequenceBranchMutationResponseFromJSON(jsonValue));
    }

    /**
     */
    async mediaSequenceBranchesBranchMutationIdGet(requestParameters: MediaSequenceBranchesBranchMutationIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MediaSequenceBranchMutationResponse> {
        const response = await this.mediaSequenceBranchesBranchMutationIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaSequenceBranchesBranchMutationIdPutRaw(requestParameters: MediaSequenceBranchesBranchMutationIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MediaSequenceBranchMutationResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mediaSequenceBranchesBranchMutationIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaSequenceBranches/branch/mutation/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MediaSequenceBranchMutationUpdateRequestToJSON(requestParameters.mediaSequenceBranchMutationUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MediaSequenceBranchMutationResponseFromJSON(jsonValue));
    }

    /**
     */
    async mediaSequenceBranchesBranchMutationIdPut(requestParameters: MediaSequenceBranchesBranchMutationIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MediaSequenceBranchMutationResponse> {
        const response = await this.mediaSequenceBranchesBranchMutationIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaSequenceBranchesIdBranchMutationPostRaw(requestParameters: MediaSequenceBranchesIdBranchMutationPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MediaSequenceBranchMutationResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mediaSequenceBranchesIdBranchMutationPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaSequenceBranches/{id}/branch-mutation`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MediaSequenceBranchMutationCreateRequestToJSON(requestParameters.mediaSequenceBranchMutationCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MediaSequenceBranchMutationResponseFromJSON(jsonValue));
    }

    /**
     */
    async mediaSequenceBranchesIdBranchMutationPost(requestParameters: MediaSequenceBranchesIdBranchMutationPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MediaSequenceBranchMutationResponse> {
        const response = await this.mediaSequenceBranchesIdBranchMutationPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaSequenceBranchesMediaSequenceIdBranchPostRaw(requestParameters: MediaSequenceBranchesMediaSequenceIdBranchPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MediaSequenceBranchResponse>> {
        if (requestParameters.mediaSequenceId === null || requestParameters.mediaSequenceId === undefined) {
            throw new runtime.RequiredError('mediaSequenceId','Required parameter requestParameters.mediaSequenceId was null or undefined when calling mediaSequenceBranchesMediaSequenceIdBranchPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaSequenceBranches/{mediaSequenceId}/branch`.replace(`{${"mediaSequenceId"}}`, encodeURIComponent(String(requestParameters.mediaSequenceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MediaSequenceBranchCreateRequestToJSON(requestParameters.mediaSequenceBranchCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MediaSequenceBranchResponseFromJSON(jsonValue));
    }

    /**
     */
    async mediaSequenceBranchesMediaSequenceIdBranchPost(requestParameters: MediaSequenceBranchesMediaSequenceIdBranchPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MediaSequenceBranchResponse> {
        const response = await this.mediaSequenceBranchesMediaSequenceIdBranchPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
