/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EEntityState } from './EEntityState';
import {
    EEntityStateFromJSON,
    EEntityStateFromJSONTyped,
    EEntityStateToJSON,
} from './EEntityState';

/**
 * 
 * @export
 * @interface ProjectCreateRequest
 */
export interface ProjectCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectCreateRequest
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectCreateRequest
     */
    singleCase?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectCreateRequest
     */
    dawaAddressId?: string | null;
    /**
     * 
     * @type {EEntityState}
     * @memberof ProjectCreateRequest
     */
    entityState: EEntityState;
}

/**
 * Check if a given object implements the ProjectCreateRequest interface.
 */
export function instanceOfProjectCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "entityState" in value;

    return isInstance;
}

export function ProjectCreateRequestFromJSON(json: any): ProjectCreateRequest {
    return ProjectCreateRequestFromJSONTyped(json, false);
}

export function ProjectCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'singleCase': !exists(json, 'singleCase') ? undefined : json['singleCase'],
        'dawaAddressId': !exists(json, 'dawaAddressId') ? undefined : json['dawaAddressId'],
        'entityState': EEntityStateFromJSON(json['entityState']),
    };
}

export function ProjectCreateRequestToJSON(value?: ProjectCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'singleCase': value.singleCase,
        'dawaAddressId': value.dawaAddressId,
        'entityState': EEntityStateToJSON(value.entityState),
    };
}

