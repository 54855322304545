/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ServiceTicketCreateRequest
 */
export interface ServiceTicketCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof ServiceTicketCreateRequest
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceTicketCreateRequest
     */
    message: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceTicketCreateRequest
     */
    assetIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ServiceTicketCreateRequest
     */
    projectId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceTicketCreateRequest
     */
    caseId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceTicketCreateRequest
     */
    mediaSequenceId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceTicketCreateRequest
     */
    publicationId?: string | null;
}

/**
 * Check if a given object implements the ServiceTicketCreateRequest interface.
 */
export function instanceOfServiceTicketCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "message" in value;
    isInstance = isInstance && "assetIds" in value;

    return isInstance;
}

export function ServiceTicketCreateRequestFromJSON(json: any): ServiceTicketCreateRequest {
    return ServiceTicketCreateRequestFromJSONTyped(json, false);
}

export function ServiceTicketCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceTicketCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'message': json['message'],
        'assetIds': json['assetIds'],
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'caseId': !exists(json, 'caseId') ? undefined : json['caseId'],
        'mediaSequenceId': !exists(json, 'mediaSequenceId') ? undefined : json['mediaSequenceId'],
        'publicationId': !exists(json, 'publicationId') ? undefined : json['publicationId'],
    };
}

export function ServiceTicketCreateRequestToJSON(value?: ServiceTicketCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'message': value.message,
        'assetIds': value.assetIds,
        'projectId': value.projectId,
        'caseId': value.caseId,
        'mediaSequenceId': value.mediaSequenceId,
        'publicationId': value.publicationId,
    };
}

