import { ControlledModal } from 'components/ControlledModal';
import { UserForm } from './UserForm';
import { useGetRoles } from 'api/useRolesApi';
import { useState } from 'react';

interface UserCreateProps {
  trigger?: React.ReactNode;
  isInitialOpen?: boolean;
  onClosed?: () => void;
}

export const UserCreate = ({
  trigger,
  isInitialOpen,
  onClosed,
}: UserCreateProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(isInitialOpen ?? false);

  const { data: roles } = useGetRoles();

  if (!roles) return null;

  const onModalStateChange = (state: boolean) => {
    setIsOpen(state);
    if (!state) {
      onClosed?.();
    }
  };

  return (
    <>
      {trigger ? (
        <div onClick={() => onModalStateChange(true)}>{trigger}</div>
      ) : null}
      <ControlledModal
        showModal={onModalStateChange}
        isOpen={isOpen}
        title="Opret bruger"
        closeOnOutsideClick
        hideActionBar
      >
        <UserForm
          allRoles={roles.data}
          onSuccess={() => onModalStateChange(false)}
          onCancel={() => onModalStateChange(false)}
          inDialog={true}
        />
      </ControlledModal>
    </>
  );
};
