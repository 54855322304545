/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AssetSimpleResponse } from './AssetSimpleResponse';
import {
    AssetSimpleResponseFromJSON,
    AssetSimpleResponseFromJSONTyped,
    AssetSimpleResponseToJSON,
} from './AssetSimpleResponse';
import type { EPublicationState } from './EPublicationState';
import {
    EPublicationStateFromJSON,
    EPublicationStateFromJSONTyped,
    EPublicationStateToJSON,
} from './EPublicationState';
import type { PublicationDestinationResponse } from './PublicationDestinationResponse';
import {
    PublicationDestinationResponseFromJSON,
    PublicationDestinationResponseFromJSONTyped,
    PublicationDestinationResponseToJSON,
} from './PublicationDestinationResponse';

/**
 * 
 * @export
 * @interface PublicationResponse
 */
export interface PublicationResponse {
    /**
     * 
     * @type {Date}
     * @memberof PublicationResponse
     */
    lastModifiedUtc: Date;
    /**
     * 
     * @type {Date}
     * @memberof PublicationResponse
     */
    createdUtc: Date;
    /**
     * 
     * @type {string}
     * @memberof PublicationResponse
     */
    id: string;
    /**
     * 
     * @type {EPublicationState}
     * @memberof PublicationResponse
     */
    state: EPublicationState;
    /**
     * 
     * @type {string}
     * @memberof PublicationResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PublicationResponse
     */
    message: string;
    /**
     * 
     * @type {Date}
     * @memberof PublicationResponse
     */
    scheduledTimeUtc: Date;
    /**
     * 
     * @type {Array<PublicationDestinationResponse>}
     * @memberof PublicationResponse
     */
    destinations: Array<PublicationDestinationResponse>;
    /**
     * 
     * @type {Array<AssetSimpleResponse>}
     * @memberof PublicationResponse
     */
    assets: Array<AssetSimpleResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof PublicationResponse
     */
    readonly canBeInvoked: boolean;
}

/**
 * Check if a given object implements the PublicationResponse interface.
 */
export function instanceOfPublicationResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lastModifiedUtc" in value;
    isInstance = isInstance && "createdUtc" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "message" in value;
    isInstance = isInstance && "scheduledTimeUtc" in value;
    isInstance = isInstance && "destinations" in value;
    isInstance = isInstance && "assets" in value;
    isInstance = isInstance && "canBeInvoked" in value;

    return isInstance;
}

export function PublicationResponseFromJSON(json: any): PublicationResponse {
    return PublicationResponseFromJSONTyped(json, false);
}

export function PublicationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublicationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastModifiedUtc': (new Date(json['lastModifiedUtc'])),
        'createdUtc': (new Date(json['createdUtc'])),
        'id': json['id'],
        'state': EPublicationStateFromJSON(json['state']),
        'name': json['name'],
        'message': json['message'],
        'scheduledTimeUtc': (new Date(json['scheduledTimeUtc'])),
        'destinations': ((json['destinations'] as Array<any>).map(PublicationDestinationResponseFromJSON)),
        'assets': ((json['assets'] as Array<any>).map(AssetSimpleResponseFromJSON)),
        'canBeInvoked': json['canBeInvoked'],
    };
}

export function PublicationResponseToJSON(value?: PublicationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lastModifiedUtc': (value.lastModifiedUtc.toISOString()),
        'createdUtc': (value.createdUtc.toISOString()),
        'id': value.id,
        'state': EPublicationStateToJSON(value.state),
        'name': value.name,
        'message': value.message,
        'scheduledTimeUtc': (value.scheduledTimeUtc.toISOString()),
        'destinations': ((value.destinations as Array<any>).map(PublicationDestinationResponseToJSON)),
        'assets': ((value.assets as Array<any>).map(AssetSimpleResponseToJSON)),
    };
}

