/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EConcatenationMode = {
    Merge: 'Merge',
    Fade: 'Fade',
    Wipe: 'Wipe'
} as const;
export type EConcatenationMode = typeof EConcatenationMode[keyof typeof EConcatenationMode];


export function EConcatenationModeFromJSON(json: any): EConcatenationMode {
    return EConcatenationModeFromJSONTyped(json, false);
}

export function EConcatenationModeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EConcatenationMode {
    return json as EConcatenationMode;
}

export function EConcatenationModeToJSON(value?: EConcatenationMode | null): any {
    return value as any;
}

