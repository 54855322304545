/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SectionUpdateRequest
 */
export interface SectionUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof SectionUpdateRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SectionUpdateRequest
     */
    maskId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SectionUpdateRequest
     */
    order?: number;
}

/**
 * Check if a given object implements the SectionUpdateRequest interface.
 */
export function instanceOfSectionUpdateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function SectionUpdateRequestFromJSON(json: any): SectionUpdateRequest {
    return SectionUpdateRequestFromJSONTyped(json, false);
}

export function SectionUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SectionUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'maskId': !exists(json, 'maskId') ? undefined : json['maskId'],
        'order': !exists(json, 'order') ? undefined : json['order'],
    };
}

export function SectionUpdateRequestToJSON(value?: SectionUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'maskId': value.maskId,
        'order': value.order,
    };
}

