import { ColumnDef } from '@tanstack/react-table';
import {
  EPermission,
  FieldResponse,
  FieldResponsePagedData,
  ProjectResponse,
} from 'api/core';
import { CustomTable } from 'components/Table/CustomTable';
import { ActionCell } from 'components/Table/Cell/ActionCell';
import { DateTimeCell } from 'components/Table/Cell/DateTimeCell';
import { useGetCustomReactTable } from 'components/Table/useGetTableSet';
import { TableQueryState } from 'components/Table/useTableQueryState';

import { useMemo, useState } from 'react';
import { FieldUpdate } from './FieldUpdate';
import { DeleteEntityById } from 'components/Form/DeleteEntityById';
import { useDeleteField, useGetField } from 'api/useFieldsApi';
import { AnimatedIcon } from 'components/Icon/AnimatedIcon';
import { AssetPreviewModal } from 'components/Asset/AssetPreviewModal';
import { BooleanCell } from 'components/Table/Cell/BooleanCell';
import {
  fieldTypeTranslate,
  fieldVisibilityTranslate,
} from 'utils/enum-translate';

interface TableProps {
  data?: FieldResponsePagedData;
  isFetching: boolean;
  queryState: TableQueryState;
  project?: ProjectResponse;
}

export const FieldsTable = ({
  data,
  isFetching,
  queryState,
  project,
}: TableProps) => {
  const [editTarget, setEditTarget] = useState<FieldResponse | null>(null);
  const [deleteTarget, setDeleteTarget] = useState<FieldResponse | null>(null);

  const showFieldVisibility = project?.estateOrbitEnabled || false;
  const showFieldIsFilter = project?.estateOrbitEnabled || false;
  const showFieldIllustration = project?.estateOrbitEnabled || false;

  const columns = useMemo(() => {
    const baseColumns: (ColumnDef<FieldResponse> | null)[] = [
      {
        accessorKey: 'order',
        header: 'Rækkefølge',
      },
      {
        accessorKey: 'name',
        header: 'Navn',
      },
      showFieldIllustration
        ? {
            accessorKey: '_illustration',
            header: 'Illustration',
            enableSorting: false,
            cell: (e) =>
              e.row.original.illustration ? (
                <AssetPreviewModal asset={e.row.original.illustration} />
              ) : null,
          }
        : null,
      {
        accessorKey: 'type',
        header: 'Type',
        cell: (e) => <> {fieldTypeTranslate(e.row.original.type)} </>,
      },
      showFieldVisibility
        ? {
            accessorKey: 'visibility',
            header: 'Synlighed',
            cell: (e) => (
              <> {fieldVisibilityTranslate(e.row.original.visibility)} </>
            ),
          }
        : null,
      showFieldIsFilter
        ? {
            accessorKey: 'isFilter',
            header: 'Filtrerbar',
            cell: (e) => <BooleanCell value={e.row.original.isFilter} />,
          }
        : null,
      {
        accessorKey: 'lastModifiedUtc',
        header: 'Sidst redigeret',
        cell: (e) => <DateTimeCell value={e.row.original.lastModifiedUtc} />,
      },
      {
        accessorKey: 'createdUtc',
        header: 'Oprettet',
        cell: (e) => <DateTimeCell value={e.row.original.createdUtc} />,
      },
      {
        accessorKey: '_',
        header: 'Handlinger',
        enableSorting: false,
        cell: (e) => (
          <div className="flex space-x-5 md:space-x-3">
            <ActionCell
              permissions={[EPermission.FieldWrite]}
              icon={
                <AnimatedIcon
                  icon="pencil-icon"
                  className="h-8 md:h-6 w-8 md:w-6"
                />
              }
              onClick={() => setEditTarget(e.row.original)}
            />
            <ActionCell
              permissions={[EPermission.FieldDelete]}
              icon={
                <AnimatedIcon
                  icon="trash-icon"
                  className="h-8 md:h-6 w-8 md:w-6"
                />
              }
              onClick={() => setDeleteTarget(e.row.original)}
            />
          </div>
        ),
      },
    ];
    const filteredColumns = baseColumns.filter((c) =>
      Boolean(c)
    ) as ColumnDef<FieldResponse>[];
    return filteredColumns;
  }, [
    setEditTarget,
    setDeleteTarget,
    showFieldIsFilter,
    showFieldIllustration,
    showFieldVisibility,
  ]);

  const table = useGetCustomReactTable(
    data?.data ?? [],
    data?.page.totalPages ?? -1,
    columns,
    queryState
  );

  return (
    <>
      <CustomTable table={table} isLoading={isFetching} />
      {editTarget ? (
        <FieldUpdate
          id={editTarget?.id}
          projectId={project?.id}
          isInitialOpen={true}
          onClosed={() => setEditTarget(null)}
        />
      ) : null}
      {deleteTarget ? (
        <DeleteEntityById
          id={deleteTarget?.id}
          isInitialOpen={true}
          onClosed={() => setDeleteTarget(null)}
          titlePropertyKey={'name'}
          getter={useGetField}
          deleter={useDeleteField}
        />
      ) : null}
    </>
  );
};
