/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MediaSequenceAssetResponse,
  MediaSequenceCloneRequest,
  MediaSequenceCreateRequest,
  MediaSequenceResponse,
  MediaSequenceResponsePagedData,
  MediaSequenceUpdateRequest,
  MediaSequenceUpdateResponse,
} from '../models';
import {
    MediaSequenceAssetResponseFromJSON,
    MediaSequenceAssetResponseToJSON,
    MediaSequenceCloneRequestFromJSON,
    MediaSequenceCloneRequestToJSON,
    MediaSequenceCreateRequestFromJSON,
    MediaSequenceCreateRequestToJSON,
    MediaSequenceResponseFromJSON,
    MediaSequenceResponseToJSON,
    MediaSequenceResponsePagedDataFromJSON,
    MediaSequenceResponsePagedDataToJSON,
    MediaSequenceUpdateRequestFromJSON,
    MediaSequenceUpdateRequestToJSON,
    MediaSequenceUpdateResponseFromJSON,
    MediaSequenceUpdateResponseToJSON,
} from '../models';

export interface MediaSequencesGetRequest {
    pageNumber?: number;
    size?: number;
    orderByProperty?: string;
    ascending?: boolean;
    searchTerm?: string;
    includeActive?: boolean;
    includeArchived?: boolean;
}

export interface MediaSequencesIdApproveTranscriptionPutRequest {
    id: string;
}

export interface MediaSequencesIdAssetsDeleteRequest {
    id: string;
}

export interface MediaSequencesIdAssetsGetRequest {
    id: string;
}

export interface MediaSequencesIdClonePutRequest {
    id: string;
    mediaSequenceCloneRequest?: MediaSequenceCloneRequest;
}

export interface MediaSequencesIdDeleteRequest {
    id: string;
}

export interface MediaSequencesIdGetRequest {
    id: string;
}

export interface MediaSequencesIdInvokePutRequest {
    id: string;
}

export interface MediaSequencesIdPreviewUrlGetRequest {
    id: string;
}

export interface MediaSequencesIdPutRequest {
    id: string;
    mediaSequenceUpdateRequest?: MediaSequenceUpdateRequest;
}

export interface MediaSequencesIdRedoPutRequest {
    id: string;
}

export interface MediaSequencesIdTranscriptionInternalPutRequest {
    id: string;
    body?: string;
}

export interface MediaSequencesIdTranscriptionPutRequest {
    id: string;
    body?: string;
}

export interface MediaSequencesIdUpdatesGetRequest {
    id: string;
}

export interface MediaSequencesIdUpdatesProcessingGetRequest {
    id: string;
}

export interface MediaSequencesLastViewedGetRequest {
    amount?: number;
}

export interface MediaSequencesPostRequest {
    mediaSequenceCreateRequest?: MediaSequenceCreateRequest;
}

export interface MediaSequencesSearchGetRequest {
    size?: number;
    searchTerm?: string;
}

/**
 * 
 */
export class MediaSequencesApi extends runtime.BaseAPI {

    /**
     */
    async mediaSequencesGetRaw(requestParameters: MediaSequencesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MediaSequenceResponsePagedData>> {
        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.orderByProperty !== undefined) {
            queryParameters['orderByProperty'] = requestParameters.orderByProperty;
        }

        if (requestParameters.ascending !== undefined) {
            queryParameters['ascending'] = requestParameters.ascending;
        }

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['searchTerm'] = requestParameters.searchTerm;
        }

        if (requestParameters.includeActive !== undefined) {
            queryParameters['includeActive'] = requestParameters.includeActive;
        }

        if (requestParameters.includeArchived !== undefined) {
            queryParameters['includeArchived'] = requestParameters.includeArchived;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaSequences`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MediaSequenceResponsePagedDataFromJSON(jsonValue));
    }

    /**
     */
    async mediaSequencesGet(requestParameters: MediaSequencesGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MediaSequenceResponsePagedData> {
        const response = await this.mediaSequencesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaSequencesIdApproveTranscriptionPutRaw(requestParameters: MediaSequencesIdApproveTranscriptionPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MediaSequenceResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mediaSequencesIdApproveTranscriptionPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaSequences/{id}/approve-transcription`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MediaSequenceResponseFromJSON(jsonValue));
    }

    /**
     */
    async mediaSequencesIdApproveTranscriptionPut(requestParameters: MediaSequencesIdApproveTranscriptionPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MediaSequenceResponse> {
        const response = await this.mediaSequencesIdApproveTranscriptionPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaSequencesIdAssetsDeleteRaw(requestParameters: MediaSequencesIdAssetsDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MediaSequenceResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mediaSequencesIdAssetsDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaSequences/{id}/assets`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MediaSequenceResponseFromJSON(jsonValue));
    }

    /**
     */
    async mediaSequencesIdAssetsDelete(requestParameters: MediaSequencesIdAssetsDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MediaSequenceResponse> {
        const response = await this.mediaSequencesIdAssetsDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaSequencesIdAssetsGetRaw(requestParameters: MediaSequencesIdAssetsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MediaSequenceAssetResponse>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mediaSequencesIdAssetsGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaSequences/{id}/assets`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MediaSequenceAssetResponseFromJSON));
    }

    /**
     */
    async mediaSequencesIdAssetsGet(requestParameters: MediaSequencesIdAssetsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MediaSequenceAssetResponse>> {
        const response = await this.mediaSequencesIdAssetsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaSequencesIdClonePutRaw(requestParameters: MediaSequencesIdClonePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MediaSequenceResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mediaSequencesIdClonePut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaSequences/{id}/clone`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MediaSequenceCloneRequestToJSON(requestParameters.mediaSequenceCloneRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MediaSequenceResponseFromJSON(jsonValue));
    }

    /**
     */
    async mediaSequencesIdClonePut(requestParameters: MediaSequencesIdClonePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MediaSequenceResponse> {
        const response = await this.mediaSequencesIdClonePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaSequencesIdDeleteRaw(requestParameters: MediaSequencesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MediaSequenceResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mediaSequencesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaSequences/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MediaSequenceResponseFromJSON(jsonValue));
    }

    /**
     */
    async mediaSequencesIdDelete(requestParameters: MediaSequencesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MediaSequenceResponse> {
        const response = await this.mediaSequencesIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaSequencesIdGetRaw(requestParameters: MediaSequencesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MediaSequenceResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mediaSequencesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaSequences/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MediaSequenceResponseFromJSON(jsonValue));
    }

    /**
     */
    async mediaSequencesIdGet(requestParameters: MediaSequencesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MediaSequenceResponse> {
        const response = await this.mediaSequencesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaSequencesIdInvokePutRaw(requestParameters: MediaSequencesIdInvokePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MediaSequenceResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mediaSequencesIdInvokePut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaSequences/{id}/invoke`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MediaSequenceResponseFromJSON(jsonValue));
    }

    /**
     */
    async mediaSequencesIdInvokePut(requestParameters: MediaSequencesIdInvokePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MediaSequenceResponse> {
        const response = await this.mediaSequencesIdInvokePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaSequencesIdPreviewUrlGetRaw(requestParameters: MediaSequencesIdPreviewUrlGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mediaSequencesIdPreviewUrlGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaSequences/{id}/preview-url`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async mediaSequencesIdPreviewUrlGet(requestParameters: MediaSequencesIdPreviewUrlGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.mediaSequencesIdPreviewUrlGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaSequencesIdPutRaw(requestParameters: MediaSequencesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MediaSequenceResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mediaSequencesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaSequences/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MediaSequenceUpdateRequestToJSON(requestParameters.mediaSequenceUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MediaSequenceResponseFromJSON(jsonValue));
    }

    /**
     */
    async mediaSequencesIdPut(requestParameters: MediaSequencesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MediaSequenceResponse> {
        const response = await this.mediaSequencesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaSequencesIdRedoPutRaw(requestParameters: MediaSequencesIdRedoPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MediaSequenceResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mediaSequencesIdRedoPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaSequences/{id}/redo`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MediaSequenceResponseFromJSON(jsonValue));
    }

    /**
     */
    async mediaSequencesIdRedoPut(requestParameters: MediaSequencesIdRedoPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MediaSequenceResponse> {
        const response = await this.mediaSequencesIdRedoPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaSequencesIdTranscriptionInternalPutRaw(requestParameters: MediaSequencesIdTranscriptionInternalPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MediaSequenceResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mediaSequencesIdTranscriptionInternalPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaSequences/{id}/transcription-internal`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MediaSequenceResponseFromJSON(jsonValue));
    }

    /**
     */
    async mediaSequencesIdTranscriptionInternalPut(requestParameters: MediaSequencesIdTranscriptionInternalPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MediaSequenceResponse> {
        const response = await this.mediaSequencesIdTranscriptionInternalPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaSequencesIdTranscriptionPutRaw(requestParameters: MediaSequencesIdTranscriptionPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MediaSequenceResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mediaSequencesIdTranscriptionPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaSequences/{id}/transcription`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MediaSequenceResponseFromJSON(jsonValue));
    }

    /**
     */
    async mediaSequencesIdTranscriptionPut(requestParameters: MediaSequencesIdTranscriptionPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MediaSequenceResponse> {
        const response = await this.mediaSequencesIdTranscriptionPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaSequencesIdUpdatesGetRaw(requestParameters: MediaSequencesIdUpdatesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MediaSequenceUpdateResponse>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mediaSequencesIdUpdatesGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaSequences/{id}/updates`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MediaSequenceUpdateResponseFromJSON));
    }

    /**
     */
    async mediaSequencesIdUpdatesGet(requestParameters: MediaSequencesIdUpdatesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MediaSequenceUpdateResponse>> {
        const response = await this.mediaSequencesIdUpdatesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaSequencesIdUpdatesProcessingGetRaw(requestParameters: MediaSequencesIdUpdatesProcessingGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MediaSequenceUpdateResponse>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mediaSequencesIdUpdatesProcessingGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaSequences/{id}/updates/processing`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MediaSequenceUpdateResponseFromJSON));
    }

    /**
     */
    async mediaSequencesIdUpdatesProcessingGet(requestParameters: MediaSequencesIdUpdatesProcessingGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MediaSequenceUpdateResponse>> {
        const response = await this.mediaSequencesIdUpdatesProcessingGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaSequencesLastViewedGetRaw(requestParameters: MediaSequencesLastViewedGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MediaSequenceResponse>>> {
        const queryParameters: any = {};

        if (requestParameters.amount !== undefined) {
            queryParameters['amount'] = requestParameters.amount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaSequences/last-viewed`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MediaSequenceResponseFromJSON));
    }

    /**
     */
    async mediaSequencesLastViewedGet(requestParameters: MediaSequencesLastViewedGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MediaSequenceResponse>> {
        const response = await this.mediaSequencesLastViewedGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaSequencesPostRaw(requestParameters: MediaSequencesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MediaSequenceResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaSequences`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MediaSequenceCreateRequestToJSON(requestParameters.mediaSequenceCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MediaSequenceResponseFromJSON(jsonValue));
    }

    /**
     */
    async mediaSequencesPost(requestParameters: MediaSequencesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MediaSequenceResponse> {
        const response = await this.mediaSequencesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaSequencesSearchGetRaw(requestParameters: MediaSequencesSearchGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MediaSequenceResponse>>> {
        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['searchTerm'] = requestParameters.searchTerm;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaSequences/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MediaSequenceResponseFromJSON));
    }

    /**
     */
    async mediaSequencesSearchGet(requestParameters: MediaSequencesSearchGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MediaSequenceResponse>> {
        const response = await this.mediaSequencesSearchGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaSequencesTemplatesGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MediaSequenceResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaSequences/templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MediaSequenceResponseFromJSON));
    }

    /**
     */
    async mediaSequencesTemplatesGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MediaSequenceResponse>> {
        const response = await this.mediaSequencesTemplatesGetRaw(initOverrides);
        return await response.value();
    }

}
