/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EServiceTicketState = {
    Deleted: 'Deleted',
    AwaitingSupport: 'AwaitingSupport',
    AwaitingCustomer: 'AwaitingCustomer',
    AwaitingRelease: 'AwaitingRelease',
    Done: 'Done'
} as const;
export type EServiceTicketState = typeof EServiceTicketState[keyof typeof EServiceTicketState];


export function EServiceTicketStateFromJSON(json: any): EServiceTicketState {
    return EServiceTicketStateFromJSONTyped(json, false);
}

export function EServiceTicketStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): EServiceTicketState {
    return json as EServiceTicketState;
}

export function EServiceTicketStateToJSON(value?: EServiceTicketState | null): any {
    return value as any;
}

