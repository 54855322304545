/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ETextPurpose } from './ETextPurpose';
import {
    ETextPurposeFromJSON,
    ETextPurposeFromJSONTyped,
    ETextPurposeToJSON,
} from './ETextPurpose';

/**
 * 
 * @export
 * @interface TextEntryUpsertRequest
 */
export interface TextEntryUpsertRequest {
    /**
     * 
     * @type {string}
     * @memberof TextEntryUpsertRequest
     */
    textCollectionId: string;
    /**
     * 
     * @type {ETextPurpose}
     * @memberof TextEntryUpsertRequest
     */
    purpose: ETextPurpose;
    /**
     * 
     * @type {string}
     * @memberof TextEntryUpsertRequest
     */
    value: string;
}

/**
 * Check if a given object implements the TextEntryUpsertRequest interface.
 */
export function instanceOfTextEntryUpsertRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "textCollectionId" in value;
    isInstance = isInstance && "purpose" in value;
    isInstance = isInstance && "value" in value;

    return isInstance;
}

export function TextEntryUpsertRequestFromJSON(json: any): TextEntryUpsertRequest {
    return TextEntryUpsertRequestFromJSONTyped(json, false);
}

export function TextEntryUpsertRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TextEntryUpsertRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'textCollectionId': json['textCollectionId'],
        'purpose': ETextPurposeFromJSON(json['purpose']),
        'value': json['value'],
    };
}

export function TextEntryUpsertRequestToJSON(value?: TextEntryUpsertRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'textCollectionId': value.textCollectionId,
        'purpose': ETextPurposeToJSON(value.purpose),
        'value': value.value,
    };
}

