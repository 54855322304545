import { useQuery } from '@tanstack/react-query';

import { CoreBaseConfiguration } from './BaseConfiguration';
import { MailingListsApi } from './core';

const MailingListApiKeys = {
  GET_MAILING_LISTS: 'mailingLists',
};

const client = new MailingListsApi(new CoreBaseConfiguration());

export const useGetMailingLists = () => {
  return useQuery({
    queryKey: [MailingListApiKeys.GET_MAILING_LISTS],
    queryFn: () => client.mailingListsGet(),
  });
};
