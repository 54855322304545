import { MediaSequenceResponse } from 'api/core';
import { useCallback, useEffect, useState } from 'react';
import { useMediaSequenceSetup } from './MediaSequenceSetupProvider';
import { MediaSequenceBranchSelectionTable } from 'pages/MediaOrbit/components/MediaSequenceBranch/MediaSequenceBranchSelectionTable';

interface MediaSequenceSetupBranchesProps {
  mediaSequence: MediaSequenceResponse;
  disabled: boolean;
}

export const MediaSequenceSetupBranches = ({
  mediaSequence,
  disabled,
}: MediaSequenceSetupBranchesProps) => {
  const { handlePrevious, handleNext } = useMediaSequenceSetup();

  const [disableNext, setDisableNext] = useState<boolean>(false);
  const [disableText, setDisableText] = useState<string>('');

  const onRevalidateStep = useCallback(() => {
    if (mediaSequence?.branches.length === 0) {
      setDisableNext(true);
      setDisableText('Tilføj mindst ét format for at fortsætte');
    } else {
      setDisableNext(false);
      setDisableText('');
    }
  }, [mediaSequence]);

  useEffect(() => {
    onRevalidateStep();
  }, [mediaSequence, onRevalidateStep]);

  return (
    <>
      <div className="grid flex-grow h-full">
        <div id="branches-overview">
          <MediaSequenceBranchSelectionTable
            data={mediaSequence}
            disabled={disabled}
            isFetching={false}
          />
        </div>
      </div>
      <div className="flex flex-col mt-4 mb-8">
        <div id="step-navigation" className="join justify-center mt-2">
          <button className="join-item btn" onClick={handlePrevious}>
            Forrige trin
          </button>
          <button
            className="join-item btn"
            onClick={handleNext}
            disabled={disableNext}
          >
            Næste trin
          </button>
        </div>
        {disableNext && disableText ? (
          <div className="text-center mt-2">
            <p className="text-sm text-gray-500">{disableText}</p>
          </div>
        ) : null}
      </div>
    </>
  );
};
