import { Page } from 'layouts/Admin/Page';
import { PublicationsCalendar } from './PublicationsCalendar';
import { useEffect, useState } from 'react';
import { PublicationsTable } from './PublicationsTable';
import { useTableQueryState } from 'components/Table/useTableQueryState';
import { useGetPublications } from 'api/usePublicationsApi';
import { PublicationCreate } from './PublicationCreate';
import { useGetExternalIntegrations } from 'api/useExternalIntegrationsApi';
import { PermissionProtectedComponent } from 'auth/PermissionProtectedComponent';
import { EPermission } from 'api/core';

export const VIEW_MODE_KEY = 'ESTATE_ENGINE_PUBLICATION_VIEW_MODE_KEY';

export const Publications = () => {
  const { state } = useTableQueryState();
  const { data, isFetching } = useGetPublications(state);
  const { data: externalIntegration } = useGetExternalIntegrations();

  const [calenderMode, setCalenderMode] = useState(() => {
    if (localStorage.getItem(VIEW_MODE_KEY) === undefined) return true;
    return localStorage.getItem(VIEW_MODE_KEY) === 'true';
  });

  useEffect(() => {
    localStorage.setItem(VIEW_MODE_KEY, calenderMode.toString());
  }, [calenderMode]);

  return (
    <Page
      title="Smart Planner - Oversigt"
      breadcrumbs={[{ name: 'Smart Planner' }, { name: 'Oversigt' }]}
      alert={
        externalIntegration?.data?.filter((e) => !e.isExpired)?.length === 0
          ? {
              message:
                'Ingen eksterne integrationer fundet. Tilknyt et sociale medie.',
              type: 'error',
            }
          : undefined
      }
    >
      <div className="flex justify-between my-4">
        <PermissionProtectedComponent
          permissions={[EPermission.PublicationWrite]}
        >
          <PublicationCreate
            trigger={
              <div className="flex justify-start w-full">
                <button className="btn btn-primary mr-2">
                  Opret publicering
                </button>
              </div>
            }
          />
        </PermissionProtectedComponent>

        <label className="cursor-pointer label">
          <span className="label-text me-2">Kalendervisning</span>
          <input
            type="checkbox"
            className="toggle toggle-primary"
            checked={calenderMode}
            onChange={() => setCalenderMode(!calenderMode)}
          />
        </label>
      </div>

      {calenderMode ? (
        <PublicationsCalendar />
      ) : (
        <PublicationsTable
          data={data}
          isFetching={isFetching}
          queryState={state}
        />
      )}
    </Page>
  );
};
