/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EImageToVideoMode = {
    PanLeftToRight: 'PanLeftToRight',
    ZoomIn: 'ZoomIn',
    PanTopLeftToBottomRight: 'PanTopLeftToBottomRight',
    KenBurns: 'KenBurns',
    Still: 'Still'
} as const;
export type EImageToVideoMode = typeof EImageToVideoMode[keyof typeof EImageToVideoMode];


export function EImageToVideoModeFromJSON(json: any): EImageToVideoMode {
    return EImageToVideoModeFromJSONTyped(json, false);
}

export function EImageToVideoModeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EImageToVideoMode {
    return json as EImageToVideoMode;
}

export function EImageToVideoModeToJSON(value?: EImageToVideoMode | null): any {
    return value as any;
}

