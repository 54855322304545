/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EWritingStyle = {
    Unspecified: 'Unspecified',
    DescriptiveAndObjective: 'DescriptiveAndObjective',
    Neutral: 'Neutral',
    CreativeAndVivid: 'CreativeAndVivid',
    Presuasive: 'Presuasive',
    FormalAndProfessional: 'FormalAndProfessional',
    CasualAndConversational: 'CasualAndConversational',
    Humorous: 'Humorous',
    Inspirational: 'Inspirational'
} as const;
export type EWritingStyle = typeof EWritingStyle[keyof typeof EWritingStyle];


export function EWritingStyleFromJSON(json: any): EWritingStyle {
    return EWritingStyleFromJSONTyped(json, false);
}

export function EWritingStyleFromJSONTyped(json: any, ignoreDiscriminator: boolean): EWritingStyle {
    return json as EWritingStyle;
}

export function EWritingStyleToJSON(value?: EWritingStyle | null): any {
    return value as any;
}

