/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EEntityState } from './EEntityState';
import {
    EEntityStateFromJSON,
    EEntityStateFromJSONTyped,
    EEntityStateToJSON,
} from './EEntityState';
import type { EEstateOrbitViewMode } from './EEstateOrbitViewMode';
import {
    EEstateOrbitViewModeFromJSON,
    EEstateOrbitViewModeFromJSONTyped,
    EEstateOrbitViewModeToJSON,
} from './EEstateOrbitViewMode';
import type { OrganizationResponse } from './OrganizationResponse';
import {
    OrganizationResponseFromJSON,
    OrganizationResponseFromJSONTyped,
    OrganizationResponseToJSON,
} from './OrganizationResponse';
import type { UserSimpleResponse } from './UserSimpleResponse';
import {
    UserSimpleResponseFromJSON,
    UserSimpleResponseFromJSONTyped,
    UserSimpleResponseToJSON,
} from './UserSimpleResponse';

/**
 * 
 * @export
 * @interface ProjectResponse
 */
export interface ProjectResponse {
    /**
     * 
     * @type {Date}
     * @memberof ProjectResponse
     */
    lastModifiedUtc: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProjectResponse
     */
    createdUtc: Date;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    id: string;
    /**
     * 
     * @type {EEntityState}
     * @memberof ProjectResponse
     */
    entityState: EEntityState;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectResponse
     */
    singleCase: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectResponse
     */
    estateOrbitEnabled: boolean;
    /**
     * 
     * @type {EEstateOrbitViewMode}
     * @memberof ProjectResponse
     */
    estateOrbitViewMode: EEstateOrbitViewMode;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    estateOrbitCustomButtonText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    estateOrbitCustomButtonScript?: string | null;
    /**
     * 
     * @type {UserSimpleResponse}
     * @memberof ProjectResponse
     */
    user: UserSimpleResponse;
    /**
     * 
     * @type {OrganizationResponse}
     * @memberof ProjectResponse
     */
    organization: OrganizationResponse;
}

/**
 * Check if a given object implements the ProjectResponse interface.
 */
export function instanceOfProjectResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lastModifiedUtc" in value;
    isInstance = isInstance && "createdUtc" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "entityState" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "singleCase" in value;
    isInstance = isInstance && "estateOrbitEnabled" in value;
    isInstance = isInstance && "estateOrbitViewMode" in value;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "organization" in value;

    return isInstance;
}

export function ProjectResponseFromJSON(json: any): ProjectResponse {
    return ProjectResponseFromJSONTyped(json, false);
}

export function ProjectResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastModifiedUtc': (new Date(json['lastModifiedUtc'])),
        'createdUtc': (new Date(json['createdUtc'])),
        'id': json['id'],
        'entityState': EEntityStateFromJSON(json['entityState']),
        'name': json['name'],
        'singleCase': json['singleCase'],
        'estateOrbitEnabled': json['estateOrbitEnabled'],
        'estateOrbitViewMode': EEstateOrbitViewModeFromJSON(json['estateOrbitViewMode']),
        'estateOrbitCustomButtonText': !exists(json, 'estateOrbitCustomButtonText') ? undefined : json['estateOrbitCustomButtonText'],
        'estateOrbitCustomButtonScript': !exists(json, 'estateOrbitCustomButtonScript') ? undefined : json['estateOrbitCustomButtonScript'],
        'user': UserSimpleResponseFromJSON(json['user']),
        'organization': OrganizationResponseFromJSON(json['organization']),
    };
}

export function ProjectResponseToJSON(value?: ProjectResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lastModifiedUtc': (value.lastModifiedUtc.toISOString()),
        'createdUtc': (value.createdUtc.toISOString()),
        'id': value.id,
        'entityState': EEntityStateToJSON(value.entityState),
        'name': value.name,
        'singleCase': value.singleCase,
        'estateOrbitEnabled': value.estateOrbitEnabled,
        'estateOrbitViewMode': EEstateOrbitViewModeToJSON(value.estateOrbitViewMode),
        'estateOrbitCustomButtonText': value.estateOrbitCustomButtonText,
        'estateOrbitCustomButtonScript': value.estateOrbitCustomButtonScript,
        'user': UserSimpleResponseToJSON(value.user),
        'organization': OrganizationResponseToJSON(value.organization),
    };
}

