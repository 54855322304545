/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AssetResponse } from './AssetResponse';
import {
    AssetResponseFromJSON,
    AssetResponseFromJSONTyped,
    AssetResponseToJSON,
} from './AssetResponse';
import type { EPosition } from './EPosition';
import {
    EPositionFromJSON,
    EPositionFromJSONTyped,
    EPositionToJSON,
} from './EPosition';

/**
 * 
 * @export
 * @interface AddOverlayResponse
 */
export interface AddOverlayResponse {
    /**
     * 
     * @type {string}
     * @memberof AddOverlayResponse
     */
    assetId: string;
    /**
     * 
     * @type {EPosition}
     * @memberof AddOverlayResponse
     */
    position: EPosition;
    /**
     * 
     * @type {number}
     * @memberof AddOverlayResponse
     */
    widthPercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof AddOverlayResponse
     */
    marginPercentageX?: number;
    /**
     * 
     * @type {number}
     * @memberof AddOverlayResponse
     */
    marginPercentageY?: number;
    /**
     * 
     * @type {number}
     * @memberof AddOverlayResponse
     */
    delayInSeconds: number;
    /**
     * 
     * @type {number}
     * @memberof AddOverlayResponse
     */
    durationInSeconds: number;
    /**
     * 
     * @type {AssetResponse}
     * @memberof AddOverlayResponse
     */
    asset: AssetResponse;
}

/**
 * Check if a given object implements the AddOverlayResponse interface.
 */
export function instanceOfAddOverlayResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "assetId" in value;
    isInstance = isInstance && "position" in value;
    isInstance = isInstance && "delayInSeconds" in value;
    isInstance = isInstance && "durationInSeconds" in value;
    isInstance = isInstance && "asset" in value;

    return isInstance;
}

export function AddOverlayResponseFromJSON(json: any): AddOverlayResponse {
    return AddOverlayResponseFromJSONTyped(json, false);
}

export function AddOverlayResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddOverlayResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assetId': json['assetId'],
        'position': EPositionFromJSON(json['position']),
        'widthPercentage': !exists(json, 'widthPercentage') ? undefined : json['widthPercentage'],
        'marginPercentageX': !exists(json, 'marginPercentageX') ? undefined : json['marginPercentageX'],
        'marginPercentageY': !exists(json, 'marginPercentageY') ? undefined : json['marginPercentageY'],
        'delayInSeconds': json['delayInSeconds'],
        'durationInSeconds': json['durationInSeconds'],
        'asset': AssetResponseFromJSON(json['asset']),
    };
}

export function AddOverlayResponseToJSON(value?: AddOverlayResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assetId': value.assetId,
        'position': EPositionToJSON(value.position),
        'widthPercentage': value.widthPercentage,
        'marginPercentageX': value.marginPercentageX,
        'marginPercentageY': value.marginPercentageY,
        'delayInSeconds': value.delayInSeconds,
        'durationInSeconds': value.durationInSeconds,
        'asset': AssetResponseToJSON(value.asset),
    };
}

