/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EImageToVideoMode } from './EImageToVideoMode';
import {
    EImageToVideoModeFromJSON,
    EImageToVideoModeFromJSONTyped,
    EImageToVideoModeToJSON,
} from './EImageToVideoMode';

/**
 * 
 * @export
 * @interface ImageToVideoResponse
 */
export interface ImageToVideoResponse {
    /**
     * 
     * @type {number}
     * @memberof ImageToVideoResponse
     */
    durationInSeconds: number;
    /**
     * 
     * @type {EImageToVideoMode}
     * @memberof ImageToVideoResponse
     */
    mode: EImageToVideoMode;
}

/**
 * Check if a given object implements the ImageToVideoResponse interface.
 */
export function instanceOfImageToVideoResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "durationInSeconds" in value;
    isInstance = isInstance && "mode" in value;

    return isInstance;
}

export function ImageToVideoResponseFromJSON(json: any): ImageToVideoResponse {
    return ImageToVideoResponseFromJSONTyped(json, false);
}

export function ImageToVideoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImageToVideoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'durationInSeconds': json['durationInSeconds'],
        'mode': EImageToVideoModeFromJSON(json['mode']),
    };
}

export function ImageToVideoResponseToJSON(value?: ImageToVideoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'durationInSeconds': value.durationInSeconds,
        'mode': EImageToVideoModeToJSON(value.mode),
    };
}

