/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MediaSequenceAssetCreateRequest,
  MediaSequenceAssetMutationCreateRequest,
  MediaSequenceAssetMutationResponse,
  MediaSequenceAssetMutationUpdateRequest,
  MediaSequenceAssetOrderUpdateRequest,
  MediaSequenceAssetResponse,
  MediaSequenceAssetUpdateRequest,
} from '../models';
import {
    MediaSequenceAssetCreateRequestFromJSON,
    MediaSequenceAssetCreateRequestToJSON,
    MediaSequenceAssetMutationCreateRequestFromJSON,
    MediaSequenceAssetMutationCreateRequestToJSON,
    MediaSequenceAssetMutationResponseFromJSON,
    MediaSequenceAssetMutationResponseToJSON,
    MediaSequenceAssetMutationUpdateRequestFromJSON,
    MediaSequenceAssetMutationUpdateRequestToJSON,
    MediaSequenceAssetOrderUpdateRequestFromJSON,
    MediaSequenceAssetOrderUpdateRequestToJSON,
    MediaSequenceAssetResponseFromJSON,
    MediaSequenceAssetResponseToJSON,
    MediaSequenceAssetUpdateRequestFromJSON,
    MediaSequenceAssetUpdateRequestToJSON,
} from '../models';

export interface MediaSequenceAssetsAssetAssetIdMutationPostRequest {
    assetId: string;
    mediaSequenceAssetMutationCreateRequest?: MediaSequenceAssetMutationCreateRequest;
}

export interface MediaSequenceAssetsAssetIdDeleteRequest {
    id: string;
}

export interface MediaSequenceAssetsAssetIdGetRequest {
    id: string;
}

export interface MediaSequenceAssetsAssetIdPutRequest {
    id: string;
    mediaSequenceAssetUpdateRequest?: MediaSequenceAssetUpdateRequest;
}

export interface MediaSequenceAssetsAssetMutationIdDeleteRequest {
    id: string;
}

export interface MediaSequenceAssetsAssetMutationIdGetRequest {
    id: string;
}

export interface MediaSequenceAssetsAssetMutationIdPutRequest {
    id: string;
    mediaSequenceAssetMutationUpdateRequest?: MediaSequenceAssetMutationUpdateRequest;
}

export interface MediaSequenceAssetsAssetOrderPutRequest {
    mediaSequenceAssetOrderUpdateRequest?: MediaSequenceAssetOrderUpdateRequest;
}

export interface MediaSequenceAssetsMediaSequenceIdAssetPostRequest {
    mediaSequenceId: string;
    mediaSequenceAssetCreateRequest?: MediaSequenceAssetCreateRequest;
}

/**
 * 
 */
export class MediaSequenceAssetsApi extends runtime.BaseAPI {

    /**
     */
    async mediaSequenceAssetsAssetAssetIdMutationPostRaw(requestParameters: MediaSequenceAssetsAssetAssetIdMutationPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MediaSequenceAssetMutationResponse>> {
        if (requestParameters.assetId === null || requestParameters.assetId === undefined) {
            throw new runtime.RequiredError('assetId','Required parameter requestParameters.assetId was null or undefined when calling mediaSequenceAssetsAssetAssetIdMutationPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaSequenceAssets/asset/{assetId}/mutation`.replace(`{${"assetId"}}`, encodeURIComponent(String(requestParameters.assetId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MediaSequenceAssetMutationCreateRequestToJSON(requestParameters.mediaSequenceAssetMutationCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MediaSequenceAssetMutationResponseFromJSON(jsonValue));
    }

    /**
     */
    async mediaSequenceAssetsAssetAssetIdMutationPost(requestParameters: MediaSequenceAssetsAssetAssetIdMutationPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MediaSequenceAssetMutationResponse> {
        const response = await this.mediaSequenceAssetsAssetAssetIdMutationPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaSequenceAssetsAssetIdDeleteRaw(requestParameters: MediaSequenceAssetsAssetIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MediaSequenceAssetResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mediaSequenceAssetsAssetIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaSequenceAssets/asset/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MediaSequenceAssetResponseFromJSON(jsonValue));
    }

    /**
     */
    async mediaSequenceAssetsAssetIdDelete(requestParameters: MediaSequenceAssetsAssetIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MediaSequenceAssetResponse> {
        const response = await this.mediaSequenceAssetsAssetIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaSequenceAssetsAssetIdGetRaw(requestParameters: MediaSequenceAssetsAssetIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MediaSequenceAssetResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mediaSequenceAssetsAssetIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaSequenceAssets/asset/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MediaSequenceAssetResponseFromJSON(jsonValue));
    }

    /**
     */
    async mediaSequenceAssetsAssetIdGet(requestParameters: MediaSequenceAssetsAssetIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MediaSequenceAssetResponse> {
        const response = await this.mediaSequenceAssetsAssetIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaSequenceAssetsAssetIdPutRaw(requestParameters: MediaSequenceAssetsAssetIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MediaSequenceAssetResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mediaSequenceAssetsAssetIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaSequenceAssets/asset/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MediaSequenceAssetUpdateRequestToJSON(requestParameters.mediaSequenceAssetUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MediaSequenceAssetResponseFromJSON(jsonValue));
    }

    /**
     */
    async mediaSequenceAssetsAssetIdPut(requestParameters: MediaSequenceAssetsAssetIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MediaSequenceAssetResponse> {
        const response = await this.mediaSequenceAssetsAssetIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaSequenceAssetsAssetMutationIdDeleteRaw(requestParameters: MediaSequenceAssetsAssetMutationIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MediaSequenceAssetMutationResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mediaSequenceAssetsAssetMutationIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaSequenceAssets/asset/mutation/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MediaSequenceAssetMutationResponseFromJSON(jsonValue));
    }

    /**
     */
    async mediaSequenceAssetsAssetMutationIdDelete(requestParameters: MediaSequenceAssetsAssetMutationIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MediaSequenceAssetMutationResponse> {
        const response = await this.mediaSequenceAssetsAssetMutationIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaSequenceAssetsAssetMutationIdGetRaw(requestParameters: MediaSequenceAssetsAssetMutationIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MediaSequenceAssetMutationResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mediaSequenceAssetsAssetMutationIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaSequenceAssets/asset/mutation/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MediaSequenceAssetMutationResponseFromJSON(jsonValue));
    }

    /**
     */
    async mediaSequenceAssetsAssetMutationIdGet(requestParameters: MediaSequenceAssetsAssetMutationIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MediaSequenceAssetMutationResponse> {
        const response = await this.mediaSequenceAssetsAssetMutationIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaSequenceAssetsAssetMutationIdPutRaw(requestParameters: MediaSequenceAssetsAssetMutationIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MediaSequenceAssetMutationResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mediaSequenceAssetsAssetMutationIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaSequenceAssets/asset/mutation/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MediaSequenceAssetMutationUpdateRequestToJSON(requestParameters.mediaSequenceAssetMutationUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MediaSequenceAssetMutationResponseFromJSON(jsonValue));
    }

    /**
     */
    async mediaSequenceAssetsAssetMutationIdPut(requestParameters: MediaSequenceAssetsAssetMutationIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MediaSequenceAssetMutationResponse> {
        const response = await this.mediaSequenceAssetsAssetMutationIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaSequenceAssetsAssetOrderPutRaw(requestParameters: MediaSequenceAssetsAssetOrderPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MediaSequenceAssetResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaSequenceAssets/asset/order`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MediaSequenceAssetOrderUpdateRequestToJSON(requestParameters.mediaSequenceAssetOrderUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MediaSequenceAssetResponseFromJSON));
    }

    /**
     */
    async mediaSequenceAssetsAssetOrderPut(requestParameters: MediaSequenceAssetsAssetOrderPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MediaSequenceAssetResponse>> {
        const response = await this.mediaSequenceAssetsAssetOrderPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaSequenceAssetsMediaSequenceIdAssetPostRaw(requestParameters: MediaSequenceAssetsMediaSequenceIdAssetPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MediaSequenceAssetResponse>> {
        if (requestParameters.mediaSequenceId === null || requestParameters.mediaSequenceId === undefined) {
            throw new runtime.RequiredError('mediaSequenceId','Required parameter requestParameters.mediaSequenceId was null or undefined when calling mediaSequenceAssetsMediaSequenceIdAssetPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaSequenceAssets/{mediaSequenceId}/asset`.replace(`{${"mediaSequenceId"}}`, encodeURIComponent(String(requestParameters.mediaSequenceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MediaSequenceAssetCreateRequestToJSON(requestParameters.mediaSequenceAssetCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MediaSequenceAssetResponseFromJSON(jsonValue));
    }

    /**
     */
    async mediaSequenceAssetsMediaSequenceIdAssetPost(requestParameters: MediaSequenceAssetsMediaSequenceIdAssetPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MediaSequenceAssetResponse> {
        const response = await this.mediaSequenceAssetsMediaSequenceIdAssetPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
