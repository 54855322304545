/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EPermission } from './EPermission';
import {
    EPermissionFromJSON,
    EPermissionFromJSONTyped,
    EPermissionToJSON,
} from './EPermission';
import type { EStatTypeAction } from './EStatTypeAction';
import {
    EStatTypeActionFromJSON,
    EStatTypeActionFromJSONTyped,
    EStatTypeActionToJSON,
} from './EStatTypeAction';

/**
 * 
 * @export
 * @interface StatResponse
 */
export interface StatResponse {
    /**
     * 
     * @type {string}
     * @memberof StatResponse
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof StatResponse
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof StatResponse
     */
    value: number;
    /**
     * 
     * @type {string}
     * @memberof StatResponse
     */
    link: string;
    /**
     * 
     * @type {string}
     * @memberof StatResponse
     */
    iconKey: string;
    /**
     * 
     * @type {boolean}
     * @memberof StatResponse
     */
    actionRequired?: boolean;
    /**
     * 
     * @type {EStatTypeAction}
     * @memberof StatResponse
     */
    actionType?: EStatTypeAction;
    /**
     * 
     * @type {Array<EPermission>}
     * @memberof StatResponse
     */
    actionPermissions?: Array<EPermission> | null;
}

/**
 * Check if a given object implements the StatResponse interface.
 */
export function instanceOfStatResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "value" in value;
    isInstance = isInstance && "link" in value;
    isInstance = isInstance && "iconKey" in value;

    return isInstance;
}

export function StatResponseFromJSON(json: any): StatResponse {
    return StatResponseFromJSONTyped(json, false);
}

export function StatResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'description': json['description'],
        'value': json['value'],
        'link': json['link'],
        'iconKey': json['iconKey'],
        'actionRequired': !exists(json, 'actionRequired') ? undefined : json['actionRequired'],
        'actionType': !exists(json, 'actionType') ? undefined : EStatTypeActionFromJSON(json['actionType']),
        'actionPermissions': !exists(json, 'actionPermissions') ? undefined : (json['actionPermissions'] === null ? null : (json['actionPermissions'] as Array<any>).map(EPermissionFromJSON)),
    };
}

export function StatResponseToJSON(value?: StatResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'description': value.description,
        'value': value.value,
        'link': value.link,
        'iconKey': value.iconKey,
        'actionRequired': value.actionRequired,
        'actionType': EStatTypeActionToJSON(value.actionType),
        'actionPermissions': value.actionPermissions === undefined ? undefined : (value.actionPermissions === null ? null : (value.actionPermissions as Array<any>).map(EPermissionToJSON)),
    };
}

