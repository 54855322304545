import { useEffect, useState } from 'react';
import { isMobileBreakpoint } from './breakpoint';

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(isMobileBreakpoint());

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(isMobileBreakpoint());
    };

    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile;
};
