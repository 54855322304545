/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AngleCreateRequest,
  AngleResponse,
  AngleResponsePagedData,
  AngleUpdateRequest,
} from '../models';
import {
    AngleCreateRequestFromJSON,
    AngleCreateRequestToJSON,
    AngleResponseFromJSON,
    AngleResponseToJSON,
    AngleResponsePagedDataFromJSON,
    AngleResponsePagedDataToJSON,
    AngleUpdateRequestFromJSON,
    AngleUpdateRequestToJSON,
} from '../models';

export interface EstateOrbitProjectsAnglesGetRequest {
    projectId?: string;
    pageNumber?: number;
    size?: number;
    orderByProperty?: string;
    ascending?: boolean;
    searchTerm?: string;
}

export interface EstateOrbitProjectsAnglesIdDeleteRequest {
    id: string;
}

export interface EstateOrbitProjectsAnglesIdGetRequest {
    id: string;
}

export interface EstateOrbitProjectsAnglesIdPutRequest {
    id: string;
    angleUpdateRequest?: AngleUpdateRequest;
}

export interface EstateOrbitProjectsAnglesPostRequest {
    angleCreateRequest?: AngleCreateRequest;
}

/**
 * 
 */
export class EstateOrbitAnglesApi extends runtime.BaseAPI {

    /**
     */
    async estateOrbitProjectsAnglesGetRaw(requestParameters: EstateOrbitProjectsAnglesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AngleResponsePagedData>> {
        const queryParameters: any = {};

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.orderByProperty !== undefined) {
            queryParameters['orderByProperty'] = requestParameters.orderByProperty;
        }

        if (requestParameters.ascending !== undefined) {
            queryParameters['ascending'] = requestParameters.ascending;
        }

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['searchTerm'] = requestParameters.searchTerm;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/EstateOrbit/Projects/Angles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AngleResponsePagedDataFromJSON(jsonValue));
    }

    /**
     */
    async estateOrbitProjectsAnglesGet(requestParameters: EstateOrbitProjectsAnglesGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AngleResponsePagedData> {
        const response = await this.estateOrbitProjectsAnglesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async estateOrbitProjectsAnglesIdDeleteRaw(requestParameters: EstateOrbitProjectsAnglesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AngleResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling estateOrbitProjectsAnglesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/EstateOrbit/Projects/Angles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AngleResponseFromJSON(jsonValue));
    }

    /**
     */
    async estateOrbitProjectsAnglesIdDelete(requestParameters: EstateOrbitProjectsAnglesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AngleResponse> {
        const response = await this.estateOrbitProjectsAnglesIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async estateOrbitProjectsAnglesIdGetRaw(requestParameters: EstateOrbitProjectsAnglesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AngleResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling estateOrbitProjectsAnglesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/EstateOrbit/Projects/Angles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AngleResponseFromJSON(jsonValue));
    }

    /**
     */
    async estateOrbitProjectsAnglesIdGet(requestParameters: EstateOrbitProjectsAnglesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AngleResponse> {
        const response = await this.estateOrbitProjectsAnglesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async estateOrbitProjectsAnglesIdPutRaw(requestParameters: EstateOrbitProjectsAnglesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AngleResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling estateOrbitProjectsAnglesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/EstateOrbit/Projects/Angles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AngleUpdateRequestToJSON(requestParameters.angleUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AngleResponseFromJSON(jsonValue));
    }

    /**
     */
    async estateOrbitProjectsAnglesIdPut(requestParameters: EstateOrbitProjectsAnglesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AngleResponse> {
        const response = await this.estateOrbitProjectsAnglesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async estateOrbitProjectsAnglesPostRaw(requestParameters: EstateOrbitProjectsAnglesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AngleResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/EstateOrbit/Projects/Angles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AngleCreateRequestToJSON(requestParameters.angleCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AngleResponseFromJSON(jsonValue));
    }

    /**
     */
    async estateOrbitProjectsAnglesPost(requestParameters: EstateOrbitProjectsAnglesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AngleResponse> {
        const response = await this.estateOrbitProjectsAnglesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
