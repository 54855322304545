/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Page } from './Page';
import {
    PageFromJSON,
    PageFromJSONTyped,
    PageToJSON,
} from './Page';
import type { SectionResponse } from './SectionResponse';
import {
    SectionResponseFromJSON,
    SectionResponseFromJSONTyped,
    SectionResponseToJSON,
} from './SectionResponse';

/**
 * 
 * @export
 * @interface SectionResponsePagedData
 */
export interface SectionResponsePagedData {
    /**
     * 
     * @type {Page}
     * @memberof SectionResponsePagedData
     */
    page: Page;
    /**
     * 
     * @type {Array<SectionResponse>}
     * @memberof SectionResponsePagedData
     */
    data: Array<SectionResponse>;
}

/**
 * Check if a given object implements the SectionResponsePagedData interface.
 */
export function instanceOfSectionResponsePagedData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "page" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function SectionResponsePagedDataFromJSON(json: any): SectionResponsePagedData {
    return SectionResponsePagedDataFromJSONTyped(json, false);
}

export function SectionResponsePagedDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SectionResponsePagedData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': PageFromJSON(json['page']),
        'data': ((json['data'] as Array<any>).map(SectionResponseFromJSON)),
    };
}

export function SectionResponsePagedDataToJSON(value?: SectionResponsePagedData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': PageToJSON(value.page),
        'data': ((value.data as Array<any>).map(SectionResponseToJSON)),
    };
}

