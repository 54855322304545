import { useTableQueryState } from 'components/Table/useTableQueryState';
import { Page } from 'layouts/Admin/Page';
import { useState } from 'react';
import { PermissionProtectedComponent } from 'auth/PermissionProtectedComponent';
import { EPermission } from 'api/core';
import { SmartTextCreate } from './SmartTextCreate';
import { SmartTextsTable } from './SmartTextsTable';
import { useGetTextCollections } from 'api/useTextCollectionsApi';

export const SmartTexts = () => {
  const [showArchived, setShowArchived] = useState(false);

  return (
    <Page
      title="Smart Text - Oversigt"
      breadcrumbs={[{ name: 'Smart Text' }, { name: 'Oversigt' }]}
    >
      <div className="flex justify-start py-2 pb-3 w-full">
        <PermissionProtectedComponent
          permissions={[EPermission.TextCollectionWrite]}
        >
          <SmartTextCreate
            trigger={
              <div className="flex justify-start w-full">
                <button className="btn btn-primary mr-2">
                  Opret boligtekst
                </button>
              </div>
            }
          />
        </PermissionProtectedComponent>
        <div className="ml-auto">
          <label className="cursor-pointer label">
            <span className="label-text me-2">Vis arkiverede</span>
            <input
              type="checkbox"
              id="showArchived"
              name="showArchived"
              checked={showArchived}
              onChange={() => setShowArchived(!showArchived)}
              className="toggle toggle-primary"
            />
          </label>
        </div>
      </div>
      <TableWrapper
        includeActive={!showArchived}
        includeArchived={showArchived}
      />
    </Page>
  );
};

interface TableWrapperProps {
  includeActive: boolean;
  includeArchived: boolean;
}

const TableWrapper = ({
  includeActive,
  includeArchived,
}: TableWrapperProps) => {
  const { state } = useTableQueryState();

  const expectedFilter = JSON.stringify({
    includeActive: includeActive,
    includeArchived: includeArchived,
  });

  if (state.customState != expectedFilter) {
    state.setCustomState(expectedFilter);
  }

  const { data, isFetching: isFetchingFields } = useGetTextCollections(state);

  return (
    <SmartTextsTable
      data={data}
      isFetching={isFetchingFields}
      queryState={state}
    />
  );
};
