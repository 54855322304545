import { useTableQueryState } from 'components/Table/useTableQueryState';
import { Page } from 'layouts/Admin/Page';
import { useGetVisualIdentities } from 'api/useVisualIdentitiesApi';
import { VisualIdentitiesTable } from './components/VisualIdentity/VisualIdentitiesTable';
import { VisualIdentityCreate } from './components/VisualIdentity/VisualIdentityCreate';
import { PermissionProtectedComponent } from 'auth/PermissionProtectedComponent';
import { EPermission } from 'api/core';

export const VisualIdentitesView = () => {
  const { state } = useTableQueryState();
  const { data, isFetching } = useGetVisualIdentities(state);

  return (
    <Page
      title={'Visuelle identiteter'}
      breadcrumbs={[{ name: 'Visuelle identiteter' }]}
    >
      <PermissionProtectedComponent
        permissions={[EPermission.VisualIdentityWrite]}
      >
        <VisualIdentityCreate
          trigger={
            <div className="flex justify-start py-4 w-full">
              <button className="btn btn-primary mr-2">
                Opret visuel identitet
              </button>
            </div>
          }
        />
      </PermissionProtectedComponent>
      <VisualIdentitiesTable
        data={data}
        isFetching={isFetching}
        queryState={state}
      />
    </Page>
  );
};
