import { ColumnDef } from '@tanstack/react-table';
import {
  EEntityState,
  EPermission,
  ProjectResponse,
  ProjectResponsePagedData,
} from 'api/core';
import { CustomTable } from 'components/Table/CustomTable';
import { ActionCell } from 'components/Table/Cell/ActionCell';
import { DateTimeCell } from 'components/Table/Cell/DateTimeCell';
import { useGetCustomReactTable } from 'components/Table/useGetTableSet';
import { TableQueryState } from 'components/Table/useTableQueryState';
import { useMemo, useState } from 'react';
import { DeleteEntityById } from 'components/Form/DeleteEntityById';
import { useDeleteProject, useGetProject } from 'api/useProjectsApi';
import { LinkCell } from 'components/Table/Cell/LinkCell';
import { AnimatedIcon } from 'components/Icon/AnimatedIcon';

interface TableProps {
  data?: ProjectResponsePagedData;
  isFetching: boolean;
  queryState: TableQueryState;
}

export const ProjectsTable = ({ data, isFetching, queryState }: TableProps) => {
  const [deleteTarget, setDeleteTarget] = useState<ProjectResponse | null>(
    null
  );

  const columns = useMemo(() => {
    const baseColumns: ColumnDef<ProjectResponse>[] = [
      {
        accessorKey: '_view',
        header: 'Vis',
        enableSorting: false,
        cell: (e) => (
          <LinkCell
            icon={<AnimatedIcon icon="open-icon" className="h-6 w-6" />}
            path={`/projects/${e.row.original.id}/${e.row.original.singleCase ? 'case' : 'cases'}`}
          />
        ),
      },
      {
        accessorKey: 'name',
        header: 'Navn',
        cell: (e) => (
          <p>
            {e.row.original.name}{' '}
            {e.row.original.entityState === EEntityState.Ghost ? '👻' : ''}
          </p>
        ),
      },
      {
        accessorKey: 'user.name',
        header: 'Bruger',
        enableSorting: false,
      },
      {
        accessorKey: 'lastModifiedUtc',
        header: 'Sidst redigeret',
        cell: (e) => <DateTimeCell value={e.row.original.lastModifiedUtc} />,
      },
      {
        accessorKey: 'createdUtc',
        header: 'Oprettet',
        cell: (e) => <DateTimeCell value={e.row.original.createdUtc} />,
      },
      {
        accessorKey: '_',
        header: 'Handlinger',
        enableSorting: false,
        cell: (e) => (
          <ActionCell
            permissions={[EPermission.ProjectDelete]}
            icon={<AnimatedIcon icon="trash-icon" className="h-6 w-6" />}
            onClick={() => setDeleteTarget(e.row.original)}
          />
        ),
      },
    ];
    return baseColumns;
  }, [setDeleteTarget]);

  const table = useGetCustomReactTable(
    data?.data ?? [],
    data?.page.totalPages ?? -1,
    columns,
    queryState
  );

  return (
    <>
      <CustomTable table={table} isLoading={isFetching} />
      {deleteTarget ? (
        <DeleteEntityById
          id={deleteTarget?.id}
          isInitialOpen={true}
          onClosed={() => setDeleteTarget(null)}
          titlePropertyKey={'name'}
          getter={useGetProject}
          deleter={useDeleteProject}
        />
      ) : null}
    </>
  );
};
