import {
  AssetSimpleResponse,
  EAssetType,
  MediaSequenceAssetMutationCreateRequest,
  MediaSequenceAssetMutationUpdateRequest,
} from 'api/core';
import { AnimatedIcon } from 'components/Icon/AnimatedIcon';
import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { captureVideoFrames } from 'utils/capture-video-frames';
import { formatTime } from 'utils/format/time';

interface TrimVideoProps {
  asset: AssetSimpleResponse;
}

type DraggableItem = 'start' | 'end' | 'playhead';

const IMAGES_FRAMES_TO_SHOW = 12;

export const TrimVideo = ({ asset }: TrimVideoProps) => {
  const {
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { disabled, errors },
  } = useFormContext<
    | MediaSequenceAssetMutationCreateRequest
    | MediaSequenceAssetMutationUpdateRequest
  >();

  const initialTrimStart = getValues('trimVideo.trimStartSeconds') ?? 0;
  const initialTrimEnd = getValues('trimVideo.trimEndSeconds') || 9999;

  const [videoDuration, setVideoDuration] = useState<number>(0);
  const [trimmedStart, setTrimmedStart] = useState<number>(0);
  const [trimmedEnd, setTrimmedEnd] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [itemBeingDragged, setItemBeingDragged] =
    useState<DraggableItem | null>(null);
  const [videoFrames, setVideoFrames] = useState<string[]>([]);
  const videoRef = useRef<HTMLVideoElement>(null);
  const animationFrameId = useRef(0);
  const progressBarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!asset.url) return;

    const video = document.createElement('video');
    video.src = asset.url;
    video.onloadedmetadata = () => {
      const duration = Math.ceil(video.duration * 10) / 10;
      setVideoDuration(duration);
      setIsLoading(false);

      const start = Math.min(Math.max(initialTrimStart, 0), duration);
      const end = Math.min(initialTrimEnd, duration);
      setTrimmedStart(start);
      setCurrentTime(start);
      setTrimmedEnd(end);
      if (videoRef.current) {
        videoRef.current.currentTime = start;
      }

      if (videoFrames.length != IMAGES_FRAMES_TO_SHOW) {
        captureVideoFrames(asset.url, IMAGES_FRAMES_TO_SHOW)
          .then((images) => {
            setVideoFrames(images);
          })
          .catch((error) => {
            setVideoFrames([]);
            console.error(error);
          });
      }
    };
    // We do not want initialTrimEnd here, as it keeps triggering the useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset]);

  useEffect(() => {
    // Cleanup the animation frame when the component unmounts
    return () => {
      cancelAnimationFrame(animationFrameId.current);
    };
  }, []);

  useEffect(() => {
    if (!videoDuration) return;

    const roundedStart = Math.max(0, Math.round(trimmedStart * 10) / 10);
    const roundedEnd = Math.min(
      videoDuration,
      Math.round(trimmedEnd * 10) / 10
    );
    setValue('trimVideo.trimStartSeconds', roundedStart, {
      shouldValidate: true,
    });
    setValue('trimVideo.trimEndSeconds', roundedEnd, { shouldValidate: true });

    if (
      isNaN(trimmedStart) === undefined ||
      trimmedEnd === undefined ||
      (trimmedStart === 0 && trimmedEnd === videoDuration)
    ) {
      setError('trimVideo.trimStartSeconds', {
        type: 'required',
        message: 'Tid ikke valgt',
      });
    } else if (trimmedStart === trimmedEnd) {
      setError('trimVideo.trimStartSeconds', {
        type: 'pattern',
        message: 'Start og slut kan ikke være det samme',
      });
    } else if (trimmedStart > trimmedEnd) {
      setError('trimVideo.trimStartSeconds', {
        type: 'required',
        message: 'Start må ikke være større end slut',
      });
    } else {
      clearErrors('trimVideo.trimStartSeconds');
    }
  }, [
    trimmedStart,
    trimmedEnd,
    videoDuration,
    clearErrors,
    setError,
    setValue,
  ]);

  const onStartDrag = (item: DraggableItem) => {
    if (disabled && (item === 'start' || item === 'end')) return;

    setItemBeingDragged(item);
  };

  const handlePlayPause = () => {
    if (!videoRef.current) return;

    if (videoRef.current.paused) {
      if (currentTime >= trimmedEnd - 1 / 30) {
        videoRef.current.currentTime = trimmedStart;
      }
      videoRef.current.play().then(() => {
        if (videoRef.current && currentTime < trimmedStart) {
          videoRef.current.currentTime = trimmedStart;
        }
      });
      animationFrameId.current = requestAnimationFrame(handleTimeUpdate);
    } else {
      videoRef.current.pause();
      cancelAnimationFrame(animationFrameId.current);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDragMove = (event: MouseEvent | TouchEvent) => {
    const minVideoDuration = 2;
    if (!progressBarRef.current) return;

    const clientX =
      typeof TouchEvent !== 'undefined' && event instanceof TouchEvent
        ? event.touches[0].clientX
        : event instanceof MouseEvent
          ? event.clientX
          : 0;

    const progressBarWidth = progressBarRef.current.offsetWidth;
    const clickPosition =
      clientX - progressBarRef.current.getBoundingClientRect().left;
    const newTime = (clickPosition / progressBarWidth) * videoDuration;

    // Moving left handle
    if (
      itemBeingDragged === 'start' &&
      newTime < trimmedEnd - minVideoDuration
    ) {
      setTrimmedStart(Math.max(0, newTime));
      if (videoRef.current) videoRef.current.currentTime = Math.max(0, newTime);
    }
    // Moving right handle
    else if (
      itemBeingDragged === 'end' &&
      newTime > trimmedStart + minVideoDuration
    ) {
      setTrimmedEnd(Math.min(videoDuration, newTime));
      if (videoRef.current)
        videoRef.current.currentTime = Math.min(videoDuration, newTime);
    }
    // Moving playhead
    else if (itemBeingDragged === 'playhead') {
      const constrainedNewTime = Math.min(
        trimmedEnd,
        Math.max(newTime, trimmedStart)
      );
      if (videoRef.current) videoRef.current.currentTime = constrainedNewTime;
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDragStop = () => {
    setItemBeingDragged(null);
  };

  useEffect(() => {
    if (itemBeingDragged) {
      document.addEventListener('mousemove', handleDragMove);
      document.addEventListener('mouseup', handleDragStop);
      document.addEventListener('touchmove', handleDragMove);
      document.addEventListener('touchend', handleDragStop);
    } else {
      document.removeEventListener('mousemove', handleDragMove);
      document.removeEventListener('mouseup', handleDragStop);
      document.removeEventListener('touchmove', handleDragMove);
      document.removeEventListener('touchend', handleDragStop);
    }

    return () => {
      document.removeEventListener('mousemove', handleDragMove);
      document.removeEventListener('mouseup', handleDragStop);
      document.removeEventListener('touchmove', handleDragMove);
      document.removeEventListener('touchend', handleDragStop);
    };
  }, [itemBeingDragged, handleDragMove, handleDragStop]);

  let isFirstPlay = true;
  const handleTimeUpdate = () => {
    if (videoRef.current) {
      const time = videoRef.current.currentTime;
      setCurrentTime(time);
      if (time >= trimmedEnd - 1 / 30) {
        videoRef.current.pause();
        cancelAnimationFrame(animationFrameId.current);
      }
      if (time < trimmedStart && isFirstPlay) {
        videoRef.current.currentTime = trimmedStart;
        isFirstPlay = false;
      }
    }
    animationFrameId.current = requestAnimationFrame(handleTimeUpdate);
  };

  const getProgressPosition = (time: number) => {
    const percentage = (time / videoDuration) * 100;
    return `${percentage}%`;
  };

  if (asset.type !== EAssetType.Video || isLoading) return null;

  return (
    <div className="flex flex-col gap-2">
      <div className="relative w-full bg-black" onClick={handlePlayPause}>
        <video
          crossOrigin="anonymous"
          ref={videoRef}
          className={twMerge(
            'w-full transition-opacity duration-200',
            (!videoRef.current || videoRef.current.paused) && 'opacity-60'
          )}
          onTimeUpdate={handleTimeUpdate}
        >
          <source src={asset.url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="absolute top-0 left-0 w-full h-full pointer-events-none flex justify-center items-center">
          <AnimatedIcon
            icon="movie-icon"
            className={twMerge(
              'w-24 h-24 transition-opacity duration-200',
              !videoRef.current?.paused && 'opacity-0',
              (!videoRef.current || videoRef.current.paused) && 'opacity-100'
            )}
            autoPlay
            loop
          />
        </div>
      </div>

      <div
        className="relative w-full h-12 bg-gray-300 rounded-lg my-6"
        ref={progressBarRef}
      >
        {/* Show thumbnails of the video across the progress bar. */}
        {videoFrames.map((frame, index) => (
          <img
            key={index}
            src={frame}
            alt="Video frame"
            className="absolute h-full select-none pointer-events-none object-cover"
            style={{
              left: getProgressPosition(
                (index * videoDuration) / videoFrames.length
              ),
              width: `calc(100% / ${videoFrames.length})`,
            }}
          />
        ))}

        {/* Area until trimmedStart */}
        <div
          className="absolute h-full bg-gray-300 opacity-80"
          style={{
            left: 0,
            width: getProgressPosition(trimmedStart),
          }}
        ></div>

        {/* Area from trimmedEnd to end */}
        <div
          className="absolute h-full bg-gray-300 opacity-80"
          style={{
            left: getProgressPosition(trimmedEnd),
            width: `calc(100% - ${getProgressPosition(trimmedEnd)})`,
          }}
        ></div>

        {/* Area trimmedStart and trimmedEnd */}
        <div
          className={twMerge(
            'absolute h-full cursor-pointer border-y-4 border-primary',
            disabled && 'border-x-4 rounded-lg'
          )}
          style={{
            left: getProgressPosition(trimmedStart),
            width: `calc(${getProgressPosition(trimmedEnd)} - ${getProgressPosition(
              trimmedStart
            )})`,
            background:
              videoFrames.length == 0
                ? 'linear-gradient(90deg, #F15C62, #D21B54)'
                : 'none',
          }}
          onClick={(event) => {
            // Move playhead to clicked position
            if (!progressBarRef.current) return;
            const progressBarWidth = progressBarRef.current?.offsetWidth || 0;
            const clickPosition =
              event.clientX -
              progressBarRef.current?.getBoundingClientRect().left;
            const newTime = (clickPosition / progressBarWidth) * videoDuration;

            if (videoRef.current) videoRef.current.currentTime = newTime;
          }}
          onMouseDown={() => onStartDrag('playhead')}
          onTouchStart={() => onStartDrag('playhead')}
        ></div>

        {/* Start handle */}
        <div
          className={twMerge(
            'absolute h-full top-0 bg-primary w-6 rounded-s-lg',
            !disabled && 'cursor-grab',
            disabled && 'opacity-20 pointer-events-none'
          )}
          style={{
            left: `calc(${getProgressPosition(trimmedStart)} - 1.5rem)`,
          }}
          onMouseDown={() => onStartDrag('start')}
          onTouchStart={() => onStartDrag('start')}
        >
          <span className="absolute inset-0 flex flex-col justify-center items-center">
            <span className="block w-1 h-1 bg-gray-500 rounded-full mb-1"></span>
            <span className="block w-1 h-1 bg-gray-500 rounded-full"></span>
          </span>
        </div>

        {/* End handle */}
        <div
          className={twMerge(
            'absolute h-full bg-primary w-6 rounded-e-lg',
            !disabled && 'cursor-grab',
            disabled && 'opacity-20 pointer-events-none'
          )}
          style={{
            left: `calc(${getProgressPosition(trimmedEnd)} + 0rem)`,
          }}
          onMouseDown={() => onStartDrag('end')}
          onTouchStart={() => onStartDrag('end')}
        >
          <span className="absolute inset-0 flex flex-col justify-center items-center">
            <span className="block w-1 h-1 bg-gray-500 rounded-full mb-1"></span>
            <span className="block w-1 h-1 bg-gray-500 rounded-full"></span>
          </span>
        </div>

        {/* Playhead indicates current time */}
        <div
          className="absolute bg-white h-[125%] rounded-lg w-1 opacity-90 pointer-events-none top-[-0.25rem]"
          style={{ left: getProgressPosition(currentTime) }}
        ></div>

        {/* Current time. Shown above playhead */}
        <div
          className="absolute bg-white rounded-lg w-16 h-6 text-xs text-gray-600 flex justify-center items-center pointer-events-none border-4 border-gray-300"
          style={{
            left: `calc(${getProgressPosition(currentTime)} - 2rem)`,
            top: '-1.75rem',
          }}
        >
          {formatTime(currentTime)}
        </div>

        {/* Trimmed video duration. Shown in middle of trimmed area */}
        <div
          className="absolute rounded-lg w-12 h-6 text-xs text-gray-400 flex justify-center items-center pointer-events-none"
          style={{
            left: `calc((${getProgressPosition(trimmedStart)} + ${getProgressPosition(
              trimmedEnd
            )}) / 2 - 1.5rem)`,
            top: '100%',
          }}
        >
          {formatTime(trimmedEnd - trimmedStart)}
        </div>

        {/* Trimmed start time. Shown below left handle */}
        <div
          className="absolute rounded-lg w-12 h-6 text-xs text-primary flex justify-center items-center pointer-events-none"
          style={{
            left: `calc(${getProgressPosition(trimmedStart)} - 2rem)`,
            top: '100%',
          }}
        >
          {formatTime(trimmedStart)}
        </div>

        {/* Trimmed end time. Shown below right handle */}
        <div
          className="absolute rounded-lg w-12 h-6 text-xs text-primary flex justify-center items-center pointer-events-none"
          style={{
            left: `calc(${getProgressPosition(trimmedEnd)} - 1rem)`,
            top: '100%',
          }}
        >
          {formatTime(trimmedEnd)}
        </div>
      </div>

      <div className="flex justify-center pt-2">
        {errors?.trimVideo?.trimStartSeconds && (
          <span className="text-red-500">
            {errors.trimVideo.trimStartSeconds.message}
          </span>
        )}
        {errors?.trimVideo?.trimEndSeconds && (
          <span className="text-red-500">
            {errors.trimVideo.trimEndSeconds.message}
          </span>
        )}
      </div>
    </div>
  );
};
