/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EStatTypeAction = {
    CreateMediaSequence: 'CreateMediaSequence',
    CreateText: 'CreateText',
    UploadFile: 'UploadFile'
} as const;
export type EStatTypeAction = typeof EStatTypeAction[keyof typeof EStatTypeAction];


export function EStatTypeActionFromJSON(json: any): EStatTypeAction {
    return EStatTypeActionFromJSONTyped(json, false);
}

export function EStatTypeActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): EStatTypeAction {
    return json as EStatTypeAction;
}

export function EStatTypeActionToJSON(value?: EStatTypeAction | null): any {
    return value as any;
}

