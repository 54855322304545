/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AssetResponse } from './AssetResponse';
import {
    AssetResponseFromJSON,
    AssetResponseFromJSONTyped,
    AssetResponseToJSON,
} from './AssetResponse';
import type { CaseFieldResponse } from './CaseFieldResponse';
import {
    CaseFieldResponseFromJSON,
    CaseFieldResponseFromJSONTyped,
    CaseFieldResponseToJSON,
} from './CaseFieldResponse';
import type { ECaseState } from './ECaseState';
import {
    ECaseStateFromJSON,
    ECaseStateFromJSONTyped,
    ECaseStateToJSON,
} from './ECaseState';
import type { ProjectResponse } from './ProjectResponse';
import {
    ProjectResponseFromJSON,
    ProjectResponseFromJSONTyped,
    ProjectResponseToJSON,
} from './ProjectResponse';

/**
 * 
 * @export
 * @interface CaseResponse
 */
export interface CaseResponse {
    /**
     * 
     * @type {Date}
     * @memberof CaseResponse
     */
    lastModifiedUtc: Date;
    /**
     * 
     * @type {Date}
     * @memberof CaseResponse
     */
    createdUtc: Date;
    /**
     * 
     * @type {string}
     * @memberof CaseResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CaseResponse
     */
    dawaAddressId: string;
    /**
     * 
     * @type {string}
     * @memberof CaseResponse
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof CaseResponse
     */
    maskId: string;
    /**
     * 
     * @type {ECaseState}
     * @memberof CaseResponse
     */
    state: ECaseState;
    /**
     * 
     * @type {ProjectResponse}
     * @memberof CaseResponse
     */
    project: ProjectResponse;
    /**
     * 
     * @type {Array<CaseFieldResponse>}
     * @memberof CaseResponse
     */
    caseFields: Array<CaseFieldResponse>;
    /**
     * 
     * @type {Array<AssetResponse>}
     * @memberof CaseResponse
     */
    assets: Array<AssetResponse>;
}

/**
 * Check if a given object implements the CaseResponse interface.
 */
export function instanceOfCaseResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lastModifiedUtc" in value;
    isInstance = isInstance && "createdUtc" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "dawaAddressId" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "maskId" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "project" in value;
    isInstance = isInstance && "caseFields" in value;
    isInstance = isInstance && "assets" in value;

    return isInstance;
}

export function CaseResponseFromJSON(json: any): CaseResponse {
    return CaseResponseFromJSONTyped(json, false);
}

export function CaseResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CaseResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastModifiedUtc': (new Date(json['lastModifiedUtc'])),
        'createdUtc': (new Date(json['createdUtc'])),
        'id': json['id'],
        'dawaAddressId': json['dawaAddressId'],
        'address': json['address'],
        'maskId': json['maskId'],
        'state': ECaseStateFromJSON(json['state']),
        'project': ProjectResponseFromJSON(json['project']),
        'caseFields': ((json['caseFields'] as Array<any>).map(CaseFieldResponseFromJSON)),
        'assets': ((json['assets'] as Array<any>).map(AssetResponseFromJSON)),
    };
}

export function CaseResponseToJSON(value?: CaseResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lastModifiedUtc': (value.lastModifiedUtc.toISOString()),
        'createdUtc': (value.createdUtc.toISOString()),
        'id': value.id,
        'dawaAddressId': value.dawaAddressId,
        'address': value.address,
        'maskId': value.maskId,
        'state': ECaseStateToJSON(value.state),
        'project': ProjectResponseToJSON(value.project),
        'caseFields': ((value.caseFields as Array<any>).map(CaseFieldResponseToJSON)),
        'assets': ((value.assets as Array<any>).map(AssetResponseToJSON)),
    };
}

