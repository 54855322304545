export interface TemplateFormat {
  name: string;
  width: number;
  height: number;
  recommendedUses: string[];
}

export const BRANCH_TEMPLATE_FORMATS: TemplateFormat[] = [
  {
    name: 'Kvadratisk',
    width: 1080,
    height: 1080,
    recommendedUses: ['Facebook feed', 'Instagram feed'],
  },
  {
    name: 'Portræt',
    width: 720,
    height: 1280,
    recommendedUses: [
      'Instagram story',
      'TikTok',
      'Instagram portræt',
      'Twitter portræt',
    ],
  },
  {
    name: 'Landskab',
    width: 1280,
    height: 720,
    recommendedUses: ['Facebook feed', 'Instagram feed'],
  },
  {
    name: 'Bredformat (HD)',
    width: 1920,
    height: 1080,
    recommendedUses: ['YouTube'],
  },
];
