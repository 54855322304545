/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DynamicTemplateCreateRequest,
  DynamicTemplateResponse,
  DynamicTemplateResponsePagedData,
  DynamicTemplateUpdateRequest,
} from '../models';
import {
    DynamicTemplateCreateRequestFromJSON,
    DynamicTemplateCreateRequestToJSON,
    DynamicTemplateResponseFromJSON,
    DynamicTemplateResponseToJSON,
    DynamicTemplateResponsePagedDataFromJSON,
    DynamicTemplateResponsePagedDataToJSON,
    DynamicTemplateUpdateRequestFromJSON,
    DynamicTemplateUpdateRequestToJSON,
} from '../models';

export interface MediaOrbitDynamicTemplatesGetRequest {
    pageNumber?: number;
    size?: number;
    orderByProperty?: string;
    ascending?: boolean;
    searchTerm?: string;
}

export interface MediaOrbitDynamicTemplatesIdDeleteRequest {
    id: string;
}

export interface MediaOrbitDynamicTemplatesIdGetRequest {
    id: string;
}

export interface MediaOrbitDynamicTemplatesIdPutRequest {
    id: string;
    dynamicTemplateUpdateRequest?: DynamicTemplateUpdateRequest;
}

export interface MediaOrbitDynamicTemplatesPostRequest {
    dynamicTemplateCreateRequest?: DynamicTemplateCreateRequest;
}

export interface MediaOrbitDynamicTemplatesSearchGetRequest {
    size?: number;
    searchTerm?: string;
}

/**
 * 
 */
export class DynamicTemplatesApi extends runtime.BaseAPI {

    /**
     */
    async mediaOrbitDynamicTemplatesGetRaw(requestParameters: MediaOrbitDynamicTemplatesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DynamicTemplateResponsePagedData>> {
        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.orderByProperty !== undefined) {
            queryParameters['orderByProperty'] = requestParameters.orderByProperty;
        }

        if (requestParameters.ascending !== undefined) {
            queryParameters['ascending'] = requestParameters.ascending;
        }

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['searchTerm'] = requestParameters.searchTerm;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaOrbit/DynamicTemplates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DynamicTemplateResponsePagedDataFromJSON(jsonValue));
    }

    /**
     */
    async mediaOrbitDynamicTemplatesGet(requestParameters: MediaOrbitDynamicTemplatesGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DynamicTemplateResponsePagedData> {
        const response = await this.mediaOrbitDynamicTemplatesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaOrbitDynamicTemplatesIdDeleteRaw(requestParameters: MediaOrbitDynamicTemplatesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DynamicTemplateResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mediaOrbitDynamicTemplatesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaOrbit/DynamicTemplates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DynamicTemplateResponseFromJSON(jsonValue));
    }

    /**
     */
    async mediaOrbitDynamicTemplatesIdDelete(requestParameters: MediaOrbitDynamicTemplatesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DynamicTemplateResponse> {
        const response = await this.mediaOrbitDynamicTemplatesIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaOrbitDynamicTemplatesIdGetRaw(requestParameters: MediaOrbitDynamicTemplatesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DynamicTemplateResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mediaOrbitDynamicTemplatesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaOrbit/DynamicTemplates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DynamicTemplateResponseFromJSON(jsonValue));
    }

    /**
     */
    async mediaOrbitDynamicTemplatesIdGet(requestParameters: MediaOrbitDynamicTemplatesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DynamicTemplateResponse> {
        const response = await this.mediaOrbitDynamicTemplatesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaOrbitDynamicTemplatesIdPutRaw(requestParameters: MediaOrbitDynamicTemplatesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DynamicTemplateResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mediaOrbitDynamicTemplatesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaOrbit/DynamicTemplates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DynamicTemplateUpdateRequestToJSON(requestParameters.dynamicTemplateUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DynamicTemplateResponseFromJSON(jsonValue));
    }

    /**
     */
    async mediaOrbitDynamicTemplatesIdPut(requestParameters: MediaOrbitDynamicTemplatesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DynamicTemplateResponse> {
        const response = await this.mediaOrbitDynamicTemplatesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaOrbitDynamicTemplatesPostRaw(requestParameters: MediaOrbitDynamicTemplatesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DynamicTemplateResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaOrbit/DynamicTemplates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DynamicTemplateCreateRequestToJSON(requestParameters.dynamicTemplateCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DynamicTemplateResponseFromJSON(jsonValue));
    }

    /**
     */
    async mediaOrbitDynamicTemplatesPost(requestParameters: MediaOrbitDynamicTemplatesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DynamicTemplateResponse> {
        const response = await this.mediaOrbitDynamicTemplatesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaOrbitDynamicTemplatesSearchGetRaw(requestParameters: MediaOrbitDynamicTemplatesSearchGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DynamicTemplateResponse>>> {
        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['searchTerm'] = requestParameters.searchTerm;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaOrbit/DynamicTemplates/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DynamicTemplateResponseFromJSON));
    }

    /**
     */
    async mediaOrbitDynamicTemplatesSearchGet(requestParameters: MediaOrbitDynamicTemplatesSearchGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DynamicTemplateResponse>> {
        const response = await this.mediaOrbitDynamicTemplatesSearchGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
