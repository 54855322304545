import {
  EAssetType,
  EResizeMode,
  MediaSequenceBranchMutationCreateRequest,
  MediaSequenceBranchMutationUpdateRequest,
  MediaSequenceResponse,
} from 'api/core';
import { useGetMediaSequenceAssets } from 'api/useMediaSequencesApi';
import { VideoResizeModePreview } from 'components/Video/VideoResizeModePreview';
import { useFormContext } from 'react-hook-form';

interface ResizeAndContactProps {
  mediaSequence: MediaSequenceResponse;
}

export const ResizeAndContact = ({ mediaSequence }: ResizeAndContactProps) => {
  const { register, watch } = useFormContext<
    | MediaSequenceBranchMutationCreateRequest
    | MediaSequenceBranchMutationUpdateRequest
  >();

  const { data: assets } = useGetMediaSequenceAssets(mediaSequence.id);
  const sampleVideoUrl =
    assets
      ?.sort((a, b) => a.order - b.order)
      ?.find((e) => !e.isTemplate && e.asset.type === EAssetType.Video)?.asset
      ?.url || '/media-orbit/sample-portrait-video.mp4';

  const branchWidth = mediaSequence.branches?.[0]?.width ?? 1080;
  const branchHeight = mediaSequence.branches?.[0]?.height ?? 1080;

  return (
    <>
      <div className="form-control">
        <label className="label">
          <span className="label-text">Størrelsestilpasningsmodus</span>
        </label>
        <select
          {...register('resizing.resizeMode', { required: true })}
          className="select select-bordered"
        >
          <option value={EResizeMode.Crop}>Crop / beskær</option>
          <option value={EResizeMode.CropWithBlurredPadding}>
            Crop med evt. sløret letterbox
          </option>
          <option value={EResizeMode.LetterboxPadding}>Letterbox</option>
        </select>
      </div>

      <VideoResizeModePreview
        mode={watch('resizing.resizeMode')}
        assetUrl={sampleVideoUrl}
        outputWidth={branchWidth}
        outputHeight={branchHeight}
      />
    </>
  );
};
