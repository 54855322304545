/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AssetSimpleResponse } from './AssetSimpleResponse';
import {
    AssetSimpleResponseFromJSON,
    AssetSimpleResponseFromJSONTyped,
    AssetSimpleResponseToJSON,
} from './AssetSimpleResponse';
import type { ETemplatePosition } from './ETemplatePosition';
import {
    ETemplatePositionFromJSON,
    ETemplatePositionFromJSONTyped,
    ETemplatePositionToJSON,
} from './ETemplatePosition';
import type { MediaSequenceAssetMutationResponse } from './MediaSequenceAssetMutationResponse';
import {
    MediaSequenceAssetMutationResponseFromJSON,
    MediaSequenceAssetMutationResponseFromJSONTyped,
    MediaSequenceAssetMutationResponseToJSON,
} from './MediaSequenceAssetMutationResponse';

/**
 * 
 * @export
 * @interface MediaSequenceAssetResponse
 */
export interface MediaSequenceAssetResponse {
    /**
     * 
     * @type {Date}
     * @memberof MediaSequenceAssetResponse
     */
    lastModifiedUtc: Date;
    /**
     * 
     * @type {Date}
     * @memberof MediaSequenceAssetResponse
     */
    createdUtc: Date;
    /**
     * 
     * @type {string}
     * @memberof MediaSequenceAssetResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MediaSequenceAssetResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MediaSequenceAssetResponse
     */
    mediaSequenceId: string;
    /**
     * 
     * @type {AssetSimpleResponse}
     * @memberof MediaSequenceAssetResponse
     */
    asset: AssetSimpleResponse;
    /**
     * 
     * @type {number}
     * @memberof MediaSequenceAssetResponse
     */
    order: number;
    /**
     * 
     * @type {string}
     * @memberof MediaSequenceAssetResponse
     */
    description: string;
    /**
     * 
     * @type {ETemplatePosition}
     * @memberof MediaSequenceAssetResponse
     */
    templatePosition: ETemplatePosition;
    /**
     * 
     * @type {boolean}
     * @memberof MediaSequenceAssetResponse
     */
    isTemplate: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MediaSequenceAssetResponse
     */
    isCopy: boolean;
    /**
     * 
     * @type {Array<MediaSequenceAssetMutationResponse>}
     * @memberof MediaSequenceAssetResponse
     */
    mutations: Array<MediaSequenceAssetMutationResponse>;
}

/**
 * Check if a given object implements the MediaSequenceAssetResponse interface.
 */
export function instanceOfMediaSequenceAssetResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lastModifiedUtc" in value;
    isInstance = isInstance && "createdUtc" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "mediaSequenceId" in value;
    isInstance = isInstance && "asset" in value;
    isInstance = isInstance && "order" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "templatePosition" in value;
    isInstance = isInstance && "isTemplate" in value;
    isInstance = isInstance && "isCopy" in value;
    isInstance = isInstance && "mutations" in value;

    return isInstance;
}

export function MediaSequenceAssetResponseFromJSON(json: any): MediaSequenceAssetResponse {
    return MediaSequenceAssetResponseFromJSONTyped(json, false);
}

export function MediaSequenceAssetResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MediaSequenceAssetResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastModifiedUtc': (new Date(json['lastModifiedUtc'])),
        'createdUtc': (new Date(json['createdUtc'])),
        'id': json['id'],
        'name': json['name'],
        'mediaSequenceId': json['mediaSequenceId'],
        'asset': AssetSimpleResponseFromJSON(json['asset']),
        'order': json['order'],
        'description': json['description'],
        'templatePosition': ETemplatePositionFromJSON(json['templatePosition']),
        'isTemplate': json['isTemplate'],
        'isCopy': json['isCopy'],
        'mutations': ((json['mutations'] as Array<any>).map(MediaSequenceAssetMutationResponseFromJSON)),
    };
}

export function MediaSequenceAssetResponseToJSON(value?: MediaSequenceAssetResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lastModifiedUtc': (value.lastModifiedUtc.toISOString()),
        'createdUtc': (value.createdUtc.toISOString()),
        'id': value.id,
        'name': value.name,
        'mediaSequenceId': value.mediaSequenceId,
        'asset': AssetSimpleResponseToJSON(value.asset),
        'order': value.order,
        'description': value.description,
        'templatePosition': ETemplatePositionToJSON(value.templatePosition),
        'isTemplate': value.isTemplate,
        'isCopy': value.isCopy,
        'mutations': ((value.mutations as Array<any>).map(MediaSequenceAssetMutationResponseToJSON)),
    };
}

