import {
  RoleResponse,
  UserCreateRequest,
  UserResponse,
  UserUpdateRequest,
} from 'api/core';
import { useCreateUser, useUpdateUser } from 'api/useUsersApi';
import { EntitySelectMultiple } from 'components/Select/EntitySelectMultiple';
import { useForm } from 'react-hook-form';

interface UserFormProps {
  targetUser?: UserResponse;
  allRoles: RoleResponse[];
  onSuccess?: () => void;
  onCancel?: () => void;
  inDialog?: boolean;
}

export const UserForm = ({
  targetUser,
  allRoles,
  onSuccess,
  onCancel,
  inDialog,
}: UserFormProps) => {
  const { mutateAsync: createAsync, isPending: isPendingCreate } =
    useCreateUser();
  const { mutateAsync: updateAsync, isPending: isPendingUpdate } =
    useUpdateUser();

  const { register, handleSubmit, setValue, watch } = useForm<
    UserCreateRequest | UserUpdateRequest
  >({
    defaultValues: {
      roleIds: targetUser?.roles.map((role) => role.id),
      ...targetUser,
      sendWelcomeEmail: true,
    },
  });

  const sendWelcomeMail = watch('sendWelcomeEmail');

  const onSubmit = handleSubmit(async (result) => {
    if (targetUser) {
      await updateAsync({
        id: targetUser.id,
        userUpdateRequest: result,
      });
    } else {
      await createAsync({
        userCreateRequest: { ...result, sendWelcomeEmail: sendWelcomeMail },
      });
    }
    onSuccess?.();
  });

  const onRolesSelected = (roles: RoleResponse[] | null) => {
    const roleIds = roles?.map((asset) => asset.id) ?? [];
    setValue('roleIds', roleIds);
  };

  return (
    <form onSubmit={onSubmit} className="space-y-2">
      <div className="form-control">
        <label className="label">
          <span className="label-text">Navn</span>
        </label>
        <input
          {...register('name', { required: true })}
          type="text"
          className="input input-bordered"
        />
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">Titel</span>
        </label>
        <input
          {...register('title')}
          type="text"
          className="input input-bordered"
        />
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">Email</span>
        </label>
        <input
          {...register('email', { required: true })}
          type="text"
          className="input input-bordered"
        />
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">Roller</span>
        </label>
        <EntitySelectMultiple<RoleResponse>
          data={allRoles}
          renderFormat={(asset) => <p>{asset.title}</p>}
          onSelect={onRolesSelected}
          initialValue={targetUser?.roles ?? []}
          searchPropertyKey="title"
          inDialog={inDialog}
        />
        <input
          {...register('roleIds', { required: true })}
          type="text"
          className="hidden"
        />
      </div>

      {!targetUser ? (
        <div className="form-control items-start">
          <label className="cursor-pointer label">
            <span className="label-text me-2">Send velkomst mail</span>
            <input
              {...register('sendWelcomeEmail')}
              type="checkbox"
              className="toggle toggle-primary"
            />
          </label>
        </div>
      ) : null}

      <div className="flex justify-center space-x-4 pt-4">
        <button
          className="btn btn-primary"
          disabled={isPendingCreate || isPendingUpdate}
        >
          {targetUser ? 'Opdater' : 'Opret'}
        </button>
        <button type="button" className="btn" onClick={onCancel}>
          Annuller
        </button>
      </div>
    </form>
  );
};
