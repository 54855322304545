/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AuthorizationRequest,
  ChangePasswordRequest,
  LoginResponse,
  ResetPageThemeResponse,
  ResetPasswordRequest,
  ResetPasswordUsingKeyRequest,
} from '../models';
import {
    AuthorizationRequestFromJSON,
    AuthorizationRequestToJSON,
    ChangePasswordRequestFromJSON,
    ChangePasswordRequestToJSON,
    LoginResponseFromJSON,
    LoginResponseToJSON,
    ResetPageThemeResponseFromJSON,
    ResetPageThemeResponseToJSON,
    ResetPasswordRequestFromJSON,
    ResetPasswordRequestToJSON,
    ResetPasswordUsingKeyRequestFromJSON,
    ResetPasswordUsingKeyRequestToJSON,
} from '../models';

export interface AccountAuthenticatePostRequest {
    authorizationRequest?: AuthorizationRequest;
}

export interface AccountChangePasswordPostRequest {
    changePasswordRequest?: ChangePasswordRequest;
}

export interface AccountForgottenPasswordRequestPostRequest {
    resetPasswordRequest?: ResetPasswordRequest;
}

export interface AccountForgottenPasswordResetPostRequest {
    resetPasswordUsingKeyRequest?: ResetPasswordUsingKeyRequest;
}

export interface AccountForgottenPasswordThemeGetRequest {
    resetKey?: string;
}

export interface AccountJoinOrganizationPutRequest {
    organizationId: string;
}

/**
 * 
 */
export class AccountApi extends runtime.BaseAPI {

    /**
     */
    async accountAuthenticatePostRaw(requestParameters: AccountAuthenticatePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Account/authenticate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthorizationRequestToJSON(requestParameters.authorizationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseFromJSON(jsonValue));
    }

    /**
     */
    async accountAuthenticatePost(requestParameters: AccountAuthenticatePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginResponse> {
        const response = await this.accountAuthenticatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async accountChangePasswordPostRaw(requestParameters: AccountChangePasswordPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Account/change-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangePasswordRequestToJSON(requestParameters.changePasswordRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async accountChangePasswordPost(requestParameters: AccountChangePasswordPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.accountChangePasswordPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async accountForgottenPasswordRequestPostRaw(requestParameters: AccountForgottenPasswordRequestPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Account/forgotten-password/request`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordRequestToJSON(requestParameters.resetPasswordRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async accountForgottenPasswordRequestPost(requestParameters: AccountForgottenPasswordRequestPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.accountForgottenPasswordRequestPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async accountForgottenPasswordResetPostRaw(requestParameters: AccountForgottenPasswordResetPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Account/forgotten-password/reset`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordUsingKeyRequestToJSON(requestParameters.resetPasswordUsingKeyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseFromJSON(jsonValue));
    }

    /**
     */
    async accountForgottenPasswordResetPost(requestParameters: AccountForgottenPasswordResetPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginResponse> {
        const response = await this.accountForgottenPasswordResetPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async accountForgottenPasswordThemeGetRaw(requestParameters: AccountForgottenPasswordThemeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResetPageThemeResponse>> {
        const queryParameters: any = {};

        if (requestParameters.resetKey !== undefined) {
            queryParameters['resetKey'] = requestParameters.resetKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Account/forgotten-password/theme`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResetPageThemeResponseFromJSON(jsonValue));
    }

    /**
     */
    async accountForgottenPasswordThemeGet(requestParameters: AccountForgottenPasswordThemeGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResetPageThemeResponse> {
        const response = await this.accountForgottenPasswordThemeGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async accountJoinOrganizationPutRaw(requestParameters: AccountJoinOrganizationPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling accountJoinOrganizationPut.');
        }

        const queryParameters: any = {};

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Account/join-organization`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseFromJSON(jsonValue));
    }

    /**
     */
    async accountJoinOrganizationPut(requestParameters: AccountJoinOrganizationPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginResponse> {
        const response = await this.accountJoinOrganizationPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async accountLogoutPostRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Account/logout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async accountLogoutPost(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.accountLogoutPostRaw(initOverrides);
    }

    /**
     */
    async accountMeGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Account/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseFromJSON(jsonValue));
    }

    /**
     */
    async accountMeGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginResponse> {
        const response = await this.accountMeGetRaw(initOverrides);
        return await response.value();
    }

}
