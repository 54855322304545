/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EPermission } from './EPermission';
import {
    EPermissionFromJSON,
    EPermissionFromJSONTyped,
    EPermissionToJSON,
} from './EPermission';
import type { VisualIdentityResponse } from './VisualIdentityResponse';
import {
    VisualIdentityResponseFromJSON,
    VisualIdentityResponseFromJSONTyped,
    VisualIdentityResponseToJSON,
} from './VisualIdentityResponse';

/**
 * 
 * @export
 * @interface OrganizationResponse
 */
export interface OrganizationResponse {
    /**
     * 
     * @type {Date}
     * @memberof OrganizationResponse
     */
    lastModifiedUtc: Date;
    /**
     * 
     * @type {Date}
     * @memberof OrganizationResponse
     */
    createdUtc: Date;
    /**
     * 
     * @type {string}
     * @memberof OrganizationResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationResponse
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationResponse
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationResponse
     */
    website: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationResponse
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationResponse
     */
    cvr: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationResponse
     */
    zipCode: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationResponse
     */
    email: string;
    /**
     * 
     * @type {VisualIdentityResponse}
     * @memberof OrganizationResponse
     */
    visualIdentity: VisualIdentityResponse;
    /**
     * 
     * @type {string}
     * @memberof OrganizationResponse
     */
    parentOrganizationId: string;
    /**
     * 
     * @type {Array<EPermission>}
     * @memberof OrganizationResponse
     */
    permissions: Array<EPermission>;
}

/**
 * Check if a given object implements the OrganizationResponse interface.
 */
export function instanceOfOrganizationResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lastModifiedUtc" in value;
    isInstance = isInstance && "createdUtc" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "phone" in value;
    isInstance = isInstance && "website" in value;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "cvr" in value;
    isInstance = isInstance && "zipCode" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "visualIdentity" in value;
    isInstance = isInstance && "parentOrganizationId" in value;
    isInstance = isInstance && "permissions" in value;

    return isInstance;
}

export function OrganizationResponseFromJSON(json: any): OrganizationResponse {
    return OrganizationResponseFromJSONTyped(json, false);
}

export function OrganizationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastModifiedUtc': (new Date(json['lastModifiedUtc'])),
        'createdUtc': (new Date(json['createdUtc'])),
        'id': json['id'],
        'name': json['name'],
        'address': json['address'],
        'phone': json['phone'],
        'website': json['website'],
        'city': json['city'],
        'cvr': json['cvr'],
        'zipCode': json['zipCode'],
        'email': json['email'],
        'visualIdentity': VisualIdentityResponseFromJSON(json['visualIdentity']),
        'parentOrganizationId': json['parentOrganizationId'],
        'permissions': ((json['permissions'] as Array<any>).map(EPermissionFromJSON)),
    };
}

export function OrganizationResponseToJSON(value?: OrganizationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lastModifiedUtc': (value.lastModifiedUtc.toISOString()),
        'createdUtc': (value.createdUtc.toISOString()),
        'id': value.id,
        'name': value.name,
        'address': value.address,
        'phone': value.phone,
        'website': value.website,
        'city': value.city,
        'cvr': value.cvr,
        'zipCode': value.zipCode,
        'email': value.email,
        'visualIdentity': VisualIdentityResponseToJSON(value.visualIdentity),
        'parentOrganizationId': value.parentOrganizationId,
        'permissions': ((value.permissions as Array<any>).map(EPermissionToJSON)),
    };
}

