import { useGetJoinableOrganizations } from 'api/useOrganizationsApi';
import { useAuth } from 'auth/AuthProvider';
import { ControlledModal } from 'components/ControlledModal';
import { useState } from 'react';
import { JoinOrganizationForm } from './JoinOrganizationForm';
import { buildQueryState } from 'components/Table/useTableQueryState';
import { OrganizationSimpleResponse } from 'api/core';

interface JoinOrganizationProps {
  trigger?: React.ReactNode;
  isInitialOpen?: boolean;
  onClosed?: () => void;
}

export const JoinOrganization = ({
  trigger,
  isInitialOpen,
  onClosed,
}: JoinOrganizationProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(isInitialOpen ?? false);

  const { user } = useAuth();

  const { data: joinableOrganizations } = useGetJoinableOrganizations(
    buildQueryState<OrganizationSimpleResponse>({
      pagination: {
        pageIndex: 0,
        pageSize: 100,
      },
      sorting: [
        {
          id: 'name',
          desc: false,
        },
      ],
    })
  );

  const onModalStateChange = (state: boolean) => {
    setIsOpen(state);
    if (!state) {
      onClosed?.();
    }
  };

  if (!user || !joinableOrganizations || joinableOrganizations.data.length < 2)
    return null;

  return (
    <>
      {trigger ? (
        <div onClick={() => onModalStateChange(true)}>{trigger}</div>
      ) : null}
      <ControlledModal
        showModal={onModalStateChange}
        isOpen={isOpen}
        title="Skift butik"
        closeOnOutsideClick
        hideActionBar
      >
        <JoinOrganizationForm
          user={user}
          allOrganizations={joinableOrganizations.data}
          onSuccess={() => onModalStateChange(false)}
          onCancel={() => onModalStateChange(false)}
          inDialog={true}
        />
      </ControlledModal>
    </>
  );
};
