import { EPermission, EStatTypeAction, StatResponse } from 'api/core';
import { PermissionProtectedComponent } from 'auth/PermissionProtectedComponent';
import { AnimatedIcon, AnimatedIconKey } from 'components/Icon/AnimatedIcon';
import { MediaSequenceCreate } from 'pages/MediaOrbit/components/MediaSequence/MediaSequenceCreate';
import { SmartTextCreate } from 'pages/SmartText/SmartTextCreate';
import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { GridItem } from './GridItem';

export const StatGrid = ({ stats }: { stats: StatResponse[] }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-4 4xl:grid-cols-6 gap-6">
      {stats.map((stat, i) => (
        <StatCard
          key={i}
          title={stat.title}
          description={stat.description}
          value={stat.value}
          link={stat.link}
          iconKey={stat.iconKey as AnimatedIconKey}
          actionRequired={stat.actionRequired}
          actionType={stat.actionType}
          actionPermissions={stat.actionPermissions ?? []}
        />
      ))}
    </div>
  );
};

interface StatCardProps {
  title: string;
  description: string;
  value: number;
  iconKey: AnimatedIconKey;
  link: string;
  actionRequired?: boolean;
  actionType?: EStatTypeAction;
  actionPermissions: EPermission[];
}

const StatCard = ({
  title,
  description,
  value,
  iconKey,
  link,
  actionRequired,
  actionType,
  actionPermissions,
}: StatCardProps) => {
  return (
    <>
      <NavLink to={link} className="py-1">
        <GridItem actionRequired={actionRequired}>
          <div className="stat-figure text-primary flex justify-center">
            <AnimatedIcon icon={iconKey} className="w-10 h-10" />
          </div>
          <div className="stat-title mt-1 text-center">{title}</div>
          <div className="stat-value text-primary text-center">{value}</div>
          <div className="stat-desc mb-1 text-center truncate">
            {description}
          </div>
        </GridItem>
      </NavLink>
      {actionType ? (
        <div className="block md:hidden">
          <PermissionProtectedComponent permissions={actionPermissions}>
            {actionType === EStatTypeAction.CreateMediaSequence ? (
              <MediaSequenceCreate
                trigger={<ActionButton>Opret Smart Video</ActionButton>}
              />
            ) : null}
            {actionType === EStatTypeAction.CreateText ? (
              <SmartTextCreate
                trigger={<ActionButton>Opret Smart Text</ActionButton>}
              />
            ) : null}
          </PermissionProtectedComponent>
        </div>
      ) : null}
    </>
  );
};

const ActionButton = ({ children }: { children: ReactNode }) => {
  return <button className="btn btn-primary w-full">{children}</button>;
};
