import {
  useGetLanguage,
  useGetLanguageTermConfigurations,
  useGetLanguageTerms,
} from 'api/useTranslationsApi';
import { ErrorComponent } from 'components/Error';
import { Page } from 'layouts/Admin/Page';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { TermsTable, Translation } from './components/TermsTable';

export const LanguageView = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const { data: language, isError: isErrorLanguage } = useGetLanguage(
    id ? +id : undefined
  );

  const {
    data: terms,
    isError: isErrorTerms,
    isFetching: isFetchingTerms,
  } = useGetLanguageTerms(id ? +id : undefined);

  const { data: termConfigurations, isError: isErrorTermConfigurations } =
    useGetLanguageTermConfigurations();

  if (
    isErrorLanguage ||
    isErrorTerms ||
    isErrorTermConfigurations ||
    !language ||
    !terms ||
    !termConfigurations
  ) {
    return <ErrorComponent />;
  }

  const translations: Translation[] = [];
  for (const [key, value] of Object.entries(terms)) {
    translations.push({
      term: key,
      value: value || '',
      originalValue: value || '',
      fallback: t(key),
      multilineSupport:
        termConfigurations.find((c) => c.term == key)?.isMultiline || false,
    });
  }
  translations.sort((a, b) => a.term.localeCompare(b.term));

  return (
    <Page
      title={language.title ?? ''}
      breadcrumbs={[{ name: t('languages') }, { name: language.title ?? '' }]}
    >
      <TermsTable data={translations} isFetching={isFetchingTerms} />
    </Page>
  );
};
