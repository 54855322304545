/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EEntityState } from './EEntityState';
import {
    EEntityStateFromJSON,
    EEntityStateFromJSONTyped,
    EEntityStateToJSON,
} from './EEntityState';

/**
 * 
 * @export
 * @interface MediaSequenceCreateRequest
 */
export interface MediaSequenceCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof MediaSequenceCreateRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MediaSequenceCreateRequest
     */
    dawaAddressId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaSequenceCreateRequest
     */
    caseId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaSequenceCreateRequest
     */
    metaMappingUserId?: string | null;
    /**
     * 
     * @type {EEntityState}
     * @memberof MediaSequenceCreateRequest
     */
    entityState: EEntityState;
}

/**
 * Check if a given object implements the MediaSequenceCreateRequest interface.
 */
export function instanceOfMediaSequenceCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "entityState" in value;

    return isInstance;
}

export function MediaSequenceCreateRequestFromJSON(json: any): MediaSequenceCreateRequest {
    return MediaSequenceCreateRequestFromJSONTyped(json, false);
}

export function MediaSequenceCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MediaSequenceCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'dawaAddressId': !exists(json, 'dawaAddressId') ? undefined : json['dawaAddressId'],
        'caseId': !exists(json, 'caseId') ? undefined : json['caseId'],
        'metaMappingUserId': !exists(json, 'metaMappingUserId') ? undefined : json['metaMappingUserId'],
        'entityState': EEntityStateFromJSON(json['entityState']),
    };
}

export function MediaSequenceCreateRequestToJSON(value?: MediaSequenceCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'dawaAddressId': value.dawaAddressId,
        'caseId': value.caseId,
        'metaMappingUserId': value.metaMappingUserId,
        'entityState': EEntityStateToJSON(value.entityState),
    };
}

