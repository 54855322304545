/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EMediaSequenceType = {
    Normal: 'Normal',
    Template: 'Template',
    StrictTemplate: 'StrictTemplate',
    Strict: 'Strict'
} as const;
export type EMediaSequenceType = typeof EMediaSequenceType[keyof typeof EMediaSequenceType];


export function EMediaSequenceTypeFromJSON(json: any): EMediaSequenceType {
    return EMediaSequenceTypeFromJSONTyped(json, false);
}

export function EMediaSequenceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EMediaSequenceType {
    return json as EMediaSequenceType;
}

export function EMediaSequenceTypeToJSON(value?: EMediaSequenceType | null): any {
    return value as any;
}

