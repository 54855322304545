/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ECaseState } from './ECaseState';
import {
    ECaseStateFromJSON,
    ECaseStateFromJSONTyped,
    ECaseStateToJSON,
} from './ECaseState';

/**
 * 
 * @export
 * @interface ECaseStateInt32KeyValueItem
 */
export interface ECaseStateInt32KeyValueItem {
    /**
     * 
     * @type {ECaseState}
     * @memberof ECaseStateInt32KeyValueItem
     */
    key: ECaseState;
    /**
     * 
     * @type {number}
     * @memberof ECaseStateInt32KeyValueItem
     */
    value: number;
}

/**
 * Check if a given object implements the ECaseStateInt32KeyValueItem interface.
 */
export function instanceOfECaseStateInt32KeyValueItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "key" in value;
    isInstance = isInstance && "value" in value;

    return isInstance;
}

export function ECaseStateInt32KeyValueItemFromJSON(json: any): ECaseStateInt32KeyValueItem {
    return ECaseStateInt32KeyValueItemFromJSONTyped(json, false);
}

export function ECaseStateInt32KeyValueItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ECaseStateInt32KeyValueItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': ECaseStateFromJSON(json['key']),
        'value': json['value'],
    };
}

export function ECaseStateInt32KeyValueItemToJSON(value?: ECaseStateInt32KeyValueItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': ECaseStateToJSON(value.key),
        'value': value.value,
    };
}

