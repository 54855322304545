/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PublicationCreateRequest,
  PublicationResponse,
  PublicationResponsePagedData,
  PublicationUpdateRequest,
} from '../models';
import {
    PublicationCreateRequestFromJSON,
    PublicationCreateRequestToJSON,
    PublicationResponseFromJSON,
    PublicationResponseToJSON,
    PublicationResponsePagedDataFromJSON,
    PublicationResponsePagedDataToJSON,
    PublicationUpdateRequestFromJSON,
    PublicationUpdateRequestToJSON,
} from '../models';

export interface PublicationsDateRangeGetRequest {
    startDate?: Date;
    endDate?: Date;
}

export interface PublicationsGetRequest {
    pageNumber?: number;
    size?: number;
    orderByProperty?: string;
    ascending?: boolean;
    searchTerm?: string;
}

export interface PublicationsIdAssetsPutRequest {
    id: string;
    requestBody?: Array<string>;
}

export interface PublicationsIdDeleteRequest {
    id: string;
}

export interface PublicationsIdGetRequest {
    id: string;
}

export interface PublicationsIdInvokePutRequest {
    id: string;
}

export interface PublicationsIdPutRequest {
    id: string;
    publicationUpdateRequest?: PublicationUpdateRequest;
}

export interface PublicationsLastViewedGetRequest {
    amount?: number;
}

export interface PublicationsPostRequest {
    publicationCreateRequest?: PublicationCreateRequest;
}

/**
 * 
 */
export class PublicationsApi extends runtime.BaseAPI {

    /**
     */
    async publicationsDateRangeGetRaw(requestParameters: PublicationsDateRangeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PublicationResponse>>> {
        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Publications/date-range`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PublicationResponseFromJSON));
    }

    /**
     */
    async publicationsDateRangeGet(requestParameters: PublicationsDateRangeGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PublicationResponse>> {
        const response = await this.publicationsDateRangeGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async publicationsGetRaw(requestParameters: PublicationsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublicationResponsePagedData>> {
        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.orderByProperty !== undefined) {
            queryParameters['orderByProperty'] = requestParameters.orderByProperty;
        }

        if (requestParameters.ascending !== undefined) {
            queryParameters['ascending'] = requestParameters.ascending;
        }

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['searchTerm'] = requestParameters.searchTerm;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Publications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublicationResponsePagedDataFromJSON(jsonValue));
    }

    /**
     */
    async publicationsGet(requestParameters: PublicationsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublicationResponsePagedData> {
        const response = await this.publicationsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async publicationsIdAssetsPutRaw(requestParameters: PublicationsIdAssetsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublicationResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling publicationsIdAssetsPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Publications/{id}/assets`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublicationResponseFromJSON(jsonValue));
    }

    /**
     */
    async publicationsIdAssetsPut(requestParameters: PublicationsIdAssetsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublicationResponse> {
        const response = await this.publicationsIdAssetsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async publicationsIdDeleteRaw(requestParameters: PublicationsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublicationResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling publicationsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Publications/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublicationResponseFromJSON(jsonValue));
    }

    /**
     */
    async publicationsIdDelete(requestParameters: PublicationsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublicationResponse> {
        const response = await this.publicationsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async publicationsIdGetRaw(requestParameters: PublicationsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublicationResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling publicationsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Publications/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublicationResponseFromJSON(jsonValue));
    }

    /**
     */
    async publicationsIdGet(requestParameters: PublicationsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublicationResponse> {
        const response = await this.publicationsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async publicationsIdInvokePutRaw(requestParameters: PublicationsIdInvokePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublicationResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling publicationsIdInvokePut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Publications/{id}/Invoke`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublicationResponseFromJSON(jsonValue));
    }

    /**
     */
    async publicationsIdInvokePut(requestParameters: PublicationsIdInvokePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublicationResponse> {
        const response = await this.publicationsIdInvokePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async publicationsIdPutRaw(requestParameters: PublicationsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublicationResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling publicationsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Publications/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PublicationUpdateRequestToJSON(requestParameters.publicationUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublicationResponseFromJSON(jsonValue));
    }

    /**
     */
    async publicationsIdPut(requestParameters: PublicationsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublicationResponse> {
        const response = await this.publicationsIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async publicationsLastViewedGetRaw(requestParameters: PublicationsLastViewedGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PublicationResponse>>> {
        const queryParameters: any = {};

        if (requestParameters.amount !== undefined) {
            queryParameters['amount'] = requestParameters.amount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Publications/last-viewed`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PublicationResponseFromJSON));
    }

    /**
     */
    async publicationsLastViewedGet(requestParameters: PublicationsLastViewedGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PublicationResponse>> {
        const response = await this.publicationsLastViewedGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async publicationsPostRaw(requestParameters: PublicationsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublicationResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Publications`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PublicationCreateRequestToJSON(requestParameters.publicationCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublicationResponseFromJSON(jsonValue));
    }

    /**
     */
    async publicationsPost(requestParameters: PublicationsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublicationResponse> {
        const response = await this.publicationsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
