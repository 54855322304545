import { useGetAssets } from 'api/useAssetsApi';
import { useTableQueryState } from 'components/Table/useTableQueryState';
import { Page } from 'layouts/Admin/Page';
import { useAuth } from 'auth/AuthProvider';
import { AssetsTable } from 'pages/Project/components/Asset/AssetsTable';
import { AssetUpload } from 'components/Form/AssetUpload';
import { useRef } from 'react';
import { PermissionProtectedComponent } from 'auth/PermissionProtectedComponent';
import { EPermission } from 'api/core';

export const OrganizationAssets = () => {
  const { user } = useAuth();
  const { state } = useTableQueryState();
  const { data: assets, isFetching } = useGetAssets(undefined, state);

  const fileInputRef = useRef<HTMLInputElement>(null);

  if (!user) return null;

  return (
    <Page
      title={user.organization.name}
      description={`Denne side viser alle filer der er tilknyttet ${user.organization.name}.`}
      breadcrumbs={[{ name: user.organization.name }, { name: 'Alle filer' }]}
    >
      <PermissionProtectedComponent permissions={[EPermission.AssetWrite]}>
        <div className="flex justify-start py-4 w-full">
          <AssetUpload
            fileInputRef={fileInputRef}
            allowMultiple
            enableAddressNaming
          />
          <button
            className="btn btn-primary"
            onClick={() => fileInputRef.current?.click()}
          >
            Upload fil
          </button>
        </div>
      </PermissionProtectedComponent>

      <AssetsTable
        data={assets}
        isFetching={isFetching}
        queryState={state}
        hideProjectColumn
      />
    </Page>
  );
};
