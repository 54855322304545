import { useMutation, useQuery } from '@tanstack/react-query';

import { TableQueryState } from 'components/Table/useTableQueryState';
import { queryClient } from 'query-client';
import { toast } from 'react-toastify';
import { CoreBaseConfiguration } from './BaseConfiguration';
import { FieldCreateRequest, FieldsApi, FieldUpdateRequest } from './core';

const FieldApiKeys = {
  GET_FIELDS: 'fields',
  GET_FIELD: 'field',
};

const client = new FieldsApi(new CoreBaseConfiguration());

export const useGetFields = (projectId?: string, state?: TableQueryState) => {
  return useQuery({
    queryKey: [FieldApiKeys.GET_FIELDS, projectId, state],
    queryFn: () =>
      client.fieldsGet({
        projectId,
        pageNumber: state?.pagination.pageIndex,
        size: state?.pagination.pageSize,
        orderByProperty: state?.sorting[0]?.id || 'order',
        ascending: !state?.sorting[0]
          ? true
          : state?.sorting[0]?.desc === false,
        searchTerm: state?.globalFilter,
      }),
    enabled: projectId !== undefined,
  });
};

export const useGetField = (id?: string) => {
  return useQuery({
    queryKey: [FieldApiKeys.GET_FIELD, id],
    queryFn: () => client.fieldsIdGet({ id: id ?? '' }),
    enabled: id !== undefined,
  });
};

export const useCreateField = () => {
  return useMutation({
    mutationFn: ({
      projectId,
      fieldCreateRequest,
    }: {
      projectId: string;
      fieldCreateRequest: FieldCreateRequest;
    }) => client.fieldsProjectIdPost({ projectId, fieldCreateRequest }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [FieldApiKeys.GET_FIELDS],
      });
      toast.success(`Feltet '${e.name}' er blevet oprettet`, {
        toastId: 'createField',
      });
    },
  });
};

export const useUpdateField = () => {
  return useMutation({
    mutationFn: ({
      id,
      fieldUpdateRequest,
    }: {
      id: string;
      fieldUpdateRequest: FieldUpdateRequest;
    }) => client.fieldsIdPut({ id, fieldUpdateRequest }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [FieldApiKeys.GET_FIELDS],
      });
      queryClient.invalidateQueries({
        queryKey: [FieldApiKeys.GET_FIELD, e.id],
      });
      toast.success(`Feltet '${e.name}' er blevet opdateret`, {
        toastId: 'updateField',
      });
    },
  });
};

export const useDeleteField = () => {
  return useMutation({
    mutationFn: ({ id }: { id: string }) => client.fieldsIdDelete({ id }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [FieldApiKeys.GET_FIELDS],
      });
      queryClient.invalidateQueries({
        queryKey: [FieldApiKeys.GET_FIELD, e.id],
      });
      toast.success(`Feltet '${e.name}' er blevet slettet`, {
        toastId: 'deleteField',
      });
    },
  });
};
