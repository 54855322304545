/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EPublicationDestinationState } from './EPublicationDestinationState';
import {
    EPublicationDestinationStateFromJSON,
    EPublicationDestinationStateFromJSONTyped,
    EPublicationDestinationStateToJSON,
} from './EPublicationDestinationState';
import type { EPublicationPlatform } from './EPublicationPlatform';
import {
    EPublicationPlatformFromJSON,
    EPublicationPlatformFromJSONTyped,
    EPublicationPlatformToJSON,
} from './EPublicationPlatform';

/**
 * 
 * @export
 * @interface PublicationDestinationResponse
 */
export interface PublicationDestinationResponse {
    /**
     * 
     * @type {Date}
     * @memberof PublicationDestinationResponse
     */
    lastModifiedUtc: Date;
    /**
     * 
     * @type {Date}
     * @memberof PublicationDestinationResponse
     */
    createdUtc: Date;
    /**
     * 
     * @type {string}
     * @memberof PublicationDestinationResponse
     */
    id: string;
    /**
     * 
     * @type {EPublicationDestinationState}
     * @memberof PublicationDestinationResponse
     */
    state: EPublicationDestinationState;
    /**
     * 
     * @type {EPublicationPlatform}
     * @memberof PublicationDestinationResponse
     */
    platform: EPublicationPlatform;
    /**
     * 
     * @type {Date}
     * @memberof PublicationDestinationResponse
     */
    publishedUtc: Date;
}

/**
 * Check if a given object implements the PublicationDestinationResponse interface.
 */
export function instanceOfPublicationDestinationResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lastModifiedUtc" in value;
    isInstance = isInstance && "createdUtc" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "platform" in value;
    isInstance = isInstance && "publishedUtc" in value;

    return isInstance;
}

export function PublicationDestinationResponseFromJSON(json: any): PublicationDestinationResponse {
    return PublicationDestinationResponseFromJSONTyped(json, false);
}

export function PublicationDestinationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublicationDestinationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastModifiedUtc': (new Date(json['lastModifiedUtc'])),
        'createdUtc': (new Date(json['createdUtc'])),
        'id': json['id'],
        'state': EPublicationDestinationStateFromJSON(json['state']),
        'platform': EPublicationPlatformFromJSON(json['platform']),
        'publishedUtc': (new Date(json['publishedUtc'])),
    };
}

export function PublicationDestinationResponseToJSON(value?: PublicationDestinationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lastModifiedUtc': (value.lastModifiedUtc.toISOString()),
        'createdUtc': (value.createdUtc.toISOString()),
        'id': value.id,
        'state': EPublicationDestinationStateToJSON(value.state),
        'platform': EPublicationPlatformToJSON(value.platform),
        'publishedUtc': (value.publishedUtc.toISOString()),
    };
}

