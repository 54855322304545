import {
  MediaSequenceAssetMutationCreateRequest,
  MediaSequenceAssetMutationUpdateRequest,
  MediaSequenceAssetResponse,
} from 'api/core';
import { ColorAdjustmentsPreview } from 'components/Image/ColorAdjustmentsPreview';
import { ListRestartIcon } from 'lucide-react';
import { useFormContext } from 'react-hook-form';

interface ColorAdjustmentsProps {
  asset: MediaSequenceAssetResponse;
}

export const ColorAdjustments = ({ asset }: ColorAdjustmentsProps) => {
  const {
    register,
    watch,
    setValue,
    formState: { disabled },
  } = useFormContext<
    | MediaSequenceAssetMutationCreateRequest
    | MediaSequenceAssetMutationUpdateRequest
  >();

  return (
    <>
      <ColorAdjustmentsPreview
        asset={asset}
        brightness={watch('colorAdjustments.brightness')}
        contrast={watch('colorAdjustments.contrast')}
        saturation={watch('colorAdjustments.saturation')}
      />
      <div className="form-control">
        <label className="label">
          <div className="flex items-center">
            <span className="label-text">
              Lysstyrke ({watch('colorAdjustments.brightness')})
            </span>
            {watch('colorAdjustments.brightness') !== 0 && !disabled ? (
              <ListRestartIcon
                className="ml-2 cursor-pointer"
                onClick={() => setValue('colorAdjustments.brightness', 0)}
              />
            ) : null}
          </div>
        </label>
        <input
          {...register('colorAdjustments.brightness', {
            required: true,
          })}
          type="range"
          defaultValue={0}
          min={-0.1}
          max={0.1}
          step={0.01}
          className="range range-primary"
        />
      </div>
      <div className="form-control">
        <label className="label">
          <div className="flex items-center">
            <span className="label-text">
              Kontrast ({watch('colorAdjustments.contrast')})
            </span>
            {watch('colorAdjustments.contrast') !== 1 && !disabled ? (
              <ListRestartIcon
                className="ml-2 cursor-pointer"
                onClick={() => setValue('colorAdjustments.contrast', 1)}
              />
            ) : null}
          </div>
        </label>
        <input
          {...register('colorAdjustments.contrast', {
            required: true,
          })}
          type="range"
          defaultValue={1}
          min={0}
          max={2}
          step={0.1}
          className="range range-primary"
        />
      </div>
      <div className="form-control">
        <label className="label">
          <div className="flex items-center">
            <span className="label-text">
              Mætning ({watch('colorAdjustments.saturation')})
            </span>
            {watch('colorAdjustments.saturation') !== 1 && !disabled ? (
              <ListRestartIcon
                className="ml-2 cursor-pointer"
                onClick={() => setValue('colorAdjustments.saturation', 1)}
              />
            ) : null}
          </div>
        </label>
        <input
          {...register('colorAdjustments.saturation', {
            required: true,
          })}
          type="range"
          defaultValue={1}
          min={0}
          max={3}
          step={0.1}
          className="range range-primary"
        />
      </div>
    </>
  );
};
