import { ControlledModal } from 'components/ControlledModal';
import { useForm } from 'react-hook-form';
import { AutocompleteAddress } from './AutocompleteAddress';

interface EnterAddressDialogProps {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  onCancel: () => void;
  onAddressConfirmed: (address: string) => void;
  fileCount: number;
}

export const EnterAddressDialog = ({
  isOpen,
  setIsOpen,
  onCancel,
  onAddressConfirmed,
  fileCount,
}: EnterAddressDialogProps) => {
  const { register, handleSubmit, watch, setValue } = useForm<{
    address: string;
  }>({
    defaultValues: {
      address: '',
    },
  });

  const onModalStateChange = (state: boolean) => {
    setIsOpen(state);
    if (!state) {
      onCancel?.();
    }
  };

  const onSubmit = handleSubmit(() => {
    const address = watch('address')?.trim();

    if (address) {
      onAddressConfirmed(address);
    } else {
      onCancel();
    }
  });

  return (
    <ControlledModal
      showModal={onModalStateChange}
      isOpen={isOpen}
      title="Omdøb filer"
      closeOnOutsideClick={false}
      hideActionBar
    >
      <form onSubmit={onSubmit} className="space-y-2">
        <p>
          Du er ved at uploade {fileCount} fil{fileCount > 1 ? 'er' : ''}.
          Ønsker du at omdøbe filerne med en adresse? Hvis ja, indtast adressen
          herunder. Hvis nej, tryk på &quot;Fortsæt uden at omdøbe&quot;.
        </p>
        <AutocompleteAddress
          label={
            <p>
              Adresse <span className="text-red-500">*</span>
            </p>
          }
          registration={() => register('address', { required: true })}
          formWatch={watch('address') || ''}
          formSetValue={(value: string) =>
            setValue('address', value, { shouldValidate: true })
          }
          inputClassName="input input-bordered"
        />

        <div className="flex justify-center space-x-4 pt-4">
          <button type="submit" className="btn btn-primary">
            Brug adresse
          </button>

          <button type="button" className="btn" onClick={onCancel}>
            Fortsæt uden at omdøbe
          </button>
        </div>
      </form>
    </ControlledModal>
  );
};
