/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExternalIntegrationResponse } from './ExternalIntegrationResponse';
import {
    ExternalIntegrationResponseFromJSON,
    ExternalIntegrationResponseFromJSONTyped,
    ExternalIntegrationResponseToJSON,
} from './ExternalIntegrationResponse';
import type { Page } from './Page';
import {
    PageFromJSON,
    PageFromJSONTyped,
    PageToJSON,
} from './Page';

/**
 * 
 * @export
 * @interface ExternalIntegrationResponsePagedData
 */
export interface ExternalIntegrationResponsePagedData {
    /**
     * 
     * @type {Page}
     * @memberof ExternalIntegrationResponsePagedData
     */
    page: Page;
    /**
     * 
     * @type {Array<ExternalIntegrationResponse>}
     * @memberof ExternalIntegrationResponsePagedData
     */
    data: Array<ExternalIntegrationResponse>;
}

/**
 * Check if a given object implements the ExternalIntegrationResponsePagedData interface.
 */
export function instanceOfExternalIntegrationResponsePagedData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "page" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function ExternalIntegrationResponsePagedDataFromJSON(json: any): ExternalIntegrationResponsePagedData {
    return ExternalIntegrationResponsePagedDataFromJSONTyped(json, false);
}

export function ExternalIntegrationResponsePagedDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalIntegrationResponsePagedData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': PageFromJSON(json['page']),
        'data': ((json['data'] as Array<any>).map(ExternalIntegrationResponseFromJSON)),
    };
}

export function ExternalIntegrationResponsePagedDataToJSON(value?: ExternalIntegrationResponsePagedData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': PageToJSON(value.page),
        'data': ((value.data as Array<any>).map(ExternalIntegrationResponseToJSON)),
    };
}

