/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EBranchMutationType = {
    ResizeAndConcat: 'ResizeAndConcat',
    GenerateSubtitles: 'GenerateSubtitles',
    BakeSubtitles: 'BakeSubtitles',
    AddBackgroundMusic: 'AddBackgroundMusic',
    AddWatermark: 'AddWatermark',
    AudioAdjustments: 'AudioAdjustments',
    AddDynamicOverlay: 'AddDynamicOverlay'
} as const;
export type EBranchMutationType = typeof EBranchMutationType[keyof typeof EBranchMutationType];


export function EBranchMutationTypeFromJSON(json: any): EBranchMutationType {
    return EBranchMutationTypeFromJSONTyped(json, false);
}

export function EBranchMutationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EBranchMutationType {
    return json as EBranchMutationType;
}

export function EBranchMutationTypeToJSON(value?: EBranchMutationType | null): any {
    return value as any;
}

