/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddDynamicOverlayCreateRequest } from './AddDynamicOverlayCreateRequest';
import {
    AddDynamicOverlayCreateRequestFromJSON,
    AddDynamicOverlayCreateRequestFromJSONTyped,
    AddDynamicOverlayCreateRequestToJSON,
} from './AddDynamicOverlayCreateRequest';
import type { AddOverlayCreateRequest } from './AddOverlayCreateRequest';
import {
    AddOverlayCreateRequestFromJSON,
    AddOverlayCreateRequestFromJSONTyped,
    AddOverlayCreateRequestToJSON,
} from './AddOverlayCreateRequest';
import type { AddVoiceoverCreateRequest } from './AddVoiceoverCreateRequest';
import {
    AddVoiceoverCreateRequestFromJSON,
    AddVoiceoverCreateRequestFromJSONTyped,
    AddVoiceoverCreateRequestToJSON,
} from './AddVoiceoverCreateRequest';
import type { ColorAdjustmentsCreateRequest } from './ColorAdjustmentsCreateRequest';
import {
    ColorAdjustmentsCreateRequestFromJSON,
    ColorAdjustmentsCreateRequestFromJSONTyped,
    ColorAdjustmentsCreateRequestToJSON,
} from './ColorAdjustmentsCreateRequest';
import type { EAssetMutationType } from './EAssetMutationType';
import {
    EAssetMutationTypeFromJSON,
    EAssetMutationTypeFromJSONTyped,
    EAssetMutationTypeToJSON,
} from './EAssetMutationType';
import type { ImageToVideoCreateRequest } from './ImageToVideoCreateRequest';
import {
    ImageToVideoCreateRequestFromJSON,
    ImageToVideoCreateRequestFromJSONTyped,
    ImageToVideoCreateRequestToJSON,
} from './ImageToVideoCreateRequest';
import type { TrimVideoCreateRequest } from './TrimVideoCreateRequest';
import {
    TrimVideoCreateRequestFromJSON,
    TrimVideoCreateRequestFromJSONTyped,
    TrimVideoCreateRequestToJSON,
} from './TrimVideoCreateRequest';

/**
 * 
 * @export
 * @interface MediaSequenceAssetMutationUpdateRequest
 */
export interface MediaSequenceAssetMutationUpdateRequest {
    /**
     * 
     * @type {EAssetMutationType}
     * @memberof MediaSequenceAssetMutationUpdateRequest
     */
    type: EAssetMutationType;
    /**
     * 
     * @type {string}
     * @memberof MediaSequenceAssetMutationUpdateRequest
     */
    mutationOptionsJson?: string | null;
    /**
     * 
     * @type {ColorAdjustmentsCreateRequest}
     * @memberof MediaSequenceAssetMutationUpdateRequest
     */
    colorAdjustments?: ColorAdjustmentsCreateRequest;
    /**
     * 
     * @type {ImageToVideoCreateRequest}
     * @memberof MediaSequenceAssetMutationUpdateRequest
     */
    imageToVideo?: ImageToVideoCreateRequest;
    /**
     * 
     * @type {TrimVideoCreateRequest}
     * @memberof MediaSequenceAssetMutationUpdateRequest
     */
    trimVideo?: TrimVideoCreateRequest;
    /**
     * 
     * @type {AddOverlayCreateRequest}
     * @memberof MediaSequenceAssetMutationUpdateRequest
     */
    overlay?: AddOverlayCreateRequest;
    /**
     * 
     * @type {AddVoiceoverCreateRequest}
     * @memberof MediaSequenceAssetMutationUpdateRequest
     */
    voiceover?: AddVoiceoverCreateRequest;
    /**
     * 
     * @type {AddDynamicOverlayCreateRequest}
     * @memberof MediaSequenceAssetMutationUpdateRequest
     */
    addDynamicOverlay?: AddDynamicOverlayCreateRequest;
}

/**
 * Check if a given object implements the MediaSequenceAssetMutationUpdateRequest interface.
 */
export function instanceOfMediaSequenceAssetMutationUpdateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function MediaSequenceAssetMutationUpdateRequestFromJSON(json: any): MediaSequenceAssetMutationUpdateRequest {
    return MediaSequenceAssetMutationUpdateRequestFromJSONTyped(json, false);
}

export function MediaSequenceAssetMutationUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MediaSequenceAssetMutationUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': EAssetMutationTypeFromJSON(json['type']),
        'mutationOptionsJson': !exists(json, 'mutationOptionsJson') ? undefined : json['mutationOptionsJson'],
        'colorAdjustments': !exists(json, 'colorAdjustments') ? undefined : ColorAdjustmentsCreateRequestFromJSON(json['colorAdjustments']),
        'imageToVideo': !exists(json, 'imageToVideo') ? undefined : ImageToVideoCreateRequestFromJSON(json['imageToVideo']),
        'trimVideo': !exists(json, 'trimVideo') ? undefined : TrimVideoCreateRequestFromJSON(json['trimVideo']),
        'overlay': !exists(json, 'overlay') ? undefined : AddOverlayCreateRequestFromJSON(json['overlay']),
        'voiceover': !exists(json, 'voiceover') ? undefined : AddVoiceoverCreateRequestFromJSON(json['voiceover']),
        'addDynamicOverlay': !exists(json, 'addDynamicOverlay') ? undefined : AddDynamicOverlayCreateRequestFromJSON(json['addDynamicOverlay']),
    };
}

export function MediaSequenceAssetMutationUpdateRequestToJSON(value?: MediaSequenceAssetMutationUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': EAssetMutationTypeToJSON(value.type),
        'mutationOptionsJson': value.mutationOptionsJson,
        'colorAdjustments': ColorAdjustmentsCreateRequestToJSON(value.colorAdjustments),
        'imageToVideo': ImageToVideoCreateRequestToJSON(value.imageToVideo),
        'trimVideo': TrimVideoCreateRequestToJSON(value.trimVideo),
        'overlay': AddOverlayCreateRequestToJSON(value.overlay),
        'voiceover': AddVoiceoverCreateRequestToJSON(value.voiceover),
        'addDynamicOverlay': AddDynamicOverlayCreateRequestToJSON(value.addDynamicOverlay),
    };
}

