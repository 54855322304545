/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddBackgroundMusicCreateRequest
 */
export interface AddBackgroundMusicCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof AddBackgroundMusicCreateRequest
     */
    assetId: string;
    /**
     * 
     * @type {number}
     * @memberof AddBackgroundMusicCreateRequest
     */
    backgroundAudioVolume: number;
}

/**
 * Check if a given object implements the AddBackgroundMusicCreateRequest interface.
 */
export function instanceOfAddBackgroundMusicCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "assetId" in value;
    isInstance = isInstance && "backgroundAudioVolume" in value;

    return isInstance;
}

export function AddBackgroundMusicCreateRequestFromJSON(json: any): AddBackgroundMusicCreateRequest {
    return AddBackgroundMusicCreateRequestFromJSONTyped(json, false);
}

export function AddBackgroundMusicCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddBackgroundMusicCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assetId': json['assetId'],
        'backgroundAudioVolume': json['backgroundAudioVolume'],
    };
}

export function AddBackgroundMusicCreateRequestToJSON(value?: AddBackgroundMusicCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assetId': value.assetId,
        'backgroundAudioVolume': value.backgroundAudioVolume,
    };
}

