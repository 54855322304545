import { useMutation, useQuery } from '@tanstack/react-query';

import { queryClient } from 'query-client';
import { toast } from 'react-toastify';
import { CoreBaseConfiguration } from './BaseConfiguration';
import {
  MediaSequenceAssetCreateRequest,
  MediaSequenceAssetMutationCreateRequest,
  MediaSequenceAssetMutationUpdateRequest,
  MediaSequenceAssetOrderUpdateRequest,
  MediaSequenceAssetsApi,
  MediaSequenceAssetUpdateRequest,
} from './core';
import { MediaSequenceApiKeys } from './useMediaSequencesApi';

export const MediaSequenceAssetApiKeys = {
  GET_MEDIA_SEQUENCE_ASSET: 'media-sequence-asset',
  GET_MEDIA_SEQUENCE_ASSET_MUTATION: 'media-sequence-asset-mutation',
};

const client = new MediaSequenceAssetsApi(new CoreBaseConfiguration());

export const useGetMediaSequenceAsset = (id?: string) => {
  return useQuery({
    queryKey: [MediaSequenceAssetApiKeys.GET_MEDIA_SEQUENCE_ASSET, id],
    queryFn: () => client.mediaSequenceAssetsAssetIdGet({ id: id ?? '' }),
    enabled: id !== undefined,
  });
};

export const useCreateMediaSequenceAsset = () => {
  return useMutation({
    mutationFn: ({
      mediaSequenceId,
      mediaSequenceAssetCreateRequest,
    }: {
      mediaSequenceId: string;
      mediaSequenceAssetCreateRequest: MediaSequenceAssetCreateRequest;
    }) =>
      client.mediaSequenceAssetsMediaSequenceIdAssetPost({
        mediaSequenceId,
        mediaSequenceAssetCreateRequest,
      }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [
          MediaSequenceApiKeys.GET_MEDIA_SEQUENCE_ASSETS,
          e.mediaSequenceId,
        ],
      });
      toast.success(`Filen er blevet tilføjet til videoen`, {
        toastId: 'createMediaSequenceAsset',
      });
    },
  });
};

export const useUpdateMediaSequenceAsset = () => {
  return useMutation({
    mutationFn: ({
      id,
      mediaSequenceAssetUpdateRequest,
    }: {
      id: string;
      mediaSequenceAssetUpdateRequest: MediaSequenceAssetUpdateRequest;
    }) =>
      client.mediaSequenceAssetsAssetIdPut({
        id,
        mediaSequenceAssetUpdateRequest,
      }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceAssetApiKeys.GET_MEDIA_SEQUENCE_ASSET, e.id],
      });
      queryClient.invalidateQueries({
        queryKey: [
          MediaSequenceApiKeys.GET_MEDIA_SEQUENCE_ASSETS,
          e.mediaSequenceId,
        ],
      });
      toast.success(`Filen på videoen er blevet opdateret`, {
        toastId: 'updateMediaSequenceAsset',
      });
    },
  });
};

export const useUpdateMediaSequenceAssetOrder = () => {
  return useMutation({
    mutationFn: ({
      mediaSequenceAssetOrderUpdateRequest,
    }: {
      mediaSequenceAssetOrderUpdateRequest: MediaSequenceAssetOrderUpdateRequest;
    }) =>
      client.mediaSequenceAssetsAssetOrderPut({
        mediaSequenceAssetOrderUpdateRequest,
      }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [
          MediaSequenceApiKeys.GET_MEDIA_SEQUENCE_ASSETS,
          // They are all the same media sequence id
          e[0].mediaSequenceId,
        ],
      });
    },
  });
};

export const useDeleteMediaSequenceAsset = () => {
  return useMutation({
    mutationFn: ({ id }: { id: string }) =>
      client.mediaSequenceAssetsAssetIdDelete({ id }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceAssetApiKeys.GET_MEDIA_SEQUENCE_ASSET, e.id],
      });
      queryClient.invalidateQueries({
        queryKey: [
          MediaSequenceApiKeys.GET_MEDIA_SEQUENCE_ASSETS,
          e.mediaSequenceId,
        ],
      });
      toast.success(`Filen på videoen er blevet slettet`, {
        toastId: 'deleteMediaSequenceAsset',
      });
    },
  });
};

export const useGetMediaSequenceAssetMutation = (id?: string) => {
  return useQuery({
    queryKey: [MediaSequenceAssetApiKeys.GET_MEDIA_SEQUENCE_ASSET_MUTATION, id],
    queryFn: () =>
      client.mediaSequenceAssetsAssetMutationIdGet({ id: id ?? '' }),
    enabled: id !== undefined,
  });
};

export const useCreateMediaSequenceAssetMutation = () => {
  return useMutation({
    mutationFn: ({
      assetId,
      mediaSequenceAssetMutationCreateRequest,
    }: {
      assetId: string;
      mediaSequenceAssetMutationCreateRequest: MediaSequenceAssetMutationCreateRequest;
    }) =>
      client.mediaSequenceAssetsAssetAssetIdMutationPost({
        assetId,
        mediaSequenceAssetMutationCreateRequest,
      }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [
          MediaSequenceAssetApiKeys.GET_MEDIA_SEQUENCE_ASSET_MUTATION,
          e.id,
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [
          MediaSequenceApiKeys.GET_MEDIA_SEQUENCE_ASSETS,
          e.mediaSequenceId,
        ],
      });
      toast.success(`Effekten er blevet tilføjet til filen`, {
        toastId: 'createMediaSequenceAssetMutation',
      });
    },
  });
};

export const useUpdateMediaSequenceAssetMutation = () => {
  return useMutation({
    mutationFn: ({
      id,
      mediaSequenceAssetMutationUpdateRequest,
    }: {
      id: string;
      mediaSequenceAssetMutationUpdateRequest: MediaSequenceAssetMutationUpdateRequest;
    }) =>
      client.mediaSequenceAssetsAssetMutationIdPut({
        id,
        mediaSequenceAssetMutationUpdateRequest,
      }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [
          MediaSequenceAssetApiKeys.GET_MEDIA_SEQUENCE_ASSET_MUTATION,
          e.id,
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [
          MediaSequenceApiKeys.GET_MEDIA_SEQUENCE_ASSETS,
          e.mediaSequenceId,
        ],
      });
      toast.success(`Effekten på filen er blevet opdateret`, {
        toastId: 'updateMediaSequenceAssetMutation',
      });
    },
  });
};

export const useDeleteMediaSequenceAssetMutation = () => {
  return useMutation({
    mutationFn: ({ id }: { id: string }) =>
      client.mediaSequenceAssetsAssetMutationIdDelete({ id }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [
          MediaSequenceAssetApiKeys.GET_MEDIA_SEQUENCE_ASSET_MUTATION,
          e.id,
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [
          MediaSequenceApiKeys.GET_MEDIA_SEQUENCE_ASSETS,
          e.mediaSequenceId,
        ],
      });
      toast.success(`Effekten på filen er blevet slettet`, {
        toastId: 'deleteMediaSequenceAssetMutation',
      });
    },
  });
};
