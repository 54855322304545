import {
  buildQueryState,
  useTableQueryState,
} from 'components/Table/useTableQueryState';
import { Page } from 'layouts/Admin/Page';
import {
  useCloneMediaSequenceTemplate,
  useGetMediaSequenceTemplates,
} from 'api/useMediaSequenceAdministrationApi';
import { MediaSequencesTable } from 'pages/MediaOrbit/components/MediaSequence/MediaSequencesTable';
import { useState } from 'react';
import { useGetOrganizations } from 'api/useOrganizationsApi';
import {
  MediaSequenceResponse,
  MediaSequenceTemplateCloneRequest,
  OrganizationResponse,
} from 'api/core';
import { ControlledModal } from 'components/ControlledModal';
import { useForm } from 'react-hook-form';
import { EntitySelectSingle } from 'components/Select/EntitySelectSingle';

export const MediaSequenceTemplatesView = () => {
  const { state } = useTableQueryState();
  const { data, isFetching } = useGetMediaSequenceTemplates(state);

  return (
    <Page
      title={'Smart Video skabeloner'}
      breadcrumbs={[{ name: 'Smart Video skabeloner' }]}
    >
      <MediaSequenceCloneTemplate
        trigger={
          <div className="flex justify-start py-4 w-full">
            <button className="btn btn-primary mr-2">Klon skabelon</button>
          </div>
        }
      />
      <MediaSequencesTable
        data={data}
        isFetching={isFetching}
        queryState={state}
        showActions={false}
        showState={false}
        showTranscriptionState={false}
        showUser={false}
        showOrganization={true}
      />
    </Page>
  );
};

interface MediaSequenceCloneTemplateProps {
  trigger?: React.ReactNode;
  isInitialOpen?: boolean;
  onClosed?: () => void;
}

export const MediaSequenceCloneTemplate = ({
  trigger,
  isInitialOpen,
  onClosed,
}: MediaSequenceCloneTemplateProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(isInitialOpen ?? false);

  const { data: allOrganizations } = useGetOrganizations(
    buildQueryState<OrganizationResponse>({
      pagination: {
        pageIndex: 0,
        pageSize: 100,
      },
      sorting: [
        {
          id: 'name',
          desc: false,
        },
      ],
    })
  );

  const { data: allTemplates } = useGetMediaSequenceTemplates(
    buildQueryState({
      pagination: {
        pageIndex: 0,
        pageSize: 100,
      },
      sorting: [
        {
          id: 'name',
          desc: false,
        },
      ],
    })
  );

  const onModalStateChange = (state: boolean) => {
    setIsOpen(state);
    if (!state) {
      onClosed?.();
    }
  };

  if (!allOrganizations) return null;
  if (!allTemplates) return null;

  return (
    <>
      {trigger ? (
        <div onClick={() => onModalStateChange(true)}>{trigger}</div>
      ) : null}
      <ControlledModal
        showModal={onModalStateChange}
        isOpen={isOpen}
        title="Klon Smart Video skabelon"
        hideActionBar
      >
        <MediaSequenceCloneForm
          allOrganizations={allOrganizations.data}
          allTemplates={allTemplates.data}
          onSuccess={() => onModalStateChange(false)}
          onCancel={() => onModalStateChange(false)}
          inDialog={true}
        />
      </ControlledModal>
    </>
  );
};

interface MediaSequenceCloneFormProps {
  allOrganizations: OrganizationResponse[];
  allTemplates: MediaSequenceResponse[];
  onSuccess: () => void;
  onCancel: () => void;
  inDialog?: boolean;
}

export const MediaSequenceCloneForm = ({
  allOrganizations,
  allTemplates,
  onSuccess,
  onCancel,
}: MediaSequenceCloneFormProps) => {
  const { mutateAsync: cloneAsync, isPending: isPendingClone } =
    useCloneMediaSequenceTemplate();

  const { register, handleSubmit, setValue } =
    useForm<MediaSequenceTemplateCloneRequest>({
      defaultValues: {},
    });

  const onSubmit = handleSubmit(async (result) => {
    await cloneAsync({ mediaSequenceTemplateCloneRequest: result });
    onSuccess?.();
  });

  return (
    <form onSubmit={onSubmit} className="space-y-2">
      <div className="form-control">
        <label className="label">
          <span className="label-text">Skabelon der skal klones</span>
        </label>
        <EntitySelectSingle<MediaSequenceResponse>
          data={allTemplates}
          renderFormat={(template) => (
            <>
              {template.organization?.name} - {template.name}
            </>
          )}
          onSelect={(val) => {
            if (!val) return;

            setValue('templateId', val.id);
            setValue('name', val.name);
          }}
          searchPropertyKey="name"
          inDialog={true}
        />
        <input
          {...register('templateId', { required: true })}
          type="text"
          className="hidden"
        />
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">
            Organisation der skal modtage klonet skabelon
          </span>
        </label>
        <EntitySelectSingle<OrganizationResponse>
          data={allOrganizations}
          renderFormat={(format) => <>{format.name}</>}
          onSelect={(val) => val && setValue('targetOrganizationId', val.id)}
          searchPropertyKey="name"
          inDialog={true}
        />
        <input
          {...register('targetOrganizationId', { required: true })}
          type="text"
          className="hidden"
        />
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">Navn på klonet skabelon</span>
        </label>
        <input
          {...register('name', { required: true })}
          type="text"
          className="input input-bordered"
          min={1}
          max={64}
        />
      </div>

      <div className="flex justify-center space-x-4 pt-4">
        <button className="btn btn-primary" disabled={isPendingClone}>
          {'Klon'}
        </button>
        {onCancel ? (
          <button type="button" className="btn" onClick={onCancel}>
            Annuller
          </button>
        ) : null}
      </div>
    </form>
  );
};
