import { MediaSequenceResponse } from 'api/core';
import { useRedoMediaSequence } from 'api/useMediaSequencesApi';
import { MutateEntity } from 'components/Form/MutateEntity';

interface MediaSequenceSetupErrorProps {
  mediaSequence: MediaSequenceResponse;
}

export const MediaSequenceSetupError = ({
  mediaSequence,
}: MediaSequenceSetupErrorProps) => {
  return (
    <div className="space-y-2">
      <p>
        Der er sket en ukendt fejl under procceseringen af videoen. Teknisk
        support er blevet informeret og vil kigge på det hurtigst muligt.
      </p>
      <p>
        I mellemtiden kan det i nogle tilfælde hjælpe at genoprette videoen med
        rettelser og forsøge igen. Hvis du ønsker at prøve dette, kan du benytte
        knappen nedenfor.
      </p>
      <MutateEntity
        trigger={
          <button type="button" className="btn btn-warning">
            Lav video om
          </button>
        }
        title={`Lav videoen ${mediaSequence.name} om?`}
        description={`Er du sikker på du vil lave videoen '${mediaSequence.name}' om? Din opsætning vil blive gemt men resultaterne vil blive slettet.`}
        setter={useRedoMediaSequence}
        mutationVariables={{ id: mediaSequence.id }}
      />
    </div>
  );
};
