import { useQueryClient } from '@tanstack/react-query';
import {
  EPermission,
  OrganizationCreateRequest,
  OrganizationResponse,
  OrganizationUpdateRequest,
  VisualIdentityResponse,
} from 'api/core';
import {
  OrganizationApiKeys,
  client as orgnanizationApiClient,
  useCreateOrganization,
  useGetOrganizations,
  useUpdateOrganization,
} from 'api/useOrganizationsApi';
import { EntitySelectMultiple } from 'components/Select/EntitySelectMultiple';
import { EntitySelectSingle } from 'components/Select/EntitySelectSingle';
import { buildQueryState } from 'components/Table/useTableQueryState';
import { useForm } from 'react-hook-form';

type ModuleWithId = { id: EPermission; title: string };

interface OrganizationFormProps {
  targetOrganization?: OrganizationResponse;
  allVisualIdentites: VisualIdentityResponse[];
  onSuccess: () => void;
  onCancel: () => void;
}

export const OrganizationForm = ({
  targetOrganization,
  allVisualIdentites,
  onSuccess,
  onCancel,
}: OrganizationFormProps) => {
  const { mutateAsync: createAsync, isPending: isPendingCreate } =
    useCreateOrganization();
  const { mutateAsync: updateAsync, isPending: isPendingUpdate } =
    useUpdateOrganization();

  const { data: allOrganizations } = useGetOrganizations(
    buildQueryState<OrganizationResponse>({
      pagination: {
        pageIndex: 0,
        pageSize: 1000,
      },
      sorting: [
        {
          id: 'name',
          desc: false,
        },
      ],
    })
  );

  const availableModules = (Object.values(EPermission) as EPermission[])
    .map((e) => ({ id: e, title: e }) as ModuleWithId)
    .filter((e) => e.title.startsWith('Module'));

  const { register, handleSubmit, setValue, watch } = useForm<
    OrganizationCreateRequest | OrganizationUpdateRequest
  >({
    defaultValues: {
      ...targetOrganization,
      visualIdentityId: targetOrganization?.visualIdentity.id,
      parentOrganizationId: targetOrganization?.parentOrganizationId,
      modules:
        availableModules
          .map((e) => e.id)
          .filter((e) => targetOrganization?.permissions.some((f) => f == e)) ??
        [],
    },
  });

  // If user enters cvr without name, then try to autofill
  const queryClient = useQueryClient();

  const onCvrBlur = async () => {
    if (targetOrganization) return;

    const enteredCvr = watch('cvr');
    const enteredName = watch('name');

    if (enteredCvr?.length != 8) return;
    if (enteredName) return;

    try {
      const data = await queryClient.fetchQuery({
        queryKey: [OrganizationApiKeys.GET_PUBLIC_ORG, enteredCvr],
        queryFn: () =>
          orgnanizationApiClient.organizationsPublicInformationGet({
            query: enteredCvr,
          }),
      });
      setValue('address', data.address || '');
      setValue('city', data.city || '');
      setValue('zipCode', data.zipcode || '');
      setValue('name', data.name || '');
      setValue('email', data.email || '');
      setValue('phone', data.phone || '');
    } catch (e) {
      console.error(e);
    }
  };

  const onSubmit = handleSubmit(async (result) => {
    if (targetOrganization) {
      await updateAsync({
        id: targetOrganization.id,
        organizationUpdateRequest: result,
      });
    } else {
      await createAsync({
        organizationCreateRequest: result,
      });
    }
    onSuccess();
  });

  const onVisualIdentitySelected = (
    visualIdentity: VisualIdentityResponse | null
  ) => {
    if (!visualIdentity) return;
    setValue('visualIdentityId', visualIdentity.id);
  };

  const onParentOrganizationSelected = (
    organization: OrganizationResponse | null
  ) => {
    if (!organization) {
      setValue('parentOrganizationId', null);
      return;
    }
    setValue('parentOrganizationId', organization.id);
    // TODO: Fix that this doesn't update the visible value in the EntitySelectSingle
    setValue('visualIdentityId', organization.visualIdentity.id);
    setValue(
      'modules',
      availableModules
        .filter((e) => organization.permissions.some((f) => f == e.id))
        .map((e) => e.id)
    );
  };

  const onModulesSelected = (modules: ModuleWithId[] | null) => {
    setValue('modules', modules?.map((e) => e.id) ?? []);
  };

  if (!allOrganizations) return null;

  return (
    <form onSubmit={onSubmit} className="space-y-2">
      <div className="form-control">
        <label className="label">
          <span className="label-text">Hovedorganisation</span>
        </label>
        <EntitySelectSingle<OrganizationResponse>
          data={allOrganizations.data}
          renderFormat={(format) => <>{format.name}</>}
          onSelect={onParentOrganizationSelected}
          initialValue={allOrganizations.data.find(
            (e) => e.id == targetOrganization?.parentOrganizationId
          )}
          searchPropertyKey="name"
          inDialog={true}
        />
        <input
          {...register('parentOrganizationId', { required: false })}
          type="text"
          className="hidden"
        />
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">Visuel identitet</span>
        </label>
        <EntitySelectSingle<VisualIdentityResponse>
          data={allVisualIdentites}
          renderFormat={(format) => <>{format.name}</>}
          onSelect={onVisualIdentitySelected}
          initialValue={targetOrganization?.visualIdentity}
          searchPropertyKey="name"
          inDialog={true}
        />
        <input
          {...register('visualIdentityId', { required: true })}
          type="text"
          className="hidden"
        />
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">Navn</span>
        </label>
        <input
          {...register('name', { required: true })}
          type="text"
          className="input input-bordered"
        />
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">Email</span>
        </label>
        <input
          {...register('email')}
          type="text"
          className="input input-bordered"
        />
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">Telefon</span>
        </label>
        <input
          {...register('phone')}
          type="text"
          className="input input-bordered"
        />
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">Hjemmeside</span>
        </label>
        <input
          {...register('website')}
          type="text"
          className="input input-bordered"
        />
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">CVR</span>
        </label>
        <input
          {...register('cvr')}
          type="text"
          className="input input-bordered"
          onBlur={onCvrBlur}
        />
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">Adresse</span>
        </label>
        <input
          {...register('address')}
          type="text"
          className="input input-bordered"
        />
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">By</span>
        </label>
        <input
          {...register('city')}
          type="text"
          className="input input-bordered"
        />
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">Postnummer</span>
        </label>
        <input
          {...register('zipCode')}
          type="text"
          className="input input-bordered"
        />
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">Moduler</span>
        </label>
        <EntitySelectMultiple<ModuleWithId>
          data={availableModules}
          renderFormat={(permission) => <p>{permission.title}</p>}
          onSelect={onModulesSelected}
          initialValue={availableModules.filter((e) =>
            targetOrganization?.permissions.some((f) => f == e.id)
          )}
          searchPropertyKey="title"
          inDialog={true}
        />
        <input
          {...register('modules', { required: true })}
          type="text"
          className="hidden"
        />
      </div>

      <div className="flex justify-center space-x-4 pt-4">
        <button
          className="btn btn-primary"
          disabled={isPendingCreate || isPendingUpdate}
        >
          {targetOrganization ? 'Opdater' : 'Opret'}
        </button>
        {onCancel ? (
          <button type="button" className="btn" onClick={onCancel}>
            Annuller
          </button>
        ) : null}
      </div>
    </form>
  );
};
