/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AssetTagResponse } from './AssetTagResponse';
import {
    AssetTagResponseFromJSON,
    AssetTagResponseFromJSONTyped,
    AssetTagResponseToJSON,
} from './AssetTagResponse';
import type { EAssetSource } from './EAssetSource';
import {
    EAssetSourceFromJSON,
    EAssetSourceFromJSONTyped,
    EAssetSourceToJSON,
} from './EAssetSource';
import type { EAssetType } from './EAssetType';
import {
    EAssetTypeFromJSON,
    EAssetTypeFromJSONTyped,
    EAssetTypeToJSON,
} from './EAssetType';
import type { UserSimpleResponse } from './UserSimpleResponse';
import {
    UserSimpleResponseFromJSON,
    UserSimpleResponseFromJSONTyped,
    UserSimpleResponseToJSON,
} from './UserSimpleResponse';

/**
 * 
 * @export
 * @interface AssetResponse
 */
export interface AssetResponse {
    /**
     * 
     * @type {Date}
     * @memberof AssetResponse
     */
    lastModifiedUtc: Date;
    /**
     * 
     * @type {Date}
     * @memberof AssetResponse
     */
    createdUtc: Date;
    /**
     * 
     * @type {string}
     * @memberof AssetResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AssetResponse
     */
    projectId?: string | null;
    /**
     * 
     * @type {EAssetType}
     * @memberof AssetResponse
     */
    type: EAssetType;
    /**
     * 
     * @type {EAssetSource}
     * @memberof AssetResponse
     */
    source: EAssetSource;
    /**
     * 
     * @type {number}
     * @memberof AssetResponse
     */
    fileSize: number;
    /**
     * 
     * @type {string}
     * @memberof AssetResponse
     */
    originalFileName: string;
    /**
     * 
     * @type {string}
     * @memberof AssetResponse
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof AssetResponse
     */
    previewUrl?: string | null;
    /**
     * 
     * @type {Array<AssetTagResponse>}
     * @memberof AssetResponse
     */
    tags: Array<AssetTagResponse>;
    /**
     * 
     * @type {string}
     * @memberof AssetResponse
     */
    userId?: string | null;
    /**
     * 
     * @type {UserSimpleResponse}
     * @memberof AssetResponse
     */
    user?: UserSimpleResponse;
    /**
     * 
     * @type {boolean}
     * @memberof AssetResponse
     */
    isGlobal: boolean;
}

/**
 * Check if a given object implements the AssetResponse interface.
 */
export function instanceOfAssetResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lastModifiedUtc" in value;
    isInstance = isInstance && "createdUtc" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "source" in value;
    isInstance = isInstance && "fileSize" in value;
    isInstance = isInstance && "originalFileName" in value;
    isInstance = isInstance && "url" in value;
    isInstance = isInstance && "tags" in value;
    isInstance = isInstance && "isGlobal" in value;

    return isInstance;
}

export function AssetResponseFromJSON(json: any): AssetResponse {
    return AssetResponseFromJSONTyped(json, false);
}

export function AssetResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastModifiedUtc': (new Date(json['lastModifiedUtc'])),
        'createdUtc': (new Date(json['createdUtc'])),
        'id': json['id'],
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'type': EAssetTypeFromJSON(json['type']),
        'source': EAssetSourceFromJSON(json['source']),
        'fileSize': json['fileSize'],
        'originalFileName': json['originalFileName'],
        'url': json['url'],
        'previewUrl': !exists(json, 'previewUrl') ? undefined : json['previewUrl'],
        'tags': ((json['tags'] as Array<any>).map(AssetTagResponseFromJSON)),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'user': !exists(json, 'user') ? undefined : UserSimpleResponseFromJSON(json['user']),
        'isGlobal': json['isGlobal'],
    };
}

export function AssetResponseToJSON(value?: AssetResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lastModifiedUtc': (value.lastModifiedUtc.toISOString()),
        'createdUtc': (value.createdUtc.toISOString()),
        'id': value.id,
        'projectId': value.projectId,
        'type': EAssetTypeToJSON(value.type),
        'source': EAssetSourceToJSON(value.source),
        'fileSize': value.fileSize,
        'originalFileName': value.originalFileName,
        'url': value.url,
        'previewUrl': value.previewUrl,
        'tags': ((value.tags as Array<any>).map(AssetTagResponseToJSON)),
        'userId': value.userId,
        'user': UserSimpleResponseToJSON(value.user),
        'isGlobal': value.isGlobal,
    };
}

