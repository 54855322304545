import { Page } from 'layouts/Admin/Page';
import { useParams } from 'react-router-dom';

import {
  useGetMediaSequence,
  useGetMediaSequenceUpdatesAll,
} from 'api/useMediaSequencesApi';
import { EMediaSequenceState } from 'api/core';
import { useMemo } from 'react';
import { MediaSequenceTimeline } from './components/MediaSequence/MediaSequenceTimeline';

export const MediaSequenceTimelineView = () => {
  const { id } = useParams<{ id: string }>();
  const { data: mediaSequence } = useGetMediaSequence(id, undefined, true);
  const { data: updates } = useGetMediaSequenceUpdatesAll(id);

  const isStuck = useMemo(() => {
    if (!mediaSequence || !updates) return false;

    const thresholdInSeconds = 60 * 5;

    if (mediaSequence.state !== EMediaSequenceState.Processing) return false;
    if (updates.length === 0) {
      const secondsSinceLastModified =
        (new Date().getTime() -
          new Date(mediaSequence.lastModifiedUtc).getTime()) /
        1000;
      return secondsSinceLastModified >= thresholdInSeconds;
    }
    const lastUpdate = updates[updates.length - 1];
    const secondsSinceLastUpdate =
      (new Date().getTime() - new Date(lastUpdate.publishTimeUtc).getTime()) /
      1000;
    return secondsSinceLastUpdate >= thresholdInSeconds;
  }, [mediaSequence, updates]);

  if (!mediaSequence || !updates) return null;

  return (
    <Page
      title={mediaSequence.name}
      breadcrumbs={[
        { name: 'Smart Video' },
        { name: mediaSequence.name },
        { name: 'Tidslinje' },
      ]}
      alert={
        isStuck
          ? {
              message:
                'Videoen er muligvis kørt fast. Der har ikke været nogle opdateringer i mere end fem minutter. Supporten er automatisk blevet informeret herom.',
              type: 'error',
            }
          : undefined
      }
    >
      <div className="mt-2">
        <MediaSequenceTimeline
          mediaSequence={mediaSequence}
          updates={updates}
        />
      </div>
    </Page>
  );
};
