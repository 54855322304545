/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EMediaSequenceState = {
    Draft: 'Draft',
    Processing: 'Processing',
    Processed: 'Processed',
    Published: 'Published',
    Failed: 'Failed'
} as const;
export type EMediaSequenceState = typeof EMediaSequenceState[keyof typeof EMediaSequenceState];


export function EMediaSequenceStateFromJSON(json: any): EMediaSequenceState {
    return EMediaSequenceStateFromJSONTyped(json, false);
}

export function EMediaSequenceStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): EMediaSequenceState {
    return json as EMediaSequenceState;
}

export function EMediaSequenceStateToJSON(value?: EMediaSequenceState | null): any {
    return value as any;
}

