import {
  EAssetMutationType,
  EAssetType,
  MediaSequenceAssetMutationResponse,
  MediaSequenceAssetResponse,
  MediaSequenceBranchMutationResponse,
  MediaSequenceResponse,
} from 'api/core';
import { EBranchMutationType } from './../api/core/models/EBranchMutationType';

const VIDEO_ONLY_MUTATIONS: EAssetMutationType[] = [
  EAssetMutationType.TrimVideo,
  EAssetMutationType.ColorAdjustments,
];

const IMAGE_ONLY_MUTATIONS: EAssetMutationType[] = [
  EAssetMutationType.ImageToVideo,
  EAssetMutationType.Voiceover,
];

const VIDEO_AND_IMAGE_MUTATIONS: EAssetMutationType[] = [
  EAssetMutationType.AddDynamicOverlay,
  EAssetMutationType.ImageOverlay,
];

const DUPLICTABLE_MUTATIONS: EAssetMutationType[] = [
  EAssetMutationType.ImageOverlay,
  EAssetMutationType.AddDynamicOverlay,
];

const AVAILABLE_ASSET_MUTATIONS = [
  ...VIDEO_AND_IMAGE_MUTATIONS,
  ...VIDEO_ONLY_MUTATIONS,
  ...IMAGE_ONLY_MUTATIONS,
];

export const getAvailableAssetMutations = (
  asset: MediaSequenceAssetResponse,
  targetMutation?: MediaSequenceAssetMutationResponse
) => {
  const availableMutations: EAssetMutationType[] = [];

  // Always push the target mutation into the array
  if (targetMutation) {
    availableMutations.push(targetMutation.type);
  }

  // Push all non existing mutations into the array
  AVAILABLE_ASSET_MUTATIONS.forEach((mutation) => {
    if (
      DUPLICTABLE_MUTATIONS.includes(mutation) ||
      !asset.mutations.some((m) => m.type === mutation)
    ) {
      availableMutations.push(mutation);
    }
  });

  // Remove video only mutations if it isn't a video
  if (asset.asset.type !== EAssetType.Video) {
    VIDEO_ONLY_MUTATIONS.forEach((mutation) => {
      const index = availableMutations.indexOf(mutation);
      if (index > -1) {
        availableMutations.splice(index, 1);
      }
    });
  }

  // If it isn't an image, remove the image to video mutation
  if (asset.asset.type !== EAssetType.Image) {
    IMAGE_ONLY_MUTATIONS.forEach((mutation) => {
      const index = availableMutations.indexOf(mutation);
      if (index > -1) {
        availableMutations.splice(index, 1);
      }
    });
  }

  return availableMutations;
};

// This needs to be in same order as in the form else initial selected bugs
const AVAILABLE_BRANCH_MUTATIONS: EBranchMutationType[] = [
  EBranchMutationType.AddDynamicOverlay,
  // Moved to its own step
  // EBranchMutationType.AddBackgroundMusic,
  EBranchMutationType.AddWatermark,
  EBranchMutationType.ResizeAndConcat,
];

const DUPLICATE_BRANCH_MUTATIONS: EBranchMutationType[] = [
  EBranchMutationType.AddDynamicOverlay,
  EBranchMutationType.AddWatermark,
];

export const getAvailableBranchMutations = (
  mediaSequence: MediaSequenceResponse,
  targetBranchMutation?: MediaSequenceBranchMutationResponse
) => {
  const availableMutations: EBranchMutationType[] = [];

  // Always push the target mutation into the array
  if (targetBranchMutation) {
    availableMutations.push(targetBranchMutation.type);
  }

  // Push all non existing (or duplicatable) mutations into the array
  AVAILABLE_BRANCH_MUTATIONS.forEach((mutation) => {
    if (
      DUPLICATE_BRANCH_MUTATIONS.includes(mutation) ||
      !mediaSequence.branchMutations.some((m) => m.type === mutation)
    ) {
      availableMutations.push(mutation);
    }
  });

  return availableMutations;
};
