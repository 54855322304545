import { twMerge } from 'tailwind-merge';

interface DropdownMenuProps {
  trigger: React.ReactNode;
  items: React.ReactNode[];
  position?:
    | 'dropdown-end'
    | 'dropdown-top'
    | 'dropdown-bottom'
    | 'dropdown-left'
    | 'dropdown-right';
  disableHover?: boolean;
}

export const DropdownMenu = ({
  trigger,
  items,
  position = 'dropdown-bottom',
  disableHover,
}: DropdownMenuProps) => {
  return (
    <>
      <div
        className={twMerge(
          'dropdown dropdown-top md:dropdown-bottom w-full md:w-auto',
          !disableHover && 'dropdown-hover',
          position && `md:${position}`
        )}
      >
        <div tabIndex={0} role="button">
          {trigger}
        </div>
        <ul
          tabIndex={0}
          className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
        >
          {items.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>
    </>
  );
};
