import { ETranscriptionState, MediaSequenceResponse } from 'api/core';
import { useGetMediaSequenceUpdatesProcessing } from 'api/useMediaSequencesApi';
import {
  assetMutationTranslateProcessingMessage,
  branchMutationTranslateProcessingMessage,
} from 'utils/enum-translate';

interface MediaSequenceProgressProps {
  mediaSequence: MediaSequenceResponse;
}

export const MediaSequenceProgress = ({
  mediaSequence,
}: MediaSequenceProgressProps) => {
  const { data: updates } = useGetMediaSequenceUpdatesProcessing(mediaSequence);
  const processingUpdate = updates?.length ? updates[0] : null;

  const progressPercentage =
    !mediaSequence ||
    !mediaSequence.mutationsCompleted ||
    !mediaSequence.mutationsRegistered
      ? 0
      : Math.round(
          (mediaSequence.mutationsCompleted /
            mediaSequence.mutationsRegistered) *
            100
        );

  const radialThickness = '0.7rem';
  const radialSize = '7rem';

  return (
    <div className="flex flex-col items-center space-y-4">
      <div className="relative inline-block">
        {/* Background full progress circle */}
        <div
          className="radial-progress text-accent text-xl absolute"
          style={
            {
              '--value': 100,
              '--thickness': radialThickness,
              '--size': radialSize,
            } as {
              [key: string]: string | number;
            }
          }
          role="progressbar"
        >
          {/* No content needed as it's just a background */}
        </div>

        {/* Foreground progress circle */}
        <div
          className="radial-progress text-primary text-xl"
          style={
            {
              '--value': progressPercentage,
              '--thickness': radialThickness,
              '--size': radialSize,
            } as {
              [key: string]: string | number;
            }
          }
          role="progressbar"
        >
          {progressPercentage}%
        </div>
      </div>

      {processingUpdate?.assetMutationType ? (
        <p className="text-xl text-primary">
          {assetMutationTranslateProcessingMessage(
            processingUpdate.assetMutationType
          )}
          &hellip;
        </p>
      ) : processingUpdate?.branchMutationType ? (
        <p className="text-xl text-primary">
          {branchMutationTranslateProcessingMessage(
            processingUpdate.branchMutationType
          )}
          &hellip;
        </p>
      ) : null}

      {mediaSequence.transcriptionState === ETranscriptionState.Pending ? (
        <p className="text-gray-400">
          Når underteksterne er klar, vil du kunne se og godkende dem her. Snup
          en kop kaffe, mens du venter.
        </p>
      ) : mediaSequence.transcriptionState === ETranscriptionState.Approved ? (
        <p className="text-gray-400">
          Underteksterne er godkendt. Snup en kop kaffe og vent på at videoen er
          færdig.
        </p>
      ) : mediaSequence.transcriptionState ===
        ETranscriptionState.NotApplicable ? (
        <p className="text-gray-400">
          Denne video har ikke undertekster. Snup en kop kaffe og vent på at
          videoen er færdig.
        </p>
      ) : null}
    </div>
  );
};
