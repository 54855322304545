export const captureVideoFrames = (url: string, frames: number) => {
  return new Promise<string[]>((resolve, reject) => {
    if (frames <= 0) {
      reject('Number of frames must be greater than 0');
      return;
    }
    if (frames > 30) {
      reject('Number of frames must be less than or equal to 30');
      return;
    }
    if (!url) {
      reject('Asset URL is required');
      return;
    }

    const video = document.createElement('video');
    video.crossOrigin = 'anonymous'; // Handle CORS if necessary
    video.src = url;

    video.onloadedmetadata = () => {
      const duration = video.duration;
      const interval = duration / frames;

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      const captureFrame = (time: number) => {
        return new Promise<string>((resolve, reject) => {
          video.currentTime = time;

          video.onseeked = () => {
            if (!context) {
              reject('2d context of the canvas is null');
              return;
            }
            context.drawImage(video, 0, 0, canvas.width, canvas.height);
            try {
              const imageUrl = canvas.toDataURL('image/jpeg');
              resolve(imageUrl);
            } catch (err) {
              console.error('Error capturing frame:', err);
              reject(err);
            }
          };
        });
      };

      const captureAllFrames = async () => {
        const capturedImages: string[] = [];

        for (let i = 0; i < frames; i++) {
          const time = i * interval;
          const image = await captureFrame(time);
          capturedImages.push(image);
        }

        resolve(capturedImages);
      };

      captureAllFrames();
    };

    video.onerror = (err) => {
      const message = err instanceof Error ? err.message : err;
      reject(`Error loading video: ${message}`);
    };
  });
};
