import { Tooltip } from 'components/Tooltip';
import { ReactNode } from 'react';

interface IconButtonProps {
  icon: ReactNode;
  onClick?: () => void;
  tooltip?: string;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
}

export const IconButton = ({
  icon,
  onClick,
  tooltip,
  disabled,
  type = 'button',
}: IconButtonProps) => {
  return (
    <Tooltip tooltip={tooltip}>
      <button onClick={onClick} disabled={disabled} type={type}>
        {icon}
      </button>
    </Tooltip>
  );
};
