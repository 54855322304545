import { useMutation, useQuery } from '@tanstack/react-query';

import { useAuth } from 'auth/AuthProvider';
import { TableQueryState } from 'components/Table/useTableQueryState';
import { queryClient } from 'query-client';
import { toast } from 'react-toastify';
import { CoreBaseConfiguration } from './BaseConfiguration';
import {
  EPermission,
  PublicationCreateRequest,
  PublicationsApi,
  PublicationUpdateRequest,
} from './core';

export const PublicationApiKeys = {
  GET_PUBLICATIONS_LAST_VIEWED: 'publications-last-viewed',
  GET_PUBLICATIONS_DATE_RANGE: 'publications-date-range',
  GET_PUBLICATIONS: 'publications',
  GET_PUBLICATION: 'publication',
};

const client = new PublicationsApi(new CoreBaseConfiguration());

export const useGetPublications = (state?: TableQueryState) => {
  return useQuery({
    queryKey: [PublicationApiKeys.GET_PUBLICATIONS, state],
    queryFn: () =>
      client.publicationsGet({
        pageNumber: state?.pagination.pageIndex,
        size: state?.pagination.pageSize,
        orderByProperty: state?.sorting[0]?.id,
        ascending: state?.sorting[0]?.desc === false,
        searchTerm: state?.globalFilter,
      }),
  });
};

export const useGetPublicationsByDateRange = (
  startDate?: Date,
  endDate?: Date
) => {
  const { hasPermissions } = useAuth();

  return useQuery({
    queryKey: [
      PublicationApiKeys.GET_PUBLICATIONS_DATE_RANGE,
      startDate,
      endDate,
    ],
    queryFn: () =>
      client.publicationsDateRangeGet({
        startDate: startDate ?? new Date(),
        endDate: endDate ?? new Date(),
      }),
    enabled:
      hasPermissions([
        EPermission.ModuleSocialMedia,
        EPermission.PublicationRead,
      ]) &&
      startDate !== undefined &&
      endDate !== undefined,
  });
};

export const useGetLastViewedPublications = (amount: number) => {
  const { hasPermissions } = useAuth();

  return useQuery({
    queryKey: [PublicationApiKeys.GET_PUBLICATIONS_LAST_VIEWED],
    queryFn: () =>
      client.publicationsLastViewedGet({
        amount,
      }),
    enabled: hasPermissions([
      EPermission.ModuleSocialMedia,
      EPermission.PublicationRead,
    ]),
  });
};

export const useGetPublication = (
  id?: string,
  invalidateLastViewed?: boolean
) => {
  return useQuery({
    queryKey: [PublicationApiKeys.GET_PUBLICATION, id],
    queryFn: async () => {
      const data = await client.publicationsIdGet({ id: id ?? '' });
      if (invalidateLastViewed) {
        queryClient.invalidateQueries({
          queryKey: [PublicationApiKeys.GET_PUBLICATIONS_LAST_VIEWED],
        });
      }
      return data;
    },
    enabled: id !== undefined,
  });
};
export const useCreatePublication = () => {
  return useMutation({
    mutationFn: ({
      publicationCreateRequest,
    }: {
      publicationCreateRequest: PublicationCreateRequest;
    }) => client.publicationsPost({ publicationCreateRequest }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [PublicationApiKeys.GET_PUBLICATIONS_LAST_VIEWED],
      });
      queryClient.invalidateQueries({
        queryKey: [PublicationApiKeys.GET_PUBLICATIONS],
      });
      queryClient.invalidateQueries({
        queryKey: [PublicationApiKeys.GET_PUBLICATIONS_DATE_RANGE],
      });
      toast.success(`Publiceringen '${e.name}' er blevet oprettet`, {
        toastId: 'createPublication',
      });
    },
  });
};

export const useUpdatePublication = () => {
  return useMutation({
    mutationFn: ({
      id,
      publicationUpdateRequest,
    }: {
      id: string;
      publicationUpdateRequest: PublicationUpdateRequest;
    }) => client.publicationsIdPut({ id, publicationUpdateRequest }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [PublicationApiKeys.GET_PUBLICATIONS_LAST_VIEWED],
      });
      queryClient.invalidateQueries({
        queryKey: [PublicationApiKeys.GET_PUBLICATIONS],
      });
      queryClient.invalidateQueries({
        queryKey: [PublicationApiKeys.GET_PUBLICATION, e.id],
      });
      queryClient.invalidateQueries({
        queryKey: [PublicationApiKeys.GET_PUBLICATIONS_DATE_RANGE],
      });
      toast.success(`Publiceringen '${e.name}' er blevet opdateret`, {
        toastId: 'updatePublication',
      });
    },
  });
};

export const useInvokePublication = () => {
  return useMutation({
    mutationFn: ({ id }: { id: string }) =>
      client.publicationsIdInvokePut({ id }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [PublicationApiKeys.GET_PUBLICATIONS_LAST_VIEWED],
      });
      queryClient.invalidateQueries({
        queryKey: [PublicationApiKeys.GET_PUBLICATIONS],
      });
      queryClient.invalidateQueries({
        queryKey: [PublicationApiKeys.GET_PUBLICATION, e.id],
      });
      queryClient.invalidateQueries({
        queryKey: [PublicationApiKeys.GET_PUBLICATIONS_DATE_RANGE],
      });
      toast.success(`Publiceringen '${e.name}' er blevet udført`, {
        toastId: 'invokePublication',
      });
    },
  });
};

export const useDeletePublication = () => {
  return useMutation({
    mutationFn: ({ id }: { id: string }) => client.publicationsIdDelete({ id }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [PublicationApiKeys.GET_PUBLICATIONS_LAST_VIEWED],
      });
      queryClient.invalidateQueries({
        queryKey: [PublicationApiKeys.GET_PUBLICATIONS],
      });
      queryClient.invalidateQueries({
        queryKey: [PublicationApiKeys.GET_PUBLICATION, e.id],
      });
      queryClient.invalidateQueries({
        queryKey: [PublicationApiKeys.GET_PUBLICATIONS_DATE_RANGE],
      });
      toast.success(`Publiceringen '${e.name}' er blevet slettet`, {
        toastId: 'deletePublication',
      });
    },
  });
};
