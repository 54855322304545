/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FacebookToken } from './FacebookToken';
import {
    FacebookTokenFromJSON,
    FacebookTokenFromJSONTyped,
    FacebookTokenToJSON,
} from './FacebookToken';

/**
 * 
 * @export
 * @interface FacebookCreateRequest
 */
export interface FacebookCreateRequest {
    /**
     * 
     * @type {FacebookToken}
     * @memberof FacebookCreateRequest
     */
    token: FacebookToken;
}

/**
 * Check if a given object implements the FacebookCreateRequest interface.
 */
export function instanceOfFacebookCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "token" in value;

    return isInstance;
}

export function FacebookCreateRequestFromJSON(json: any): FacebookCreateRequest {
    return FacebookCreateRequestFromJSONTyped(json, false);
}

export function FacebookCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FacebookCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': FacebookTokenFromJSON(json['token']),
    };
}

export function FacebookCreateRequestToJSON(value?: FacebookCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': FacebookTokenToJSON(value.token),
    };
}

