/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EEntityState = {
    Active: 'Active',
    Ghost: 'Ghost',
    Archived: 'Archived',
    Deleted: 'Deleted'
} as const;
export type EEntityState = typeof EEntityState[keyof typeof EEntityState];


export function EEntityStateFromJSON(json: any): EEntityState {
    return EEntityStateFromJSONTyped(json, false);
}

export function EEntityStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): EEntityState {
    return json as EEntityState;
}

export function EEntityStateToJSON(value?: EEntityState | null): any {
    return value as any;
}

