import { useAuth } from 'auth/AuthProvider';
import { Page } from 'layouts/Admin/Page';

export const OrganizationSettings = () => {
  const { user } = useAuth();
  return (
    <Page
      title={user?.organization.name}
      breadcrumbs={[
        { name: user?.organization.name ?? '' },
        { name: 'Indstillinger' },
      ]}
    >
      <p>Indstillinger</p>
    </Page>
  );
};
