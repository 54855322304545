/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AssetCreateRequest,
  AssetCreateResponse,
  AssetResponse,
  AssetResponsePagedData,
  AssetTagRequest,
  AssetUpdateRequest,
  EAssetType,
} from '../models';
import {
    AssetCreateRequestFromJSON,
    AssetCreateRequestToJSON,
    AssetCreateResponseFromJSON,
    AssetCreateResponseToJSON,
    AssetResponseFromJSON,
    AssetResponseToJSON,
    AssetResponsePagedDataFromJSON,
    AssetResponsePagedDataToJSON,
    AssetTagRequestFromJSON,
    AssetTagRequestToJSON,
    AssetUpdateRequestFromJSON,
    AssetUpdateRequestToJSON,
    EAssetTypeFromJSON,
    EAssetTypeToJSON,
} from '../models';

export interface AssetsFinalizeIdPutRequest {
    id: string;
}

export interface AssetsGetRequest {
    projectId?: string;
    pageNumber?: number;
    size?: number;
    orderByProperty?: string;
    ascending?: boolean;
    searchTerm?: string;
}

export interface AssetsIdDeleteRequest {
    id: string;
}

export interface AssetsIdGetRequest {
    id: string;
}

export interface AssetsIdPutRequest {
    id: string;
    assetUpdateRequest?: AssetUpdateRequest;
}

export interface AssetsIdTagsPutRequest {
    id: string;
    assetTagRequest: Array<AssetTagRequest>;
}

export interface AssetsPostRequest {
    assetCreateRequest?: AssetCreateRequest;
}

export interface AssetsSearchGetRequest {
    projectId?: string;
    size?: number;
    searchTerm?: string;
    types?: Array<EAssetType>;
}

/**
 * 
 */
export class AssetsApi extends runtime.BaseAPI {

    /**
     */
    async assetsFinalizeIdPutRaw(requestParameters: AssetsFinalizeIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AssetResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling assetsFinalizeIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Assets/finalize/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AssetResponseFromJSON(jsonValue));
    }

    /**
     */
    async assetsFinalizeIdPut(requestParameters: AssetsFinalizeIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AssetResponse> {
        const response = await this.assetsFinalizeIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async assetsGetRaw(requestParameters: AssetsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AssetResponsePagedData>> {
        const queryParameters: any = {};

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.orderByProperty !== undefined) {
            queryParameters['orderByProperty'] = requestParameters.orderByProperty;
        }

        if (requestParameters.ascending !== undefined) {
            queryParameters['ascending'] = requestParameters.ascending;
        }

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['searchTerm'] = requestParameters.searchTerm;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Assets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AssetResponsePagedDataFromJSON(jsonValue));
    }

    /**
     */
    async assetsGet(requestParameters: AssetsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AssetResponsePagedData> {
        const response = await this.assetsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async assetsIdDeleteRaw(requestParameters: AssetsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AssetResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling assetsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Assets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AssetResponseFromJSON(jsonValue));
    }

    /**
     */
    async assetsIdDelete(requestParameters: AssetsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AssetResponse> {
        const response = await this.assetsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async assetsIdGetRaw(requestParameters: AssetsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AssetResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling assetsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Assets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AssetResponseFromJSON(jsonValue));
    }

    /**
     */
    async assetsIdGet(requestParameters: AssetsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AssetResponse> {
        const response = await this.assetsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async assetsIdPutRaw(requestParameters: AssetsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AssetResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling assetsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Assets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AssetUpdateRequestToJSON(requestParameters.assetUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AssetResponseFromJSON(jsonValue));
    }

    /**
     */
    async assetsIdPut(requestParameters: AssetsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AssetResponse> {
        const response = await this.assetsIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async assetsIdTagsPutRaw(requestParameters: AssetsIdTagsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AssetResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling assetsIdTagsPut.');
        }

        if (requestParameters.assetTagRequest === null || requestParameters.assetTagRequest === undefined) {
            throw new runtime.RequiredError('assetTagRequest','Required parameter requestParameters.assetTagRequest was null or undefined when calling assetsIdTagsPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Assets/{id}/Tags`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.assetTagRequest.map(AssetTagRequestToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AssetResponseFromJSON(jsonValue));
    }

    /**
     */
    async assetsIdTagsPut(requestParameters: AssetsIdTagsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AssetResponse> {
        const response = await this.assetsIdTagsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async assetsPostRaw(requestParameters: AssetsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AssetCreateResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Assets`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AssetCreateRequestToJSON(requestParameters.assetCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AssetCreateResponseFromJSON(jsonValue));
    }

    /**
     */
    async assetsPost(requestParameters: AssetsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AssetCreateResponse> {
        const response = await this.assetsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async assetsPreliminaryDeleteRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Assets/Preliminary`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async assetsPreliminaryDelete(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.assetsPreliminaryDeleteRaw(initOverrides);
    }

    /**
     */
    async assetsSearchGetRaw(requestParameters: AssetsSearchGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AssetResponse>>> {
        const queryParameters: any = {};

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['searchTerm'] = requestParameters.searchTerm;
        }

        if (requestParameters.types) {
            queryParameters['types'] = requestParameters.types;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Assets/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AssetResponseFromJSON));
    }

    /**
     */
    async assetsSearchGet(requestParameters: AssetsSearchGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AssetResponse>> {
        const response = await this.assetsSearchGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
