/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EPermission } from './EPermission';
import {
    EPermissionFromJSON,
    EPermissionFromJSONTyped,
    EPermissionToJSON,
} from './EPermission';

/**
 * 
 * @export
 * @interface RoleResponse
 */
export interface RoleResponse {
    /**
     * 
     * @type {string}
     * @memberof RoleResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof RoleResponse
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof RoleResponse
     */
    description?: string | null;
    /**
     * 
     * @type {Array<EPermission>}
     * @memberof RoleResponse
     */
    permissions: Array<EPermission>;
}

/**
 * Check if a given object implements the RoleResponse interface.
 */
export function instanceOfRoleResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "permissions" in value;

    return isInstance;
}

export function RoleResponseFromJSON(json: any): RoleResponse {
    return RoleResponseFromJSONTyped(json, false);
}

export function RoleResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'permissions': ((json['permissions'] as Array<any>).map(EPermissionFromJSON)),
    };
}

export function RoleResponseToJSON(value?: RoleResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'description': value.description,
        'permissions': ((value.permissions as Array<any>).map(EPermissionToJSON)),
    };
}

