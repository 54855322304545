import { Tooltip } from 'components/Tooltip';

interface ListCellProps<T> {
  items: T[];
  textSelector: (item: T) => string;
  maxItemsToShow?: number;
  emptyListText?: string;
}

export function ListCell<T>({
  items,
  textSelector,
  maxItemsToShow = 3,
  emptyListText = 'Ingen',
}: ListCellProps<T>) {
  if (!items?.length) return emptyListText;

  const itemCount =
    items.length <= maxItemsToShow ? items.length : maxItemsToShow - 1;
  const itemsToShow = items.slice(0, itemCount);
  const tooltip = items.slice(itemCount).join(', ');

  return (
    <ul>
      {itemsToShow.map((item, index) => (
        <li key={index}>{textSelector(item)}</li>
      ))}
      {items.length > maxItemsToShow ? (
        <Tooltip tooltip={tooltip}>
          <li className="text-gray-500">
            Og {items.length - itemCount} flere &hellip;
          </li>
        </Tooltip>
      ) : null}
    </ul>
  );
}
