/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EEstateOrbitViewMode = {
    SideBySide: 'SIDE_BY_SIDE',
    OverUnder: 'OVER_UNDER'
} as const;
export type EEstateOrbitViewMode = typeof EEstateOrbitViewMode[keyof typeof EEstateOrbitViewMode];


export function EEstateOrbitViewModeFromJSON(json: any): EEstateOrbitViewMode {
    return EEstateOrbitViewModeFromJSONTyped(json, false);
}

export function EEstateOrbitViewModeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EEstateOrbitViewMode {
    return json as EEstateOrbitViewMode;
}

export function EEstateOrbitViewModeToJSON(value?: EEstateOrbitViewMode | null): any {
    return value as any;
}

