import { ControlledModal } from 'components/ControlledModal';
import { useState } from 'react';
import { MediaSequenceAssetMutationForm } from './MediaSequenceAssetMutationForm';
import { MediaSequenceAssetResponse } from 'api/core';
import { useGetMediaSequenceAssetMutation } from 'api/useMediaSequenceAssetsApi';

interface MediaSequenceAssetMutationUpdateProps {
  id: string;
  asset: MediaSequenceAssetResponse;
  projectId?: string;
  trigger?: React.ReactNode;
  isInitialOpen?: boolean;
  onClosed?: () => void;
  disabled?: boolean;
}

export const MediaSequenceAssetMutationUpdate = ({
  id,
  asset,
  projectId,
  trigger,
  isInitialOpen,
  onClosed,
  disabled,
}: MediaSequenceAssetMutationUpdateProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(isInitialOpen ?? false);
  const [modalTitle, setModalTitle] = useState<string>('');

  const { data } = useGetMediaSequenceAssetMutation(id);

  const onModalStateChange = (state: boolean) => {
    setIsOpen(state);
    if (!state) {
      onClosed?.();
    }
  };

  if (!data) return null;

  return (
    <>
      {trigger ? (
        <div onClick={() => onModalStateChange(true)}>{trigger}</div>
      ) : null}
      <ControlledModal
        showModal={onModalStateChange}
        isOpen={isOpen}
        title={modalTitle}
        closeOnOutsideClick
        hideActionBar
        className="w-auto max-w-full"
      >
        <MediaSequenceAssetMutationForm
          asset={asset}
          targetAssetMutation={data}
          setTitle={setModalTitle}
          projectId={projectId}
          onSuccess={() => onModalStateChange(false)}
          onCancel={() => onModalStateChange(false)}
          inDialog={true}
          disabled={disabled}
          canChangeMutationType={true}
        />
      </ControlledModal>
    </>
  );
};
