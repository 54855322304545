/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  RoleCreateRequest,
  RoleResponse,
  RoleResponsePagedData,
  RoleUpdateRequest,
} from '../models';
import {
    RoleCreateRequestFromJSON,
    RoleCreateRequestToJSON,
    RoleResponseFromJSON,
    RoleResponseToJSON,
    RoleResponsePagedDataFromJSON,
    RoleResponsePagedDataToJSON,
    RoleUpdateRequestFromJSON,
    RoleUpdateRequestToJSON,
} from '../models';

export interface RolesGetRequest {
    pageNumber?: number;
    size?: number;
    orderByProperty?: string;
    ascending?: boolean;
    searchTerm?: string;
}

export interface RolesIdDeleteRequest {
    id: string;
}

export interface RolesIdGetRequest {
    id: string;
}

export interface RolesIdPutRequest {
    id: string;
    roleUpdateRequest?: RoleUpdateRequest;
}

export interface RolesPostRequest {
    roleCreateRequest?: RoleCreateRequest;
}

/**
 * 
 */
export class RolesApi extends runtime.BaseAPI {

    /**
     */
    async rolesExcelGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Roles/Excel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async rolesExcelGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.rolesExcelGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async rolesGetRaw(requestParameters: RolesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RoleResponsePagedData>> {
        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.orderByProperty !== undefined) {
            queryParameters['orderByProperty'] = requestParameters.orderByProperty;
        }

        if (requestParameters.ascending !== undefined) {
            queryParameters['ascending'] = requestParameters.ascending;
        }

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['searchTerm'] = requestParameters.searchTerm;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Roles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleResponsePagedDataFromJSON(jsonValue));
    }

    /**
     */
    async rolesGet(requestParameters: RolesGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RoleResponsePagedData> {
        const response = await this.rolesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async rolesIdDeleteRaw(requestParameters: RolesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RoleResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling rolesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Roles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleResponseFromJSON(jsonValue));
    }

    /**
     */
    async rolesIdDelete(requestParameters: RolesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RoleResponse> {
        const response = await this.rolesIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async rolesIdGetRaw(requestParameters: RolesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RoleResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling rolesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Roles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleResponseFromJSON(jsonValue));
    }

    /**
     */
    async rolesIdGet(requestParameters: RolesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RoleResponse> {
        const response = await this.rolesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async rolesIdPutRaw(requestParameters: RolesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RoleResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling rolesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Roles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RoleUpdateRequestToJSON(requestParameters.roleUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleResponseFromJSON(jsonValue));
    }

    /**
     */
    async rolesIdPut(requestParameters: RolesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RoleResponse> {
        const response = await this.rolesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async rolesPostRaw(requestParameters: RolesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RoleResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Roles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RoleCreateRequestToJSON(requestParameters.roleCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleResponseFromJSON(jsonValue));
    }

    /**
     */
    async rolesPost(requestParameters: RolesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RoleResponse> {
        const response = await this.rolesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
