/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EResizeMode = {
    Forced: 'Forced',
    LetterboxPadding: 'LetterboxPadding',
    Crop: 'Crop',
    CropWithBlurredPadding: 'CropWithBlurredPadding'
} as const;
export type EResizeMode = typeof EResizeMode[keyof typeof EResizeMode];


export function EResizeModeFromJSON(json: any): EResizeMode {
    return EResizeModeFromJSONTyped(json, false);
}

export function EResizeModeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EResizeMode {
    return json as EResizeMode;
}

export function EResizeModeToJSON(value?: EResizeMode | null): any {
    return value as any;
}

