import {
  EUpdateType,
  MediaSequenceResponse,
  MediaSequenceUpdateResponse,
} from 'api/core';
import { AnimatedIcon } from 'components/Icon/AnimatedIcon';
import { Tooltip } from 'components/Tooltip';
import {
  BadgeCheckIcon,
  CheckCircle2Icon,
  CircleCheckBigIcon,
  CirclePlusIcon,
  CircleSlash2Icon,
} from 'lucide-react';
import { twMerge } from 'tailwind-merge';
import {
  assetMutationTranslate,
  branchMutationTranslate,
  updateTypeTranslate,
} from 'utils/enum-translate';
import { formatDistanceToNow, formatTimestamp } from 'utils/format/date';
import { useIsMobile } from 'utils/useIsMobile';

interface MediaSequenceTimelineProps {
  mediaSequence: MediaSequenceResponse;
  updates: MediaSequenceUpdateResponse[];
}

export const MediaSequenceTimeline = ({
  mediaSequence,
  updates,
}: MediaSequenceTimelineProps) => {
  const isMobile = useIsMobile();
  if (updates.length === 0) return null;

  return (
    <ul className="timeline timeline-vertical">
      <li>
        <div className="timeline-start timeline-box">
          <Tooltip tooltip={formatDistanceToNow(mediaSequence.createdUtc)}>
            <span className="text-sm text-gray-500">
              {formatTimestamp(mediaSequence.createdUtc)}:
            </span>
            &nbsp; Oprettet
          </Tooltip>
        </div>
        <div className="timeline-middle">
          <CirclePlusIcon className="h-5 w-5 text-primary" />
        </div>
        <hr className="bg-primary" />
      </li>
      {updates.map((update, index) => (
        <TimelineEvent
          key={index}
          update={update}
          index={index}
          isActive={isCurrentlyActive(updates, update)}
          isLast={index === updates.length - 1}
          isMobile={isMobile}
        />
      ))}
    </ul>
  );
};

const isCurrentlyActive = (
  updates: MediaSequenceUpdateResponse[],
  update: MediaSequenceUpdateResponse
) => {
  if (update.type !== EUpdateType.Processing) return false;
  if (update.isForecast) return false;

  const index = updates.findIndex((u) => u === update);
  for (let i = 0; i < index; i++) {
    if (updates[i].isForecast) {
      return false;
    }
  }

  for (let i = index + 1; i < updates.length; i++) {
    if (!updates[i].isForecast) {
      return false;
    }
  }

  return true;
};

interface TimelineEventProps {
  update: MediaSequenceUpdateResponse;
  index: number;
  isActive: boolean;
  isLast: boolean;
  isMobile: boolean;
}

const TimelineEvent = ({
  update,
  index,
  isActive,
  isLast,
  isMobile,
}: TimelineEventProps) => (
  <li>
    <hr className={twMerge(!update.isForecast && 'bg-primary')} />

    {index % 2 === 1 ? (
      <TimelineEventContent
        update={update}
        additionalClasses="timeline-start"
      />
    ) : null}

    <div className="timeline-middle">
      {update.type === EUpdateType.Failed ? (
        <CircleSlash2Icon className="h-5 w-5 text-error" />
      ) : update.type === EUpdateType.ProcessedWithMissingFrames ? (
        <BadgeCheckIcon className="h-5 w-5 text-warning" />
      ) : isActive ? (
        <AnimatedIcon
          icon="polygon-icon"
          autoPlay={true}
          loop={true}
          disablePlayOnHover
          className="h-10 w-10 text-primary"
        />
      ) : (
        !isLast && (
          <CheckCircle2Icon
            className={twMerge(
              'h-5 w-5',
              !update.isForecast ? 'text-primary' : 'text-secondary'
            )}
          />
        )
      )}
      {isLast && (
        <CircleCheckBigIcon
          className={twMerge(
            'h-5 w-5',
            !update.isForecast ? 'text-primary' : 'text-secondary'
          )}
        />
      )}
    </div>

    {index % 2 === 0 ? (
      <TimelineEventContent
        update={update}
        additionalClasses={twMerge(
          !isMobile && 'timeline-end',
          isMobile && 'timeline-start'
        )}
      />
    ) : null}

    {!isLast && <hr className={twMerge(!update.isForecast && 'bg-primary')} />}
  </li>
);

interface TimelineEventContentProps {
  update: MediaSequenceUpdateResponse;
  additionalClasses: string;
}

const TimelineEventContent = ({
  update,
  additionalClasses,
}: TimelineEventContentProps) => {
  let tooltip = update.isForecast
    ? 'Ej udført endnu'
    : formatDistanceToNow(update.publishTimeUtc);
  if (update.message) tooltip += `: ${update.message}`;

  return (
    <div className={twMerge('timeline-box', additionalClasses)}>
      <Tooltip tooltip={tooltip}>
        {!update.isForecast ? (
          <span className="text-sm text-gray-500">
            {formatTimestamp(update.publishTimeUtc)}: &nbsp;
          </span>
        ) : null}
        {updateTypeTranslate(update.type)}&nbsp;
        {update.assetMutationType
          ? assetMutationTranslate(update.assetMutationType).toLocaleLowerCase()
          : update.branchMutationType
            ? branchMutationTranslate(
                update.branchMutationType
              ).toLocaleLowerCase()
            : null}
      </Tooltip>
    </div>
  );
};
